// @ts-ignore
import template from './transaction-exact-events.component.html';

import { EExactEventsType, IAdminExactTransactionResultEvent, IEvent } from '../../../models/event.model';
import { IOrder } from '../../../models/order.model';

export const AdminTransactionExactEventsComponent = {
  bindings: {
    events: '<',
    transaction: '<',
  },
  template,
  controller: class AdminTransactionExactEventsController {
    // Bindings
    // @ts-ignore
    events: IEvent[];
    // @ts-ignore
    transaction: IOrder;

    constructor(
      private $filter: ng.IFilterService,
      private $sce: ng.ISCEService,
    ) {
      'ngInject';
    }

    $onChanges(changes: ng.IOnChangesObject): void {
      if (changes.events && changes.events.currentValue) {
        this.events = (changes.events.currentValue).filter((event: IEvent) => {
          return event.event !== EExactEventsType.AUTOMATIC_CREATE_EXACT_TRANSACTION
            && event.event !== EExactEventsType.ADMIN_CREATE_EXACT_TRANSACTION;
        });
      }

      if (changes.transaction && changes.transaction.currentValue) {
        this.transaction = changes.transaction.currentValue;
      }
    }

    isExactTransactionResultEvent(e: IEvent): boolean {
      return e.event === EExactEventsType.ADMIN_EXACT_TRANSACTION_RESULT;
    }

    exactTransactionResultEventResponseStatus(e: IEvent): number {
      const values = (e as IAdminExactTransactionResultEvent).data.values;
      return values && values.response && values.response.status ? values.response.status : 0;
    }

    eventIcon(e: IEvent): string {
      const resultEvent = this.isExactTransactionResultEvent(e);
      const authomaticEvent = e.event === EExactEventsType.AUTOMATIC_CREATE_EXACT_TRANSACTION;

      if (authomaticEvent) {
        return '–';
      }

      if (!resultEvent) {
        return '?';
      }

      const status = this.exactTransactionResultEventResponseStatus(e);
      return status >= 200 && status < 300 ? '✔' : '✘';
    }

    eventDescription(e: any) {
      const resultEvent = this.isExactTransactionResultEvent(e);
      const status = this.exactTransactionResultEventResponseStatus(e);
      const successfulStatus = status >= 200 && status < 300;

      const html = [ `<h5 style="margin-top: 0;">${e.event}</h5>` ];

      try {
        if (resultEvent) {
          html.push(`<div>Amount ${Math.abs(e.data.values.request.GeneralJournalEntryLines[0].AmountFC)}€</div>`);

          if (successfulStatus) {
            html.push(`<div>Period ${e.data.values.response.body.d.FinancialYear}/${e.data.values.response.body.d.FinancialPeriod}</div>`);
          } else {
            html.push(`<div>Period ${e.data.values.request.FinancialYear}/${e.data.values.request.FinancialPeriod}</div>`);
          }

          html.push(`<div>Response status ${e.data.values.response.status}</div>`);
        }
      } catch (e) {
        // not empty
      }

      html.push(`<div>on ${this.$filter('date')(e.createdAt, 'medium')}</div>`);
      return this.$sce.trustAsHtml(html.join(''));
    }
  }
};
