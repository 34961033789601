// @ts-ignore
import template from './team-feed-project-trust-set.component.html';

import { IProjectTrustSetEvent } from '../../../../../models/event.model';

export const TeamFeedProjectTrustSetComponent: ng.IComponentOptions = {
  bindings: {
    item: '<',
  },
  template,
  controller: class TeamFeedProjectTrustSetController {
    // Bindings
    // @ts-ignore
    item: IProjectTrustSetEvent = this.item;

    get createdAt(): Date {
      return this.item.createdAt;
    }

    get initiatingUser() {
      return this.item.data.initiatingUser;
    }

    get project() {
      return this.item.data.project;
    }

    get isTrusted() {
      return this.item.data.values.isTrusted;
    }
  }
};
