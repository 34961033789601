import angular from 'angular';
import { MyAccountReferralController } from './referral-ctrl';
import MyAccountReferralTemplate from './referral.html';

export default angular.module('app.accounts.my.referral', [
  require('@uirouter/angularjs').default,
  require('modules/common/url.module').default,
])
  .config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state('accounts.my.referral', {
      url: '/referral',
      views: {
        account: {
          template: MyAccountReferralTemplate,
          controller: 'MyAccountReferralCtrl'
        }
      },
      data: {
        title: 'Referral program'
      },
      resolve: {
        referrals: function (Account, UserManager) {
          return Account.getReferrals({ id: UserManager.data._id }).$promise;
        },
        teams: function ($window, Team) {
          return Team.query({ stats: true, cache: $window.localStorage.resourceCacheBuster }).$promise;
        },
        referralTransactions: function (Order) {
          return Order.query({ filter: 'referrals' }).$promise;
        }
      }
    });
  })
  .controller('MyAccountReferralCtrl', MyAccountReferralController)
  .name;

