import Dinero, { Currency, RoundingMode } from 'dinero.js';
import { IMoney } from 'models/money.model';
import { CONSTANTS } from '../../constants';
import { isObject } from 'underscore';

export class Money {
  static create(amount: number, currency: Currency = CONSTANTS.CURRENCY as Currency) {
    return Dinero({
      amount: Money.toInt(amount),
      currency
    });
  }

  static createFromEuros(amount: number) {
    return Money.create(Money.toInt(amount) * 100);
  }

  static isGreaterThanZero(money: IMoney) {
    return money && money.greaterThan(Money.zero(money.getCurrency() as Currency));
  }

  static isZeroOrNegative(money: IMoney) {
    return money.isNegative() || money.isZero();
  }

  static toRoundedUnit(money: IMoney, digits = 0) {
    return money.toRoundedUnit(digits, CONSTANTS.MONEY_ROUNDING_MODE as RoundingMode);
  }

  static toInt(input: number | string) {
    // @ts-ignore
    return parseInt(input, 10) || 0;
  }

  static zero(currency?: Currency) {
    return Money.create(0, currency);
  }

  static instanceOfMoney(value: any): value is Dinero.Dinero {
    return isObject(value) && 'isZero' in value;
  }
}
