import angular from 'angular';

export const SwitcherComponent = {
  bindings: {
    ngModel: '<'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  controller: class SwitcherController {
    constructor($element, $scope) {
      'ngInject';

      this.$element = $element;
      this.$scope = $scope;
    }

    $postLink() {
      const component = this;
      const options = this.$element.find('[value]');

      options.on('click', function () {
        const option = angular.element(this);

        option.addClass('__active')
          .siblings().removeClass('__active');

        component.$scope.$apply(() => {
          component.ngModelCtrl.$setViewValue(option.attr('value'));
        });
      });

      this.ngModelCtrl.$render = () => {
        options.removeClass('__active')
          .filter('[value="' + this.ngModelCtrl.$viewValue + '"]').addClass('__active');
      };
    }

    ngModelChange() {
      this.ngModelCtrl.$setViewValue(this.ngModel);
    }
  }
};
