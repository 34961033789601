import _ from 'underscore';
import angular from 'angular';
import template from './manage-project-delegates.html';

export default angular.module('app.projects.manage-delegates', [
  require('modules/resources/account').default,
  'app.resources.project',
  'app.ui.management-dropdown'
])

  .directive('manageProjectDelegates', function () {
    return {
      template,
      scope: {
        project: '=manageProjectDelegates'
      },
      controller: function ($scope, Account, Project) {
        'ngInject';

        Account.queryActive(accounts => {
          $scope.allUsers = accounts.data;
        });

        $scope.getAssistant = function (assistantId) {
          return _($scope.allUsers).findWhere({ _id: assistantId });
        };

        $scope.sortAssistants = function (assistant) {
          var item = $scope.getAssistant(assistant);
          return item ? item.name : '';
        };

        $scope.sortAssistantCandidates = function (candidate) {
          if ($scope.isAssistant(candidate._id)) {
            // Selected assistants go first
            return '_' + candidate.name;
          }
          return candidate.name;
        };

        $scope.isAssistant = function (userId) {
          return _($scope.project.assistants).contains(userId);
        };

        $scope.toggleAssistant = function (userId) {
          if ($scope.isAssistant(userId)) {
            $scope.project.assistants = _($scope.project.assistants).without(userId);
          } else {
            $scope.project.assistants.push(userId);
          }

          Project.update(_($scope.project).pick('_id', 'assistants'));
        };

        $scope.$watch('delegateFilter', findUsers);

        function findUsers() {
          Account.queryActive({ filter: $scope.delegateFilter }).$promise
            .then(accounts => $scope.allUsers = accounts.data);
        }
      }
    };
  })
  .name;
