import angular from 'angular';

import AuthenticationModule from 'modules/authentication';
import CapacityModule from 'modules/capacity';
import MoneyModule from 'modules/money';
import NotificationsModule from 'modules/notifications';
import OrderResourceModule from 'modules/resources/order';
import SegmentModule from 'modules/common/segment.service';
import TeamResourceModule from 'modules/resources/team';
import UserManagerModule from 'modules/user-manager';
import { AppHeaderComponent } from './app-header.component';
import { AvatarModule } from 'modules/ui/avatar';
import { BuyCreditsModalModule } from '../buy-credits-modal';
import { SendInvoiceModalModule } from '../send-invoice-modal';

export default angular.module('app.header', [
  AuthenticationModule,
  AvatarModule,
  BuyCreditsModalModule,
  CapacityModule,
  MoneyModule,
  NotificationsModule,
  OrderResourceModule,
  SegmentModule,
  SendInvoiceModalModule,
  TeamResourceModule,
  UserManagerModule,
])
  .component('appHeader', AppHeaderComponent)
  .name;
