import angular from 'angular';

export default angular.module('app.common.directives', [])

  .directive('autofocus', function ($timeout) {
    'ngInject';

    return function (scope, element) {
      $timeout(function () {
        element.focus();
      }, 50);
    };
  })

  .directive('animateOnce', ($timeout, $rootScope) => {
    'ngInject';

    return {
      scope: false,
      link: (scope, element, attrs) => {
        const animateFn = () => {
          element.addClass(attrs.animateOnce);

          $timeout(() => {
            element.removeClass(attrs.animateOnce);
          }, 1000);
        };

        const unsubscribe = $rootScope.$on(attrs.animateOnceOn, animateFn);

        scope.$on('$destroy', unsubscribe);
      }
    };
  })

  .directive('customMessage', function () {
    return {
      restrict: 'A',
      require: ['^form', '^ngModel'],
      link: function (scope, element, attrs, ctrls) {
        var name = attrs.name;

        if (!name) throw new Error('customMessage directive requires a named input field');

        var formFieldCtrl = ctrls[0][name];
        var modelCtrl = ctrls[1];

        scope.$watch(
          () => modelCtrl.$viewValue,
          () => {
            var error = formFieldCtrl.$invalid ? attrs.customMessage : '';
            element[0].setCustomValidity(error);
          }
        );
      }
    };
  })

  .directive('ngClickSelect', function () {
    return {
      restrict: 'A',
      link: function (scope, element) {
        element.bind('click', function () {
          this.select();
        });

        setTimeout(function () {
          element.select();
        }, 0);
      }
    };
  })

  // Directive creates an isolated scope (for example, to make ng-init safe)
  .directive('isolatedScope', function () {
    return {
      scope: true
    };
  })

  /**
   * Binds filter value with more intelligent watch which fires only when actual value change happened,
   * in contrast to filters which fire on each digest.
   *
   * @example:
   *  <td filter="date" filter-value="{{ result.dueDate }}" filter-param="mediumDate" filter-default="-"></td>
   */
  .directive('filter', function ($filter, $log) {
    'ngInject';

    return {
      restrict: 'A',
      link: function (scope, elem, attrs) {
        if (!attrs.hasOwnProperty('filter')) {
          return $log.error('Filter name expected, got: ' + attrs.filter);
        }

        if (!attrs.hasOwnProperty('filterValue')) {
          return $log.error('Filter value expected, got: ' + attrs.filterValue);
        }

        var filter = $filter(attrs.filter);

        attrs.$observe('filterValue', function (newValue) {
          elem.html(filter(newValue, attrs.filterParam) || attrs.filterDefault);
        });
      }
    };
  })

  // keep it the last line
  .name;
