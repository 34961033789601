import angular from 'angular';
import moment from 'moment';
import template from './my-deliveries-project.component.html';
import { Money } from '../money/money';

export const MyDeliveriesProjectComponent = {
  bindings: {
    project: '<',
    team: '<',
    onLoaded: '&',
    onReleased: '&'
  },
  template,
  controller: class MyDeliveriesProjectController {
    constructor($animate, $element, SendSalesInvoiceModalService, CONFIG, EventEmitter, growl, Project, UserManager) {
      'ngInject';

      this.$animate = $animate;
      this.$element = $element;
      this.CONFIG = CONFIG;
      this.EventEmitter = EventEmitter;
      this.growl = growl;
      this.ProjectResource = Project;
      this.UserManager = UserManager;
      this.SendSalesInvoiceModalService = SendSalesInvoiceModalService;

      this.currentWeek = parseInt(moment().format(this.CONFIG.YEAR_WEEK_FORMAT), 10);
      this.showAcceptedWeeks = false;
      this.showDrawer = false;
      this.page = 0;
      this.perPage = 10;
      this.zero = Money.zero();
      this.isSent = false;

      this.filterAcceptedWeeks = this.filterAcceptedWeeks.bind(this);
      this.filterActiveWeeks = this.filterActiveWeeks.bind(this);
    }

    $onChanges(changes) {
      if (changes.team) {
        this.team = angular.copy(changes.team.currentValue);
      }

      if (changes.project) {
        this.project = angular.copy(changes.project.currentValue);
      }
    }

    $onInit() {
      const weeks = this.project.weeks;
      this.activeWeeks = weeks.filter(this.filterActiveWeeks);
      // Raw list of acceptedWeeks
      this._acceptedWeeks = weeks.filter(this.filterAcceptedWeeks);
      // Paginated list of acceptedWeeks
      this.acceptedWeeks = [];
      this.acceptedWeeksLength = this._acceptedWeeks.length;
      this.reachedEnd = false;
      this.showCurrentPage();
    }

    $postLink() {
      this.$animate.on('enter', this.$element, (element, phase) => {
        // cool we detected an enter animation within the container
        if (phase === 'close') {
          this.onLoaded(this.EventEmitter({ $element: this.$element }));
        }
      });
    }

    get isUserGuardian() {
      return this.UserManager.data._id === this.project.guardian;
    }

    filterAcceptedWeeks(week) {
      if (week.id === this.currentWeek) {
        // do not show current week
        return false;
      }

      return week.booking.isAccepted;
    }

    filterActiveWeeks(week) {
      if (week.id === this.currentWeek) {
        // always show current week
        return true;
      }

      return !week.booking.isAccepted;
    }

    onProjectReleased() {
      this.onReleased(this.EventEmitter({
        project: this.project._id
      }));
    }

    toggleDrawer() {
      this.showDrawer = !this.showDrawer;
    }

    showMoreAcceptedWeeks() {
      if (!this.showAcceptedWeeks || this.reachedEnd) {
        return;
      }

      this.showCurrentPage();
    }

    showCurrentPage() {
      const weeksPage = this._acceptedWeeks.slice(this.page * this.perPage, (this.page + 1) * this.perPage);
      this.acceptedWeeks = this.acceptedWeeks.concat(weeksPage);
      this.page += 1;

      if (this.acceptedWeeksLength <= (this.page + 1) * this.perPage) {
        this.reachedEnd = true;
      }
    }

    toggleTrusted() {
      if (this.toggleTrustedIsInProgress) {
        return;
      }

      if (this.project.isTrusted && !confirm(`Do you want to promote ${this.project.name} to trusted?`)) {
        this.project.isTrusted = false;
        return;
      }

      this.toggleTrustedIsInProgress = true;

      this.ProjectResource.setTrusted({ id: this.project._id }, { trusted: this.project.isTrusted }).$promise
        .then(() => {
          let message;
          if (this.project.isTrusted) {
            message = `${this.project.name} of ${this.project.owner.name} was promoted to trusted.`;
          } else {
            message = `${this.project.name} of ${this.project.owner.name} trust was halt.`;
          }
          this.growl.success(message);
        })
        .catch(() => {
          this.project.isTrusted = !this.project.isTrusted;
        })
        .finally(() => this.toggleTrustedIsInProgress = false);
    }

    showSendSalesInvoiceModal() {
      this.SendSalesInvoiceModalService
        .open(this.project)
        .then((isSent) => this.isSent = isSent);
    }
  }
};
