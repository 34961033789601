import angular from 'angular';
import { MetricsEndOfYearBalancesComponent } from './end-of-year-balances.component';

export const MetricsEndOfYearBalancesModule = angular
  .module('app.admin-panel.metrics.end-of-year-balances', [])
  .config(($stateProvider) => {
    'ngInject';

    $stateProvider
      .state('admin-panel.metrics.end-of-year-balances', {
        url: '/end-of-year-balances',
        views: {
          metric: {
            component: 'metricsEndOfYearBalances'
          }
        },
        permissions: ['admin'],
        data: {
          title: 'End-of-year balances | Metrics | Admin panel'
        }
      });
  })
  .component('metricsEndOfYearBalances', MetricsEndOfYearBalancesComponent)
  .name;
