import moment from 'moment';

export const MonthFilter = () => {
  'ngInject';

  return monthIndex => {
    if (monthIndex) {
      return moment(monthIndex, 'M').format('MMMM');
    } else {
      return '';
    }
  };
};
