import _ from 'underscore';
import angular from 'angular';
import moment from 'moment';
import template from './my-deliveries.component.html';
import { Money} from 'modules/money/money';

export const MyDeliveriesComponent = {
  bindings: {
    data: '<',
  },
  template,
  controller: class MyDeliveriesController {
    constructor($element, $timeout, CONFIG) {
      'ngInject';

      this.$element = $element;
      this.$timeout = $timeout;
      this.CONFIG = CONFIG;

      this.currentWeek = parseInt(moment().format(this.CONFIG.YEAR_WEEK_FORMAT), 10);
    }

    $onChanges(changes) {
      if (changes.data) {
        const value = changes.data.currentValue;
        if (value.bookingsPerProject) {
          this.seedBookings(value.bookingsPerProject);
        }

        if (value.projects) {
          this.seedProjects(value.projects);
        }

        if (value.teams) {
          this.teams = angular.copy(value.teams);
        }
      }
    }

    $postLink() {
      this.$element.addClass('my-deliveries');
    }

    getTeam(teamId) {
      return _(this.teams).findWhere({ _id: teamId });
    }

    createEmptyBooking(weekId) {
      return {
        week: weekId,
        isAccepted: false,
        isSprintFinished: false,
        type: 'booking',
        value: Money.zero()
      };
    }

    createWeek(id, booking) {
      return { id, booking };
    }

    // Prepares summary information for next weeks
    createNextWeekSummary(id, booking) {
      const booked = booking && booking.value ? booking.value : Money.zero();
      const nextWeekId = moment().add(1, 'week').format(this.CONFIG.YEAR_WEEK_FORMAT);
      const isNextWeek = parseInt(id, 10) === parseInt(nextWeekId, 10);

      return { id, booked, isNextWeek, operations: [booking] };
    }

    initProjectUI(project, bookings) {
      let acceptedBookingsNumber = 0;
      let nextWeeksSummary = [];
      let openBookingsNumber = 0;
      let totalEarned = Money.zero();
      let weeks = [];

      bookings.forEach(booking => {
        if (booking.week <= this.currentWeek) {
          weeks.push(this.createWeek(booking.week, booking));
          if (booking.isAccepted) {
            acceptedBookingsNumber += 1;
            totalEarned = totalEarned.add(booking.value || Money.zero());
          } else {
            openBookingsNumber += 1;
          }
        } else {
          nextWeeksSummary.push(this.createNextWeekSummary(booking.week, booking));
        }
      });

      let weeksSinceLastBooking = 0;
      if (openBookingsNumber > 0) {
        // Unaccepted weeks
        weeksSinceLastBooking = 0;
      } else if (acceptedBookingsNumber === 0) {
        // Projects w/o bookings yet (new)
        weeksSinceLastBooking = 0;
      } else {
        // Projects with bookings in history
        const lastBookedWeek = weeks[0].id;
        weeksSinceLastBooking = moment().diff(moment(lastBookedWeek, 'GGGGWW'), 'week');
      }

      // Force add current week when it has no operations in it
      const currentWeek = _(weeks).findWhere({ id: this.currentWeek });
      if (!currentWeek) {
        const booking = this.createEmptyBooking(this.currentWeek);
        weeks.unshift(this.createWeek(this.currentWeek, booking));
      }

      return _.extend({}, project, { acceptedBookingsNumber, nextWeeksSummary, openBookingsNumber, totalEarned, weeks, weeksSinceLastBooking });
    }

    // Removes released project from the list
    onProjectReleased(event) {
      this.projects = this.projects.filter((project) => project._id !== event.project);
    }

    seedBookings(bookings) {
      this.bookings = _(angular.copy(bookings)).mapObject(projectBookings => {
        return _(projectBookings).chain()
          .filter(booking => booking.value !== 0)
          .map(booking => {
            booking.value = Money.create(booking.value);
            return booking;
          })
          .value();
      });
    }

    seedProjects(projects) {
      const list = _(angular.copy(projects)).chain()
        .map(project => {
          const bookings = this.bookings[project._id] || [];
          return this.initProjectUI(project, bookings);
        })
        // Sort projects, already sorted by name
        .sortBy(project => -project.weeksSinceLastBooking)
        .sortBy(project => this.bookings[project._id] ? -this.bookings[project._id].length : -100)
        .sortBy(project => -project.nextWeeksSummary.length)
        .sortBy(project => -project.openBookingsNumber)
        .sortBy(project => {
          const week = project.weeks[0];
          return week.id === this.currentWeek && -week.booking.value.getAmount();
        })
        .value();

      this.projects = list.filter(project => {
        return project.weeksSinceLastBooking < 9;
      });

      this.hiddenProjects = list.filter(project => {
        return project.weeksSinceLastBooking > 8;
      });
    }

    toggleProject(project) {
      project.checked = !project.checked;
    }

    /**
     * Fired from inside the project component when the enter animation finishes
     *
     */
    scrollProjectIntoView({ $element }) {
      const wrapperElem = this.$element.get(0);
      const projectElem = $element.get(0);
      const scrollLeft = projectElem.offsetLeft - wrapperElem.clientWidth + projectElem.clientWidth;
      const scrollTop = projectElem.offsetTop - wrapperElem.clientHeight + projectElem.clientHeight;
      this.$element.stop().animate({ scrollLeft, scrollTop }, 300, 'swing');
    }
  }
};
