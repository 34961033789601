import angular from 'angular';

/**
 * Allows to specify behavior when the bottom of an element reaches the bottom of the screen.
 *
 * @param {expression} infinite-scroll - expression to evaluate when the bottom of the element reached the bottom of the screen.
 * @param {string} infinite-scroll-edge - the edge to test the infinite scroll against.
 * @param {string} infinite-scroll-selector - the selector of an element in which scrolling is contained.
 * @param {number} infinite-scroll-threshold - how close the bottom of the element must be to the bottom of the screen to trigger.
 */

// Triggers a callback when element is scrolled
// to the very bottom
export function InfiniteScrollDirective($parse, $timeout, INFINITE_SCROLL_THRESHOLD, ScrollService) {
  'ngInject';

  return function (scope, element, attrs) {
    const fn = $parse(attrs.infiniteScroll);
    const scrollOnWindow = attrs.hasOwnProperty('infiniteScrollOnWindow');
    // To listen to scroll events onto
    const scrollElement = scrollOnWindow ? angular.element(window) : element;
    // To calculate scroll state
    const scrollContainer = scrollOnWindow ? angular.element('html') : element;
    const threshold = parseInt(attrs.infiniteScrollThreshold, 10) || INFINITE_SCROLL_THRESHOLD;

    function listener() {
      $timeout(() => {
        const edge = attrs.infiniteScrollEdge || 'bottom';
        const scrollBottom = ScrollService.getScroll(scrollContainer, edge);
        if (scrollBottom <= threshold) {
          scope.$apply(() => fn(scope));
        }
      });
    }

    scrollElement.on('scroll', listener);

    scope.$applyAsync(listener);
  };
}
