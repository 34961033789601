import _ from 'underscore';

export const ProjectManageController = function ($rootScope, $scope, $state, CapacityService, project, projectModals, projectService, UserManager, SendSalesInvoiceModalService) {
  'ngInject';

  $scope.project = project;

  $scope.showEditProjectModal = projectModals.openAddEditProject;

  $scope.isProjectDelegated = _($scope.project.assistants).some(function (assistantId) {
    return assistantId === UserManager.data._id;
  }) && $scope.project.owner;

  $scope.onProjectReleased = function () {
    $state.go('projects.single.team-matching', { id: $scope.project._id });
  };

  $scope.removeProject = function () {
    var projectId = $scope.project._id;
    projectService.removeProject($scope.project, function () {
      $state.go('projects.single', { id: projectId }, { reload: true });
    });
  };

  $scope.showSendSalesInvoiceModal = function () {
    SendSalesInvoiceModalService
      .open(this.project)
      .then((isSent) => $scope.isSent = isSent);
  };
};
