import angular from 'angular';
import MenuItemTemplate from './menu-item.html';

export default angular.module('app.ui.menu', [
  require('@uirouter/angularjs').default
])

  .directive('menu', function () {
    return {
      replace: true,
      restrict: 'E',
      template: '<ul class="menu" ng-transclude></ul>',
      transclude: true
    };
  })

  .directive('menuI', function ($state, $uiRouterGlobals, $transitions) {
    'ngInject';

    return {
      replace: true,
      restrict: 'E',
      scope: {
        sref: '@',
        trackEvent: '@'
      },
      template: MenuItemTemplate,
      transclude: true,
      link: function (scope) {
        function update() {
          scope.isCurrent = $state.includes(scope.sref);
          scope.isLink = scope.isCurrent ? false : !$state.is(scope.sref);

          // teams menu is leading to two different states based on currently logged in user
          const teams = scope.sref === 'teams.list' && $uiRouterGlobals.current.name === 'teams.visit-card';

          if (teams) {
            scope.isCurrent = true;
            scope.isLink = false;
          }
        }

        update();

        const unsubscribe = $transitions.onSuccess({}, () => update());
        scope.$on('$destroy', unsubscribe);
      }
    };
  })
  .name;
