import * as angular from 'angular';
import { compact, find, findWhere, pick, shuffle } from 'underscore';

// @ts-ignore
import template from './team-visit-card.component.html';

import { IAccount } from 'models/account.model';
import { ITeam, ITeamExpandedTags, ITeamMember } from 'models/team.model';
import { ITag, ITeamResourceService } from 'interfaces';

import { UrlService } from 'modules/common/url.module';
import { UserManagerService } from 'modules/user-manager/user-manager.service';

type teamEditableFields = 'name' | 'description' | 'fullDescription' | 'tags';

export const TeamVisitCardComponent: ng.IComponentOptions = {
  template,
  bindings: {
    allTags: '<',
    team: '<',
  },
  controller: class TeamVisitCardController {
    // Bindings
    // @ts-ignore
    allTags: ITag[] = this.allTags;
    // @ts-ignore
    team: ITeam = this.team;

    canEdit = true;
    isMyTeam = false;
    editMode: { [key: string]: boolean } = {
      description: false,
      fullDescription: false,
      name: false,
      tags: false
    };

    members: ITeamMember[] = [];
    tags: ITag[] = [];
    user: IAccount;

    constructor(
      private $element: ng.IRootElementService,
      private $location: ng.ILocationService,
      private $window: ng.IWindowService,
      private Team: ITeamResourceService,
      private UrlService: UrlService,
      private UserManager: UserManagerService,
    ) {
      'ngInject';
    }

    $onInit(): void {
      this.user = this.UserManager.data;
      this.$element.addClass('team-promo');
      this.isMyTeam = find(this.team.members, (member) => member.user._id === this.user._id);
      this.canEdit = this.isMyTeam;
    }

    $onChanges(changes: ng.IOnChangesObject): void {
      if (changes.team) {
        const team: ITeam = angular.copy(changes.team.currentValue);

        this.members = shuffle(team.members); // todo only 4 are shown
        this.updateTagsFromTeam(team);
      }
    }

    updateTeamField(field: teamEditableFields, value: any) {
      const prevValue: any = this.team[field];
      const nameChanged = field === 'name' && prevValue !== value;
      this.team[field] = value;
      const teamToSave = pick(this.team, '_id', 'name', 'description', 'fullDescription', 'tags');

      this.Team.update(teamToSave).$promise
        .then(() => {
          this.toggleEdit(field, false);
          this.updateTagsFromTeam(this.team);

          if (nameChanged) {
            this.$location.path('/teams/' + this.UrlService.slugify(teamToSave.name));
          }
          this.$window.localStorage.resourceCacheBuster = Date.now();
        })
        .catch(() => {
          // Rollback UI
          this.team[field] = prevValue;
        });
    }

    toggleEdit(field: teamEditableFields, editing: boolean): void {
      for (const i in this.editMode) {
        if (this.editMode.hasOwnProperty(i)) {
          this.editMode[i] = i === field ? editing : false;
        }
      }
    }

    private updateTagsFromTeam(team: ITeamExpandedTags): void {
      this.tags = compact(
        team.tags.map((tag) => {
          // it's needed to have a link here to the original tag for ui-select to work
          return findWhere(this.allTags, { _id: tag._id });
        })
      );
    }
  }
};
