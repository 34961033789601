import template from './notification-history.component.html';

export const NotificationHistoryComponent = {
  bindings: {
    notifications: '<',
    onUpdate: '&'
  },
  template,
  controller: class NotificationHistoryController {
    constructor(EventEmitter) {
      'ngInject';

      this.EventEmitter = EventEmitter;
    }

    propagateUpdate(value) {
      this.onUpdate(this.EventEmitter(value));
    }

    togglePinning($event, notification) {
      if ($event) {
        $event.stopPropagation();
      }

      notification.pinned = !notification.pinned;

      notification.$update(updatedNotification => {
        // Show/hide pinned notifications immediately
        if (updatedNotification.to === 'client') {
          this.notifications.pinnedNotificationToClients = updatedNotification.pinned ? updatedNotification : null;
        } else if (updatedNotification.to === 'team_member') {
          this.notifications.pinnedNotificationToTeamMembers = updatedNotification.pinned ? updatedNotification : null;
        }
      });
    }
  }
};
