// @ts-ignore
import template from './trusted-project-lacking-credits-modal.component.html';

export const TrustedProjectLackingCreditsModalComponent: ng.IComponentOptions = {
  bindings: {
    dismiss: '&',
    close: '&',
  },
  template,
  controller: class TrustedProjectLackingCreditsModalController {

  }
};
