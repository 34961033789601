import angular from 'angular';
import { BtnDeliveryComponent } from './btn-delivery.component';
import { MyDeliveriesProjectWeekComponent } from './my-deliveries-project-week.component';
import { MyDeliveriesProjectComponent } from './my-deliveries-project.component';
import { MyDeliveriesComponent } from './my-deliveries.component';
import { NextWeeksSummaryComponent } from './next-weeks-summary.component';
import { OtherDeliveriesComponent } from './other-deliveries.component';
import FinishSprintModule from './finish-sprint';
import AdminPanelBookingsDeliveriesListTemplate from '../admin-panel/bookings-deliveries/bookings-deliveries-list.html';
import { SendSalesInvoiceModalModule } from '../send-sales-invoice-modal';

export default angular.module('app.my-deliveries', [
  FinishSprintModule,
  SendSalesInvoiceModalModule,
  require('@uirouter/angularjs').default,
  require('app-constants').default,
  require('modules/authentication').default,
  require('modules/money').default,
  require('modules/projects/manage-guardian').default,
  require('modules/projects/manage-team').default,
  require('modules/resources/account').default,
  require('modules/resources/capacity').default,
  require('modules/resources/my-deliveries').default,
  require('modules/resources/project').default,
  require('modules/resources/team').default,
  require('modules/user-manager').default
])
  .config(function ($stateProvider) {
    'ngInject';

    $stateProvider
      .state('my-deliveries', {
        url: '/my-deliveries',
        component: 'myDeliveries',
        permissions: ['team_member', 'hired_gun'],
        data: {
          title: 'My deliveries'
        },
        resolve: {
          data: (MyDeliveries) => MyDeliveries.get().$promise,
        }
      })
      .state('my-deliveries-history', {
        url: '/my-deliveries/history',
        controller: 'AdminPanelBookingsDeliveriesListCtrl',// already defined in the admin-panel
        template: AdminPanelBookingsDeliveriesListTemplate,
        resolve: {
          capacityOperations: Capacity => Capacity.query().$promise
        },
        data: {
          title: 'Bookings and deliveries'
        }
      });
  })
  .component('btnDelivery', BtnDeliveryComponent)
  .component('myDeliveries', MyDeliveriesComponent)
  .component('myDeliveriesProject', MyDeliveriesProjectComponent)
  .component('myDeliveriesProjectWeek', MyDeliveriesProjectWeekComponent)
  .component('nextWeeksSummary', NextWeeksSummaryComponent)
  .component('otherDeliveries', OtherDeliveriesComponent)
  .name;
