// @ts-ignore
import template from './send-invoice-modal.component.html';

import { IAccount, IAccountCompany } from '../../models/account.model';
import { UserManagerService } from '../user-manager/user-manager.service';

enum Form {
  company = 'company',
  invoice = 'invoice',
}

export const SendInvoiceModalComponent: ng.IComponentOptions = {
  bindings: {
    close: '&',
    dismiss: '&'
  },
  template,
  controller: class SendInvoiceModalController implements ng.IController {
    // bindings
    // @ts-ignore
    dismiss: () => void = this.dismiss;
    // @ts-ignore
    close: () => void = this.close;

    account: IAccount;
    form: Form.invoice | Form.company = Form.invoice;

    constructor(
      private $rootScope: ng.IRootScopeService,
      private UserManager: UserManagerService,
    ) {
      'ngInject';

      this.account = this.UserManager.data;
    }

    showInvoiceForm(): void {
      this.form = Form.invoice;
    }

    showCompanyForm(): void {
      this.form = Form.company;
    }

    companyDetailsUpdated($event: { company: IAccountCompany; vatExempt: boolean; }) {
      this.$rootScope.$on('UserManager:set', () => {
        this.showInvoiceForm();
      });

      // Update user manager without side effects
      this.UserManager.set({
        company: $event.company,
        vatExempt: $event.vatExempt
      }, {}, true);
    }
  }
};
