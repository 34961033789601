import _, { reduce } from 'underscore';
import { Money } from 'modules/money/money';
import { ITeamResourceService } from 'interfaces';
import { IMoney } from 'models/money.model';
import {
  ECapacityOperationType,
  ICapacityOperationPopulated
} from 'models/capacity-operations.model';
import { IProject } from 'models/project.model';

// @ts-ignore
import template from './team-feed-weekly-earnings.component.html';

interface IFeedProjectWeek {
  available: IMoney;
  booking: ICapacityOperationPopulated;
  delivered: IMoney;
  deliveries: ICapacityOperationPopulated[];
  project: IProject;
}

interface IFeedUserWeek {
  earned: IMoney;
  operations: ICapacityOperationPopulated[];
  name: string;
}

interface IFeedByProject {
  [key: string]: IFeedProjectWeek;
}

interface IFeedByUser {
  [key: string]: IFeedUserWeek;
}

export const TeamFeedWeeklyEarningsComponent: ng.IComponentOptions = {
  bindings: {
    teamId: '<',
    week: '<',
  },
  template,
  controller: class TeamFeedWeeklyEarningsController {
    // Bindings
    // @ts-ignore
    teamId: string = this.teamId;
    // @ts-ignore
    week: string = this.week;

    loading = true;
    empty = false;

    leftUnbooked: IMoney = Money.zero();
    maxEarning: IMoney = Money.zero();
    totalEarned: IMoney = Money.zero();

    byProject: IFeedByProject = {};
    byUser: IFeedByUser = {};

    constructor(
      private CONFIG: any,
      private Team: ITeamResourceService,
    ) {
      'ngInject';
    }

    $onInit() {
      this.Team.getProjectWeekOperations({
        id: this.teamId,
        week: this.week
      }).$promise
        .then((response) => {
          if (!response.length) {
            this.empty = true;
            return;
          }

          const operations = _(response).map((operation) => {
            const json = operation.toJSON();
            json.value = Money.create(json.value as unknown as number);
            return json;
          });

          this.operationsByProject(operations);
          this.operationsByUser(operations);

          this.leftUnbooked = _(this.byProject).reduce<IMoney>((memo, week) => {
            return memo.add((week.booking.value as IMoney).subtract(week.delivered));
          }, Money.zero());

          this.maxEarning = _(this.byUser).reduce((memo, userData) => {
            return memo.greaterThan(userData.earned) ? memo : userData.earned;
          }, Money.zero());

          this.totalEarned = _(this.byUser).reduce((memo, userData) => {
            return memo.add(userData.earned);
          }, Money.zero());
        })
        .finally(() => this.loading = false);
    }

    operationsByProject(operations: ICapacityOperationPopulated[]) {
      this.byProject = _(operations).chain()
        .groupBy((operation) => operation.project._id)
        .mapObject((operations, projectId) => {
          return this.formProjectWeek(projectId.toString(), operations);
        })
        .values()
        .sortBy((operations) => operations.project.name)
        .value() as unknown as IFeedByProject;
    }

    operationsByUser(operations: ICapacityOperationPopulated[]) {
      this.byUser = _(operations).chain()
        .where({ type: ECapacityOperationType.DELIVERY })
        .groupBy((operation) => operation.user._id)
        .mapObject((operations, userId) => {
          return this.formUserWeek(userId.toString(), operations);
        })
        .values()
        .sortBy((operations) => operations.name)
        .value() as unknown as IFeedByUser;
    }

    formProjectWeek(projectId: string, operations: ICapacityOperationPopulated[]): IFeedProjectWeek {
      const booking = _(operations).find({ type: ECapacityOperationType.BOOKING }) as ICapacityOperationPopulated;
      const deliveries = _(operations).where({ type: ECapacityOperationType.DELIVERY });
      const delivered = _(deliveries).reduce((memo, operation) => memo.add(operation.value as IMoney), Money.zero());
      const project = booking.project;
      const available = (booking.value as IMoney).subtract(delivered);

      return {
        available,
        booking,
        delivered,
        deliveries,
        project,
      };
    }

    formUserWeek(userId: string, operations: ICapacityOperationPopulated[]): IFeedUserWeek {
      const name = operations[0].user.name;
      const earned = reduce(operations, (memo, delivery) => memo.add(delivery.value as IMoney), Money.zero());

      return {
        earned,
        operations,
        name
      };
    }
  }
};
