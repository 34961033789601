import template from './manage-project-team.component.html';

export const ManageProjectTeamComponent = {
  template,
  bindings: {
    project: '<',
    team: '<',
    onRelease: '&'
  },
  controller: class ManageProjectTeamController {
    constructor(Team) {
      'ngInject';

      this.TeamResource = Team;
    }

    releaseProject() {
      const teamId = this.project.assignedTeam._id ? this.project.assignedTeam._id : this.project.assignedTeam;
      this.TeamResource.releaseProject(
        { id: teamId },
        { projectId: this.project._id }
      ).$promise.then(() => {
        this.project.assignedTeam = null;
        this.onRelease();
      });
    }
  }
};
