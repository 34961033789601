import angular from 'angular';

export default angular.module('app.resources.auth', [
  require('angular-resource')
])
  .service('Auth', function ($resource) {
    'ngInject';

    return $resource('/api/auth/:path',
      { path: '@path' },
      {
        getUser: {
          method: 'GET',
          params: {
            path: 'user'
          }
        },
        login: {
          method: 'POST',
          params: {
            path: 'login'
          },
          headers: {
            'X-Recaptcha-Token': config => config.data.$$recaptchaToken
          }
        },
        logout: {
          method: 'GET',
          params: {
            path: 'logout'
          }
        },
        resetPassword: {
          method: 'POST',
          params: {
            path: 'reset-password'
          },
          headers: {
            'X-Recaptcha-Token': config => config.data.$$recaptchaToken
          }
        },
        checkToken: {
          method: 'POST',
          params: {
            path: 'check-token'
          }
        },
        changePassword: {
          method: 'POST',
          params: {
            path: 'change-password'
          },
          headers: {
            'X-Recaptcha-Token': config => config.data.$$recaptchaToken
          }
        },
        changeEmail: {
          method: 'POST',
          params: {
            path: 'change-email'
          },
          headers: {
            'X-Recaptcha-Token': config => config.data.$$recaptchaToken
          }
        }
      }
    );
  })
  .name;
