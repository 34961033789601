import * as angular from 'angular';
import 'angular-growl-v2';

import AuthResourceModule from 'modules/resources/auth';
import RemoveAccountModule from 'modules/accounts/remove';
import UiRouterModule, { StateProvider } from '@uirouter/angularjs';
import UserManagerModule from 'modules/user-manager';

import { MyAccountSettingsComponent } from './settings.component';

export default angular.module('app.accounts.my.settings', [
  'angular-growl',
  AuthResourceModule,
  RemoveAccountModule,
  UiRouterModule,
  UserManagerModule,
])
  .config(($stateProvider: StateProvider) => {
    'ngInject';

    $stateProvider.state('accounts.my.settings', {
      url: '/settings',
      views: {
        account: {
          component: 'myAccountSettings'
        }
      },
      data: {
        title: 'Settings'
      }
    });
  })
  .component('myAccountSettings', MyAccountSettingsComponent)
  .name;
