export const InvoiceableMoneyFilter = $filter => {
  'ngInject';

  return (money, showCurrency = true, showCents = false) => {
    if (money) {
      return $filter('money')(money.percentage(75), showCurrency, showCents);
    } else {
      return '';
    }
  };
};
