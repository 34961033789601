import { StateService } from '@uirouter/angularjs';

import { IAuthResourceService } from 'interfaces';
import { Recaptcha } from '../recaptcha/recaptcha-component.abstract';

// @ts-ignore
import template from './reset-password.component.html';

export const ResetPasswordComponent: ng.IComponentOptions = {
  template,
  bindings: {
    params: '<'
  },
  controller: class ResetPasswordController extends Recaptcha {
    // Bindings
    // @ts-ignore
    params: { token: string; } = this.params;

    confirmPassword = '';
    password = '';
    inProgress = false;

    private token = '';

    constructor(
      private $q: ng.IQService,
      private $state: StateService,
      private $window: ng.IWindowService,
      private Auth: IAuthResourceService,
    ) {
      'ngInject';

      super($q);
    }

    $onInit() {
      this.token = this.params.token;
    }

    changePassword(form: ng.IFormController) {
      if (this.inProgress || form.$invalid) {
        return;
      }

      this.inProgress = true;

      this.recaptcha.execute()
        .then(this.recaptchaVerified)
        .then(($$recaptchaToken: string) => {
          return this.Auth.changePassword({
            token: this.token,
            password: this.password,
            $$recaptchaToken
          }).$promise;
        })
        .then(() => {
          this.$window.location.replace('/');
        })
        .catch(() => this.inProgress = false);
    }
  }
};
