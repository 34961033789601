// @ts-ignore
import template from './project-view.component.html';

import { IAccount } from 'models/account.model';
import { IProject } from 'models/project.model';
import { IProjectResourceService, IProjectService } from 'interfaces';
import { UserManagerService } from 'modules/user-manager/user-manager.service';

export const ProjectViewComponent = {
  bindings: {
    project: '<',
  },
  template,
  controller: class ProjectViewController {
    // Bindings
    // @ts-ignore
    project: IProject = this.project;

    contactPerson: Partial<IAccount> = null;
    restoreProject: (project: IProject) => void;
    user: IAccount;

    emailBody = '';
    emailSubject = '';
    phone = '';
    whatsappMessage = '';

    constructor(
      private Project: IProjectResourceService,
      private projectService: IProjectService,
      private UserManager: UserManagerService,
    ) {
      'ngInject';

      this.restoreProject = this.projectService.restoreProject;
      this.user = this.UserManager.data;
    }

    $onInit(): void {
      this.Project.getContactPerson({ id: this.project._id }).$promise
        .then((account) => {
          const projectLink = `https://app.squads.com/projects/${this.project._id}`;
          this.contactPerson = account;
          this.phone = account.phone ? account.phone.raw.replace('+', '') : '';
          const starter = encodeURIComponent(`Hi ${account.name}. I have a question about Squads project ${this.project.name} ${projectLink}`);
          this.emailBody = this.whatsappMessage = starter;
          this.emailSubject = `A question about Squads project ${this.project.name}`;
        });
    }
  }
};
