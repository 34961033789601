import angular from 'angular';

import AuthenticationModule from 'modules/authentication';
import ConstantsModule from 'app-constants';
import MoneyModule from 'modules/money';
import OrderResourceModule from 'modules/resources/order';
import UserManagerModule from 'modules/user-manager';
import UiRouterModule from '@uirouter/angularjs';
import { OrderDetailsComponent } from './order-details.component';

export default angular.module('app.order', [
  AuthenticationModule,
  ConstantsModule,
  MoneyModule,
  OrderResourceModule,
  UiRouterModule,
  UserManagerModule
])
  .config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state('order', {
      url: '/order/:id',
      resolve: {
        order: (Order, $transition$) => {
          return Order.get({ id: $transition$.params().id }).$promise;
        }
      },
      component: 'orderDetails'
    });
  })
  .component('orderDetails', OrderDetailsComponent)
  .name;
