import { IModalInstanceService } from 'interfaces';
import { IProject } from '../../models/project.model';

export class SendSalesInvoiceModalService {
  constructor(
    private $uibModal: IModalInstanceService,
  ) {
    'ngInject';
  }

  open(project: IProject) {
    return this.$uibModal.open({
      component: 'sendSalesInvoiceModal',
      size: 'small',
      resolve: {
        project: () => project
      }
    }).result;
  }
}
