import * as angular from 'angular';
import UiRouterModule, { StateProvider } from '@uirouter/angularjs';

import AccountResourceModule from 'modules/resources/account';
import IntegrationResourceModule from 'modules/resources/integration';
import UserManagerModule from 'modules/user-manager';

import { ISQStateDeclaration } from 'interfaces';
import { AccountIntegrationsComponent } from './account-integrations.component';
import { ExactIntegrationComponent } from './exact-integration.component';

export default angular.module('app.admin-panel.accounts.integrations', [
  AccountResourceModule,
  IntegrationResourceModule,
  UiRouterModule,
  UserManagerModule
])
  .config(($stateProvider: StateProvider) => {
    'ngInject';

    $stateProvider
      .state('admin-panel.account.integrations', {
        url: '/integrations',
        views: {
          account: {
            component: 'accountIntegrations'
          }
        },
        permissions: ['admin'],
        data: {
          title: 'Account Integrations'
        }
      } as ISQStateDeclaration);
  })
  .component('accountIntegrations', AccountIntegrationsComponent)
  .component('exactIntegration', ExactIntegrationComponent)
  .name;
