// @ts-ignore
import template from './transaction-status-callout.component.html';
import { IOrderResourceService } from '../../../interfaces';
import { CapacityService } from '../../capacity/capacity.service';
import { EOrderStatus, IOrder } from '../../../models/order.model';

export const TransactionStatusCalloutComponent: ng.IComponentOptions = {
  template,
  bindings: {
    transaction: '<',
    successMessage: '@',
    pendingMessage: '@',
    onStatusChange: '&',
  },
  controller: class TransactionStatusCalloutController implements ng.IController {
    // Bindings
    // @ts-ignore
    transaction: IOrder = this.transaction;
    // @ts-ignore
    successMessage: string = this.successMessage;
    // @ts-ignore
    pendingMessage: string = this.pendingMessage;
    // @ts-ignore
    onStatusChange: ($event: { status: EOrderStatus }) => void;

    updateIn = 10; // Seconds
    fetchOrderInterval: any;
    updateInInterval: any;

    constructor(
      private Order: IOrderResourceService,
      private CapacityService: CapacityService,
      private $interval: ng.IIntervalService,
    ) {
      'ngInject';
    }

    $onInit() {
      this.successMessage = this.successMessage || 'New credits have been added to your account. You can start booking the team now.';
      this.pendingMessage = this.pendingMessage || 'Your payment has been sent, we will email you when it is processed.';

      if (this.transaction && this.transaction.status === 'pending') {
        this.updateIn = 10;
        this.fetchOrderInterval = this.$interval(() => this.refetchOrder(), 10000);
        this.updateInInterval = this.$interval(() => this.updateIn--, 1000);
      }
    }

    // Clear the interval when the component is destroyed
    $onDestroy() {
      if (this.fetchOrderInterval) {
        this.$interval.cancel(this.fetchOrderInterval);
      }
      if (this.updateInInterval) {
        this.$interval.cancel(this.updateInInterval);
      }
    }

    refetchOrder() {
      this.Order.get({ id: this.transaction._id }, (response: IOrder) => {
        this.transaction = response;

        if (this.transaction.status !== 'pending') {
          this.onStatusChange({ status: this.transaction.status });
          this.$interval.cancel(this.fetchOrderInterval);
          this.$interval.cancel(this.updateInInterval);
          this.CapacityService.sync();
        } else {
          this.updateIn = 10;
        }
      });
    }
  }
};
