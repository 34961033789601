import angular from 'angular';
import { ProjectsTeamMatchingController } from './projects-team-matching-ctrl';
import template from './projects-team-matching.html';

export default angular.module('app.projects.team-matching', [
  require('@uirouter/angularjs').default,
  require('app-constants').default,
  require('modules/projects/manage-delegates').default,
  require('modules/projects/modals').default,
  require('modules/projects/project-team-matching-service').default,
  require('modules/resources/account').default,
  require('modules/resources/project').default,
  require('modules/resources/team').default,
  require('modules/teams/modals').default,
  require('modules/teams/team-card').default,
  require('modules/common/ui-bootstrap-modal-2.5.6').default,
  require('modules/user-manager').default,
])
  .config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state('projects.single.team-matching', {
      controller: 'ProjectsTeamMatchingController',
      template,
      // project inherited from parent state
      data: {
        title: 'Teams for your project'
      }
    });
  })
  .controller('ProjectsTeamMatchingController', ProjectsTeamMatchingController)
  .name;
