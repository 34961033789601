import * as angular from 'angular';
import UiRouter, { StateProvider } from '@uirouter/angularjs';

import { CompaniesListComponent } from './companies-list.component';
import { ISQStateDeclaration } from 'interfaces';

export default angular.module('app.admin-panel.companies', [
  UiRouter
])
  .config(($stateProvider: StateProvider) => {
    'ngInject';

    $stateProvider.state('admin-panel.companies', {
      url: '/companies',
      views: {
        'admin-panel': {
          component: 'adminPanelCompaniesList',
        }
      },
      permissions: ['admin'],
      data: {
        title: 'Companies'
      }
    } as ISQStateDeclaration);
  })
  .component('adminPanelCompaniesList', CompaniesListComponent)
  .name;
