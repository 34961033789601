import angular from 'angular';

export default angular.module('app.common.page-meta', [])
  .service('pageMeta', function ($rootScope) {
    'ngInject';

    this.setDescription = function (description) {
      $rootScope.pageDescription = description;
    };
  })
  .name;
