import angular from 'angular';
import 'angular-growl-v2';
import { InternationalPhoneModule } from 'modules/international-phone';
import { MyAccountProfileController } from './profile-ctrl';
import MyAccountProfileTemplate from './profile.html';

export default angular.module('app.accounts.my.profile', [
  InternationalPhoneModule,
  require('@uirouter/angularjs').default,
  require('modules/resources/account').default,
  'angular-growl'
])
  .config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state('accounts.my.profile', {
      url: '/profile',
      views: {
        account: {
          template: MyAccountProfileTemplate,
          controller: 'MyAccountProfileCtrl'
        }
      },
      data: {
        title: 'Profile'
      }
    });
  })
  .controller('MyAccountProfileCtrl', MyAccountProfileController)
  .name;

