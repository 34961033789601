import angular from 'angular';
import { TeamCardComponent } from './team-card.component';

export default angular.module('app.teams.team-card', [
  require('@uirouter/angularjs').default,
  require('modules/common/url.module').default,
  require('modules/teams/modals').default,
  require('modules/user-manager').default
])
  .component('teamCard', TeamCardComponent)
  .name;
