export enum EWeekRatings {
  QualityOfPresentation = 'Quality of presentation',
  MeetsExpectations = 'Meets expectations',
  Collaboration = 'Collaboration'
}

export type IProjectWeekRatings = {
  [key in EWeekRatings]: number;
};

export interface IProjectWeek {
  deliveryDescription: string;
  ratings: IProjectWeekRatings;
}
