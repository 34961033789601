import _ from 'underscore';
import angular from 'angular';

export const AddEditTribeModalController = function ($scope, $state, $window, Account, Tribe, tribe) {
  'ngInject';
  /**
   * Init models
   */

  $scope.tribe = tribe;

  /**
   * Methods
   */

  $scope.addTribe = function () {
    var tribeToPost = angular.copy($scope.tribe);
    tribeToPost.creator = $scope.tribe.creator._id;

    Tribe.save(tribeToPost, function () {
      $scope.$close($scope.tribe);
    });
  };

  $scope.saveTribe = function () {
    var tribeToSave = deflateTeam($scope.team);

    tribeToSave.$update(function (updatedTeam) {
      $scope.$close(updatedTeam);
    });
  };

  $scope.findUser = function (name) {
    return Account.queryActive({ filter: name }).$promise.then(accounts => accounts.data);
  };

  function deflateTeam(team) {
    var deflated = angular.copy(team);

    _(deflated.members).each(function (member) {
      member.user = member.user._id;
    });

    deflated.tribe = deflated.tribe._id;

    return deflated;
  }
};
