import { find } from 'underscore';
import { Transition } from '@uirouter/angularjs';

import { ITag } from 'interfaces';
import { ITeam } from 'models/team.model';
import { UrlService } from 'modules/common/url.module';

// @ts-ignore
import template from './teams-visit-cards-list.component.html';

export const TeamsVisitCardsListComponent: ng.IComponentOptions = {
  template,
  bindings: {
    allTags: '<',
    teams: '<',
    $transition$: '<'
  },
  controller: class TeamsVisitCardsListController {
    // Bindings
    // @ts-ignore
    allTags: ITag[] = this.allTags;
    // @ts-ignore
    teams: ITeam[] = this.teams;
    // @ts-ignore
    $transition$: Transition = this.$transition$;

    focusedTeam: ITeam = null;
    previousTeam: ITeam = null;
    nextTeam: ITeam = null;

    focusedTeamIndex = 0;

    constructor(
      private $location: ng.ILocationService,
      private pageMeta: any,
      private UrlService: UrlService,
    ) {
      'ngInject';
    }

    $onInit() {
      const teamName = this.UrlService.slugify(this.$transition$.params().name);
      let focusedTeam = find(this.teams, (team) => teamName === this.UrlService.slugify(team.name));
      if (!focusedTeam) {
        focusedTeam = this.teams[0];
      }
      this.focusTeam(focusedTeam);
    }

    focusTeam(focusedTeam: ITeam) {
      this.focusedTeamIndex = this.teams.indexOf(focusedTeam);
      this.pageMeta.setDescription(focusedTeam.name + ' – ' + focusedTeam.description);

      this.focusedTeam = this.teams[this.focusedTeamIndex];
      this.previousTeam = this.teams[this.focusedTeamIndex - 1];
      this.nextTeam = this.teams[this.focusedTeamIndex + 1];

      this.$location.path('/teams/' + this.UrlService.slugify(this.teams[this.focusedTeamIndex].name));
    }
  }
};
