import angular from 'angular';
import moment from 'moment';
import template from './project-planner-budget.component.html';
import { Money } from 'modules/money/money';

export const ProjectPlannerBudgetComponent = {
  bindings: {
    onUpdate: '&',
    project: '<',
    week: '<'
  },
  template,
  controller: class ProjectPlannerBudgetController {
    constructor($filter, $rootScope, CapacityService, CONFIG, EventEmitter) {
      'ngInject';

      this.$filter = $filter;
      this.$rootScope = $rootScope;
      this.CapacityService = CapacityService;
      this.CONFIG = CONFIG;
      this.EventEmitter = EventEmitter;

      this.currentWeek = moment.utc().format(this.CONFIG.YEAR_WEEK_FORMAT);
    }

    $onChanges(changes) {
      if (changes.week) {
        this.week = angular.copy(changes.week.currentValue);
        // format to euro number
        this.booked = Money.toRoundedUnit(this.week.booked);
        this.delivered = Money.toRoundedUnit(this.week.delivered);
      }

      if (changes.project) {
        this.project = angular.copy(changes.project.currentValue);
      }
    }

    get canBeCancelled() {
      const isPast = this.week.id < this.currentWeek;
      const isAccepted = this.week.isDemoAccepted;
      const hasDelivery = this.delivered > 0;
      const hasBooking = this.booked > 0;

      return !isPast && !isAccepted && !hasDelivery && hasBooking;
    }

    /**
     * Returns maximum allowed booking amount for the week
     */
    get max() {
      const balance = (this.CapacityService.data && this.CapacityService.data.balance) || Money.create(0);
      const bookedServer = this.week.bookedServer;
      const availableForWeek = balance.add(bookedServer);
      const isTrusted = this.project.isTrusted;

      return isTrusted ? Money.zero() : availableForWeek;
    }

    /**
     * Returns a number to pass to ng-max
     */
    get formattedMax() {
      if (this.max.isZero()) {
        // If max is happened to be 0, return nothing so client can book anything
        return '';
      } else {
        return Money.toRoundedUnit(this.max);
      }
    }

    propagateUpdate() {
      if (this.form.$invalid) {
        return;
      }

      this.onUpdate(this.EventEmitter({
        value: Money.createFromEuros(this.booked)
      }));
    }

    /**
     * Input uses custom-message directive for validation messages,
     * which works with table layout, while standard tooltip can't be rendered there.
     * This function returns text for that custom-message
     * @param field {NgFormControl}
     * @returns {string}
     */
    errorMessage(field) {
      let message = '';

      if (!field || !field.$error) {
        return message;
      }

      const error = field.$error;
      const value = parseInt(field.$viewValue, 10);
      const valueMoney = Money.createFromEuros(value);
      const isTrusted = this.project.isTrusted;

      if (error.max) {
        if (!isTrusted && valueMoney.greaterThan(this.CapacityService.data.balance)) {
          message = 'Insufficient credits';
          this.$rootScope.$emit('animate-credits');
        }
      } else if (error.maxlength) {
        message = 'Number is too big';
      } else if (error.min) {
        if (value < 0) {
          message = 'Type a positive number';
        } else if (value < this.delivered) {
          const deliveredMoney = Money.createFromEuros(this.delivered);
          message = `Team has already delivered ${this.$filter('money')(deliveredMoney)}`;
        }
      } else if (error.number) {
        message = 'Not a number';
      }

      return message;
    }

    remove(event) {
      event.stopPropagation();

      if (!this.canBeCancelled) {
        return;
      }

      this.onUpdate(this.EventEmitter({
        value: Money.zero()
      }));
    }
  }
};
