import { sortBy } from 'underscore';
import template from './accounts.component.html';

export const AdminPanelAccountsComponent = {
  template,
  controller: class AdminPanelAccountsController {
    constructor(Account) {
      'ngInject';

      this.AccountResource = Account;
    }

    $onInit() {
      this.loading = false;
      this.pageSizes = [50, 100, 150];
      this.perPage = this.pageSizes[0];

      this.filter = {
        option: 'all', // all, requestedRemoval
        text: ''
      };
    }

    fetchAccounts(page = 1, perPage = this.perPage) {
      if (this.loading) {
        return;
      }

      this.perPage = perPage;
      this.loading = true;

      this.AccountResource.query({ page, perPage,
        filter: this.filter.text,
        requestedRemoval: this.filter.option === 'requestedRemoval'
      }).$promise
        .then(accounts => {
          this.accounts = sortBy(accounts.data, 'name');
          this.pages = accounts.pages;
        })
        .finally(() => this.loading = false);
    }
  }
};
