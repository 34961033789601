import * as angular from 'angular';
import UiRouter, { StateProvider, Transition } from '@uirouter/angularjs';

import { AdminProjectsComponent } from './admin-projects.component';
import { IProjectResourceService, ISQStateDeclaration } from 'interfaces';

import ProjectResourceModule from 'modules/resources/project';

export default angular.module('app.admin-panel.projects', [
  ProjectResourceModule,
  UiRouter,
])
  .config(($stateProvider: StateProvider) => {
    'ngInject';

    $stateProvider.state('admin-panel.projects', {
      url: '/projects?query',
      resolve: {
        projects: (Project: IProjectResourceService) => {
          return Project.queryAll().$promise;
        },
        queryParam: ($transition$: Transition) => {
          return $transition$.params().query;
        }
      },
      views: {
        'admin-panel': 'adminProjects'
      },
      permissions: ['admin'],
      data: {
        title: 'Projects'
      }
    } as ISQStateDeclaration);
  })
  .component('adminProjects', AdminProjectsComponent)
  .name;
