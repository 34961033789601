import { noop } from 'underscore';
import template from './app-header.component.html';
import { Money } from 'modules/money/money';

export const AppHeaderComponent = {
  template,
  controller: class AppHeaderController {
    constructor($rootScope, $element, $state, $transitions, $window, Auth, BuyCreditsModalService, CapacityService, notifications, SendInvoiceModalService, UserManager) {
      'ngInject';

      this.$rootScope = $rootScope;
      this.$element = $element;
      this.$state = $state;
      this.$transitions = $transitions;
      this.$window = $window;
      this.Auth = Auth;
      this.BuyCreditsModalService = BuyCreditsModalService;
      this.CapacityService = CapacityService;// Used in template
      this.notifications = notifications;
      this.SendInvoiceModalService = SendInvoiceModalService;
      this.UserManager = UserManager;
      this.capacity = CapacityService.data;
      this.user = UserManager.data;
      this.isBalanceHidden = this.$window.localStorage.getItem('is_balance_hidden') === 'true';
    }

    $onInit() {
      this.showSidenav = false;

      this.$transitions.onFinish({}, () => {
        this.showSidenav = false;
      });

      this.isFreelancer = this.user.isTeamMember || this.user.isHiredGun || this.user.accountType === 'team_member';
      this.showMyDeliveries = this.user.isTeamMember || this.user.isHiredGun;
    }

    $postLink() {
      this.$element.addClass('header');
    }

    get canInvoice() {
      if (!this.capacity || !this.capacity.balance) {
        return false;
      }

      return Money.isGreaterThanZero(this.capacity.balance.subtract(this.capacity.booked.totalBooked))
        && !this.CapacityService.impersonalizedUser
        && this.isFreelancer;
    }

    get canRequestTeamCreation() {
      return !(this.isFreelancer || this.user.isAdmin) && this.user.accountType !== 'client';
    }

    logout() {
      this.Auth.logout(() => {
        this.UserManager.clean();
        this.$state.go('signin', {}, { reload: true });
      });
    }

    openBuyCreditsModal() {
      this.BuyCreditsModalService.open()
        .catch(noop);
    }

    openSendInvoiceModal(event) {
      this.SendInvoiceModalService.open(event)
        .catch(noop);
    }

    toggleBalanceVisibility() {
      this.isBalanceHidden = !this.isBalanceHidden;
      this.$window.localStorage.setItem('is_balance_hidden', this.isBalanceHidden.toString());
    }
  }
};
