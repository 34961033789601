import angular from 'angular';

export default angular.module('app.common.file-change', [])
  .directive('fileChange', function ($parse) {
    return {
      restrict: 'A',
      compile: function ($element, attrs) {
        const fn = $parse(attrs.fileChange);
        return (scope, element) => {
          element.on('change', event => {
            scope.$applyAsync(() => {
              fn(scope, { $event: event });
            });
          });
        };
      }
    };
  })
  .name;
