import * as angular from 'angular';

/**
 * This is a Recaptcha API mock that does nothing.
 * It is used by other modules instead of the real one
 * when ReCAPTCHA feature toggle is disabled.
 *
 * When the feature toggle is removed this could safely
 * be removed as well.
 */

export class NoopcaptchaService {
  callbacks: any[] = [];

  constructor() {
    this.execute = this.execute.bind(this);
    this.getResponse = this.getResponse.bind(this);
    this.render = this.render.bind(this);
    this.reset = this.reset.bind(this);
  }

  getResponse() {
    // does nothing, just a stub
  }

  execute(id: number) {
    if (angular.isFunction(this.callbacks[id])) {
      this.callbacks[id]();
    }
  }

  render(element: HTMLElement, params: any) {
    return this.callbacks.push(params.callback) - 1;
  }

  reset() {
    // Repeats the reCaptcha api, but does not need to do anything in noopCaptcha
  }
}
