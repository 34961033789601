// @ts-ignore
import template from './team-feed-week.component.html';

import { IFeedWeek } from './team-feed.component';
import { ITeam } from '../../../../models/team.model';
import { IEvent } from '../../../../models/event.model';
import { ITeamResourceService } from '../../../../interfaces';

export const TeamFeedWeekComponent = {
  bindings: {
    team: '<',
    week: '<'
  },
  template,
  controller: class TeamFeedWeekController {
    // Bindings
    // @ts-ignore
    team: ITeam = this.team;
    // @ts-ignore
    week: IFeedWeek = this.week;

    items: IEvent[] = [];

    constructor(
      private CONFIG: any,
      private Team: ITeamResourceService,
    ) {
      'ngInject';
    }

    $onInit() {
      return this.Team.getFeed({
        id: this.team._id,
        week: this.week.id
      }).$promise.then((response) => {
        this.items = response;
      });
    }
  }
};
