import angular from 'angular';
import 'jquery.waitforimages';

export default angular.module('app.common.emerge', [])
  .directive('emerge', ($compile, $rootScope, $window) => {
    'ngInject';

    const compiledSpinnerElement = $compile(`
      <icon class="emerge-spinner" type="spinner"></icon>
    `)($rootScope);

    return {
      priority: -1000,
      link: function (scope, element) {
        const spinnerElement = compiledSpinnerElement.clone();
        element.before(spinnerElement);
        $window.requestAnimationFrame(() => {
          element.waitForImages(() => {
            $window.requestAnimationFrame(() => {
              element.addClass('emerge-visible');
              spinnerElement.remove();
            });
          });
        });
      }
    };
  })
  .name;
