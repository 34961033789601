import angular from 'angular';
import moment from 'moment-timezone';
import phonesExamples from 'libphonenumber-js/examples.mobile.json';
import { compact, findWhere, filter, find, map } from 'underscore';
import { AsYouType, getPhoneCode, isValidNumber } from 'libphonenumber-js';
import template from './international-phone.component.html';

export const InternationalPhoneComponent = {
  bindings: {
    phone: '<',
    fieldId: '@',
    onUpdate: '&'
  },
  template,
  controller: class InternationalPhoneController {
    constructor($element, COUNTRIES, EventEmitter, TIMEZONES) {
      'ngInject';

      this.$element = $element;
      this.COUNTRIES = COUNTRIES;
      this.EventEmitter = EventEmitter;
      this.TIMEZONES = TIMEZONES;

      this.phoneFormatter = new AsYouType();
      this.highlightedCountriesCodes = [];
      this.maskOptions = {
        maskDefinitions: {
          '9': null, // Disable default numeric mask char (as number '9' is included to some country codes)
          'x': /\d/ // Introduce our custom numeric mask char (the one is used in AsUserType.template)
        }
      };
    }

    $onInit() {
      // Init values
      this.phone = this.phone || {};
      this.country = null;

      // Unfiltered list of all countries
      this.allCountries = compact(map(this.COUNTRIES, (name, code) => {
        let phoneCode;
        try {
          phoneCode = getPhoneCode(code);
          return { code, name, phoneCode };
        } catch (e) {
          return null;
        }
      }));

      // Unfiltered list of all the countries that should stay on top of the list
      this.allHighlightedCountries = filter(this.allCountries, country => {
        return this.highlightedCountriesCodes.indexOf(country.code) !== -1;
      });

      // Unfiltered list of all other not highlighted countries
      this.allNotHighlightedCountries = filter(this.allCountries, country => {
        return this.highlightedCountriesCodes.indexOf(country.code) === -1;
      });

      // Filtered lists, which used for the UI output
      this.highlightedCountries = angular.copy(this.allHighlightedCountries);
      this.notHighlightedCountries = angular.copy(this.allNotHighlightedCountries);

      if (!this.phone.country) { // Detect country and select it
        const detectedCountry = this.findTimezoneCountry(moment.tz.guess());
        const foundCountry = this.getCountryByCode(detectedCountry);

        if (foundCountry) {
          this.selectCountry(foundCountry, false, false);
        }
      } else { // Find country with the phone code
        const foundCountry = this.getCountryByCode(this.phone.country);
        if (foundCountry) {
          this.selectCountry(foundCountry, false, false);
        }
      }
    }

    $postLink() {
      this.$element.addClass('intl-phone');
      this.phoneInputElement = this.$element.find('[ng-model="$ctrl.phone"]');
    }

    /**
     * Returns true if phone number is empty (but not undefined)
     * @returns {boolean}
     */
    get isEmpty() {
      return this.phone.number === '';
    }

    /**
     * Returns true if phone number is valid
     * @returns {boolean}
     */
    get isValid() {
      const countryCode = this.country && this.country.code;
      return this.phone && this.phone.number ? isValidNumber(this.phone.number, countryCode) : false;
    }

    /**
     * Prevents pressing Enter key
     * @param event
     */
    static preventReturnKey(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    }

    /**
     * Triggers on user input
     */
    onInput() {
      // Validate input so we can disable Save button
      this.IntlPhoneForm.phone.$setValidity('valid', this.isValid || this.isEmpty);

      this.propagateUpdate();
    }

    /**
     * Updates UI countries lists to contain countries which match query
     */
    filterCountries() {
      const query = this.query || '';
      const matcher = (country) => {
        return country.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
      };

      this.notHighlightedCountries = filter(this.allNotHighlightedCountries, matcher);
      this.highlightedCountries = filter(this.allHighlightedCountries, matcher);
    }

    /**
     * Finds country by phone code
     * @param code Phone code
     */
    getCountryByCode(code) {
      return find(this.allCountries, { code });
    }

    /**
     * Sets country
     * @param country {Object} Country
     * @param focusInput {Boolean} Focus phone number input after selecting
     * @param updateModel {Boolean} Updates model after selecting
     */
    selectCountry(country, focusInput = false, updateModel = true) {
      this.country = country;
      const code = '+' + country.phoneCode;

      // Get national phone number template
      this.phoneFormatter.reset();
      this.phoneFormatter.input(code + phonesExamples[this.country.code]);
      let nationalPhoneNumberTemplate = this.phoneFormatter.formatter.template;

      // Remove masked (xxxx) country code from template so we can add a digital one (+380)
      nationalPhoneNumberTemplate = nationalPhoneNumberTemplate.split(' ').slice(1).join(' ');
      // Update ui-mask template
      this.mask = `${ code } ${ nationalPhoneNumberTemplate }`;

      if (updateModel) {
        this.propagateUpdate();
      }

      if (focusInput) {
        setTimeout(() => {
          this.phoneInputElement.focus();
        }, 0);
      }
    }

    /**
     * Emits event of changes to external component
     */
    propagateUpdate() {
      let value;

      if (this.isValid && !this.isEmpty) {
        const code = '+' + this.country.phoneCode;

        value = {
          number: this.phone.number,
          code,
          country: this.country.code,
          raw: code + this.phone.number
        };
      } else {
        value = null;
      }

      this.onUpdate(this.EventEmitter({ value }));
    }

    /**
     * Finds Timezone by time zone name
     * @param zoneName
     * @return {Timezone}
     */
    findTimezoneCountry(zoneName) {
      const foundTimezone = findWhere(this.TIMEZONES, { zoneName });
      return foundTimezone ? foundTimezone.countryCode : null;
    }
  }
};
