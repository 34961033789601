import angular from 'angular';

export default angular.module('app.resources.project', [
  require('angular-resource')
])
  .service('Project', function ($resource) {
    'ngInject';

    return $resource('/api/projects/:id/:path', { id: '@_id', path: '@path' }, {
      acceptTeam: {
        url: '/api/projects/:projectId/acceptTeam/:teamId',
        method: 'POST',
        params: { projectId: '@projectId', teamId: '@teamId' },
        headers: {
          'X-Recaptcha-Token': config => config.data.$$recaptchaToken
        }
      },
      queryAll: {
        method: 'GET',
        isArray: true,
        params: { path: 'all' }
      },
      queryMy: {
        method: 'GET',
        isArray: true,
        params: { path: 'my' }
      },
      save: {
        method: 'POST',
        cancellable: true
      },
      update: {
        method: 'PUT',
        cancellable: true
      },
      updateNote: {
        method: 'PUT',
        params: { path: 'note' }
      },
      getWeekDeliveries: {
        url: '/api/projects/:id/deliveries/:week',
        isArray: true,
        method: 'GET',
        params: { id: '@id', week: '@week' }
      },
      getFeed: {
        params: { path: 'feed' },
        isArray: true
      },
      setGuardian: {
        params: { path: 'guardian' },
        method: 'PUT'
      },
      setTrusted: {
        params: { path: 'trusted' },
        method: 'PUT'
      },
      getProjectsWithRecurringSalesInvoices: {
        params: { path: 'recurringSalesInvoices' },
        isArray: true,
        method: 'GET'
      },
      setRecurringSalesInvoices: {
        params: { id: '@id', path: 'recurringSalesInvoices' },
        method: 'POST'
      },
      updateRecurringSalesInvoices: {
        params: { id: '@id', path: 'recurringSalesInvoices' },
        method: 'PUT'
      },
      removeRecurringSalesInvoices: {
        params: { id: '@id', path: 'recurringSalesInvoices' },
        method: 'DELETE'
      },
      sendSalesInvoice: {
        params: { id: '@id', path: 'salesInvoices' },
        method: 'POST'
      },
      getContactPerson: {
        params: { path: 'contactPerson' },
        method: 'GET'
      }
    });
  })
  .name;

