import angular from 'angular';
import ProjectResourceModule from 'modules/resources/project';
import UiRouterModule, { StateProvider } from '@uirouter/angularjs';
import { AdminRecurringInvoicesComponent } from './recurring-invoices.component';
import { IProjectResourceService } from '../../../interfaces';

export const AdminPanelRecurringInvoicesModule = angular.module('app.admin-panel.recurring-invoices', [
  ProjectResourceModule,
  UiRouterModule
])
  .component('adminRecurringInvoices', AdminRecurringInvoicesComponent)
  .config(($stateProvider: StateProvider) => {
    'ngInject';

    $stateProvider.state('admin-panel.recurring-invoices', {
      url: '/recurring-invoices',
      resolve: {
        projectsWithRecurringSalesInvoices: (Project: IProjectResourceService) => {
          return Project.getProjectsWithRecurringSalesInvoices().$promise;
        }
      },
      views: {
        'admin-panel': {
          component: 'adminRecurringInvoices'
        }
      },
      data: {
        permissions: ['admin'],
        title: 'Recurring Invoices | Admin panel'
      }
    });
  })
  .name;
