// @ts-ignore
import template from './week-ratings.component.html';

import { WeekRatingsController } from './week-ratings.controller';

export const WeekRatingsComponent: ng.IComponentOptions = {
  bindings: {
    onCancel: '&',
    onUpdate: '&',
    project: '<',
    week: '<',
    disabled: '<'
  },
  controller: WeekRatingsController,
  template
};
