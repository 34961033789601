import _ from 'underscore';
import angular from 'angular';
import MyDeliveryWeek from './my-delivery-week.class';
import template from './my-deliveries-project-week.component.html';
import { Money } from 'modules/money/money';

export const MyDeliveriesProjectWeekComponent = {
  bindings: {
    project: '<',
    team: '<',
    week: '<'
  },
  template,
  controller: class MyDeliveriesProjectWeekController {
    constructor($element, Account, CapacityService, Project, UserManager) {
      'ngInject';

      this.$element = $element;
      this.Account = Account;
      this.CapacityService = CapacityService;
      this.Project = Project;
      this.UserManager = UserManager;
    }

    $onChanges(changes) {
      if (changes.team) {
        this.team = angular.copy(changes.team.currentValue);
      }

      if (changes.project) {
        this.project = angular.copy(changes.project.currentValue);
      }

      if (changes.week) {
        let week = angular.copy(changes.week.currentValue);
        this.week = new MyDeliveryWeek(week.id, week.booking, [], []);
        this.getWeekDeliveries();
      }
    }

    $onInit() {
      this.user = this.UserManager.data;
    }

    get showFinishSprint() {
      return this.week.hasBooking
        && !this.week.isAccepted
        && this.week.hasDeliveries()
        && this.user._id === this.project.guardian;
    }

    getWeekDeliveries() {
      this.loading = true;

      this.Project.getWeekDeliveries({
        id: this.project._id,
        week: this.week.id
      }).$promise.then(response => {
        response.forEach(operation => {
          operation.value = Money.create(operation.value);

          if (operation.user._id === this.UserManager.data._id) {
            this.week.myDeliveries.push(operation);
          } else {
            this.week.otherDeliveries.push(operation);
          }
        });
      }).finally(() => this.loading = false);
    }

    deliverCapacity(project, week, delivery, $event) {
      const isExistingDelivery = delivery && delivery._id;
      const comment = $event.comment;
      const value = $event.value;

      const payload = {
        amount: value.getAmount(),
        comment: comment,
        deliveryId: isExistingDelivery ? delivery._id : undefined,
        id: this.user._id,
        projectId: project._id,
        weekId: week.id
      };

      this.Account.deliver(payload).$promise
        .then(response => {
          response.value = Money.create(response.value);
          if (isExistingDelivery) {
            _(delivery).extend(response);
          } else {
            week.addDelivery(response);
          }
        })
        .finally(() => {
          this.CapacityService.sync();
        });
    }

    removeDelivery(week, delivery) {
      week.removeDelivery(delivery);

      this.Account.removeDelivery({
        id: this.user._id,
        deliveryId: delivery._id
      }).$promise
        .catch(() => {
          week.addDelivery(delivery);
        })
        .finally(() => {
          this.CapacityService.sync();
        });
    }

    toRoundedUnit(value) {
      return Money.toRoundedUnit(value);
    }
  }
};
