import angular from 'angular';
import template from './admin-note.component.html';

export default angular.module('app.admin-note', [
  require('modules/user-manager').default,
  require('modules/common/event-emitter.module').default,
  require('modules/resources/project').default,
])
  .component('adminNote', {
    bindings: {
      project: '<',
      onUpdate: '&'
    },
    template,
    controller: class AdminNoteController {
      constructor($element, $scope, EventEmitter, Project, UserManager) {
        'ngInject';
        this.$element = $element;
        this.$scope = $scope;
        this.EventEmitter = EventEmitter;
        this.ProjectResource = Project;
        this.UserManager = UserManager;
      }

      $onInit() {
        if (!this.UserManager.data.isAdmin) {
          this.$element.remove();
          this.$scope.$destroy();
          return;
        }

        this.note = this.project.note;
      }

      $postLink() {
        this.$loader = this.$element.find('.fa-circle-notch');
        this.$successMark = this.$element.find('.fa-check');
      }

      save() {
        // wait until previous save request finished
        if (this.save.isInProgress) {
          return;
        }

        this.save.isInProgress = true;

        this.$successMark.hide();
        this.$loader.show();

        this.ProjectResource.updateNote({ id: this.project._id }, { note: this.note }).$promise
          .then(() => {
            this.project.note = this.note;
            this.onUpdate(new this.EventEmitter(this.note));

            this.$loader.hide();
            this.$successMark.fadeIn(100);

            setTimeout(() => {
              this.$successMark.fadeOut(250);
            }, 300);

            this.save.isInProgress = false;
          })
          .catch(() => {
            this.$loader.hide();
            this.save.isInProgress = false;
          });
      }
    }
  })
  .name;
