import { sortBy } from 'underscore';
import { IAccountCompany } from 'models/account.model';

// @ts-ignore
import template from './companies-list.component.html';
import { IAccountResourceService } from 'models/account-resource.model';

export const CompaniesListComponent = {
  bindings: {
    companies: '<',
  },
  template,
  controller: class CompaniesListController {
    companies: IAccountCompany[] = [];
    loading = true;

    constructor(
      private Account: IAccountResourceService,
    ) {
      'ngInject';
    }

    $onInit() {
      this.Account.getCompanies().$promise.then((companies) => {
        this.companies = sortBy(companies, 'name');
      }).finally(() => {
        this.loading = false;
      });
    }
  }
};
