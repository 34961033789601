import { range, throttle } from 'underscore';
import moment, { Moment } from 'moment';

// @ts-ignore
import template from './team-feed.component.html';

import { ITeam } from '../../../../models/team.model';

export interface IFeedWeek {
  id: string;
  number: string;
  month: string;
  year: string;
}

export const TeamFeedComponent: ng.IComponentOptions = {
  bindings: {
    team: '<',
  },
  template,
  controller: class TeamFeedController {
    // Bindings
    // @ts-ignore
    team: ITeam = this.team;

    weekCursor: Moment = moment();
    weeks: IFeedWeek[] = [];
    loadMoreWeeks: () => void;

    constructor(
      private CONFIG: any,
    ) {
      'ngInject';

      this.loadMoreWeeks = throttle(this._loadMoreWeeks.bind(this), 1000);
    }

    get reachedEnd(): boolean {
      return this.weekCursor.isBefore(this.team.createdAt);
    }

    _loadMoreWeeks(): void {
      if (this.reachedEnd) {
        return;
      }

      range(5).forEach(() => {
        if (this.reachedEnd) {
          return;
        }

        this.weeks.push({
          id: this.weekCursor.format(this.CONFIG.YEAR_WEEK_FORMAT),
          number: this.weekCursor.format('WW'),
          month: this.weekCursor.format('MMMM'),
          year: this.weekCursor.format('YYYY')
        });

        this.weekCursor = this.weekCursor.subtract(1, 'week');
      });
    }
  }
};
