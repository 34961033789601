import angular from 'angular';
import template from './btn-delivery.component.html';
import { Money } from 'modules/money/money';

const KEY_ESCAPE = 27;

export const BtnDeliveryComponent = {
  template,
  bindings: {
    delivery: '<',
    mode: '@',
    week: '<',
    onRemove: '&',
    onUpdate: '&'
  },
  controller: class BtnDeliveryController {
    constructor($element, $rootScope, $scope, $window, EventEmitter) {
      'ngInject';

      this.$element = $element;
      this.$rootScope = $rootScope;
      this.$scope = $scope; // to be able to call digest to update view
      this.$window = $window;
      this.EventEmitter = EventEmitter;
    }

    $onChanges(changes) {
      if (changes.delivery) {
        this.delivery = angular.copy(changes.delivery.currentValue);

        if (this.delivery) {
          this.comment = this.delivery.comment;
          this.value = Money.toRoundedUnit(this.delivery.value);
        } else {
          this.comment = '';
          this.value = '';
        }
      }

      if (changes.mode) {
        this.mode = changes.mode.currentValue || 'create';
      }

      if (changes.week) {
        this.week = angular.copy(changes.week.currentValue);

        // when editing existing delivery max is set to availableMoney + deliveryValue
        const max = this.mode === 'create' ? this.week.availableMoney : this.week.availableMoney.add(this.delivery.value);
        this.max = Money.toRoundedUnit(max);
      }
    }

    $onInit() {
      this.stopListening = this.$rootScope.$on('btn-delivery.show', (event, weekId, deliveryId) => {
        if (!this.isActive) {
          return;
        }

        // When user clicks on another btn-delivery
        if (weekId !== this.week.id || deliveryId !== this.deliveryId) {
          this.isActive = false;
        }
      });
    }

    $onDestroy() {
      this.stopListening();
    }

    $postLink() {
      // quit editing when escape pressed
      this.$element.on('keydown', 'input', event => {
        this.$scope.$apply(() => {
          if (event.which === KEY_ESCAPE) {
            this.isActive = false;
            this.activate.isInProgress = false;
          }
        });
      });
    }

    get deliveryId() {
      return this.delivery ? this.delivery._id : 'new';
    }

    activate() {
      if (this.mode === 'create') {
        this.value = '';
        this.comment = '';
      }

      if (this.isActive) {
        return;
      }

      this.$rootScope.$emit('btn-delivery.show', this.week.id, this.deliveryId);

      this.isActive = true;

      this.$window.requestAnimationFrame(() => {
        this.$element.find('input:first').select();
      });
    }

    onDeliveryUpdated(changes) {
      this.isActive = false;

      this.value = changes.value;
      this.comment = changes.comment;

      this.onUpdate(this.EventEmitter({
        value: Money.createFromEuros(this.value),
        comment: this.comment
      }));
    }
  }
};
