import _ from 'underscore';
import angular from 'angular';

import { ProjectViewModule } from './view';

export default angular.module('app.projects', [
  require('@uirouter/angularjs').default,
  require('modules/projects/add-edit').default,
  require('modules/projects/list').default,
  require('modules/projects/manage').default,
  require('modules/projects/modals').default,
  require('modules/projects/project-budget.module').default,
  require('modules/projects/project-card').default,
  require('modules/projects/team-matching').default,
  require('modules/user-manager').default,
  ProjectViewModule,
])
  .config(function ($stateProvider) {
    'ngInject';

    $stateProvider
      // Direct access to "projects" redirects to "projects.list"
      .state('projects', {
        abstract: true,
        url: '/projects',
        template: '<ui-view></ui-view>',
        resolve: {
          capacity: CapacityService => CapacityService.sync()
        }
      })
      // Direct access to "projects.single" redirects to either "projects.single.manage"
      // or "projects.single.view" depending on if user is able to manage the project
      .state('projects.single', {
        url: '/:id?autoOfferTeamId',
        template: '<ui-view></ui-view>',
        resolve: {
          project: function ($q, $state, Project, $transition$) {
            var deferred = $q.defer();

            Project.get({ id: $transition$.params().id }).$promise
              .then(response => deferred.resolve(response))
              .catch(() => {
                $state.transitionTo('projects.list');
                deferred.reject('Project not found');
              });

            return deferred.promise;
          }
        },
        onEnter: function ($state, $transition$, UserManager, project) {
          const amIOwner = project.owner._id === UserManager.data._id;
          const amIAssistant = _(project.assistants).contains(UserManager.data._id);
          const myArchivedProject = amIOwner && project.archived === true;

          let toState = 'projects.single.view';
          if ((amIOwner || amIAssistant) && !myArchivedProject) {
            toState = project.assignedTeam ? 'projects.single.manage.planner' : 'projects.single.team-matching';
          }

          // replace the URL so this redirect is not stored in history
          $state.go(toState, $transition$.params(), { location: 'replace' });
        }
      });
  })

  .service('projectService', function (Project, CapacityService) {
    'ngInject';

    return {
      removeProject: function (project, callback) {
        project.archived = true;

        Project.remove({ id: project._id }).$promise
          .then(() => {
            CapacityService.sync();
            callback();
          })
          .catch(() => project.archived = false);
      },

      restoreProject: function (project) {
        project.archived = false;

        Project.update(project);
      }
    };
  })
  .name;
