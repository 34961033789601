import angular from 'angular';
import 'jquery-caret-lm';
import EventEmitterModule from 'modules/common/event-emitter.module';
import { TxboxDeliveryComponent } from './txbox-delivery.component';

export default angular.module('app.ui.txbox-delivery', [
  EventEmitterModule
])
  .component('txboxDelivery', TxboxDeliveryComponent)
  .name;
