import angular from 'angular';
import template from './sparkline.html';

export default angular.module('app.ui.sparkline', [])

  .directive('sparkline', function () {
    return {
      restrict: 'E',
      replace: true,
      template,
      scope: {
        max: '=',
        value: '='
      },
      controller: function ($scope) {
        'ngInject';

        $scope.getMeterWidth = function () {
          return ($scope.value / $scope.max) * 100 + '%';
        };
      }
    };
  })
  .name;
