import * as angular from 'angular';
import uirouterModule, { StateProvider } from '@uirouter/angularjs';
import eventsResourceModule from 'modules/resources/events';
import uiModalModule from 'modules/common/ui-bootstrap-modal-2.5.6';
import userManagerModule from 'modules/user-manager';
import { ColorTagModule } from 'modules/ui/color-tag';

import { AdminPanelEventsComponent } from './events.component';
import { AdminPanelGeneralEventComponent } from './general-event.component';
import { AcceptedDemoEventComponent } from './accepted-demo-event.component';

export default angular.module('app.admin-panel.events', [
  ColorTagModule,
  eventsResourceModule,
  uirouterModule,
  uiModalModule,
  userManagerModule
])
  .config(function ($stateProvider: StateProvider) {
    'ngInject';

    $stateProvider
      .state('admin-panel.events', {
        url: '/events',
        views: {
          'admin-panel': {
            component: 'adminPanelEventsComponent'
          }
        },
        permissions: ['admin'],
        data: {
          title: 'Events'
        }
      } as any);
  })
  .component('adminPanelEventsComponent', AdminPanelEventsComponent)
  .component('eventGeneral', AdminPanelGeneralEventComponent)
  .component('eventAcceptedDemo', AcceptedDemoEventComponent)
  .name;
