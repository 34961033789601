import angular from 'angular';
import { InternationalPhoneComponent } from './international-phone.component';
import UiMask from 'angular-ui-mask';

export const InternationalPhoneModule = angular.module('app.international-phone', [
  UiMask,
  require('app-constants').default,
  require('modules/common/event-emitter.module').default
])
  .component('internationalPhone', InternationalPhoneComponent)
  .name;
