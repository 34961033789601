// @ts-ignore
import template from './exact-integration.component.html';
import { IAccountResourceService } from 'models/account-resource.model';
import { IAccount } from 'models/account.model';
import { IExactAccount, IIntegrationResourceService } from 'interfaces';

export const ExactIntegrationComponent = {
  bindings: {
    account: '<'
  },
  template,
  controller: class ExactIntegrationController {
    // @ts-ignore
    account: IAccount = this.account;

    exactAccount: IExactAccount = null;
    isCreateInProgress = false;
    isFetchInProgress = false;
    isSaveInProgress = false;
    userId: string = '';

    constructor(
      private $q: ng.IQService,
      private Account: IAccountResourceService,
      private growl: ng.growl.IGrowlService,
      private IntegrationResource: IIntegrationResourceService,
    ) {
      'ngInject';
    }

    $onInit(): void {
      this.userId = this.account.exactUserId || '';

      if (this.account.exactUserId) {
        this.fetchExactAccount();
      }
    }

    fetchExactAccount() {
      if (this.isFetchInProgress) {
        return;
      }

      this.isFetchInProgress = true;

      return this.$q<void>((resolve) => {
        if (this.account.exactUserId) {
          return this.IntegrationResource.getExactAccount({ id: this.account.exactUserId }).$promise
            .then((data) => this.exactAccount = data)
            .catch(() => this.exactAccount = null)
            .finally(() => {
              this.isFetchInProgress = false;
              resolve();
            });
        } else {
          this.exactAccount = null;
          this.isFetchInProgress = false;
          resolve();
        }
      });
    }

    createAccount() {
      if (this.isCreateInProgress) {
        return;
      }

      this.isCreateInProgress = true;
      const companyName = this.account.company?.name;
      const request = {
        remarks: `https://${location.host}/admin-panel/accounts/${this.account._id}/overview`,
        name: this.account.name + (companyName ? ' ' + this.account.company?.name : ''),
      };

      return this.$q<void>((resolve) => {
        return this.IntegrationResource.createExactAccount(request).$promise
          .then((data) => {
            this.exactAccount = data;
            this.userId = data.ID;
            this.save();
          })
          .catch(() => this.exactAccount = null)
          .finally(() => {
            this.isCreateInProgress = false;
            resolve();
          });
      });
    }

    save(): void {
      if (this.isSaveInProgress) {
        return;
      }

      this.isSaveInProgress = true;

      this.Account.setExactUserId({ id: this.account._id }, { exactUserId: this.userId }).$promise
        .then(() => {
          this.growl.success('Exact ID set.');
        })
        .finally(() => this.isSaveInProgress = false);
    }
  }
};
