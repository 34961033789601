import _ from 'underscore';
import angular from 'angular';

export const AdminPanelTeamsListController = function ($scope, teams, Team, TeamsModalsService) {
  'ngInject';

  $scope.teams = teams;

  /**
   * Methods
   */

  $scope.openAddTeamModal = function (event) {
    TeamsModalsService
      .openAddEditModal(null, false, false, undefined, event)
      .result
      .then(function (createdTeam) {
        if (createdTeam) {
          $scope.teams.push(createdTeam);
        }
      })
      .catch(angular.noop);
  };

  $scope.openEditTeamModal = function (event, team) {
    TeamsModalsService
      .openAddEditModal(team._id, false, false, undefined, event)
      .result
      .then(function (updatedTeam) {
        if (updatedTeam) {
          _(team).extend(updatedTeam);
        }
      })
      .catch(angular.noop);
  };

  $scope.removeTeam = function (team) {
    var hasMembers = team.members && team.members.length;
    var confirmMessage = 'This team has team members inside. Are you sure you want to remove this team?';

    if (!hasMembers || (hasMembers && confirm(confirmMessage))) {
      team.$remove(function () {
        team.isArchived = true;
      });
    }
  };

  $scope.restoreTeam = function (team) {
    team.$restore(function () {
      team.isArchived = false;
    });
  };
};
