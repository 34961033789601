import { noop } from 'underscore';
import { TransitionService } from '@uirouter/angularjs';
import { Money } from '../money/money';
import { UserManagerService } from '../user-manager/user-manager.service';
import { IMoney } from 'models/money.model';
import { IAccount } from 'models/account.model';
import { IProject } from 'models/project.model';
import { ICapacityServiceHttpResponse, ICapacityServiceResponse } from 'models/http/account-capacity-http.model';
import { ICapacityResource } from 'models/capacity-resource.model';
import { IAccountResourceService } from 'models/account-resource.model';
import { httpAccountCapacity } from 'models/mutators/capacity';

export class CapacityService {
  impersonalizedUser: Partial<IAccount> | null = null;
  project: IProject | null = null;

  // Use to know when capacity stats are updated
  isInProgress = true;

  data: ICapacityServiceResponse<IMoney> = {} as ICapacityServiceResponse<IMoney>;
  pendingDelivered: IMoney = Money.zero();

  constructor(
    private $transitions: TransitionService,
    private Account: IAccountResourceService,
    private Capacity: ICapacityResource,
    private UserManager: UserManagerService,
  ) {
    'ngInject';

    // wipe stats showing for another user on navigating away
    $transitions.onStart({}, () => {
      if (this.impersonalizedUser) {
        this.impersonalizedUser = null;
        this.project = null;
        this.sync();
      }
    });
  }

  /**
   * Enables showing of capacity stats for another user,
   * cleaned when navigating away to a different page
   */
  showStatsForUserInProject(user: Partial<IAccount>, project: IProject): ng.IPromise<void | ICapacityServiceHttpResponse> {
    return this.sync(user, project).then((response) => {
      this.impersonalizedUser = user;
      this.project = project;

      return response;
    });
  }

  set(value: ICapacityServiceResponse<IMoney>): void {
    // Subtract invoiced amounts to make balance more intuitive
    if (Money.isGreaterThanZero(value.pendingInvoices.totalInvoiced)) {
      value.balance = value.balance.subtract(value.pendingInvoices.totalInvoiced);
    }

    Object.assign(this.data, value);
  }

  // Shown on my-deliveries page
  getPendingDelivered(): ng.IPromise<void> {
    return this.Capacity.getPendingDelivered().$promise.then((response) => {
      this.pendingDelivered = Money.create(response.total);
    });
  }

  sync(user?: Partial<IAccount>, project?: IProject): ng.IPromise<void | ICapacityServiceHttpResponse> {
    this.isInProgress = true;

    let params;
    if (user && user._id) {
      params = { id: user._id, projectId: project?._id };
    } else {
      params = { id: this.UserManager.data._id };
    }

    return this.Account.capacity(params).$promise
      .then((response) => {
        this.set(httpAccountCapacity(response));

        return response;
      })
      .catch(noop)
      .finally(() => this.isInProgress = false);
  }
}
