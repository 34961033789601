import 'jquery-mousewheel';

export function TrapScrollDirective(ScrollService) {
  'ngInject';

  return {
    restrict: 'A',
    link(scope, element) {
      element.on('mousewheel', function (event) {
        if (event.deltaY > 0 ? ScrollService.getScroll(element, 'top') === 0 : ScrollService.getScroll(element, 'bottom') === 0) {
          event.preventDefault();
        }
      });
    }
  };
}
