export class ScrollService {
  getOverflowY(element) {
    const offsetHeight = angular.element(element).prop('offsetHeight');
    const scrollHeight = angular.element(element).prop('scrollHeight');
    return scrollHeight - offsetHeight;
  }

  getScroll(element, direction) {
    element = element[0];

    if (direction === 'top') {
      return element.scrollTop;
    }

    if (direction === 'right') {
      return (
        element.scrollWidth -
        element.scrollLeft -
        Math.round(element.clientWidth)
      );
    }

    if (direction === 'bottom') {
      return (
        element.scrollHeight -
        element.scrollTop -
        Math.round(element.clientHeight)
      );
    }

    if (direction === 'left') {
      return element.scrollLeft;
    }

    return null;
  }
}
