import { RecaptchaService } from './recaptcha.service';
import { IEventEmitter } from 'interfaces';

export const RecaptchaComponent = {
  bindings: {
    onInit: '&',
    size: '@'
  },
  controller: class RecaptchaComponent {
    // Bindings
    // @ts-ignore
    onInit: (event: IEventEmitter) => void = this.onInit;
    // @ts-ignore
    size: string = this.size;

    private deferredSuccess?: ng.IDeferred<any>;
    private whenRendered?: PromiseLike<any>;

    constructor(
      private $element: JQuery<HTMLElement>,
      private $q: ng.IQService,
      private $window: ng.IWindowService,
      private EventEmitter: IEventEmitter<any>,
      private RecaptchaService: RecaptchaService,
      private RECAPTCHA_SITE_KEY: string,
    ) {
      'ngInject';
    }

    get invisible() {
      return this.size === 'invisible';
    }

    $onInit(): void {
      this.onInit(this.EventEmitter({ controller: this }));
    }

    $postLink(): void {
      const config: any = {
        sitekey: this.RECAPTCHA_SITE_KEY,
        callback: (responseToken: string) => {
          // Invisible Recaptcha is being reset as otherwise
          // any subsequent execute calls won't work.
          // On the contrary, inline Recaptcha
          // will work only if it wasn't reset.
          if (this.invisible) {
            this.reset();
          }

          if (this.deferredSuccess) {
            this.deferredSuccess.resolve(responseToken);
          }
        }
      };

      if (this.invisible) {
        config.badge = 'bottomleft';
        config.size = 'invisible';
      }

      this.whenRendered = this.RecaptchaService.render(this.$element[0], config);
    }

    $onDestroy(): void {
      this.reset();
    }

    execute() {
      this.deferredSuccess = this.$q.defer();

      const promise = this.whenRendered;

      if (this.invisible && promise) {
        return promise
          .then((id) => this.RecaptchaService.execute(id))
          .then(() => this.RecaptchaService.rejectWhenDismissed(this.deferredSuccess));
      } else if (promise) {
        return promise.then((id) => this.RecaptchaService.getResponse(id));
      }
    }

    reset(): void {
      if (this.whenRendered) {
        // @ts-ignore
        this.whenRendered.then((id) => this.RecaptchaService.reset(id));
      }
    }
  }
};
