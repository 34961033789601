import angular from 'angular';

export default angular.module('app.resources.integration', [
  require('angular-resource')
])
  .service('IntegrationResource', function ($resource) {
    'ngInject';

    return $resource('/api/integrations/:slug/:path/:id', { id: '@id', path: '@path', slug: '@slug' }, {
      createExactAccount: {
        method: 'POST',
        params: {
          slug: 'exact',
          path: 'account',
        }
      },
      getExactAccount: {
        method: 'GET',
        params: {
          slug: 'exact',
          path: 'account',
        }
      },
      createSalesInvoice: {
        method: 'POST',
        params: {
          slug: 'factuursturen',
          path: 'invoiceForOrder'
        }
      },
      createExactTransaction: {
        method: 'POST',
        params: {
          slug: 'exact',
          path: 'transactionForOrder'
        }
      },
      createExactPurchaseEntry: {
        method: 'POST',
        params: {
          slug: 'exact',
          path: 'purchaseEntryForInvoice'
        }
      },
      increaseOutstandingFreelancerInvoiceableCredits: {
        method: 'POST',
        params: {
          slug: 'exact',
          path: 'increaseOutstandingFreelancerInvoiceableCredits'
        }
      },
      increaseOutstandingMollieBalance: {
        method: 'POST',
        params: {
          slug: 'exact',
          path: 'increaseOutstandingMollieBalance'
        }
      },
      reduceOutstandingFreelancerInvoiceableCredits: {
        method: 'POST',
        params: {
          slug: 'exact',
          path: 'reduceOutstandingFreelancerInvoiceableCredits'
        }
      },
    });
  })
  .name;

