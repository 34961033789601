import angular from 'angular';

export default angular.module('app.ui.autosize', [])
  .directive('autosize', function ($timeout) {
    'ngInject';

    return function (scope, element) {
      // Shrinks textarea for a moment so scrollHeight would be equal to the
      // content height. Then expands textarea to be that high
      var updateHeight = function () {
        element.height(0).outerHeight(element[0].scrollHeight);
      };

      // Sets the default style
      element.css({
        // Disable scrollbars
        overflow: 'hidden',
        // Disable the manual resizing
        resize: 'none'
      });

      // Initialization is done upon timeout to make sure everything is
      // rendered so the initial height could be picked
      $timeout(function () {
        // Minimal height should be equal to the initial height
        element.css('min-height', element.css('height'));

        // Updates on every textual change and on initialization
        element.on('input', updateHeight);
        updateHeight();
      });
    };
  })
  .name;
