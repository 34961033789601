import angular from 'angular';
import { ManageProjectGuardianComponent } from './manage-project-guardian.component';

export default angular.module('app.projects.manage-guardian', [
  require('modules/common/event-emitter.module').default,
  require('modules/resources/project').default,
  require('modules/ui/management-dropdown-directive').default
])

  .component('manageProjectGuardian', ManageProjectGuardianComponent)
  .name;
