// @ts-ignore
import template from './pinned-notification.html';
// @ts-ignore
import PinnedTeaserTemplate from './pinned-teaser.html';
import angular from 'angular';
import { ISegmentService } from '../../../interfaces';

export default angular.module('app.ui.pinned-notification', [])
  .component('pinnedNotification', {
    bindings: {
      notification: '<'
    },
    template,
    controller: class PinnedNotificationController {
      notification: any;

      isBadgeShown = false;

      constructor(
        private $location: ng.ILocationService,
        private $window: ng.IWindowService,
        private SegmentService: ISegmentService
      ) {

      }

      // Watch changes
      $onChanges(changes: ng.IOnChangesObject) {
        if (changes.notification?.currentValue?._id) {
          this.notification = changes.notification.currentValue;

          this.isBadgeShown = this.$window.localStorage.getItem(`seen_pinned_notification_${this.notification._id}`) !== 'true';
        }
      }

      handleMouseLeave() {
        this.$window.localStorage.setItem(`seen_pinned_notification_${this.notification._id}`, 'true');
        this.isBadgeShown = false;
      }

      clickNotification() {
        if (!this.notification.url) {
          return;
        }

        this.SegmentService.track('Click pinned notification', this.notification);

        if (this.isInternal(this.notification.url)) {
          this.$window.location.href = this.notification.url;
        } else {
          this.$window.open(this.notification.url, '_blank').focus();
        }
      }

      private isInternal(url: string) {
        const parsedUrl = document.createElement('a');
        parsedUrl.href = url;

        return parsedUrl.protocol.slice(0, -1) === this.$location.protocol() && parsedUrl.hostname === this.$location.host();
      }
    }
  } as ng.IComponentOptions)

  // TODO migrate this to component
  .directive('pinnedTeaser', ($window, $location, $rootScope, $compile, SegmentService) => {
    'ngInject';

    return {
      restrict: 'A',
      scope: {
        notification: '=pinnedTeaser'
      },
      link: (scope: any, element) => {
        /**
         * Methods
         */

        scope.clickTeaser = () => {
          if (!scope.notification.url) {
            return;
          }
          SegmentService.track('Click pinned teaser', scope.notification);
          if (isInternal(scope.notification.url)) {
            $window.location.href = scope.notification.url;
          } else {
            $window.open(scope.notification.url, '_blank').focus();
          }
        };

        function isInternal(url: string) {
          const parsedUrl = document.createElement('a');
          parsedUrl.href = url;

          return parsedUrl.protocol.slice(0, -1) === $location.protocol() && parsedUrl.hostname === $location.host();
        }

        /**
         * Init
         */

        let linked = false;
        $rootScope.$on('projects-list.page-fetched', () => {
          if (!linked) {
            element.children().eq(element.children().length - 2)
              .after($compile(PinnedTeaserTemplate)(scope));
            linked = true;
          }
        });
      }
    };
  })
  .name;
