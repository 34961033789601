import angular from 'angular';
import { NotificationHistoryComponent } from './notification-history.component';
import { PinnedNotificationsController } from './pinned-notifications-ctrl';
import PinnedNotificationsTemplate from './pinned-notifications.html';

export default angular.module('app.admin-panel.pinned-notifications', [
  require('@uirouter/angularjs').default,
  require('modules/common/event-emitter.module').default,
  require('modules/resources/notification').default,
  require('modules/user-manager').default
])
  .component('notificationHistory', NotificationHistoryComponent)
  .config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state('admin-panel.pinned-notifications', {
      url: '/pinned-notifications',
      resolve: {
        pinnedNotifications: (Notification) => {
          return Notification.getPinned({ all: true }).$promise;
        }
      },
      views: {
        'admin-panel': {
          controller: 'PinnedNotificationsCtrl',
          template: PinnedNotificationsTemplate,
        }
      },
      permissions: ['admin'],
      data: {
        title: 'Pinned notifications'
      }
    });
  })
  .controller('PinnedNotificationsCtrl', PinnedNotificationsController)
  .name;

