// @ts-ignore
import template from './buy-credits-modal.component.html';

import { IResolve } from './interfaces';
import { IAccount, IAccountCompany } from 'models/account.model';
import { UserManagerService } from 'modules/user-manager/user-manager.service';

export const BuyCreditsModalComponent: ng.IComponentOptions = {
  bindings: {
    dismiss: '&',
    resolve: '<'
  },
  template,
  controller: class BuyCreditsModalController implements ng.IController {
    // @ts-ignore
    resolve: IResolve = this.resolve;

    account: IAccount;
    showCongratulations: boolean = false;
    showCompanyForm: boolean = false;

    constructor(
      private UserManager: UserManagerService,
    ) {
      'ngInject';

      this.account = this.UserManager.data;
    }

    companyDetailsCancelled(): void {
      this.showCompanyForm = false;
    }

    companyDetailsUpdated($event: { company: IAccountCompany; vatExempt: boolean; }): void {
      this.showCompanyForm = false;

      if ($event.vatExempt) {
        this.showCongratulations = true;
      }

      // Update user manager without side effects
      this.UserManager.set({
        company: $event.company,
        vatExempt: $event.vatExempt
      }, {}, true);
    }
  }
};
