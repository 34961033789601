import * as angular from 'angular';
import UiRouterModule, { StateProvider } from '@uirouter/angularjs';

import AccountResourceModule from 'modules/resources/account';
import UserManagerModule from 'modules/user-manager';
import { ISQStateDeclaration } from 'interfaces';
import { AdminPanelAccountCompanyComponent } from './account-company.component';

export const AdminPanelAccountsCompanyModule = angular.module('app.admin-panel.accounts.company', [
  AccountResourceModule,
  UiRouterModule,
  UserManagerModule
])
  .config(($stateProvider: StateProvider) => {
    'ngInject';

    $stateProvider
      .state('admin-panel.account.company', {
        url: '/company',
        views: {
          account: {
            component: 'adminPanelAccountCompany'
          }
        },
        permissions: ['admin'],
        data: {
          title: 'Account Company'
        }
      } as ISQStateDeclaration);
  })
  .component('adminPanelAccountCompany', AdminPanelAccountCompanyComponent)
  .name;
