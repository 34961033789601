import angular from 'angular';
import { AdminPanelTagsListController } from './tags-list-ctrl';
import AdminPanelTagsListTemplate from './tags-list.html';

export default angular.module('app.admin-panel.tags', [
  require('@uirouter/angularjs').default,
  require('modules/resources/tag').default,
  require('modules/user-manager').default
])
  .config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state('admin-panel.tags', {
      url: '/tags',
      resolve: {
        tags: (Tag) => {
          return Tag.query({ populate: 'createdBy' }).$promise;
        }
      },
      views: {
        'admin-panel': {
          controller: 'AdminPanelTagsListCtrl',
          template: AdminPanelTagsListTemplate,
        }
      },
      permissions: ['admin'],
      data: {
        title: 'Tags'
      }
    });
  })
  .controller('AdminPanelTagsListCtrl', AdminPanelTagsListController)
  .name;
