import angular from 'angular';

export const AdminPanelTribesController = function ($scope, UserManager, tribes, Tribe, Account, TeamsModalsService, TribesModalsService) {
  'ngInject';

  $scope.user = UserManager.data;
  $scope.tribes = tribes;

  $scope.createTribe = function (tribe) {
    var tribeToPost = angular.copy(tribe);
    tribeToPost.creator = tribe.creator._id;

    Tribe.save(tribeToPost, function () {
      $scope.tribes.push(tribe);
      $scope.newTribe = {};
    });
  };

  $scope.openAddTeamModal = function (event, tribe) {
    TeamsModalsService
    //teamId, addMeAutomatically, forceRedirectToCreatedTeam, tribe, event
      .openAddEditModal(null, false, false, tribe, event)
      .result
      .then(function (createdTeam) {
        if (createdTeam) {
          $scope.tribe.teams.push(createdTeam);
        }
      })
      .catch(angular.noop);
  };

  $scope.openAddTribeModal = function (event) {
    TribesModalsService
      .openAddEditModal(undefined, event)
      .result.then(function (newTribe) {
        $scope.tribes.push(newTribe);
      })
      .catch(angular.noop);
  };

};
