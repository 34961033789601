/**
 * loads sub modules and wraps them up into the main module
 * this should be used for top-level module definitions only
 */

import _, { debounce } from 'underscore';
import angular from 'angular';
import locationHelper from 'modules/common/location-helper';

import 'angular-growl-v2';
import 'angularjs-tooltips';
import 'ng-elif';
import './third-party';
import '../sass/main.scss';

import * as Sentry from '@sentry/browser';
import { Angular as AngularIntegration } from '@sentry/integrations';

// Make sure to call Sentry.init after importing AngularJS.
// You can also pass {angular: AngularInstance} to the Integrations.Angular() constructor.
Sentry.init({
  dsn: 'https://b70190972eec49cb9d059efcfa7d0565@o397657.ingest.sentry.io/5252342',
  integrations: [
    new AngularIntegration(),
  ],
});

import { AvatarModule } from 'modules/ui/avatar';
import { PublicTransactionsModule } from './modules/transactions';

const appModule = angular.module('app', [
  'angular-growl',
  'elif',
  'ngSentry',
  'tooltips',
  AvatarModule,
  PublicTransactionsModule,
  require('./angular-locale').default,
  require('./app-constants').default,
  require('@uirouter/angularjs').default,
  require('angular-animate'),
  require('angular-cookies'),
  require('angular-messages'),
  require('modules/accounts').default,
  require('modules/admin-panel').default,
  require('modules/authentication').default,
  require('modules/common/directives').default,
  require('modules/common/emerge.module').default,
  require('modules/common/filters').default,
  require('modules/common/marked.module').default,
  require('modules/common/segment.service').default,
  require('modules/common/user-directive').default,
  require('modules/common/validate-form-control.directive').default,
  require('modules/footer').default,
  require('modules/header').default,
  require('modules/my-deliveries').default,
  require('modules/notifications').default,
  require('modules/order').default,
  require('modules/projects').default,
  require('modules/teams').default,
  require('modules/ui').default,
  require('modules/user-manager').default,
  require('ui-select')
])
  .factory('titleDirective', () => angular.noop) // Disable AngularJS Tooltips for 'title' attribute
  .config(($animateProvider, $compileProvider, $logProvider, $qProvider, DEBUG) => {
    'ngInject';

    $animateProvider.classNameFilter(/animate|growl/);
    $compileProvider.debugInfoEnabled(DEBUG);
    $logProvider.debugEnabled(DEBUG);
    $qProvider.errorOnUnhandledRejections(false);
  })

  .config(($locationProvider, $httpProvider) => {
    'ngInject';

    $locationProvider.html5Mode(true);
    $httpProvider.useApplyAsync(true);
  })

  .config((growlProvider) => {
    'ngInject';

    growlProvider.globalTimeToLive(10000);
    growlProvider.globalDisableCountDown(true);
    growlProvider.globalDisableIcons(true);
  })

  .config((uiSelectConfig) => {
    'ngInject';

    uiSelectConfig.theme = 'select2';
    uiSelectConfig.resetSearchInput = true;
  })

  .config(($urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise($injector => {
      const $location = $injector.get('$location');
      const $window = $injector.get('$window');
      const UserManager = $injector.get('UserManager');
      const user = UserManager.data;
      let redirectUrl = '/projects';

      if (!UserManager.isLogged) {
        pickUpAttributionQueryParams($window, $location);

        redirectUrl = '/signin' + $window.location.search;
      } else if (user.isTeamMember || user.isHiredGun) {
        redirectUrl = '/my-deliveries';
      } else if (user.accountType === 'team_member') {
        redirectUrl = '/teams/';
      }

      return redirectUrl;
    });
  })

  .config(function ($httpProvider) {
    'ngInject';

    $httpProvider.interceptors.push(($q, $location, $injector, growl, UserManager) => {
      return {
        responseError: (response) => {
          const $state = $injector.get('$state');
          const notAtAuthPage = $location.path().indexOf('/reset-password') === -1;

          if (response.status === 401 && notAtAuthPage) {
            // Redirect them back to login page
            UserManager.clean();
            $state.go('signin');
          } else if (response.data && response.data.message) {
            // specify expected conditions when api returns error code, but toast should not be shown
            growl.error(response.data.message);
          } else {
            growl.error('An internal error occurred. Please contact us.');
          }

          return $q.reject(response);
        }
      };
    });
  })

  .run(($transitions, $window, $location, $rootScope, $state, $uibModalStack, UserManager, CapacityService, Team, notifications) => {
    'ngInject';

    const setDeviceType = () => {
      $rootScope.isTablet = window.innerWidth <= 768;
      $rootScope.isDesktop = !$rootScope.isTablet;
    };

    setDeviceType();

    window.addEventListener('resize', debounce(() => {
      setDeviceType();
      $rootScope.$digest();
    }, 100));

    if (window.user) {
      UserManager.set(window.user);
      delete window.user;

      // Load current user's capacity stats
      CapacityService.sync();

      // Load pinned notifications
      notifications.loadPinnedNotifications();
    }

    const permissionsMap = {
      admin: () => UserManager.data.isAdmin,
      hired_gun: () => UserManager.data.isHiredGun,
      team_member: () => UserManager.data.isTeamMember
    };

    $transitions.onStart({}, (trans) => {
      const from = trans.$from();
      const to = trans.$to();


      if (!UserManager.isLogged) {
        pickUpAttributionQueryParams($window, $location);
      }

      const isAuthState = to.data && to.data.type === 'auth';
      const isPublicState = to.data && to.data.type === 'public';

      const forceRedirectTo = (state) => {
        trans.abort();
        $window.location.href = state ? $state.href(state) : '/';
      };

      if (!UserManager.isLogged && !isAuthState && !isPublicState) {
        // logged in user, tries to enter secured state
        forceRedirectTo('signin');
      } else if (UserManager.isLogged && isAuthState) {
        // logged in user, tries to go to auth states (intended for not logged in)
        if (from.name) {
          // prevent app navigating to the auth state
          trans.abort();
        } else {
          // redirect to signin, if app starts at secured state
          forceRedirectTo();
        }
      } else {
        if (to.permissionsChecker && !to.permissionsChecker()) {
          forceRedirectTo();
        } else if (!to.permissionsChecker && (to.permissions || to.data && to.data.permissions)) {
          const permissions = to.permissions || to.data && to.data.permissions;
          let doAllowAccess;
          _(permissions).each((permission) => {
            if (permissionsMap[permission]()) {
              doAllowAccess = true;
            }
          });

          if (!doAllowAccess) {
            forceRedirectTo();
          }
        }
      }

      // Dismiss all modals
      $uibModalStack.dismissAll();
    });

    // Used onSuccess to change background color after transition, without blink effect
    $transitions.onSuccess({}, (trans) => {
      const to = trans.$to();

      $rootScope.contextClass = 'logged-' + (UserManager.isLogged ? 'in' : 'out');

      // Set state-ready to stop splash screen just when all is ready to avoid blinking
      $rootScope.contextClass += ' state-ready';

      // Set context class based on state name: some.module => some-module-state
      if (to.name) {
        $rootScope.contextClass += ' ' + to.name.replace(/([a-z])\.?([A-Z])/g, '$1-$2').replace(/\W+/g, '-').toLowerCase() + '-state';
      }

      // Set title from state
      $rootScope.title = (to.data && to.data.title) || 'Squads.com';
      $rootScope.pageDescription =  (to.data && to.data.pageDescription) || 'Find a team to execute your idea. Squads.com offers vetted teams of freelancers';
    });
  });

/**
 * Pick up referrer from GET parameter and store it to localStorage
 */
function pickUpAttributionQueryParams($window, $location) {
  const params = [
    'referrer',
    'utm_campaign',
    'utm_content',
    'utm_medium',
    'utm_source',
    'utm_term',
  ];

  params.forEach(param => {
    const value = locationHelper.getParam(param);
    if (angular.isDefined(value) && value) {
      $window.localStorage[param] = value;
    }
    $location.search(param, null);
  });
}

angular.injector(['ng'])
  .invoke(($http, $q) => {
    'ngInject';

    function bootstrapApplication(debug) {
      angular.bootstrap(document, ['app'], { strictDi: !debug });
    }

    let config;

    $q.all([
      $http.get('/api/config')
        .then(response => config = response.data)
        .catch(angular.noop),
      $http.get('/api/auth/user')
        .then(response => window.user = response.data)
        .catch(angular.noop),
    ])
      .finally(() => {
        if (config) {
          // Define constants on the main app
          Object.keys(config).map(key => appModule.constant(key, config[key]));
        } else {
          // Mock constants if server didn't reply
          appModule
            .constant('DEBUG', true)
            .constant('ENV', 'prod')
            .constant('SEGMENTIO', { ENABLED: false });
        }

        // bootstrap app
        const debug = config && config.DEBUG || false;
        bootstrapApplication(debug);
      });
  });
