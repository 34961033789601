import { IModalInstanceService } from 'interfaces';
import { IMoney } from 'models/money.model';

export class SendInvoiceModalService {
  constructor(
    private $uibModal: IModalInstanceService,
  ) {
    'ngInject';
  }

  open(event: Event, amount?: IMoney) {
    return this.$uibModal.open({
      event,
      backdrop: 'static',
      component: 'sendInvoiceModal',
      size: 'small',
      resolve: {
        amount: () => amount
      }
    }).result;
  }
}
