export const MyAccountProfileController = function ($scope, Account, growl) {
  'ngInject';

  // some old accounts can happen to have no type
  $scope.user.accountType = $scope.user.accountType || ($scope.user.isTeamMember ? 'team_member' : 'client');

  $scope.saveChanges = function () {
    Account.updateProfile($scope.user, function () {
      growl.success('Your profile has been updated');
    });
  };
};
