import template from './manage-project-guardian.component.html';

export const ManageProjectGuardianComponent = {
  template,
  bindings: {
    project: '<',
    team: '<',
    onUpdate: '&'
  },
  controller: class ManageProjectGuardianController {
    constructor(EventEmitter, Project) {
      'ngInject';

      this.EventEmitter = EventEmitter;
      this.Project = Project;

      this.filter = undefined;
    }

    $onChanges(changes) {
      if (changes.team) {
        this.team = angular.copy(changes.team.currentValue);
      }
    }

    setGuardian(project, userId) {
      this.Project.setGuardian(
        { _id: project._id, userId }
      ).$promise
        .then(() => {
          this.onUpdate(new this.EventEmitter(userId));
        });
    }
  }
};
