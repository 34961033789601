import { find, reject } from 'underscore';

// @ts-ignore
import TeamMembersComponentTemplate from './team-members.component.html';
// @ts-ignore
import TypeaheadMemberDetailsTemplate from './typeahead-member-details.html';

import { ITeamResourceService } from 'interfaces';
import { ITeamExpandedMembers, ITeamMemberExpanded } from 'models/team.model';
import { IAccount, IAccountListItem } from 'models/account.model';
import { IAccountResourceService } from 'models/account-resource.model';
import { UserManagerService } from 'modules/user-manager/user-manager.service';

export const TeamMembersComponent = {
  bindings: {
    team: '<'
  },
  template: TeamMembersComponentTemplate,
  controller: class TeamMembersController {
    // Bindings
    // @ts-ignore
    team: ITeamExpandedMembers = this.team;

    me?: ITeamMemberExpanded;
    selectedUser?: ITeamMemberExpanded;
    readonly: boolean = true;
    readonly user: IAccount;

    constructor(
      private $templateCache: ng.ITemplateCacheService,
      private Account: IAccountResourceService,
      private growl: ng.growl.IGrowlService,
      private Team: ITeamResourceService,
      private UserManager: UserManagerService,
    ) {
      'ngInject';

      this.user = UserManager.data;
      $templateCache.put('/js/modules/teams/view/typeahead-member-details.html', TypeaheadMemberDetailsTemplate);
    }

    $onInit(): void {
      this.me = find(this.team.members, (member) => {
        return member.user._id === this.UserManager.data._id;
      });

      this.readonly = !this.me;
    }

    findUser(name: string): ng.IPromise<IAccountListItem[]> {
      return this.Account.queryActive({ filter: name, populateTotalLoad: true, perPage: 7 }).$promise
        .then((res) => res.data);
    }

    inviteUser(user: IAccountListItem): void {
      // Clear typeahead
      this.selectedUser = undefined;

      function memberIdMatcher(id: string) {
        return (member: ITeamMemberExpanded) => member.user._id === id;
      }

      const doesMemberExist = find(this.team.members, memberIdMatcher(user._id));

      if (doesMemberExist) {
        return;
      }

      if (!confirm(`Do you want to invite ${user.name} to ${this.team.name}?`)) {
        return;
      }

      // Add user immediately (positive UI)
      this.team.members.push({ user } as ITeamMemberExpanded);

      // Invite user to the team
      this.Team.inviteUser({ _id: this.team._id, userId: user._id }).$promise
        .then(() => {
          this.growl.success(`Invited ${user.name}`);
        })
        .catch(() => {
          // Rollback positive UI
          this.team.members = reject(this.team.members, memberIdMatcher(user._id));
        })
        .finally(() => {
          this.selectedUser = undefined;
        });
    }
  }
};
