import _ from 'underscore';

export const HireGunController = function ($scope, $window, team, Account, myTeamsProjects, Project, Team) {
  'ngInject';

  /**
   * Init models
   */

  $scope.team = team;
  $scope.hiredGun = {};

  // filter team.assignedProjects from archived projects and populate
  $scope.team.assignedProjects = _(myTeamsProjects).filter(project => {
    return $scope.team.assignedProjects.indexOf(project._id) !== -1;
  });

  /**
   * Methods
   */

  $scope.selectUser = function (user) {
    $scope.selectedUser = user;
    $scope.hiredGun.user = user._id;
  };

  $scope.hireGun = function () {
    Team.hireGun({ id: team._id }, $scope.hiredGun).$promise
      .then(savedHiredGun => {
        savedHiredGun.user = $scope.selectedUser;
        $scope.$close(savedHiredGun);
      });
  };

  $scope.clearHiredGun = function () {
    $scope.selectedUser = null;
    $scope.hiredGun = {};
  };

  $scope.findUser = function (name) {
    return Account.queryActive({ filter: name }).$promise.then(accounts => accounts.data);
  };
};
