import _ from 'underscore';

// @ts-ignore
import template from './team-feed-client-rating.component.html';

import { IClientAcceptedDemoEvent } from '../../../../../models/event.model';

export const TeamFeedClientRatingComponent = {
  bindings: {
    item: '<',
  },
  template,
  controller: class TeamFeedClientRatingController {
    // Bindings
    // @ts-ignore
    item: IClientAcceptedDemoEvent = this.item;

    isEmpty: boolean = false; // No feedback and no rating
    hasFeedback: boolean = false;
    hasRating: boolean = false;

    orderedRatings: Array<{key: string, value: number}> = [];

    get createdAt() {
      return this.item.createdAt;
    }

    get feedback() {
      return this.item.data.values.feedback;
    }

    get project() {
      return this.item.data.project;
    }

    get ratings() {
      return this.item.data.values.ratings;
    }

    $onInit() {
      this.orderedRatings = _.map(this.ratings, (value, key) => ({ key, value }));
      this.hasFeedback = Boolean(this.feedback);
      this.hasRating = Boolean(this.orderedRatings.length);
      this.isEmpty = !this.hasFeedback && !this.hasRating;
    }
  }
};
