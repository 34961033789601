import angular from 'angular';
import 'jquery.actual';
import template from './progress-caption.html';

export default angular.module('app.ui.progress-caption', [])
  .directive('progressCaption', function () {
    return {
      replace: true,
      restrict: 'AE',
      template,
      scope: {
        max: '=',
        value: '='
      },
      link: function (scope, element) {
        var maxElement = element.find('.progress-caption_max');
        var meterElement = element.find('.progress-caption_meter');
        var titleElement = element.find('.progress-caption_title');
        var valueElement = element.find('.progress-caption_value').hide();

        var getRatio = function () {
          var value = Math.min(Math.max(scope.value, 0), scope.max);
          return value / scope.max;
        };

        scope.$watch(getRatio, function (ratio) {
          setTimeout(function () {
            var elementWidth = element.actual('width');
            var minRight = maxElement.actual('width');
            var maxRight = elementWidth - titleElement.actual('width') - valueElement.actual('width');

            var right = Math.round((1 - ratio) * elementWidth);
            right = Math.min(Math.max(right, minRight), maxRight);

            if (ratio < 1) {
              valueElement
                .css('right', right)
                .show();
              element.removeClass('__done');
            } else {
              valueElement
                .css('right', 0)
                .show();
              element.addClass('__done');
            }

            element.toggleClass('__shifted', right < maxRight);
            meterElement.css('width', 100 * ratio + '%');
          }, 0);
        });
      }
    };
  })
  .name;
