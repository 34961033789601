import { ITeamCreationRequestModel } from './team.model';

export enum EAccountTypes {
  CLIENT = 'client',
  TEAM_MEMBER = 'team_member',
}

export enum EAccountStatuses {
  ACTIVE = 'active',
  BLOCKED = 'blocked',
  INACTIVE = 'inactive',
  REMOVED = 'removed',
}

export type IAccountAttributionKeys = 'utm_campaign' | 'utm_content' | 'utm_medium' | 'utm_source' | 'utm_term';

export interface IAccountAttribution {
  utm_campaign: string;
  utm_content: string;
  utm_medium: string;
  utm_source: string;
  utm_term: string;
}

export interface IAccount {
  _id: string;
  accountType: EAccountTypes;
  attribution?: Partial<IAccountAttribution>;
  changeEmail?: string;
  changeEmailToken?: string;
  changeEmailTokenExpire?: number;
  company?: IAccountCompany;
  createdAt?: number;
  email?: string;
  emailHash?: string;
  exactUserId?: string;
  isAdmin?: boolean;
  isHiredGun?: boolean;
  isTeamMember?: boolean;
  location?: string;
  name: string;
  password?: string;
  phone?: {
    country: string;
    raw: string;
  };
  position?: string;
  preferences?: IAccountPreferences;
  referralBonus?: IReferralBonus;
  referrals?: string[];
  referrer?: string;
  requestedRemoval?: boolean;
  requestedTeam?: ITeamCreationRequestModel;
  resetPasswordToken?: string;
  resetPasswordTokenExpire?: number;
  status: EAccountStatuses;
  vatExempt?: boolean;
}

export interface IAccountListItem {
  _id: string;
  emailHash: string;
  name: string;
  email: string;
  preferences: IAccountPreferences;
  requestedRemoval: boolean;
  status: EAccountStatuses;
  vatExempt: boolean;
}

export interface IAccountCompany {
  createdAt: number;
  email: string;
  inEU: boolean;
  inNL: boolean;
  location: string;
  name: string;
  registrationNumber: string;
  vatId: string;
}

export interface IAccountPreferences {
  [key: string]: boolean;
  notifyProject: boolean;
  notifyReferral: boolean;
  notifyPayments: boolean;
}

export interface IReferralBonus {
  [key: string]: number;
  first: number;
  second: number;
  third: number;
}
