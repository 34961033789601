// @ts-ignore
import template from './unaccepted-weeks.component.html';

import { IMetricsResourceService } from 'models/metrics-resource.model';
import { IMetricsUnacceptedWeeks } from 'models/metrics.model';
import { httpUnacceptedWeeksMetrics } from 'models/mutators/metrics';

export const MetricsUnacceptedWeeksComponent: ng.IComponentOptions = {
  template,
  controller: class MetricsEndOfYearBalancesController implements ng.IController {
    data: IMetricsUnacceptedWeeks[] = [];
    public loading = true;

    constructor(
      private Metrics: IMetricsResourceService,
    ) {
      'ngInject';
    }

    public fetch() {
      this.loading = true;

      this.Metrics.getUnacceptedWeeks().$promise
        .then((data) => {
          this.data = httpUnacceptedWeeksMetrics(data);
          this.loading = false;
        });
    }

    public $onInit() {
      this.fetch();
    }
  },
};
