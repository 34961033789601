import $ from 'jquery';
import _ from 'underscore';
import angular from 'angular';
import moment from 'moment';
import { marked } from 'marked';

export default angular.module('app.common.filters', [
  'app.constants',
])

  .filter('firstWord', function () {
    var whitespace = /\s+/;
    return function (string) {
      if (typeof string !== 'string') {
        return string;
      }
      var firstWord = $.trim(string).split(whitespace)[0];
      if (!firstWord.length) {
        return string;
      }
      return firstWord;
    };
  })

  .filter('avgByKey', function () {
    return function (data, key) {
      if (typeof(data) === 'undefined' || typeof(key) === 'undefined' || !data.length) {
        return 0;
      }

      var sum = 0;
      for (var i = data.length - 1; i >= 0; i--) {
        sum += parseInt(data[i][key]);
      }

      return (sum / data.length).toFixed(1);
    };
  })

  .filter('enumerateUsers', function () {
    return function (data) {
      var names = _(data).chain().pluck('user').pluck('name').unique().value();
      if (names.length > 1) {
        var lastName = names.pop();
        return [names.join(', '), lastName].join(' and ');
      }
      return names[0];
    };
  })

  .filter('stripMarkdown', function (MARKED_OPTIONS, $sce) {
    'ngInject';

    var TAGS = /<[^>]+>/g;

    return function (input) {
      if (typeof input !== 'string') {
        return input;
      }

      input = marked(input, MARKED_OPTIONS).replace(TAGS, '');
      return $sce.trustAsHtml(input);
    };
  })

  .filter('weekTitle', function (CONFIG) {
    'ngInject';

    const cache = {};

    const isSameYear = (a, b) => {
      return moment(a).isSame(b || moment(), 'year');
    };

    return function (input, shortMonth) {
      const cacheProperty = input;
      if (cache.hasOwnProperty(cacheProperty)) {
        return cache[cacheProperty];
      }

      const startOfWeek = moment.utc(input, CONFIG.YEAR_WEEK_FORMAT);
      const endOfWeek = startOfWeek.clone().add(6, 'days');
      let result;

      if (startOfWeek.month() !== endOfWeek.month()) {
        // If week is located in 2 months
        result = startOfWeek.format(
          `D MMM${isSameYear(startOfWeek, endOfWeek) ? '' : ' YYYY'}`) + ' – ' +
          endOfWeek.format(`D MMM${isSameYear(endOfWeek) ? '' : ' YYYY'}`);
      } else {
        // If week is located in same month
        result = startOfWeek.format('D') + '–' +
          endOfWeek.format(`D ${shortMonth ? 'MMM' : 'MMMM'}${isSameYear(endOfWeek) ? '' : ' YYYY'}`);
      }

      cache[cacheProperty] = result;
      return result;
    };
  })

  .filter('weekNumber', function (CONFIG) {
    'ngInject';

    // Accepts week number id, e.g. 201801
    // Returns number of week in year, e.g. 01
    return input => {
      return moment.utc(input, CONFIG.YEAR_WEEK_FORMAT).format('WW');
    };
  })

  .filter('timeAgo', function () {
    // time ago is a heavy calculation
    // hence it's made to be evaluated on every 20th call for every timestamp
    var cache = {};
    var calls = {};

    return function (input) {
      if (!calls[input]) {
        calls[input] = 0;
      }

      if (calls[input] % 20 === 0) {
        cache[input] = moment.utc(input).fromNow();
        if (cache[input].indexOf('a few seconds') !== -1) {
          cache[input] = 'just now';
        }
      }

      calls[input] += 1;
      return cache[input];
    };
  })

  .filter('keysNumber', () => {
    return function (input) {
      if (!_.isObject(input)) {
        return undefined;
      }

      return Object.keys(input).length;
    };
  })

  // keep it the last line
  .name;
