import _ from 'underscore';
import angular from 'angular';

/**
 * Populates budget label by the value in BUDGET_CHOICES constant
 * Example:
 * <span bg-bind="{{ project.budget | projectBudget }}"></span>
 */
export default angular
  .module('app.projects.budget', [require('app-constants').default])
  .filter('projectBudget', BUDGET_CHOICES => {
    'ngInject';

    return value => {
      const budget = _(BUDGET_CHOICES).findWhere({ value: Number(value) });
      return budget ? budget.label : value;
    };
  })
  .name;
