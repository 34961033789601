import _, { findWhere } from 'underscore';
import angular from 'angular';

// @ts-ignore
import template from './team-hired-guns.component.html';

import { IProjectResourceService, ITeamResourceService, ITeamsModalsService } from '../../../../interfaces';
import { IProject } from '../../../../models/project.model';
import { ITeam } from '../../../../models/team.model';

interface IProjectGun {
  gunId: string;
  projectId: string;
  responsibilities: string;
}

interface IGroupedHiredGun {
  _id: string;
  user: string;
  projects: IProjectGun[];
}

export const TeamHiredGunsComponent: ng.IComponentOptions = {
  template,
  bindings: {
    team: '<',
  },
  controller: class TeamHiredGunsController {
    // Bindings
    // @ts-ignore
    team: ITeam = this.team;

    groupedHiredGuns: IGroupedHiredGun[] = [];
    myTeamsProjects: IProject[] = [];

    constructor(
      private $q: ng.IQProvider,
      private CONFIG: any,
      private Project: IProjectResourceService,
      private Team: ITeamResourceService,
      private TeamsModalsService: ITeamsModalsService,
    ) {
      'ngInject';
    }

    $onInit(): void {
      this.Project.queryMy().$promise.then((myTeamsProjects) => {
        this.myTeamsProjects = [...myTeamsProjects];
        this.updateGrouped();
      });
    }

    getProject(id: string): IProject | undefined {
      return findWhere(this.myTeamsProjects, { _id: id });
    }

    openHireGunModal(event: Event) {
      this.TeamsModalsService.openHireGunModal(this.team, event).result
        .then((savedHiredGun) => {
          if (savedHiredGun) {
            this.team.hiredGuns.push(savedHiredGun);
            this.updateGrouped();
          }
        })
        .catch(angular.noop);
    }

    fireGunFromProject(projectGun: IProjectGun) {
      const project = this.getProject(projectGun.projectId);

      if (!project) {
        return;
      }

      if (confirm(`Are you sure you want to fire this gun from "${project.name}"?`)) {
        this.Team.fireGun({ id: this.team._id, gunId: projectGun.gunId }).$promise
          .then(() => {
            const gunToFire = _(this.team.hiredGuns).findWhere({ _id: projectGun.gunId });
            this.team.hiredGuns = _(this.team.hiredGuns).without(gunToFire);
            this.updateGrouped();
          });
      }
    }

    updateGrouped() {
      // Show only guns for not archived projects
      const filteredGuns = _(this.team.hiredGuns).filter((gun) => {
        return Boolean(this.getProject(gun.project));
      });

      this.groupedHiredGuns = _(filteredGuns).chain()
        .groupBy((hiredGun) => hiredGun.user._id)
        .map((groupedHiredGuns, hiredGunId: string) => {
          return {
            _id: hiredGunId,
            user: groupedHiredGuns[0].user,
            projects: _(groupedHiredGuns).map((group) => {
              return {
                gunId: group._id,
                projectId: group.project,
                responsibilities: group.responsibilities
              } as IProjectGun;
            })
          };
        })
        .value();
    }
  }
};
