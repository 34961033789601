// @ts-ignore
import template from './account-integrations.component.html';

import { IAccount } from 'models/account.model';

export const AccountIntegrationsComponent = {
  bindings: {
    account: '<'
  },
  template,
  controller: class AccountIntegrationsController {
    // Bindings
    // @ts-ignore
    account: IAccount = this.account;

    constructor() {
      'ngInject';
    }
  }
};
