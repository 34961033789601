import angular from 'angular';
import { AdminPanelTransactionsModule } from './transactions';
import AdminPanelTemplate from './admin-panel.html';
import { AdminPanelRecurringInvoicesModule } from './recurring-invoices';

export default angular.module('app.admin-panel', [
  AdminPanelTransactionsModule,
  AdminPanelRecurringInvoicesModule,
  require('modules/admin-panel/accounts').default,
  require('modules/admin-panel/bookings-deliveries').default,
  require('modules/admin-panel/companies').default,
  require('modules/admin-panel/events').default,
  require('modules/admin-panel/metrics').default,
  require('modules/admin-panel/pinned-notifications').default,
  require('modules/admin-panel/projects').default,
  require('modules/admin-panel/tags').default,
  require('modules/admin-panel/teams').default,
  require('modules/admin-panel/tribes').default,
  require('@uirouter/angularjs').default
])
  .config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state('admin-panel', {
      url: '/admin-panel',
      abstract: true,
      template: AdminPanelTemplate,
      permissions: ['admin']
    });
  })
  .name;
