// @ts-ignore
import template from './signin.component.html';

import { IAuthResourceService, ISegmentService } from 'interfaces';
import { Recaptcha } from '../recaptcha/recaptcha-component.abstract';

export const SigninComponent = {
  template,
  controller: class SigninController extends Recaptcha {
    account = {
      email: '',
      password: ''
    };

    inProgress = false;
    loginError: string = '';
    resetPasswordSuccess = false;
    resetPasswordMode = false;

    constructor(
      private $q: ng.IQService,
      private $window: ng.IWindowService,
      private Auth: IAuthResourceService,
      private SegmentService: ISegmentService
    ) {
      'ngInject';

      super($q);
    }

    onSubmit() {
      if (this.inProgress) {
        return;
      }

      this.inProgress = true;
      this.resetPasswordSuccess = false;
      this.loginError = '';

      this.recaptcha.execute()
        .then(this.recaptchaVerified)
        .then(($$recaptchaToken: string) => {
          if (this.resetPasswordMode) {
            return this.Auth.resetPassword({
              email: this.account.email,
              $$recaptchaToken
            }).$promise
              .then(() => {
                this.resetPasswordSuccess = true;
              });
          } else {
            return this.Auth.login({ account: this.account, $$recaptchaToken }).$promise
              .then((account) => {
                this.SegmentService.alias(account._id);
                this.SegmentService.track('Signed in', {
                  name: account.name,
                  email: account.email
                });
                this.$window.location.replace('/');
              })
              .catch((error) => {
                this.loginError = error.data.message;
              });
          }
        })
        .finally(() => this.inProgress = false);
    }
  }
};
