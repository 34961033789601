export enum ETeamFeedType {
  CLIENT_ACCEPTED_DEMO = 'CLIENT_ACCEPTED_DEMO',
  PROJECT_MATCHED_TO_TEAM = 'PROJECT_MATCHED_TO_TEAM',
  PROJECT_TRUST_SET = 'PROJECT_TRUST_SET',
  TEAM_INVITED_USER = 'TEAM_INVITED_USER'
}

export enum EExactEventsType {
  ADMIN_CREATE_EXACT_TRANSACTION = 'ADMIN_CREATE_EXACT_TRANSACTION',
  ADMIN_EXACT_TRANSACTION_RESULT = 'ADMIN_EXACT_TRANSACTION_RESULT',
  AUTOMATIC_CREATE_EXACT_TRANSACTION = 'AUTOMATIC_CREATE_EXACT_TRANSACTION',
}

export type IEvent =
  | IAdminCreateExactTransaction
  | IAdminExactTransactionResultEvent
  | IAutomaticCreateExactTransaction
  | IClientAcceptedDemoEvent
  | IProjectMatchedToTeamEvent
  | IProjectTrustSetEvent
  | ITeamInvitedUserEvent;

export interface IClientAcceptedDemoEvent {
  createdAt: Date;
  event: ETeamFeedType.CLIENT_ACCEPTED_DEMO;
  data: {
    initiatingUser: IEventUser;
    project: IEventProject;
    team: IEventTeam;
    values: {
      delivered: number;
      deliveryDescription: string;
      feedback: string;
      ratings: {
        [key: string]: number;
      };
      sprintGoal: string;
      weekId: string;
    };
  };
}

export interface IProjectMatchedToTeamEvent {
  createdAt: Date;
  event: ETeamFeedType.PROJECT_MATCHED_TO_TEAM;
  data: {
    initiatingUser: IEventUser;
    project: IEventProject;
    team: IEventTeam;
    values: {
      isAcceptingOwnTeam: boolean;
    };
  };
}

export interface IProjectTrustSetEvent {
  createdAt: Date;
  event: ETeamFeedType.PROJECT_TRUST_SET;
  data: {
    initiatingUser: IEventUser;
    project: IEventProject;
    team: IEventTeam;
    user: IEventUser;
    values: {
      isTrusted: boolean;
    };
  };
}

export interface ITeamInvitedUserEvent {
  createdAt: Date;
  event: ETeamFeedType.TEAM_INVITED_USER;
  data: {
    initiatingUser: IEventUser;
    team: IEventTeam;
    user: IEventUser;
  };
}

export interface IAdminExactTransactionResultEvent {
  createdAt: Date;
  event: EExactEventsType.ADMIN_EXACT_TRANSACTION_RESULT;
  data: {
    initiatingUser: IEventUser;
    team: IEventTeam;
    transaction: string;
    user: IEventUser;
    values: {
      caller: string;
      request: any;
      response: any;
    }
  };
}

export interface IAutomaticCreateExactTransaction {
  createdAt: Date;
  event: EExactEventsType.AUTOMATIC_CREATE_EXACT_TRANSACTION;
  data: {
    initiatingUser: IEventUser;
    transaction: string;
    values: {
      exactAccountCode: string;
      orderPaymentProvider: string;
      orderId: string;
    }
  };
}

export interface IAdminCreateExactTransaction {
  createdAt: Date;
  event: EExactEventsType.ADMIN_CREATE_EXACT_TRANSACTION;
  data: {
    initiatingUser: IEventUser;
    transaction: string;
    user: IEventUser;
    values: {
      exactAccountCode: string;
      orderPaymentProvider: string;
      orderId: string;
    }
  };
}

export interface IEventProject {
  _id: string;
  name: string;
  owner: string;
}

export interface IEventTeam {
  _id: string;
  name: string;
}

export interface IEventUser {
  _id: string;
  emailHash: string;
  name: string;
}
