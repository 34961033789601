import angular from 'angular';
import UiRouter, { StateProvider } from '@uirouter/angularjs';

import ConstantsModule from 'app-constants';
import ModalsModule from 'modules/teams/modals';
import ProjectResourceModule from 'modules/resources/project';
import TeamResourceModule from 'modules/resources/team';

import { TeamHiredGunsComponent } from './team-hired-guns.component';

export default angular.module('app.teams.hired-guns', [
  ConstantsModule,
  ModalsModule,
  ProjectResourceModule,
  TeamResourceModule,
  UiRouter,
])
  .config(($stateProvider: StateProvider) => {
    'ngInject';

    $stateProvider.state('teams.view.hired-guns', {
      url: '/hired-guns',
      views: {
        content: 'teamHiredGuns'
      }
    });
  })
  .component('teamHiredGuns', TeamHiredGunsComponent)
  .name;
