import _, { isUndefined } from 'underscore';

// @ts-ignore
import template from './my-account-balance.component.html';

import { IAccountResourceService } from 'models/account-resource.model';
import { ICapacityServiceResponse } from 'models/http/account-capacity-http.model';
import { IMoney } from 'models/money.model';
import { BuyCreditsModalService } from 'modules/buy-credits-modal/buy-credits-modal.service';
import { CapacityService } from 'modules/capacity/capacity.service';
import { SendInvoiceModalService } from 'modules/send-invoice-modal/send-invoice-modal.service';
import { Money } from '../../../money/money';
import { UserManagerService } from '../../../user-manager/user-manager.service';
import { StateParams } from '@uirouter/angularjs';

export const MyAccountBalanceComponent = {
  template,
  controller: class MyAccountPreferencesController {
    clientProjects: any;
    freelancerView = false;
    hasExpectedPayments: boolean = false;
    isFreelancer: boolean;
    showToggle: boolean = true;
    stats: ICapacityServiceResponse<IMoney>;

    constructor(
      private Account: IAccountResourceService,
      private BuyCreditsModalService: BuyCreditsModalService,
      private CapacityService: CapacityService,
      private SendInvoiceModalService: SendInvoiceModalService,
      private UserManager: UserManagerService,
      private $stateParams: StateParams,
    ) {
      'ngInject';
    }

    $onInit() {
      this.isFreelancer = this.UserManager.data.isTeamMember ||
        this.UserManager.data.isHiredGun ||
        this.UserManager.data.accountType === 'team_member';
      this.stats = this.CapacityService.data;
      this.hasExpectedPayments = !this.stats.expectedPayments.isZero();
      this.clientProjects = [
        ..._.values(this.CapacityService.data.booked.perProject),
        ..._.values(this.CapacityService.data.delegated.perProject),
      ];

      if (this.isFreelancer) {
        const ownedProjectsNum = Object.keys(this.CapacityService.data.booked.perProject).length;
        // If freelancer also has a project (like Iwein)
        // or depends on which state we've clicked balance widget
        this.freelancerView = isUndefined(this.$stateParams.isFreelancerView) ? ownedProjectsNum === 0 : this.$stateParams.isFreelancerView;
        // Checks for both owned and delegated projects
        this.showToggle = this.clientProjects.length !== 0;
      } else {
        // For "pure" clients
        this.freelancerView = false;
        this.showToggle = false;
      }
    }

    openSendInvoiceModal(event: Event) {
      this.SendInvoiceModalService.open(event)
        .then(() => this.CapacityService.sync());
    }

    openBuyCreditsModal(event: Event, amount?: IMoney) {
      const value = amount ? Money.toRoundedUnit(amount) : undefined;
      this.BuyCreditsModalService.open(value, {})
        .then(() => this.CapacityService.sync());
    }
  }
};
