import angular from 'angular';
import template from './footer.html';

export default angular.module('app.footer', [])
  .directive('appFooter', function () {
    return {
      restrict: 'E',
      template,
      link: function (scope, element) {
        element.addClass('footer');
      }
    };
  })
  .name;

