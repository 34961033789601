import _ from 'underscore';
import angular from 'angular';
import template from './finish-sprint.component.html';

export const FinishSprintComponent = {
  bindings: {
    project: '<',
    team: '<',
    week: '<'
  },
  template,
  controller: class FinishSprintController {
    constructor($uibModal, Account, UserManager) {
      'ngInject';

      this.$uibModal = $uibModal;
      this.Account = Account;

      this.user = UserManager.data;
    }

    $onChanges(changes) {
      if (changes.project) {
        this.project = angular.copy(changes.project.currentValue);
      }

      if (changes.team) {
        this.team = angular.copy(changes.team.currentValue);
      }

      if (changes.week) {
        this.week = angular.copy(changes.week.currentValue);
      }
    }

    showModal(event) {
      this.$uibModal.open({
        event,
        component: 'finishSprintModal',
        resolve: {
          project: () => this.project,
          team: () => this.team,
          week: () => this.week
        }
      }).result
        .then((booking) => {
          angular.extend(this.week.booking, _.pick(booking, ['isSprintFinished', 'deliveryDescription']));
        })
        .catch(() => {
          this.week.booking.isSprintFinished = false;
        });

      this.week.booking.isSprintFinished = true;
    }

    unfinish() {
      this.Account.unfinishSprint(
        { id: this.user._id },
        {
          projectId: this.project._id,
          weekId: this.week.id
        }
      ).$promise
        .catch(() => {
          this.week.booking.isSprintFinished = true;
        });

      this.week.booking.isSprintFinished = false;
    }

  }
};
