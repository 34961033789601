import angular from 'angular';

export default angular.module('app.resources.tribe', [
  require('angular-resource')
])
  .service('Tribe', function ($resource) {
    'ngInject';

    return $resource('/api/tribes/:id/:path', { id: '@_id' }, {});
  })
  .name;
