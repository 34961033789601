/*
Function that takes a string as input and returns a hexadecimal color code as a string.
The function uses a hash function to convert the input string into a numerical value,
which is then used to generate a color code by extracting the RGB values and concatenating them into a hexadecimal string.

Overall, this function is a simple way to generate visually distinct color codes from arbitrary strings.
It can be useful for generating unique colors for data visualization or other applications where color-coding is necessary.
 */
export const stringToColour = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}
