import angular from 'angular';
import _ from 'underscore';
import template from './finish-sprint-modal.component.html';

export const FinishSprintModalComponent = {
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  template,
  controller: class FinishSprintModalController {
    constructor(Account, growl, UserManager) {
      'ngInject';

      this.Account = Account;
      this.growl = growl;
      this.UserManager = UserManager;

      this.isInProgress = false;
    }

    $onChanges(changes) {
      if (changes.resolve) {
        const resolve = angular.copy(changes.resolve.currentValue);
        this.project = resolve.project;
        this.team = resolve.team;
        this.week = resolve.week;
      }
    }

    $onInit() {
      if (this.week.booking.deliveryDescription) {
        this.deliveryDescription = this.week.booking.deliveryDescription;
      } else {
        this.deliveryDescription = autodescribe(this.week);
      }
    }

    finishSprint() {
      if (this.isInProgress) {
        return;
      }

      this.isInProgress = true;

      this.Account.finishSprint({
        id: this.UserManager.data._id,
      }, {
        deliveryDescription: this.deliveryDescription,
        projectId: this.project._id,
        weekId: this.week.id,
      }).$promise
        .then(booking => {
          this.growl.success('Sprint finished.');
          this.close({ $value: booking });
        })
        .finally(() => this.isInProgress = false);
    }
  }
};

function autodescribe(week) {
  var deliveryComments = _(week.myDeliveries).chain()
    .concat(week.otherDeliveries)
    .sortBy(function (delivery) {
      return new Date(delivery.createdAt);
    })
    .pluck('comment')
    .compact()
    .value();

  if (deliveryComments.length) {
    return '- ' + deliveryComments.join('\n- ');
  }

  return '';
}

