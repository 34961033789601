import _ from 'underscore';
import angular from 'angular';
import moment from 'moment';
import { Money } from 'modules/money/money';

export const ProjectsTeamMatchingController = function (
  $rootScope, $scope, $state, $transition$, $timeout,
  CapacityService,  CONFIG, projectTeamMatching, projectModals, projectService, project,
  Team, TeamsModalsService, UserManager,
) {
  'ngInject';

  const transitionParams = $transition$.params();

  $scope.project = project;
  $scope.user = UserManager.data;
  $scope.currentWeek = moment().format(CONFIG.YEAR_WEEK_FORMAT);
  $scope.ratings = ['Predictability', 'Transparency', 'Excellent results'];

  $scope.isProjectDelegated = _($scope.project.assistants).some(assistantId => assistantId === UserManager.data._id);

  $scope.restoreProject = projectService.restoreProject;
  $scope.showEditProjectModal = projectModals.openAddEditProject;

  // Load available teams to show in a list if project has no assignedTeam yet
  if (!$scope.project.assignedTeam) {
    Team.query({
      project: $scope.project._id,
      populate: 'members.user',
      stats: true
    }, function (availableTeams) {
      projectTeamMatching.defineRelevance($scope.project, availableTeams);

      availableTeams = _(availableTeams).map(team => {
        // Shuffle members list
        team.members = _(team.members).shuffle();

        // Remove all dots at the end of description
        if (team.description && team.description[team.description.length - 1] === '.') {
          team.description = team.description.replace(/\.+$/, '');
        }

        return team;
      });

      $scope.recommendedTeams = projectTeamMatching.getRecommendedTeams(availableTeams, 3);
      $scope.otherTeams = _(availableTeams).reject(team => _($scope.recommendedTeams).findWhere({ _id: team._id }));

      // open team conversation modal immediately if defined so in state
      if (transitionParams.autoOfferTeamId) {
        TeamsModalsService.openHireModal(
          _(availableTeams).findWhere({ _id: transitionParams.autoOfferTeamId }),
          $scope.project,
          true
        ).result.catch(angular.noop);
      }
    });
  }

  // Load project owner's capacity stats
  if ($scope.isProjectDelegated) {
    CapacityService.showStatsForUserInProject($scope.project.owner, $scope.project);
  }

  // Bounce credits widget to change user's focus
  if (Money.isZeroOrNegative(CapacityService.data.balance)) {
    $timeout(() => {
      $rootScope.$emit('animate-credits');
    }, 1000);
  }

  $scope.removeProject = function () {
    var projectId = $scope.project._id;
    projectService.removeProject($scope.project, function () {
      $state.go('projects.single', { id: projectId }, { reload: true });
    });
  };
};
