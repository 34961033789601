import { IMoney } from './money.model';
import { IProject } from './project.model';
import { IAccount } from './account.model';

export enum ECapacityOperationType {
  BOOKING = 'booking',
  DELIVERY = 'delivery',
}

export interface ICapacityOperation {
  comment: string;
  createdAt: Date | number;
  deliveryDescription: string;
  isAccepted: boolean;
  isSprintFinished: boolean;
  isTrusted: boolean;
  project: string;
  team: string;
  type: ECapacityOperationType;
  user: string;
  value: IMoney;
  week: number;
}

export interface ICapacityOperationPopulated {
  comment: string;
  createdAt: Date | number;
  deliveryDescription: string;
  isAccepted: boolean;
  isSprintFinished: boolean;
  isTrusted: boolean;
  project: IProject;
  team: string;
  type: ECapacityOperationType;
  user: IAccount;
  value: IMoney;
  week: number;
}
