import _, { findWhere, noop } from 'underscore';
import angular from 'angular';
import moment from 'moment';
import template from './project-planner.component.html';
import { Money } from 'modules/money/money';

export const ProjectPlannerComponent = {
  bindings: {
    capacityWeeks: '<',
    project: '<',
    transaction: '<'
  },
  template,
  controller: class ProjectPlannerController {
    constructor(
      $rootScope, $window, $state, $timeout, Account, BuyCreditsModalService, CapacityService, CONFIG,
      growl, projectService, TrustedProjectLackingCreditsModalService,
      UserManager, ALLOWED_TRUSTED_CLIENT_CREDIT_DEFICIT
    ) {
      'ngInject';

      this.$window = $window;
      this.$rootScope = $rootScope;
      this.$state = $state;
      this.$timeout = $timeout;
      this.AccountResource = Account;
      this.BuyCreditsModalService = BuyCreditsModalService;
      this.CapacityService = CapacityService;
      this.CONFIG = CONFIG;
      this.growl = growl;
      this.projectService = projectService;
      this.TrustedProjectLackingCreditsModalService = TrustedProjectLackingCreditsModalService;
      this.UserManager = UserManager;
      this.ALLOWED_TRUSTED_CLIENT_CREDIT_DEFICIT = ALLOWED_TRUSTED_CLIENT_CREDIT_DEFICIT;
    }

    $onInit() {
      this.currentWeek = moment.utc().format(this.CONFIG.YEAR_WEEK_FORMAT);
      this.weeks = [];
      this.ratings = ['Quality of presentation', 'Meets expectations', 'Collaboration'];

      // Is week to accept
      this.weekToAccept = this.$state.params.weekToAccept;

      this.capacityWeeks = _(this.capacityWeeks).mapObject(week => {
        week.booked = Money.create(week.booked);
        week.delivered = Money.create(week.delivered);
        return week;
      });

      // Find unaccepted week
      const firstUnacceptedWeek = _(this.capacityWeeks).find(week => this.canBeAccepted(week));
      const unacceptedWeekMoment = firstUnacceptedWeek && moment.utc(firstUnacceptedWeek.id, this.CONFIG.YEAR_WEEK_FORMAT).startOf('week');

      // Start with first unaccepted week or current week
      this.start = unacceptedWeekMoment || moment.utc().startOf('week');
      this.end = moment.utc().startOf('week').add(2, 'weeks');

      this.active = null;
      this.activeMode = null;

      this.syncingCapacity = false;

      this.renderWeeks(this.start, this.end, this.capacityWeeks);

      this.checkForWeekToAccept();
    }

    acceptDemo(week, { averageRating, ratings, feedback }) {
      this.active = null; // Unselect active week

      const backup = {
        feedback: week.feedback,
        ratings: angular.copy(week.ratings)
      };

      // Apply updates from child component
      week.ratings = ratings;
      week.feedback = feedback;

      if (averageRating === 0) {
        delete week.ratings;
      }

      this.AccountResource.acceptDemo(
        {
          id: this.UserManager.data._id,
          projectId: this.project._id
        },
        {
          delivered: week.delivered.getAmount(),
          weekId: week.id,
          ratings: week.ratings,
          feedback: week.feedback
        }
      ).$promise
        .then(() => {
          week.isDemoAccepted = true;
          week.isRatingTeamFormOpened = false;
          this.weeks = _(this.weeks).without(week);

          // Update capacity stats
          this.syncingCapacity = true;
          this.CapacityService.sync().finally(() => this.syncingCapacity = false);
        })
        .catch(() => {
          week.feedback = backup.feedback;
          week.ratings = backup.ratings;
        });
    }

    isActiveWeek(week) {
      return week.id === this.active;
    }

    isPastWeek(week) {
      return week.id < this.currentWeek;
    }

    // Show when week can be accepted and there are enough money
    isWeekAcceptButtonVisible(week) {
      return this.canBeAccepted(week)
        && Money.isGreaterThanZero(week.delivered)
        && this.CapacityService.data.balance.greaterThanOrEqual(week.delivered)
        && !week.isRatingTeamFormOpened;
    }

    // Show when week can be accepted and there are not enough money
    isWeekPayAndAcceptButtonVisible(week) {
      return this.canBeAccepted(week)
        && Money.isGreaterThanZero(week.delivered)
        && this.CapacityService.data.balance.lessThan(week.delivered)
        && !week.isRatingTeamFormOpened;
    }

    isWeekRecoverButtonVisible(week) {
      return this.canBeAccepted(week) && Money.isZeroOrNegative(week.delivered);
    }

    canBeAccepted(week) {
      const isCurrentWeek = week.id === this.currentWeek;
      const hasNonZeroBooking = week.booked && Money.isGreaterThanZero(week.booked);
      const hasNonZeroDeliveries = week.delivered && Money.isGreaterThanZero(week.delivered);

      const isCurrentWeekWithoutDeliveries = isCurrentWeek && hasNonZeroBooking && !hasNonZeroDeliveries;
      return !week.isDemoAccepted // already accepted?
        && hasNonZeroBooking // anything booked?
        && !isCurrentWeekWithoutDeliveries // current week in the booking process
        && (this.isPastWeek(week) || week.isSprintFinished);
    }

    // Check if there is an acceptance in progress
    checkForWeekToAccept() {
      if (this.weekToAccept) {
        const found = findWhere(this.weeks, { id: this.weekToAccept });
        if (found) {
          if (this.isWeekPayAndAcceptButtonVisible(found)) {
            this.growl.error('The payment is not confirmed yet. Please make sure the payment was finished and check again. Contact support in case of issues.');
          } else {
            this.toggleAcceptDemoForm(found);
          }
        }
      }
    }

    openBuyCreditsModal(event, week) {
      event.stopPropagation();

      const lackingAmount = week.delivered.subtract(this.CapacityService.data.balance);
      const lackingAmountNumber = Money.toRoundedUnit(lackingAmount, 2);
      const trustedClientDeficit = -Money.toRoundedUnit(Money.create(this.ALLOWED_TRUSTED_CLIENT_CREDIT_DEFICIT));

      if (this.project.isTrusted && lackingAmountNumber <= trustedClientDeficit) {
        // For trusted projects allow lack of at most 100 euros
        this.TrustedProjectLackingCreditsModalService.open()
          .then(() => {
            this.toggleAcceptDemoForm(week);
          })
          .catch(() => {});
      } else {
        const orderApiParams = {
          // Current location as a mollie redirectUrl
          redirectUrl: this.$window.location.pathname,
          weekToAccept: week.id
        };

        this.BuyCreditsModalService.open(lackingAmountNumber, orderApiParams)
          .catch(noop);
      }
    }

    sprintCanBeChanged(week) {
      return !this.isPastWeek(week) && !week.isDemoAccepted;
    }

    getHiddenBookedAmount() {
      // Prevent blinking of numbers when client is waiting for server response
      if (this.syncingCapacity) {
        return this.getHiddenBookedAmount.cache;
      }

      const capacityData = this.CapacityService.data.booked.perProject[this.project._id] || this.CapacityService.data.delegated.perProject[this.project._id];
      const totalBooked = (capacityData && capacityData.totalBooked) || Money.zero();

      const visibleBooked = _(this.weeks).reduce((memo, week) => {
        return week.isDemoAccepted ? memo : memo.add(week.bookedServer);
      }, Money.zero());

      const value = totalBooked.subtract(visibleBooked);

      this.getHiddenBookedAmount.cache = value;

      return value;
    }

    loadMoreWeeks() {
      if (this.loadMoreWeeks.isInProgress) {
        return;
      }

      this.loadMoreWeeks.isInProgress = true;

      var intervalStart = this.end.clone().add(1, 'week');
      var intervalEnd = this.end.add(2, 'week'); // it changes end intentionally

      this.AccountResource.getProjectCapacityWeeks({
        id: this.UserManager.data._id,
        projectId: this.project._id,
        start: intervalStart.format(this.CONFIG.YEAR_WEEK_FORMAT),
        end: intervalEnd.format(this.CONFIG.YEAR_WEEK_FORMAT)
      }).$promise
        .then(olderCapacityWeeks => {
          const weeks = _(olderCapacityWeeks.toJSON()).mapObject(week => {
            week.booked = Money.create(week.booked);
            week.delivered = Money.create(week.delivered);
            return week;
          });

          this.renderWeeks(intervalStart, intervalEnd, weeks);
        })
        .finally(() => this.loadMoreWeeks.isInProgress = false);
    }

    renderWeeks(start, end, capacityWeeks) {
      var weeksIterator = start.clone();
      // loop from start to end with 1 week step
      while (moment.utc(weeksIterator, this.CONFIG.YEAR_WEEK_FORMAT) <= moment.utc(end, this.CONFIG.YEAR_WEEK_FORMAT)) {
        var weekId = weeksIterator.format(this.CONFIG.YEAR_WEEK_FORMAT);
        var weekFromServer = capacityWeeks && capacityWeeks[weekId];
        var booked = weekFromServer ? weekFromServer.booked : Money.zero();
        var delivered = weekFromServer ? weekFromServer.delivered : Money.zero();
        var comment = weekFromServer ? weekFromServer.comment : '';

        var isPast = weekId < this.currentWeek;
        var isDemoAccepted = weekFromServer && weekFromServer.isDemoAccepted;
        var isSprintFinished = weekFromServer && weekFromServer.isSprintFinished;

        // set 1 week forward for next iteration
        weeksIterator.add(1, 'week');

        // Past weeks that are either accepted or had nothing booked
        // are not being added
        if (isPast && (isDemoAccepted || booked.isZero())) {
          continue;
        }

        // Fetching data from the server
        this.weeks.push({
          id: weekId,
          booked: booked,
          bookedServer: booked,
          comment: comment,
          commentServer: comment,
          delivered: delivered,
          deliveryDescription: weekFromServer && weekFromServer.deliveryDescription,
          isDemoAccepted: isDemoAccepted,
          isSprintFinished: isSprintFinished,
          ratings: {}
        });
      }
    }

    restoreProject(project) {
      return this.projectService.restoreProject(project);
    }

    unfocus(week, field) {
      if (field === 'goal') {
        this.setActiveWeek(week, 'budget');
      } else {
        this.setActiveWeek(null);
      }
    }

    onBudgetChanged($event, week) {
      if (week.booked.equalsTo($event.value)) {
        this.unfocus(week, 'budget');
        return;
      }

      week.booked = $event.value;
      this.saveBooking(week, 'budget');

      if (!this.project.isTrusted && this.CapacityService.available.lessThanOrEqual(Money.create(this.CONFIG.MIN_CREDITS_TO_NOTICE))) {
        this.$rootScope.$emit('animate-credits');
      }
    }

    saveBooking(week, field) {
      const weekToBook = {
        id: week.id,
        amount: week.booked.getAmount(),
        comment: week.comment
      };

      this.AccountResource.book({
        id: this.UserManager.data._id,
        projectId: this.project._id,
        weeks: [weekToBook]
      }).$promise.then(() => {
        week.bookedServer = week.booked;
        week.commentServer = week.comment;
        this.syncingCapacity = true;
        this.CapacityService.sync()
          .finally(() => this.syncingCapacity = false);
      }).catch(() => {
        week.booked = week.bookedServer;
        week.comment = week.commentServer;
      });

      this.unfocus(week, field);
    }

    recoverBooking(week, field) {
      this.AccountResource.recoverCapacity(
        { id: this.UserManager.data._id, projectId: this.project._id, },
        { weekId: week.id }
      ).$promise.then(() => {
        week.bookedServer = Money.zero();
        week.booked = Money.zero();
        this.syncingCapacity = true;
        this.CapacityService.sync()
          .finally(() => this.syncingCapacity = false);
      }).catch(() => {
        week.booked = week.bookedServer;
      });

      this.unfocus(week, field);
    }

    setActiveWeek(week, mode, $event) {
      if ($event) {
        $event.stopPropagation();
      }

      if (!week) {
        this.active = null;
        return;
      }

      if (mode === 'budget' && !this.sprintCanBeChanged(week)) {
        return;
      }

      if (mode === 'sprint-goals' && !this.sprintCanBeChanged(week)) {
        return;
      }

      this.active = week.id;
      this.activeMode = mode;

      if ($event) {
        window.requestAnimationFrame(() => {
          angular.element($event.currentTarget)
            .find('input')
            .select();
        });
      }
    }

    toggleAcceptDemoForm(week) {
      this.active = null;

      week.isRatingTeamFormOpened = !week.isRatingTeamFormOpened;
    }

    handleTransactionStatusUpdate(status) {
      // Update order status
      this.transaction.status = status;
    }
  }
};
