import { IRecaptchaComponent } from 'interfaces';

export abstract class Recaptcha {
  recaptcha: IRecaptchaComponent;

  constructor(
    // Here name does not matter, because this class does not use angularjs dependency injection.
    // _$q is used to highlight the expected content, _ is added to prevent name overlapping in deriving classes.
    private _$q: ng.IQService,
  ) {
  }

  handleRecaptchaInit($event: { controller: IRecaptchaComponent }): void {
    this.recaptcha = $event.controller;
  }

  protected recaptchaVerified = (token: string) => {
    if (token === '') {
      return this._$q.reject();
    } else {
      return this._$q.resolve(token);
    }
  }
}
