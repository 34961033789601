import _ from 'underscore';
import angular from 'angular';

export const AdminPanelTagsListController = function ($scope, Tag, tags) {
  'ngInject';

  $scope.tags = [];
  $scope.pristineTags = [];

  function storeTags(newTags) {
    $scope.tags.length = 0;
    $scope.pristineTags.length = 0;
    [].push.apply($scope.tags, newTags);
    [].push.apply($scope.pristineTags, angular.copy(newTags));
  }

  $scope.filterQuery = '';

  storeTags(tags);

  /**
   * Methods
   */

  $scope.removeTag = function (tag) {
    storeTags(_($scope.tags).without(tag));

    tag.$remove();
  };

  // Get pristineTags by id
  $scope.getPristineTag = function (id) {
    return _($scope.pristineTags).findWhere({ _id: id });
  };

  $scope.saveTagTitles = function () {
    _($scope.tags).chain()
      .filter(function (tag, index) {
        // Find what tags have titles updated
        return tag.title !== $scope.pristineTags[index].title;
      })
      .each(function (tag) {
        Tag.update(tag, function (updatedTag) {
          var newTags = angular.copy($scope.tags);
          var index = _($scope.tags).findIndex({ _id: tag._id });
          newTags[index].title = updatedTag.title;
          storeTags(newTags);
        });
      });
  };
};
