import angular from 'angular';
import MetricsResourceModule from 'modules/resources/metrics';
import MoneyModule from 'modules/money';
import UiRouterModule from '@uirouter/angularjs';
import { AdminPanelMetricsComponent } from './admin-panel-metrics.component';
import { MetricsEndOfYearBalancesModule } from './end-of-year-balances';
import { MetricsUnacceptedWeeksModule } from './unaccepted-weeks';
import { MetricsWeeklyStatsModule } from './weekly-stats';
import { MetricsAnnualEarningsModule } from './annual-earnings';
import { YearPicker } from './year-picker/year-picker.component';
import { AnnualEarningsChartComponent } from './annual-earnings-chart/annual-earnings-chart.component';

export default angular.module('app.admin-panel.metrics', [
  MetricsAnnualEarningsModule,
  MetricsEndOfYearBalancesModule,
  MetricsResourceModule,
  MetricsUnacceptedWeeksModule,
  MetricsWeeklyStatsModule,
  MoneyModule,
  UiRouterModule
])
  .config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state('admin-panel.metrics', {
      url: '/metrics',
      redirectTo: 'admin-panel.metrics.annual-earnings',
      views: {
        'admin-panel': {
          component: 'adminPanelMetrics'
        }
      },
      permissions: ['admin'],
      data: {
        title: 'Metrics | Admin panel'
      }
    });
  })
  .component('yearPicker', YearPicker)
  .component('annualEarningsChart', AnnualEarningsChartComponent)
  .component('adminPanelMetrics', AdminPanelMetricsComponent)
  .name;
