import _ from 'underscore';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { Money } from 'modules/money/money';
import { EOrderPaymentProvider, EOrderStatus } from '../../../../models/order.model';

export const MyAccountTransactionsController = function ($filter, $scope, transactions, UserManager) {
  'ngInject';

  $scope.user = UserManager.data;
  $scope.groupedTransactions = groupTransactionsByMonth(transactions);

  $scope.downloadTransactionsCsv = function () {
    const filename = 'transactions.csv';
    const csvPrefix = 'Payment date;Credits;Invoiceable;Status;Description\n';
    const data = _($scope.groupedTransactions).reduce(transactionFormatter, csvPrefix);
    const blob = new Blob([data], {type: 'data:text/csv;charset=utf-8;'});

    saveAs(blob, filename);
  };

  function groupTransactionsByMonth(transactions) {
    function creationMonth(order) {
      return moment(order.createdAt).format('YYYYMM');
    }

    function translateCapacityIntoMoney(transaction) {
      transaction.capacity = transaction.capacity ? Money.create(transaction.capacity) : Money.zero();
    }

    const result = [];

    _(transactions).chain()
      .each(translateCapacityIntoMoney)
      .groupBy(creationMonth)
      .mapObject((ordersForGivenMonth, month) => {
        const meaningOrders = ordersForGivenMonth.filter((order) => {
          if (order.paymentProvider === EOrderPaymentProvider.transactionFee) {
            return false;
          }

          if (order.status === EOrderStatus.paid) {
            return true;
          }
        });

        const totalEarned = _(meaningOrders).reduce((acc, order) => {
          return Money.isGreaterThanZero(order.capacity) ? acc.add(order.capacity) : acc;
        }, Money.zero());

        const totalInvoiced = _(meaningOrders).reduce((acc, order) => {
          return order.capacity.isNegative() ? acc.add(order.capacity) : acc;
        }, Money.zero());

        result.push({
          yearMonth: parseInt(month, 10),
          transactions: ordersForGivenMonth,
          title: moment(month, 'YYYYMM').format('MMM YYYY'),
          totalEarned,
          totalInvoiced: totalInvoiced.multiply(-1) // Make value positive to render in template
        });
      });

    return result.sort((a, b) => {
      return b.yearMonth - a.yearMonth;
    });
  }

  function transactionFormatter(memo, month) {
    const transactions = month.transactions.reduce((memo, transaction) => {
      return memo + ($filter('date')(transaction.createdAt, 'medium') + ';'
        + $filter('money')(transaction.capacity) + ';'
        + $filter('invoiceableMoney')(transaction.capacity) + ';'
        + transaction.status + ';'
        + (transaction.description ? transaction.description : '') + '\n');
    }, '');

    return memo + transactions;
  }
};
