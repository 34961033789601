export class FadeoutOverflowService {
  constructor($window, ScrollService) {
    'ngInject';

    this.$window = $window;
    this.ScrollService = ScrollService;
  }

  assertValidDirection(direction, componentName) {
    if (direction !== 'x' && direction !== 'y') {
      throw new Error(
        `[${componentName}] Expected value of 'x' or 'y' but got '${direction}'`
      );
    }
  }

  update(fadeoutElement, scrollElement, direction, shouldAnimate) {
    this.$window.requestAnimationFrame(() => {
      const scrollStart = this.ScrollService.getScroll(
        scrollElement,
        direction === 'x' ? 'left' : 'top'
      );

      const scrollEnd = this.ScrollService.getScroll(
        scrollElement,
        direction === 'x' ? 'right' : 'bottom'
      );

      fadeoutElement.toggleClass('fadeout-overflow_animate', shouldAnimate);
      fadeoutElement.toggleClass('fadeout-overflow_start', scrollStart > 0);
      fadeoutElement.toggleClass('fadeout-overflow_end', scrollEnd > 0);
    });
  }
}
