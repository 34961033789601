import { ISegmentService, ITeamResourceService } from '../../../../interfaces';
import { ITeam } from '../../../../models/team.model';
import { pick } from 'underscore';
import { StateService } from '@uirouter/angularjs';

// @ts-ignore
import template from './team-settings.component.html';

export const TeamSettingsComponent: ng.IComponentOptions = {
  template,
  bindings: {
    team: '<',
  },
  controller: class TeamSettingsController {
    // Bindings
    // @ts-ignore
    team: ITeam = this.team;

    constructor(
      private $state: StateService,
      private $window: ng.IWindowService,
      private growl: angular.growl.IGrowlService,
      private Team: ITeamResourceService,
      private SegmentService: ISegmentService
    ) {
      'ngInject';
    }

    changeTeamEmail() {
      this.Team.update(pick(this.team, ['_id', 'email'])).$promise.then(() => {
        this.growl.success('Updated.');
      });
    }

    leaveTeam() {
      if (confirm('Are you sure you would like to leave this team?')) {
        this.Team.leave({ _id: this.team._id }).$promise.then(() => {
          this.SegmentService.track('Click leave team');
          this.$window.localStorage.resourceCacheBuster = Date.now();
          this.$state.go('teams.list');
        });
      }
    }
  }
};
