// @ts-ignore
import template from './send-invoice-form.component.html';

import * as angular from 'angular';
import { IMoney } from 'models/money.model';
import { IOrderRequestPaymentRequest } from 'models/http/order-http.model';
import { Money } from 'modules/money/money';
import { CapacityService } from 'modules/capacity/capacity.service';
import { UserManagerService } from 'modules/user-manager/user-manager.service';

export const SendInvoiceFormComponent: ng.IComponentOptions = {
  bindings: {
    onShowCompanyForm: '&',
    onSuccess: '&',
  },
  template,
  controller: class SendInvoiceFormController implements ng.IController {
    // bindings
    // @ts-ignore
    onShowCompanyForm: () => void = this.onShowCompanyForm;
    // @ts-ignore
    onSuccess: () => void = this.onSuccess;

    accountDetailsChanged: boolean = false;
    numberAvailableToInvoice: number = 0;
    moneyAvailableToInvoice: IMoney = Money.zero();
    isUploading: boolean = false;

    // User input
    amount: number = 0;
    file: File | null = null;
    invoiceNumber: string = '';

    get isCompanySet() {
      return this.UserManager.isCompanySet;
    }

    constructor(
      private CapacityService: CapacityService,
      private growl: angular.growl.IGrowlService,
      private Order: any,
      private UserManager: UserManagerService,
      private $filter: ng.IFilterService,
      private readonly PLATFORM_FEE: number,
      private readonly TRANSACTION_AMOUNT_WITHOUT_FEE: number,
      private readonly TRANSACTION_FEE: number,
    ) {
      'ngInject';
      this.TRANSACTION_AMOUNT_WITHOUT_FEE = TRANSACTION_AMOUNT_WITHOUT_FEE / 100; // cents to euros
      this.TRANSACTION_FEE = TRANSACTION_FEE / 100; // cents to euros
    }

    $onInit(): void {
      this.moneyAvailableToInvoice = this.CapacityService.data.balance.percentage(this.invoiceablePercentage);
      this.numberAvailableToInvoice = Money.toRoundedUnit(this.moneyAvailableToInvoice);

      // If available money are less than TRANSACTION_AMOUNT_WITHOUT_FEE, subtract transaction fee which is inevitable
      if (this.numberAvailableToInvoice < this.TRANSACTION_AMOUNT_WITHOUT_FEE) {
        this.numberAvailableToInvoice = this.numberAvailableToInvoice - this.TRANSACTION_FEE;
      }

      this.amount = this.numberAvailableToInvoice;
    }

    get invoiceablePercentage(): number {
      return (1 - this.PLATFORM_FEE) * 100;
    }

    get transactionCostsAreTooHigh(): boolean {
      return this.amount < this.TRANSACTION_AMOUNT_WITHOUT_FEE;
    }

    attachFile(e: Event): void {
      if (!e || !e.target) {
        return;
      }

      const target = e.target as HTMLInputElement;

      if (!target.files || !target.files.length) {
        return;
      }

      const file = target.files[0];
      const extension = file.name.split('.').pop();

      if (extension !== 'pdf' || file.type !== 'application/pdf') {
        this.growl.error('Only PDF files are allowed to be uploaded.');
        return;
      }

      this.file = file;

      // @ts-ignore
      // The element's value is being reset to allow consequent selection of a same file.
      // This should be done synchronously inside the event handler so it won't cause
      // another change event trigger in IE.
      e.target.value = null;
    }

    clearFile(e: Event): void {
      e.preventDefault();

      this.file = null;
    }

    objectToFormData(object: any): FormData {
      const formdata = new FormData();

      for (const i in object) {
        if (object.hasOwnProperty(i)) {
          formdata.append(i, object[i]);
        }
      }

      return formdata;
    }

    sendInvoice(form: ng.IFormController): void {
      if (this.isUploading) {
        return;
      }

      if (form.$invalid || !this.file) {
        return;
      }

      this.isUploading = true;

      let description = `Invoice ${this.invoiceNumber} sent on ${this.$filter('date')(Date.now(), 'yyyy-MM-dd')}`;
      if (this.accountDetailsChanged) {
        description = 'CHANGED, ' + description;
      }

      const payload: IOrderRequestPaymentRequest = {
        amount: Money.createFromEuros(this.amount).getAmount(),
        description,
        file: this.file,
        invoiceNumber: this.invoiceNumber
      };

      this.Order.requestPayment({}, this.objectToFormData(payload)).$promise
        .then(() => {
          this.growl.success('A confirmation of your payment request has been sent to your email.');
        })
        .then(() => this.CapacityService.sync())
        .then(() => this.onSuccess())
        .finally(() => this.isUploading = false);
    }

    showCompanyForm(): void {
      this.onShowCompanyForm();
    }
  }
};
