import _ from 'underscore';

import { IMoney } from '../money.model';
import { Money } from 'modules/money/money';
import {
  ICapacityBooking, ICapacityDelivery,
  ICapacityServiceHttpResponse, ICapacityServicePendingInvoices, ICapacityServiceProject,
  ICapacityServiceResponseItem, ICapacityServiceValue
} from '../http/account-capacity-http.model';

export function httpCapacityResponseItem(data: ICapacityServiceResponseItem<number>): ICapacityServiceResponseItem<IMoney> {
  const response: ICapacityServiceResponseItem<IMoney> = {
    totalDelivered: Money.create(data.totalDelivered),
  } as ICapacityServiceResponseItem<IMoney>;

  if (data.hasOwnProperty('totalBooked')) {
    response.totalBooked = Money.create(data.totalBooked);
  }

  response.perProject = _.mapObject(data.perProject, (item) => {
    const itemResponse = {
      project: item.project,
      team: item.team,
      totalDelivered: Money.create(item.totalDelivered),
    } as ICapacityServiceProject<IMoney>;

    if (item.hasOwnProperty('totalBooked')) {
      itemResponse.totalBooked = Money.create(item.totalBooked);
    }

    if (item.hasOwnProperty('bookings')) {
      itemResponse.bookings = _.mapObject(item.bookings, (bookings) => bookings.map(mapBooking));
    }

    itemResponse.deliveries = _.mapObject(item.deliveries, (deliveries) => deliveries.map(mapDelivery));

    return itemResponse;
  });

  return response;
}

export function httpAccountCapacity(data: ICapacityServiceHttpResponse): ICapacityServiceValue {
  const response: ICapacityServiceValue = {
    balance: Money.create(data.balance),
    expectedPayments: Money.create(data.expectedPayments),
    pendingInvoices: mapPendingInvoices(data.pendingInvoices),
    awaitingAcceptance: httpCapacityResponseItem(data.awaitingAcceptance),
    booked: httpCapacityResponseItem(data.booked),
    delegated: httpCapacityResponseItem(data.delegated),
  };

  return response;
}

export function mapBooking(booking: ICapacityBooking<number>): ICapacityBooking<IMoney> {
  return {
    comment: booking.comment,
    createdAt: booking.createdAt,
    isAccepted: booking.isAccepted,
    isTrusted: booking.isTrusted,
    value: Money.create(booking.value),
    week: booking.week,
  } as ICapacityBooking<IMoney>;
}

export function mapDelivery(delivery: ICapacityDelivery<number>): ICapacityDelivery<IMoney> {
  return {
    comment: delivery.comment,
    createdAt: delivery.createdAt,
    value: Money.create(delivery.value),
    week: delivery.week,
  } as ICapacityDelivery<IMoney>;
}

export function mapPendingInvoices(pendingInvoices: ICapacityServicePendingInvoices<number>): ICapacityServicePendingInvoices<IMoney> {
  return {
    totalInvoiced: Money.create(pendingInvoices.totalInvoiced),
    items: pendingInvoices.items.map<any>((i) => {
      i.capacity = Money.create(-1 * i.capacity) as any;
      return i;
    })
  };
}
