import angular from 'angular';

export default angular.module('app.resources.notification', [
  require('angular-resource')
])
  .service('Notification', function ($resource) {
    'ngInject';

    return $resource('/api/notifications/:id/:path', { id: '@_id', path: '@path' }, {
      update: {
        method: 'PUT'
      },
      getPinned: {
        isArray: true,
        params: {
          path: 'pinned'
        }
      },
      pin: {
        method: 'POST',
        params: {
          path: 'pinned'
        }
      }
    });
  })
  .name;

