// @ts-ignore
import template from './account-company.component.html';

import { IAccount } from 'models/account.model';
import { IAccountResourceService } from 'models/account-resource.model';

export const AdminPanelAccountCompanyComponent = {
  bindings: {
    account: '<'
  },
  template,
  controller: class AdminPanelAccountCompanyController {
    // Bindings
    // @ts-ignore
    account: IAccount = this.account;

    loading = true;

    constructor(
      private Account: IAccountResourceService,
      private growl: ng.growl.IGrowlService,
    ) {
      'ngInject';
    }

    companyDetailsUpdated() {
      this.growl.success('Saved');
    }
  }
};
