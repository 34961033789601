import _ from 'underscore';
import angular from 'angular';

export default angular.module('app.notifications', [
  require('modules/resources/notification').default
])
  .service('notifications', function ($rootScope, Notification) {
    'ngInject';

    var service = {
      loadPinnedNotifications: function () {
        Notification.getPinned(function (pinnedNotifications) {
          service.pinnedNotificationToClients = _(pinnedNotifications).findWhere({ to: 'client' });
          service.pinnedNotificationToTeamMembers = _(pinnedNotifications).findWhere({ to: 'team_member' });
        });
      }
    };

    return service;
  })
  .name;
