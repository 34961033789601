import angular from 'angular';

import { InfiniteScrollDirective } from './infinite-scroll.directive';
import { ScrollService } from './scroll.service';
import { TrapScrollDirective } from './trap-scroll.directive';

export default angular.module('app.ui.scroll', [])
  .constant('INFINITE_SCROLL_THRESHOLD', 500) // It includes the footer height into consideration. Use default when listening to scroll on windows
  .directive('infiniteScroll', InfiniteScrollDirective)
  .directive('trapScroll', TrapScrollDirective)
  .service('ScrollService', ScrollService)
  .name;
