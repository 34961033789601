import { ICapacityOperationHttpResponse } from '../http/capacity-operation-http.model';
import { ICapacityOperation } from '../capacity-operations.model';
import { Money } from '../../modules/money/money';

export function httpCapacityOperation(data: ICapacityOperationHttpResponse): ICapacityOperation {
  const result: ICapacityOperation = {
    ...data,
    value: Money.create(data.value)
  };

  return result;
}
