import angular from 'angular';

import MoneyModule from 'modules/money';
import OrderResourceModule from 'modules/resources/order';
import UiRouterModule, { StateProvider, Transition } from '@uirouter/angularjs';
import { IOrderResourceService } from '../../interfaces';
import { TransactionComponent } from './transaction.component';
import { TransactionStatusCalloutComponent } from './transaction-status-callout/transaction-status-callout.component';

export const PublicTransactionsModule = angular.module('app.transactions', [
  MoneyModule,
  OrderResourceModule,
  UiRouterModule
])
  .component('transactionStatusCallout', TransactionStatusCalloutComponent)
  .component('publicTransaction', TransactionComponent)
  .config(($stateProvider: StateProvider) => {
    'ngInject';

    $stateProvider.state('transaction', {
      url: '/transactions/:id?success',
      component: 'publicTransaction',
      resolve: {
        transaction: (Order: IOrderResourceService, $transition$: Transition) => {
          return Order.get({ id: $transition$.params().id }).$promise;
        }
      },
      data: {
        title: 'Order details',
        type: 'public'
      }
    });
  })
  .name;
