import angular from 'angular';

export default angular.module('app.resources.tag', [
  require('angular-resource')
])
  .service('Tag', function ($resource) {
    'ngInject';

    return $resource('/api/tags/:id/:path', { id: '@_id', path: '@path' }, {
      bulkSave: {
        method: 'POST',
        params: {
          path: 'bulkSave'
        },
        isArray: true
      },
      update: {
        method: 'PUT'
      }
    });
  })
  .name;
