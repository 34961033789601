import _ from 'underscore';

export const ProjectsListController = function ($scope, $filter, $rootScope, $window, Project, UserManager,
  order, notifications, projectTeamMatching, userProjects, projectModals, optionOpenModal, userTeams) {
  'ngInject';

  $scope.projects = [];
  $scope.order = order;
  $scope.userTeams = userTeams;
  $scope.notifications = notifications;
  var activeProjects = $filter('filter')(userProjects, { archived: false });
  $scope.myManagedProjects = $filter('orderBy')(activeProjects, ['unsaved', 'name']);
  // maintain list of owned projects ids to quickly check if other project is owned
  var myManagedProjectsIds = _.pluck($scope.myManagedProjects, '_id');

  $scope.state = {
    filter: 'all',
    sort: 'latest',
    allProjectsFetched: false,
    page: 1,
    showProjectsLookingForATeam: $scope.userTeams.length > 0
  };

  $scope.handleProjectArhived = function (projectList, project) {
    const index = _.findIndex(projectList, { _id: project._id });
    projectList.splice(index, 1);
  };

  $scope.showAddProjectModal = function (event) {
    projectModals
      .openAddEditProject(undefined, event)
      .result
      .then(function (newProject) {
        if (!newProject.archived) {
          $scope.myManagedProjects.push(newProject);
          myManagedProjectsIds.push(newProject._id);
        }
      });
  };

  $scope.fetchProjects = function (page) {
    if ($scope.state.allProjectsFetched || $scope.fetchProjects.isInProgress) {
      return;
    }

    $scope.fetchProjects.isInProgress = true;

    var filter = $scope.state.filter;
    var sort = $scope.state.sort;
    var query = { sort, page };

    if (filter === 'active') {
      query.activeConversations = true;
    }

    Project.get(query, response => {
      if (!response.data.length) {
        $scope.state.allProjectsFetched = true;
      }

      // In case a first page reply is empty
      if (page === 1 && _.isEmpty(response.data) && _.isEmpty(response.pages)) {
        $scope.state.showProjectsLookingForATeam = false;
      }

      $scope.state.page = page;

      let notUserProjects = _.filter(response.data, (project) => {
        return myManagedProjectsIds.indexOf(project._id) === -1;
      });

      if (page > 1) {
        Array.prototype.push.apply($scope.projects, notUserProjects);
      } else {
        $scope.projects = notUserProjects;
      }
    }).$promise.finally(() => {
      $scope.fetchProjects.isInProgress = false;
      $rootScope.$emit('projects-list.page-fetched');
    });
  };

  // if user came to page following cta and has no projects - open create project modal
  if (optionOpenModal && !userProjects.length) {
    $scope.showAddProjectModal();
  }

  if ($scope.userTeams.length > 0) {
    // get first page for projects
    $scope.fetchProjects(1);
  }
};
