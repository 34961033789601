import _ from 'underscore';
import moment from 'moment';

_.mixin({
  sum: (obj, iterator, context) => {
    if (!iterator && _.isEmpty(obj)) return 0;
    let result = 0;
    if (!iterator && _.isArray(obj)) {
      for (let i = obj.length - 1; i > -1; i -= 1) {
        result += obj[i];
      }
      return result;
    } else if (iterator && _.isString(iterator)) {
      return _(obj).chain().pluck(iterator).sum().value();
    } else if (iterator && _.isFunction(iterator)) {
      _.each(obj, (value, index, list) => {
        const computed = iterator ? iterator.call(context, value, index, list) : value;
        result += computed;
      });

      return result;
    }
  }
});

moment.updateLocale('en', {
  week: {
    dow: 1 // Monday is the first day of the week.
  }
});
