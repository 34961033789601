// @ts-ignore
import template from './team-feed-new-project.component.html';

import { IProjectMatchedToTeamEvent } from '../../../../../models/event.model';

export const TeamFeedNewProjectComponent: ng.IComponentOptions = {
  bindings: {
    item: '<',
  },
  template,
  controller: class TeamFeedNewProjectController {
    // Bindings
    // @ts-ignore
    item: IProjectMatchedToTeamEvent = this.item;

    get project() {
      return this.item.data.project;
    }
  }
};
