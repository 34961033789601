import { IModalInstanceService } from 'interfaces';

export class AuthModalService {
  constructor(
    private $uibModal: IModalInstanceService
  ) {
    'ngInject';
  }

  openLogin(): ng.IPromise<void> {
    return this.$uibModal.open({
      size: 'small',
      component: 'signinModal'
    }).result;
  }

  openSignup(): ng.IPromise<void> {
    return this.$uibModal.open({
      size: 'small',
      component: 'signupModal'
    }).result;
  }
}
