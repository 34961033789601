import angular from 'angular';

export default angular.module('app.resources.account', [
  require('angular-resource')
])
  .service('Account', function ($resource) {
    'ngInject';

    return $resource('/api/accounts/:id/:path',
      {
        id: '@id',
        path: '@path'
      },
      {
        query: {
          method: 'GET',
          isArray: false
        },
        queryActive: {
          method: 'GET',
          isArray: false,
          params: { status: 'active' }
        },
        save: {
          method: 'POST',
          headers: {
            'X-Recaptcha-Token': config => config.data.$$recaptchaToken
          }
        },
        updateCompanyDetails: {
          method: 'PUT',
          params: { path: 'company' }
        },
        updateProfile: {
          // Server will defend against non-profile fields (e.g. isAdmin, email) being changed
          method: 'PUT',
          params: { id: '@_id', path: 'profile' }
        },
        updatePreferences: {
          // Server will defend against non-preference fields (e.g. isAdmin, email) being changed
          method: 'PUT',
          params: { id: '@_id', path: 'preferences' }
        },
        updateVatExempt: {
          method: 'PUT',
          params: { path: 'vatExempt' }
        },
        requestTeam: {
          method: 'PUT',
          params: { 'id': '@_id', path: 'requestTeam' }
        },
        book: {
          method: 'POST',
          params: {
            path: 'book'
          }
        },
        capacity: {
          params: {
            path: 'capacity'
          }
        },
        deliver: {
          method: 'POST',
          params: {
            path: 'deliver'
          }
        },
        removeDelivery: {
          method: 'POST',
          params: {
            path: 'removeDelivery'
          }
        },
        finishSprint: {
          method: 'POST',
          params: {
            path: 'finishSprint'
          }
        },
        unfinishSprint: {
          method: 'POST',
          params: {
            path: 'unfinishSprint'
          }
        },
        addProject: {
          method: 'POST',
          params: {
            path: 'projects'
          }
        },
        updateProject: {
          url: '/api/accounts/:id/projects/:projectId',
          params: { projectId: '@projectId' },
          method: 'PUT'
        },
        removeProject: {
          url: '/api/accounts/:id/projects/:projectId',
          params: { projectId: '@projectId' },
          method: 'DELETE'
        },
        acceptDemo: {
          url: '/api/accounts/:id/projects/:projectId/acceptDemo',
          params: { projectId: '@projectId' },
          method: 'PUT'
        },
        recoverCapacity: {
          url: '/api/accounts/:id/projects/:projectId/recover',
          method: 'PUT',
          params: { projectId: '@projectId', id: '@id' },
        },
        getProjectCapacityWeeks: {
          url: '/api/accounts/:id/projects/:projectId/capacity',
          params: { projectId: '@projectId' },
        },
        rechargeCapacity: {
          method: 'POST',
          params: {
            path: 'rechargeCapacity'
          }
        },
        getCompanies: {
          url: '/api/accounts/companies',
          isArray: true,
          cache: true
        },
        getProjects: {
          params: { path: 'projects' },
          isArray: true
        },
        getStats: {
          params: { path: 'stats' }
        },
        updateRemovalMark: {
          method: 'PUT',
          params: { path: 'removalMark' }
        },
        updateStatus: {
          method: 'PUT',
          params: { path: 'status' }
        },
        getReferrals: {
          params: { 'id': '@_id', path: 'referrals' },
          isArray: true
        },
        addReferral: {
          url: '/api/accounts/:id/referrals/:referralId',
          params: { 'id': '@id', referralId: '@referralId' },
          method: 'PUT'
        },
        deleteReferral: {
          url: '/api/accounts/:id/referrals/:referralId',
          params: { 'id': '@id', referralId: '@referralId' },
          method: 'DELETE'
        },
        setExactUserId: {
          params: {
            path: 'exactUserId'
          },
          method: 'PUT'
        },
        setReferralBonus: {
          params: {
            path: 'referralBonus'
          },
          method: 'PUT'
        }
      }
    );
  })
  .name;
