import { StateService } from '@uirouter/angularjs';
import { IUrlService } from '../../../interfaces';
import { ITeamExpandedMembers } from '../../../models/team.model';

// @ts-ignore
import template from './team-view.component.html';

export const TeamViewComponent: ng.IComponentOptions = {
  bindings: {
    isReadOnly: '<',
    team: '<'
  },
  template,
  controller: class TeamsViewController {
    // Bindings
    // @ts-ignore
    isReadOnly: boolean = this.isReadOnly;
    // @ts-ignore
    team: ITeamExpandedMembers = this.team;

    constructor(
      private $state: StateService,
      private UrlService: IUrlService,
    ) {
      'ngInject';
    }

    get teamVisitCardLink(): string {
      return this.$state.href('teams.visit-card', {
        name: this.UrlService.slugify(this.team.name)
      });
    }
  }
};
