import angular from 'angular';
import UiRouter, { StateProvider, StateParams } from '@uirouter/angularjs';
import { ISQStateDeclaration } from 'interfaces';
import { AccountReferralsComponent } from './account-referrals.component';
import { AddReferralModalComponent } from './add-referral-modal.component';
import { IAccount } from 'models/account.model';
import { IAccountResourceService } from 'models/account-resource.model';

export const AccountReferralsModule = angular.module('app.admin-panel.accounts.referrals', [
  UiRouter
])
  .component('accountReferrals', AccountReferralsComponent)
  .component('addReferralModal', AddReferralModalComponent)
  .config(($stateProvider: StateProvider) => {
    'ngInject';

    $stateProvider
      .state('admin-panel.account.referrals', {
        url: '/referrals',
        resolve: {
          referrer: (account: IAccount, Account: IAccountResourceService) => {
            return account.referrer ? Account.get({ id: account.referrer }).$promise : null;
          },
          referrals: ($q: ng.IQService, $stateParams: StateParams, Account: IAccountResourceService) => {
            return Account.getReferrals({ id: $stateParams.id }).$promise
              .catch(() => $q.resolve([/* Empty referrals list */]));
          }
        },
        views: {
          account: {
            component: 'accountReferrals'
          }
        },
        permissions: ['admin'],
        data: {
          title: 'Account Referrals'
        }
      } as ISQStateDeclaration);
  })
  .name;
