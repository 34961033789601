import _ from 'underscore';
import template from './planner-summary.html';

export default angular.module('app.projects.planner-summary', [])
  .directive('plannerSummary', function () {
    return {
      restrict: 'A',
      template,
      scope: {
        weeks: '=plannerSummary',
        size: '=plannerSummarySize'
      },
      controller: function ($scope) {
        'ngInject';

        $scope.everythingIsAccepted = _($scope.weeks).every({ isDemoAccepted: true });
      }
    };
  })
  .name;
