import template from './admin-transaction-modal.component.html';

export const AdminTransactionModalComponent = {
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  template,
  controller: class AdminTransactionModalController {
    constructor($window, Events, IntegrationResource, growl, Order, ORDER_STATUSES) {
      'ngInject';

      this.$window = $window;
      this.EventsResource = Events;
      this.IntegrationResource = IntegrationResource;
      this.growl = growl;
      this.OrderResource = Order;
      this.orderStatuses = ORDER_STATUSES;
    }

    $onInit() {
      this.inProgress = {
        createExactTransaction: false,
        createPurchaseEntry: false,
        createSalesInvoice: false,
        fetchTransactionEvents: false,
        increaseOutstandingMollieBalance: false,
        increaseOutstandingFreelancerInvoiceableCredits: false,
        reduceOutstandingFreelancerInvoiceableCredits: false,
      };

      this.showEvent = undefined;
      this.transaction = this.resolve.transaction;
      this.isReferralBonusTransaction = Boolean(this.transaction.description && this.transaction.description.indexOf('You earned a referral bonus') !== -1);

      this.fetchTransactionEvents();
    }

    toggleEvent(eventId) {
      this.showEvent = this.showEvent === eventId ? undefined : eventId;
    }

    _runApiCall(call, successMessage) {
      if (this.inProgress[call]) {
        return;
      }

      this.inProgress[call] = true;

      this.IntegrationResource[call]({ id: this.transaction._id }).$promise
        .then(() => {
          this.growl.success(successMessage);
        })
        .catch(response => {
          this.growl.error(response.data.message);
        })
        .finally(() => {
          this.inProgress[call] = false;
          this.fetchTransactionEvents();
        });
    }

    createExactTransaction() {
      this._runApiCall(
        'createExactTransaction',
        'Successfully created a transaction on Exact'
      );
    }

    createPurchaseEntry() {
      this._runApiCall(
        'createExactPurchaseEntry',
        'Successfully created a purchase entry on Exact'
      );
    }

    increaseOutstandingFreelancerInvoiceableCredits() {
      this._runApiCall(
        'increaseOutstandingFreelancerInvoiceableCredits',
        'Successfully increased outstanding freelancer invoiceable credits on Exact'
      );
    }

    increaseOutstandingMollieBalance() {
      this._runApiCall(
        'increaseOutstandingMollieBalance',
        'Successfully increased outstanding freelancer invoiceable credits on Exact'
      );
    }

    reduceOutstandingFreelancerInvoiceableCredits() {
      this._runApiCall(
        'reduceOutstandingFreelancerInvoiceableCredits',
        'Successfully increased outstanding freelancer invoiceable credits on Exact'
      );
    }

    createSalesInvoice() {
      if (this.inProgress.createSalesInvoice) {
        return;
      }

      const sendNotification = this.$window.confirm('Do you want to send email with invoice to client?\r\n(Cancel means "Do not send")');

      this.inProgress.createSalesInvoice = true;

      this.OrderResource.createSalesInvoiceFromOrder({ id: this.transaction._id }, { silent: !sendNotification }).$promise
        .then(() => {
          this.growl.success('Successfully created an invoice');
        })
        .catch(response => {
          this.growl.error(response.data.message);
        })
        .finally(() => {
          this.inProgress.createSalesInvoice = false;
        });
    }

    fetchTransactionEvents() {
      if (this.inProgress.fetchTransactionEvents) {
        return;
      }

      this.inProgress.fetchTransactionEvents = true;

      this.EventsResource.query({ transactionId: this.transaction._id, perPage: 50 }).$promise
        .then(response => {
          this.events = response;
        })
        .finally(() => this.inProgress.fetchTransactionEvents = false);
    }
  }
};
