import _ from 'underscore';

import { IEventEmitter, IEventEmitterValue } from '../../../interfaces';
import { IOrder } from '../../../models/order.model';

// @ts-ignore
import template from './edit-internal-flag.component.html';

interface IValue {
  value: { isInternal: boolean; };
}

export const AdminTransactionEditInternalFlagComponent = {
  bindings: {
    onUpdate: '&',
    transaction: '<',
  },
  template,
  controller: class AdminTransactionEditInternalFlagController {
    // Bindings
    // @ts-ignore
    transaction: IOrder = this.transaction;
    // @ts-ignore
    onUpdate: (value: IEventEmitterValue<IValue>) => void = this.onUpdate;

    constructor(
      private EventEmitter: IEventEmitter<IValue>,
      private growl: angular.growl.IGrowlService,
      private Order: any
    ) {
      'ngInject';
    }

    save() {
      this.Order.changeInternalFlag(
        { id: this.transaction._id },
        _(this.transaction).pick('isInternal')
      ).$promise.then((response: any) => {
        this.onUpdate(this.EventEmitter({ value: response.isInternal }));
        this.growl.success('Internal flag updated.');
      });
    }
  }
};
