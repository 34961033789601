import angular from 'angular';
import { AddEditTribeModalController } from './add-edit-tribe-ctrl';
import TribeOpenAddEditModalTemplate from './add-edit-tribe.html';

export default angular.module('app.tribes.modals', [
  require('modules/common/segment.service').default,
  require('modules/resources/account').default,
  require('modules/resources/tribe').default,
  require('modules/user-manager').default,
  require('modules/common/ui-bootstrap-modal-2.5.6').default
])
  .service('TribesModalsService', function ($uibModal) {
    'ngInject';

    return {
      openAddEditModal: function (tribeId, event) {
        // @NB: for now it's only "add tribe"
        return $uibModal.open({
          event: event,
          template: TribeOpenAddEditModalTemplate,
          controller: 'AddEditTribeCtrl',
          size: 'small',
          resolve: {
            tribe: (Tribe) => {
              return tribeId ? Tribe.get({ id: tribeId }).$promise : new Tribe({});
            }
          }
        });
      }
    };
  })
  .controller('AddEditTribeCtrl', AddEditTribeModalController)
  .name;
