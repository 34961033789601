// @ts-ignore
import template from './credits-details-project.component.html';

export const CreditsDetailsProjectComponent = {
  template,
  bindings: {
    data: '<'
  },
  controller: class CreditsDetailsProjectController {
    // @ts-ignore
    data = this.data;

    constructor() {
      'ngInject';
    }
  }
};
