/**
 * This module can:
 *   - create project
 *   - edit project
 *   - sign up new client and then create project for him
 */
import { ProjectAddEditComponent } from './project-add-edit.component';
import { ProjectFormComponent } from './project-form.component';

export default angular.module('app.projects.add-edit', [
  require('@uirouter/angularjs').default,
  require('app-constants').default,
  require('modules/common/event-emitter.module').default,
  require('modules/resources/account').default,
  require('modules/resources/project').default,
  require('modules/user-manager').default,
])
  .config($stateProvider => {
    'ngInject';

    $stateProvider
      .state('projects-new', {
        url: '/projects/new?newClientEmail&teamId',
        component: 'projectAddEdit',
        data: {
          title: 'New project',
          type: 'public'
        }
      })
      .state('projects.edit', {
        url: '/:id/edit',
        component: 'projectAddEdit',
        resolve: {
          project: ($q, Project, $transition$) => {
            const deferred = $q.defer();

            Project.get({ id: $transition$.params().id }, response => {
              deferred.resolve(response);
            }, () => {
              deferred.resolve(false);
            });

            return deferred.promise;
          }
        }
      });
  })
  .component('projectAddEdit', ProjectAddEditComponent)
  .component('projectForm', ProjectFormComponent)
  .name;
