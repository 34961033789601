import { IModalInstanceService } from 'interfaces';
import { IOrderApiParams } from './interfaces';

export class BuyCreditsModalService {
  private readonly DEFAULT_AMOUNT = 1000;

  constructor(
    private $uibModal: IModalInstanceService,
  ) {
    'ngInject';
  }

  open(amount: number = this.DEFAULT_AMOUNT, apiParams: IOrderApiParams): ng.IPromise<void> {
    return this.$uibModal.open({
      backdrop: 'static',
      component: 'buyCreditsModal',
      resolve: {
        amount: () => amount,
        apiParams: () => apiParams
      },
      size: 'small'
    }).result;
  }
}
