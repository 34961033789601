import { Money } from 'modules/money/money';
import { IMetricsResourceService } from 'models/metrics-resource.model';
import {
  IMetricsEndOfYearBalances,
  IMetricsEndOfYearBalancesYear
} from 'models/metrics.model';

// @ts-ignore
import template from './end-of-year-balances.component.html';

export const MetricsEndOfYearBalancesComponent = {
  template,
  controller: class MetricsEndOfYearBalancesController {

    endOfYearBalances: IMetricsEndOfYearBalances = {
      data: [],
      mutation: Money.create(0),
      total: Money.create(0)
    };

    descending: { [key: string]: boolean } = {
      year: true,
      month: true,
      week: true
    };

    loading = false;

    constructor(
      private Metrics: IMetricsResourceService
    ) {
      'ngInject';
    }

    $onInit() {
      this.fetch();
    }

    fetch() {
      this.loading = true;

      this.Metrics.getEndOfYearBalances().$promise
        .then((response) => {
          const data: IMetricsEndOfYearBalancesYear[] = [];
          const years = response.items;

          years.forEach((year) => {
            if (year.year === 'total') {
              this.endOfYearBalances.mutation = Money.create(year.mutation);
              this.endOfYearBalances.total = Money.create(year.total);
            } else {
              data.push({
                items: year.items.map((month) => ({
                  month: month.month,
                  mutation: Money.create(month.mutation),
                  total: Money.create(month.total)
                })),
                mutation: Money.create(year.mutation),
                total: Money.create(year.total),
                year: year.year,
              });
            }
          });

          this.endOfYearBalances.data = data;
        })
        .finally(() => this.loading = false);
    }

    changeOrder(column: string) {
      this.descending[column] = !this.descending[column];
    }
  }
};
