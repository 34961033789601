import angular from 'angular';
import { RequestTeamController } from './request-team-ctrl';
import RequestTeamTemplate from './request-team.html';

export default angular.module('app.teams.request', [
  require('@uirouter/angularjs').default,
  require('modules/resources/account').default,
  require('modules/user-manager').default,
])
  .config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state('teams.request', {
      url: '/request',
      controller: 'RequestTeamCtrl',
      template: RequestTeamTemplate,
      data: {
        title: 'Start a team'
      }
    });
  })
  .controller('RequestTeamCtrl', RequestTeamController)
  .name;
