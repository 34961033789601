// @ts-ignore
import template from './edit-team-tags.component.html';

import { IEventEmitter, IEventEmitterValue, ITag } from 'interfaces';

export const EditTeamTagsComponent: ng.IComponentOptions = {
  bindings: {
    allTags: '<',
    value: '<',
    onCancel: '&',
    onChange: '&'
  },
  template,
  controller: class EditTeamTagsController {
    // @ts-ignore
    allTags: ITag[] = this.allTags;
    // @ts-ignore
    value: ITag[] = this.value;
    // @ts-ignore
    onCancel: () => void = this.onCancel;
    // @ts-ignore
    onChange: (value: IEventEmitterValue<ITag[]>) => void = this.onChange;

    model: ITag[] = [];

    constructor(
      private EventEmitter: IEventEmitter<ITag[]>,
    ) {
      'ngInject';
    }

    $onInit() {
      this.model = this.value;
    }

    $onChanges(changes: ng.IOnChangesObject) {
      if (changes.value) {
        this.model = changes.value.currentValue;
      }
    }

    save() {
      this.onChange(this.EventEmitter(this.model));
    }

    cancel() {
      this.onCancel();
    }
  }
};
