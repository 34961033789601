import angular from 'angular';
import { TeamViewModule } from './view';

export default angular.module('app.teams', [
  require('@uirouter/angularjs').default,
  require('modules/teams/list').default,
  require('modules/teams/request').default,
  require('modules/teams/visit-card').default,
  TeamViewModule,
])
  .config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state('teams', {
      url: '/teams',
      abstract: true,
      template: '<div ui-view></div>'
    });
  })
  .name;
