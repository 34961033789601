import template from './remove-account.component.html';
import { Money } from 'modules/money/money';

export const RemoveAccountComponent = {
  bindings: {
    account: '<'
  },
  template,
  controller: class RemoveAccountController {
    constructor(Account, CapacityService, growl) {
      'ngInject';

      this.Account = Account;
      this.CapacityService = CapacityService;
      this.growl = growl;

      this.showDelete = false;
      this.stats = undefined;
    }

    get hasCapacity() {
      const data = this.CapacityService.data;
      return Money.isGreaterThanZero(data.balance) || Money.isGreaterThanZero(data.expectedPayments);
    }

    get isAccountSafeToDelete() {
      if (this.stats) {
        const hasProjects = this.stats.projects.managed > 0;
        const hasTeams = (this.stats.teams.hiredGun + this.stats.teams.member) > 0;

        return !hasTeams && !hasProjects && !this.hasCapacity;
      } else  {
        return false;
      }
    }

    checkAccountRemoval() {
      if (this.checkAccountRemoval.isInProgress) {
        return;
      }

      this.checkAccountRemoval.isInProgress = true;

      this.Account.getStats({ id: this.account._id }).$promise
        .then(response => {
          this.stats = response;
        })
        .finally(() => this.checkAccountRemoval.isInProgress = false);
    }

    requestAccountRemoval() {
      if (this.requestAccountRemoval.isInProgress) {
        return;
      }

      this.requestAccountRemoval.isInProgress = true;

      this.Account.updateRemovalMark({ id: this.account._id }, { requestedRemoval: true }).$promise
        .then(() => {
          this.growl.success('Account was marked to be removed and will be processed shortly.');
          this.account.requestedRemoval = true;
        })
        .finally(() => this.requestAccountRemoval.isInProgress = false);
    }
  }
};
