import { Money } from '../../modules/money/money';
import { IMetricsUnacceptedWeeksHttpResponse } from '../http/metrics-http.model';
import { IMetricsUnacceptedWeeks, IMetricsUnacceptedWeeksOperation } from '../metrics.model';
import { httpCapacityOperation } from './capacity-operation';

export function httpUnacceptedWeeksMetrics(data: IMetricsUnacceptedWeeksHttpResponse[]): IMetricsUnacceptedWeeks[] {
  return data.map((projectMetrics) => {
    const result: IMetricsUnacceptedWeeks = {
      project: projectMetrics.project,
      totalUnaccepted: Money.create(projectMetrics.totalUnaccepted),
      weeks: projectMetrics.weeks.map((week ) => {
        const resultWeek: IMetricsUnacceptedWeeksOperation = {
          id: week.id,
          booking: httpCapacityOperation(week.booking),
          deliveries: week.deliveries.map((item) => httpCapacityOperation(item)),
          booked: Money.create(week.booked),
          delivered: Money.create(week.delivered),
        };
        return resultWeek;
      })
    };
    return result;
  });
}
