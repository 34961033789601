import * as angular from 'angular';
import UiRouterModule, { StateProvider } from '@uirouter/angularjs';
import AccountResourceModule from 'modules/resources/account';
import { MyAccountBalanceComponent } from './my-account-balance.component';
import { CapacityService } from '../../../capacity/capacity.service';
import { ClientProjectOverviewComponent } from './client-project-overview.component';
import { FreelancerProjectOverviewComponent } from './freelancer-project-overview.component';
import { ProjectOverviewDetailsComponent } from './project-overview-details.component';

export default angular.module('app.accounts.my.balance', [
  AccountResourceModule,
  UiRouterModule
])
  .config(($stateProvider: StateProvider) => {
    'ngInject';

    $stateProvider.state('accounts.my.balance', {
      url: '/balance',
      params: {
        // Needed to pre-toggle switch depends on from which state user clicked on balance widget
        isFreelancerView: undefined
      },
      resolve: {
        capacity: (CapacityService: CapacityService) => CapacityService.sync(),
      },
      views: {
        account: {
          component: 'myAccountBalance'
        }
      },
      data: {
        title: 'Credits Balance'
      }
    });
  })
  .component('myAccountBalance', MyAccountBalanceComponent)
  .component('clientProjectOverview', ClientProjectOverviewComponent)
  .component('freelancerProjectOverview', FreelancerProjectOverviewComponent)
  .component('projectOverviewDetails', ProjectOverviewDetailsComponent)
  .name;
