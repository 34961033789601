import _ from 'underscore';
import { saveAs } from 'file-saver';
import { Money } from 'modules/money/money';
import template from './project-accounting.component.html';

export const ProjectAccountingComponent = {
  bindings: {
    capacityWeeks: '<',
  },
  template,
  controller: class ProjectAccountingController {
    constructor($filter) {
      'ngInject';
      this.$filter = $filter;
    }

    $onInit() {
      _(this.capacityWeeks).mapObject(week => {
        week.booked = Money.create(week.booked);
        week.delivered = Money.create(week.delivered);
        return week;
      });

      this.accountedWeeks = _(this.capacityWeeks).chain()
        .filter(week => !week.delivered.isZero())
        .sortBy('id')
        .reverse()
        .value();

      this.totalSpent = _(this.accountedWeeks).reduce((memo, week) => {
        return memo.add(week.delivered);
      }, Money.zero());

      this.groupedAccountedWeeks = _(this.accountedWeeks)
        .chain()
        .groupBy(week => week.team)
        .pairs()
        .value();
    }

    downloadAccountingCsv() {
      const filename = 'accounting.csv';
      const csvPrefix = 'week,weekId,delivered\n';
      const data = _(this.groupedAccountedWeeks).reduce(this.weekFormatter.bind(this), csvPrefix);
      const blob = new Blob([data], {type: 'data:text/csv;charset=utf-8;'});

      saveAs(blob, filename);
    }

    weekFormatter(memo, weeksGroup, index, arr) {
      const projectName = weeksGroup[0];
      const projectWeeks = weeksGroup[1];

      const weeks = projectWeeks.reduce((memo, week) => {
        return memo + this.$filter('weekTitle')(week.id) + ','
          + week.id + ','
          + Money.toRoundedUnit(week.delivered) + '\n';
      }, arr.length > 1 ? projectName + ',,\n' : '');

      return memo + weeks;
    }
  }
};
