import * as $ from 'jquery';
import * as angular from 'angular';
import { NoopcaptchaService } from './noopcaptcha.service';

const onLoadCallbackName = 'handleRecaptchaLoad';

export class RecaptchaService {
  execute = this.deferredMethodFactory('execute');
  /**
   * In case captcha was not completed getResponse returns empty token "",
   * and in case captcha is not enabled it returns undefined.
   */
  getResponse = this.deferredMethodFactory('getResponse');
  render = this.deferredMethodFactory('render');
  reset = this.deferredMethodFactory('reset');

  private whenLoaded?: PromiseLike<any>;

  constructor(
    private $interval: ng.IIntervalService,
    private $q: ng.IQService,
    private $log: ng.ILogService,
    private $window: ng.IWindowService,
    private NoopcaptchaService: NoopcaptchaService,
    private RECAPTCHA_SITE_KEY: string,
  ) {
    'ngInject';

    this.loadLibrary();
  }

  get libraryUrl() {
    return `https://www.google.com/recaptcha/api.js?render=explicit&hl=en&onload=${onLoadCallbackName}`;
  }

  loadLibrary() {
    const deferred = this.$q.defer();

    this.$window[onLoadCallbackName] = () => {
      deferred.resolve(this.$window.grecaptcha);

      delete this.$window.grecaptcha;
      delete this.$window[onLoadCallbackName];
    };

    this.whenLoaded = deferred.promise;

    if (this.RECAPTCHA_SITE_KEY) {
      $.getScript(this.libraryUrl);
    } else {
      // Use a mocked Recaptcha API
      this.$window.grecaptcha = this.NoopcaptchaService;
      this.$window[onLoadCallbackName]();
    }
  }

  /**
   * Creates a deferred version of a provided Recaptcha method
   * that waits for the library to load
   */
  deferredMethodFactory(name: string) {
    return (...args: any[]) => {
      return this.whenLoaded && this.whenLoaded
        .then((grecaptcha) => grecaptcha[name].apply(this, args));
    };
  }

  /**
   * Rejects the provided deferred when the Recaptcha window is dismissed.
   * Used for invisible recaptcha.
   *
   * Returns the promise object associated with that deferred.
   */
  rejectWhenDismissed(deferred: ng.IDeferred<any>, pollInterval = 100) {
    const container = angular.element('iframe[src*="google.com/recaptcha/api2/bframe"]').parent().parent();
    let wasVisible = false;

    const whenComplete = this.$interval(() => {
      if (container.css('visibility') === 'visible') {
        wasVisible = true;
      } else if (wasVisible) {
        this.$interval.cancel(whenComplete);
        deferred.reject();
      }
    }, pollInterval);

    deferred.promise.then(() => {
      this.$interval.cancel(whenComplete);
    }).catch((error: any) => this.$log.log(error));

    return deferred.promise;
  }
}
