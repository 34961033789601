import angular from 'angular';
import UiRouter, { StateProvider } from '@uirouter/angularjs';

import AccountResourceModule from 'modules/resources/account';
import ConstantsModule from 'app-constants';
import TeamResourceModule from 'modules/resources/team';
import UserManagerModule from 'modules/user-manager';

import { TeamMembersComponent } from './team-members.component';

export default angular.module('app.teams.team-members', [
  AccountResourceModule,
  ConstantsModule,
  TeamResourceModule,
  UiRouter,
  UserManagerModule,
])
  .config(($stateProvider: StateProvider) => {
    'ngInject';

    $stateProvider.state('teams.view.members', {
      url: '/members',
      views: {
        content: 'teamMembers'
      }
    });
  })
  .component('teamMembers', TeamMembersComponent)
  .name;
