// @ts-ignore
import template from './edit-team-name.component.html';

import { IEventEmitter, IEventEmitterValue } from 'interfaces';

export const EditTeamNameComponent: ng.IComponentOptions = {
  bindings: {
    value: '<',
    onCancel: '&',
    onChange: '&'
  },
  template,
  controller: class EditTeamNameController {
    // @ts-ignore
    value: string = this.value;
    // @ts-ignore
    onCancel: () => void = this.onCancel;
    // @ts-ignore
    onChange: (value: IEventEmitterValue<string>) => void = this.onChange;

    model: string = '';

    constructor(
      private EventEmitter: IEventEmitter<string>,
    ) {
      'ngInject';
    }

    $onInit() {
      this.model = this.value;
    }

    $onChanges(changes: ng.IOnChangesObject) {
      if (changes.value) {
        this.model = changes.value.currentValue;
      }
    }

    save() {
      this.onChange(this.EventEmitter(this.model));
    }

    cancel() {
      this.onCancel();
    }
  }
};
