import angular from 'angular';
import { InternationalPhoneModule } from 'modules/international-phone';
import { RecaptchaModule } from 'modules/recaptcha';

import { AuthModalService } from './auth-modal.service';
import { ResetPasswordComponent } from './reset-password.component';
import { SigninComponent } from './signin.component';
import { SigninModalComponent } from './signin-modal.component';
import { SignupComponent } from './signup.component';
import { SignupModalComponent } from './signup-modal.component';

export default angular.module('app.authentication', [
  InternationalPhoneModule,
  RecaptchaModule,
  require('@uirouter/angularjs').default,
  require('app-constants').default,
  require('modules/common/segment.service').default,
  require('modules/resources/account').default,
  require('modules/resources/auth').default,
  require('modules/common/ui-bootstrap-modal-2.5.6').default,
  require('modules/user-manager').default,
])
  .config(function ($stateProvider) {
    'ngInject';

    $stateProvider
      .state('reset-password', {
        url: '/reset-password/:token',
        component: 'resetPassword',
        resolve: {
          isTokenValid: ($transition$, $window, Auth) => {
            const redirect = () => $window.location.replace('/');
            return Auth.checkToken({ token: $transition$.params().token }).$promise
              .then((response) => {
                if (!response.validity) {
                  redirect();
                }
              })
              .catch(() => redirect());
          },
          params: ($transition$) => $transition$.params()
        },
        data: {
          type: 'auth',
          title: 'Reset Password'
        }
      })

      .state('signin', {
        url: '/signin',
        component: 'signin',
        data: {
          type: 'auth',
          title: 'Log In'
        }
      })

      .state('signup', {
        url: '/signup',
        component: 'signup',
        data: {
          type: 'auth',
          title: 'Register'
        }
      });
  })
  .service('AuthModal', AuthModalService)
  .component('resetPassword', ResetPasswordComponent)
  .component('signin', SigninComponent)
  .component('signinModal', SigninModalComponent)
  .component('signup', SignupComponent)
  .component('signupModal', SignupModalComponent)
  .name;
