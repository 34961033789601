import _ from 'underscore';
import angular from 'angular';
import UiRouter, { StateProvider, Transition } from '@uirouter/angularjs';

import AccountResourceModule from 'modules/resources/account';
import SegmentModule from 'modules/common/segment.service';
import UrlModule from 'modules/common/url.module';
import TagResourceModule from 'modules/resources/tag';
import TeamFeedModule from 'modules/teams/view/feed';
import TeamHiredGunsModule from 'modules/teams/view/hired-guns';
import TeamMembersModule from 'modules/teams/view/members';
import TeamResourceModule from 'modules/resources/team';
import TeamSettingsModule from 'modules/teams/view/settings';
import UserManagerModule from 'modules/user-manager';
import { ITeamResourceService } from 'interfaces';
import { UserManagerService } from 'modules/user-manager/user-manager.service';
import { ITeam } from 'models/team.model';

import { TeamViewComponent } from './team-view.component';

export const TeamViewModule = angular.module('app.teams.view', [
  UiRouter,
  SegmentModule,
  UrlModule,
  AccountResourceModule,
  TagResourceModule,
  TeamResourceModule,
  TeamFeedModule,
  TeamHiredGunsModule,
  TeamMembersModule,
  TeamSettingsModule,
  UserManagerModule,
])
  .config(($stateProvider: StateProvider) => {
    'ngInject';

    $stateProvider.state('teams.view', {
      url: '/:id/:name',
      component: 'teamView',
      redirectTo: (trans: Transition) =>
        trans.injector().getAsync('isReadOnly').then((isReadOnly) => {
          return isReadOnly ? 'teams.view.members' : 'teams.view.feed';
        }),
      resolve: {
        team: ($transition$: Transition, Team: ITeamResourceService) => {
          return Team.get({ id: $transition$.params().id, stats: true }).$promise;
        },
        isReadOnly: (team: ITeam, UserManager: UserManagerService) => !_.some(team.members, (member) => {
          return member.user._id === UserManager.data._id;
        })
      }
    });
  })
  .component('teamView', TeamViewComponent)
  .name;
