import { sortBy, sortedIndex, without } from 'underscore';
import { IAccount, IReferralBonus } from 'models/account.model';
import { IModalInstanceService } from 'interfaces';
import { IAccountResourceService } from 'models/account-resource.model';

// @ts-ignore
import template from './account-referrals.component.html';

export const AccountReferralsComponent = {
  bindings: {
    account: '<',
    referrals: '<',
    referrer: '<',
  },
  template,
  controller: class AccountReferralsController {
    // Bindings
    // @ts-ignore
    account: IAccount = this.account;
    // @ts-ignore
    referrals: Array<Partial<IAccount>> = this.referrals;
    // @ts-ignore
    referrer: IAccount = this.referrer;

    addingInProgress = false;
    removalInProgress: { [key: string]: boolean; } = {};
    saveBonusInProgress = false;

    referralBonus: IReferralBonus = {
      first: undefined,
      second: undefined,
      third: undefined,
    };

    constructor(
      private $uibModal: IModalInstanceService,
      private Account: IAccountResourceService,
      private growl: angular.growl.IGrowlService,
      public readonly REFERRAL_BONUS: IReferralBonus, // used in template
    ) {
      'ngInject';
    }

    $onInit() {
      if (this.referrals) {
        delete this.referrals.$promise;
        delete this.referrals.$resolved;
        this.referrals = sortBy(this.referrals, 'name');
      }

      if (this.account) {
        this.referralBonus = this.account.referralBonus;
      }
    }

    // removes account from referrals and referrer from that account
    removeReferral(referral: IAccount) {
      if (this.removalInProgress[referral._id] || !confirm(`Are you sure about removing ${referral.name} from ${this.account.name}\`s referrals?`)) {
        return;
      }

      this.removalInProgress[referral._id] = true;

      this.Account.deleteReferral({ id: this.account._id, referralId: referral._id }).$promise
        .then(() => {
          this.referrals = without(this.referrals, referral);
          this.growl.success(`Removed ${referral.name} from ${this.account.name}\`s referrals.`);
        })
        .finally(() => {
          this.removalInProgress[referral._id] = false;
        });
    }

    showAddReferralModal(event: Event) {
      if (this.addingInProgress) {
        return;
      }

      this.addingInProgress = true;

      this.$uibModal.open({
        event,
        component: 'addReferralModal',
        resolve: {
          account: () => this.account
        }
      }).result.then((referral) => {
        // adds account to referrals and updates referrer on that account
        // show a warning when referrer is to be overwritten
        this.Account.addReferral({ id: this.account._id, referralId: referral._id }).$promise
          .then(() => {
            const index = sortedIndex(this.referrals, referral, 'name');
            this.referrals.splice(index, 0, referral);
            this.account.referrals.push(referral._id);
            this.growl.success(`Marked ${referral.name} as ${this.account.name}\`s referral.`);
          })
          .catch(() => {
            // not empty block
          })
          .finally(() => {
            this.addingInProgress = false;
          });
      }).catch(() => {
        this.addingInProgress = false;
      });
    }

    bonusValueToSave() {
      const result: { [key: string]: number; } = {};

      for (const prop in this.referralBonus) {
        if (!this.referralBonus.hasOwnProperty(prop) || !this.REFERRAL_BONUS.hasOwnProperty(prop)) {
          continue;
        }

        // Save only values different from default
        if (this.REFERRAL_BONUS[prop] !== this.referralBonus[prop]) {
          result[prop] = this.referralBonus[prop];
        }
      }

      return result;
    }

    saveBonus(form: ng.IFormController) {
      if (form.$invalid || this.saveBonusInProgress) {
        return;
      }

      this.saveBonusInProgress = true;

      this.Account.setReferralBonus({ id: this.account._id }, this.bonusValueToSave()).$promise
        .then(() => {
          this.growl.success('Updated referral bonus');
        })
        .finally(() => this.saveBonusInProgress = false);
    }
  }
};
