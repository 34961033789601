import angular from 'angular';
import template from './recharge-modal.component.html';
import { Money } from 'modules/money/money';

export const AdminPanelRechargeModalComponent = {
  bindings: {
    close: '&',
    dismiss: '&',
    resolve: '<',
  },
  template,
  controller: class AdminPanelRechargeModalController {
    constructor(Account) {
      'ngInject';

      this.AccountResource = Account;

      // Value to be sent to api
      this.transaction = {
        amount: undefined,
        description: undefined,
        money: undefined
      };
    }

    $onInit() {
      this.balance = this.resolve.balance;
      this.account = angular.copy(this.resolve.account);
      this.balance.afterUpdate = this.balance.available;

      this.onUpdate();
    }

    onUpdate() {
      this.transaction.money = Money.createFromEuros(this.transaction.amount);
      this.balance.afterUpdate = this.balance.available.add(this.transaction.money);
    }

    rechargeCapacity() {
      if (this.rechargeCapacity.isInProgress) {
        return;
      }

      this.rechargeCapacity.isInProgress = true;

      this.AccountResource.rechargeCapacity({
        amount: this.transaction.money.getAmount(),
        description: this.transaction.isInvoice ? '' : this.transaction.description,
        id: this.account._id,
        isInternal: this.transaction.isInvoice ? false : this.transaction.isInternal,
        isInvoice: this.transaction.isInvoice
      }).$promise
        .then(() => this.close())
        .finally(() => this.rechargeCapacity.isInProgress = false);
    }
  }
};
