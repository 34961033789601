// @ts-ignore
import template from './projects.html';
import { IProject } from 'models/project.model';

interface IUIProject extends IProject {
  assignedTeamName: string;
  hasBookingLabel: string;
  isTrustedLabel: string;
  statusLabel: string;
  createdAtFormatted: string;
  createdAtTimeAgo: string;
}

interface IState {
  filter: {
    isTrusted: boolean | undefined;
    hasBooking: boolean | undefined;
    archived: boolean | undefined;
  };
  orderBy: string;
  query: string;
  reverseOrder: boolean;
}

export const AdminProjectsComponent = {
  template,
  bindings: {
    projects: '<',
    queryParam: '<'
  },
  controller: class AdminProjectsController {
    // Bindings
    // @ts-ignore
    queryParam: string = this.queryParam;
    // @ts-ignore
    projects: IProject[] = this.projects;

    allProjects: IUIProject[] = [];
    filteredProjects: IUIProject[] = [];

    state: IState = {
      filter: {
        isTrusted: undefined,
        hasBooking: undefined,
        archived: undefined,
      },
      orderBy: 'name',
      query: '',
      reverseOrder: false,
    };

    constructor(
      private $filter: ng.IFilterService,
    ) {
      'ngInject';
    }

    $onInit() {
      this.state.query = this.queryParam || '';

      this.allProjects = this.filteredProjects = this.projects.map((project) => ({
        ...project,
        assignedTeamName: project.assignedTeam ? project.assignedTeam.name : '–',
        createdAtFormatted: this.$filter('date')(project.createdAt, 'shortDate'),
        createdAtTimeAgo: (this.$filter('timeAgo') as any)(project.createdAt),
        publishedAtFormatted: this.$filter('date')(project.publishedAt, 'shortDate'),
        publishedAtTimeAgo: (this.$filter('timeAgo') as any)(project.publishedAt),
        hasBookingLabel: project.hasBooking ? 'Yes' : 'No',
        isTrustedLabel: project.isTrusted ? 'Yes' : 'No',
        name: project.name ? project.name : '–– No name ––',
        statusLabel: project.archived ? 'Archived' : 'Active',
      }));
    }

    setTrustedFilter(value: boolean | undefined) {
      this.state.filter.isTrusted = value;
      this.filter();
    }

    setBookingFilter(value: boolean | undefined) {
      this.state.filter.hasBooking = value;
      this.filter();
    }

    setArchivedFilter(value: boolean | undefined) {
      this.state.filter.archived = value;
      this.filter();
    }

    filter = () => {
      console.log('filter');
      this.filteredProjects = this.allProjects.filter((project) => {
        // Text search
        const lowercasedQuery = this.state.query.toLowerCase();
        const idMatches = project._id === lowercasedQuery;
        const projectNameMatches = project.name && project.name.toLowerCase().indexOf(lowercasedQuery) !== -1;
        const ownerIdMatches = project.owner && project.owner._id === lowercasedQuery;
        const ownerNameMatches = project.owner && project.owner.name.toLowerCase().indexOf(lowercasedQuery) !== -1;

        // Trusted match
        let isTrustedMatches = true;
        if (this.state.filter.isTrusted === true && !project.isTrusted) {
          isTrustedMatches = false;
        } else if (this.state.filter.isTrusted === false && project.isTrusted) {
          isTrustedMatches = false;
        }

        // Booking match
        let hasBookingMatches = true;
        if (this.state.filter.hasBooking === true && !project.hasBooking) {
          hasBookingMatches = false;
        } else if (this.state.filter.hasBooking === false && project.hasBooking) {
          hasBookingMatches = false;
        }

        // Status match
        let statusMatches = true;
        if (this.state.filter.archived === true && !project.archived) {
          statusMatches = false;
        } else if (this.state.filter.archived === false && project.archived) {
          statusMatches = false;
        }

        return (idMatches || projectNameMatches || ownerIdMatches || ownerNameMatches)
          && isTrustedMatches
          && hasBookingMatches
          && statusMatches;
      });
    }

    orderBy(field: string) {
      this.state.orderBy = field;

      if (field === 'name') {
        this.state.reverseOrder = false;
      } else if (field === 'createdAt') {
        this.state.reverseOrder = true;
      }
    }
  }
};
