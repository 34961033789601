import angular from 'angular';

export default angular.module('app.ui.icon', [])

  .directive('icon', function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        color: '@',
        size: '@'
      },
      template: function (element, attrs) {
        return require('./' + attrs.type + '.svg').default;
      },
      link: function (scope, element, attrs) {
        var baseClass = attrs.class || '';
        // #addClass cant add a class to an SVG, but #attr can.
        element.attr('class', baseClass + ' icon icon_type_' + attrs.type);
      }
    };
  })
  .name;
