// @ts-ignore
import template from './signup.component.html';

import { UserManagerService } from '../user-manager/user-manager.service';
import { IRecaptchaComponent, ISegmentService } from 'interfaces';
import { IAccountResourceService } from 'models/account-resource.model';
import { EAccountTypes, IAccount } from 'models/account.model';
import { Recaptcha } from '../recaptcha/recaptcha-component.abstract';

function validateEmail(email: string) {
  const re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return re.test(email);
}

export const SignupComponent = {
  template,
  controller: class SignupController extends Recaptcha {
    account: Partial<IAccount>;
    consent = false;
    signupInProgress = false;

    constructor(
      private $log: ng.ILogService,
      private $q: ng.IQService,
      private $window: ng.IWindowService,
      private Account: IAccountResourceService,
      private SegmentService: ISegmentService,
      private UserManager: UserManagerService,
    ) {
      'ngInject';

      super($q);
    }

    $onInit() {
      this.account = {
        accountType: EAccountTypes.CLIENT,
        referrer: this.referrer,
        attribution: this.UserManager.getAttribution()
      };
    }

    get referrer() {
      const referrer = this.$window.localStorage.referrer;
      return referrer !== 'false' ? referrer : undefined;
    }

    signup(form: ng.IFormController): void {
      if (this.signupInProgress || form.$invalid) {
        return;
      }

      this.signupInProgress = true;

      this.recaptcha.execute()
        .then(this.recaptchaVerified)
        .then(($$recaptchaToken: string) => {
          return this.Account.save({
            account: this.account,
            consent: this.consent,
            $$recaptchaToken
          }).$promise;
        })
        .then((account) => {
          this.track();
          this.SegmentService.alias(account._id);
          this.$window.location.href = '/';
        })
        .catch((error) => this.$log.log(error))
        .finally(() => {
          this.signupInProgress = false;
        });
    }

    captureEmail(email: string, trackName: string) {
      if (validateEmail(email)) {
        this.SegmentService.track((trackName ? (trackName + ' ') : '') + 'captured email', { email });
      }
    }

    private track(): void {
      this.SegmentService.track('Signed up', {
        name: this.account.name,
        email: this.account.email,
        phone: this.account.phone && this.account.phone.raw,
        referredBy: this.referrer,
        createdAt: new Date()
      });
    }
  }
};
