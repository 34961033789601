import * as angular from 'angular';
import { chain, each } from 'underscore';

import { IEventEmitter, IEventEmitterValue } from 'interfaces';
import { EWeekRatings, IProjectWeek, IProjectWeekRatings } from 'models/project-week.model';

interface IWeekRatingsValue {
  averageRating: number;
  feedback: string;
  ratings: IProjectWeekRatings;
}

export class WeekRatingsController implements ng.IController {
  // Bindings
  // @ts-ignore
  onCancel: () => void = this.onCancel;
  // @ts-ignore
  onUpdate: (value: IEventEmitterValue<IWeekRatingsValue>) => void = this.onUpdate;
  // @ts-ignore
  project: Project = this.project;
  // @ts-ignore
  week: IProjectWeek = this.week;
  // @ts-ignore
  disabled?: boolean = this.disabled;

  feedback: string = '';

  ratings: IProjectWeekRatings = {
    [EWeekRatings.QualityOfPresentation]: 0,
    [EWeekRatings.MeetsExpectations]: 0,
    [EWeekRatings.Collaboration]: 0,
  };

  ratingsList: EWeekRatings[] = [
    EWeekRatings.QualityOfPresentation,
    EWeekRatings.MeetsExpectations,
    EWeekRatings.Collaboration
  ];

  private readonly lowRatingPlaceholder = 'You\'ve given a low rating, how could we improve?';
  private readonly ratingPlaceholder = 'What did you like best and how can we improve?';

  constructor(
    private EventEmitter: IEventEmitter<IWeekRatingsValue>
  ) {
    'ngInject';
  }

  $onChanges(newValue: ng.IOnChangesObject) {
    if (newValue.week && newValue.week.currentValue) {
      this.week = angular.copy(newValue.week.currentValue);
    }
  }

  get averageRating(): number {
    return chain(this.ratings)
      .values()
      .reduce((a, b) => b + a, 0)
      .value() / this.ratingsList.length;
  }

  get feedbackPlaceholder() {
    const lowRatingGiven = this.averageRating > 0 && this.averageRating < 3;
    return (lowRatingGiven ? this.lowRatingPlaceholder : this.ratingPlaceholder) + ' (Optional)';
  }

  update(): void {
    this.onUpdate(
      this.EventEmitter({
        averageRating: this.averageRating,
        ratings: this.ratings,
        feedback: this.feedback
      })
    );
  }

  cancel() {
    this.onCancel();
  }

  setAllRatingsTo(rate: number): void {
    each(this.ratingsList, (key: EWeekRatings) => {
      this.ratings[key] = rate;
    });
  }
}
