export class CheckboxDirective implements ng.IDirective {
  public restrict: string;

  constructor() {
    'ngInject';
    this.restrict = 'C';
  }

  public link(scope: ng.IScope, element: JQLite): void {
    element.find('input[type="checkbox"]').after('<i class="checkbox-tick"></i>');
  }
}
