import angular from 'angular';
import { CONSTANTS } from './constants';

const module = angular.module('app.constants', []);

for (let key in CONSTANTS) {
  if (CONSTANTS.hasOwnProperty(key)) {
    module.constant(key, CONSTANTS[key]);
  }
}

export default module.name;
