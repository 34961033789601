// @ts-ignore
import template from './transaction.component.html';
import { EOrderStatus, IOrder } from '../../models/order.model';
import { Money } from '../money/money';
import { IMoney } from '../../models/money.model';
import { StateParams } from '@uirouter/angularjs';

export const TransactionComponent: ng.IComponentOptions = {
  template,
  bindings: {
    transaction: '<'
  },
  controller: class TransactionController implements ng.IController {
    // Bindings
    // @ts-ignore
    transaction: IOrder = this.transaction;

    id: string;
    redirectUrl: string;
    amount: IMoney;
    success = false;

    constructor(
      private $stateParams: StateParams,
      private $window: ng.IWindowService
    ) {
      'ngInject';
    }

    $onInit() {
      this.id = this.$stateParams.id;
      this.redirectUrl = this.$window.location.pathname;
      this.amount = Money.create(this.transaction.capacity);
      // Get success from state params
      this.success = this.$stateParams.success;
    }

    redirectToMollie() {
      this.$window.location = `/api/orders/${this.id}/pay?redirectUrl=${this.redirectUrl}`;
    }

    handleStatusChanged(status: EOrderStatus) {
      // Update order status
      this.transaction.status = status;
    }
  }
};
