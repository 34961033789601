import _ from 'underscore';
import { Money } from 'modules/money/money';

export class MetricsWeeklyStatsController {
  constructor(Metrics) {
    'ngInject';

    this.MetricsResource = Metrics;
  }

  $onInit() {
    this.descending = {
      year: true,
      month: true,
      week: true
    };

    this.weeklyStats = {
      loading: false,
      data: undefined
    };

    this.loading = false;

    this.fetch();
  }

  changeOrder(column) {
    this.descending[column] = !this.descending[column];
  }

  fetch() {
    this.loading = true;

    this.MetricsResource.getWeeklyStats().$promise
      .then(response => {
        this.weeklyStats.data = _(response.items).map(item => {
          item.referralCosts = Money.create(item.referralCosts);
          item.referralRevenue = Money.create(item.referralRevenue);
          item.totalBooked = Money.create(item.totalBooked);

          return item;
        });
      })
      .finally(() => this.loading = false);
  }
}
