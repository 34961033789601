import angular from 'angular';

export class UrlService {
  slugify(input) {
    return String(input).toLowerCase()
      .replace(/\s+/g, '-')      // Replace spaces with dashes
      .replace(/[^a-z0-9-]/g, '') // Remove all weird characters
      .replace(/--+/g, '-')      // Replace multiple dashes with a single one
      .replace(/^-+/, '')        // Trim dashes from the start of text
      .replace(/-+$/, '');       // Trim dashes from the end of text
  }
}

export default angular.module('app.common.url', [])
  .service('UrlService', UrlService)
  .name;
