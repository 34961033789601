import angular from 'angular';
import template from './order-details.component.html';
import { Money } from 'modules/money/money';

export const OrderDetailsComponent = {
  bindings: {
    order: '<'
  },
  template,
  controller: class OrderDetailsController {
    constructor(UserManager) {
      'ngInject';

      this.UserManager = UserManager;
    }

    $onChanges(changes) {
      if (changes.order) {
        const order = angular.copy(changes.order.currentValue);
        order.value = Money.create(order.capacity);

        this.order = order;
      }
    }

    $onInit() {
      this.user = this.UserManager.data;
    }
  }
};
