import angular from 'angular';
import { MetricsWeeklyStatsComponent } from './weekly-stats.component';

export const MetricsWeeklyStatsModule = angular
  .module('app.admin-panel.metrics.weekly-stats', [])
  .config(($stateProvider) => {
    'ngInject';

    $stateProvider
      .state('admin-panel.metrics.weekly-stats', {
        url: '/weekly-stats',
        views: {
          metric: {
            component: 'metricsWeeklyStats'
          }
        },
        permissions: ['admin'],
        data: {
          title: 'Weekly stats | Metrics | Admin panel'
        }
      });
  })
  .component('metricsWeeklyStats', MetricsWeeklyStatsComponent)
  .name;
