// @ts-ignore
import template from './project-overview-details.component.html';

import _ from 'underscore';
import { IMoney } from '../../../../models/money.model';
import { Money } from '../../../money/money';

export const ProjectOverviewDetailsComponent = {
  template,
  bindings: {
    dismiss: '&',
    resolve: '<',
  },
  controller: class ProjectOverviewDetailsController {
    // @ts-ignore
    dismiss = this.dismiss;
    // @ts-ignore
    resolve = this.resolve;

    deliveriesOnly: boolean = false;
    data: any;
    weeksKeys: string[];
    weeklyDeliveries: { [key: string]: IMoney } = {};
    weeksNumber: number;

    constructor(
    ) {
      'ngInject';
    }

    $onInit() {
      this.data = this.resolve.data;
      this.deliveriesOnly = _.isUndefined(this.data.totalBooked);

      if (this.deliveriesOnly) {
        this.weeksKeys = Object.keys(this.data.deliveries);
      } else {
        this.weeksKeys = Object.keys(this.data.bookings);
      }

      this.weeksNumber = this.weeksKeys.length;

      this.weeksKeys.forEach((week) => {
        this.weeklyDeliveries[week] = _(this.data.deliveries[week]).reduce((memo, d) => memo.add(d.value), Money.create(0));
      });
    }
  }
};
