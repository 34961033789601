import template from './events.component.html';

export const AdminPanelEventsComponent = {
  template,
  controller: class AdminPanelEventsController {
    constructor(Events) {
      'ngInject';

      this.EventsResource = Events;
      this.events = [];

      this.loading = false;
      this.pageSizes = [50, 100, 150];
      this.perPage = this.pageSizes[0];

      this.state = {
        event: null,
        projectId: '',
        teamId: '',
        initiatingUserId: '',
        orderBy: 'createdAt',
        reversedOrder: true
      };
    }

    $onInit() {
      this.EventsResource.getTypes().$promise.then(res => this.types = res);
    }

    changeOrder() {
      this.state.reversedOrder = !this.state.reversedOrder;
    }

    fetchEvents(page = 1, perPage = this.perPage) {
      if (this.loading) {
        return;
      }

      this.perPage = perPage;
      this.loading = true;

      const reqQuery = {
        page: page,
        perPage: perPage,
        event: this.state.event,
        projectId: this.state.projectId,
        teamId: this.state.teamId,
        initiatingUserId: this.state.initiatingUserId
      };

      this.EventsResource.query(reqQuery).$promise
        .then(events => {
          this.events = events.data;
          this.pages = events.pages;
        })
        .finally(() => this.loading = false);
    }
  }
};
