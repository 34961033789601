import { Money } from './money';

export const MoneyFilter = (TYPOGRAPHY, MONEY_ROUNDING_MODE) => {
  'ngInject';

  return (money, showCurrency = true, showCents = false) => {
    if (!Money.instanceOfMoney(money) && isNaN(parseFloat(money))) {
      return '';
    }

    if (!Money.instanceOfMoney(money)) {
      money = Money.create(money);
    }

    const isNegative = money.isNegative();

    if (isNegative) {
      money = money.multiply(-1);
    }

    const centsFormat = showCents ? '.00' : '';
    const result = money.toFormat(showCurrency ? `$0,0${centsFormat}` : '0', MONEY_ROUNDING_MODE);

    return isNegative ? `-${result}` : result;
  };
};
