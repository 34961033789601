import _ from 'underscore';
import moment from 'moment';
import { Money } from 'modules/money/money';

export const BookingsDeliveriesListController = function ($scope, $state, $log, capacityOperations, CONFIG) {
  'ngInject';

  const currentWeekId = moment().format(CONFIG.YEAR_WEEK_FORMAT);

  capacityOperations = _(capacityOperations).each(operation => {
    operation.value = Money.create(operation.value);
  });

  $scope.isInsideAdminPanel = $state.current.name === 'admin-panel.bookings-deliveries';

  $scope.allDeliverers = _(capacityOperations).chain()
    .where({ type: 'delivery' })
    .pluck('user')
    .uniq(user => user._id)
    .value();

  // Form projects list from project field of capacityOperations
  $scope.allProjects = _(capacityOperations).chain()
    .pluck('project')
    .uniq(project => project._id )
    .compact()
    .value();

  $scope.populateProject = function (projectId) {
    const project = _($scope.allProjects).findWhere({ _id: projectId });
    return project || {
      id: projectId,
      name: 'Deleted project'
    };
  };

  // week:
  // - id
  // - projectOperations: { projectId: [operations] }
  // - teamMemberOperations: { userId: [operations] }
  // - isCurrent: Boolean
  // - capacities: [{
  //     booked: booking.value,
  //     isDemoAccepted: booking.isAccepted,
  //     delivered: totalDelivered,
  //     projectId: projectId
  //   }]
  // - earnings: [{
  //     userName: teamMemberOperations[0].user.name,
  //     earned: totalEarned
  //   }]
  // - totalDelivered: Money
  // - totalBooked: Money
  $scope.filterWeeks = function (projectId, delivererId) {
    let weeks = [];
    let operations = capacityOperations;

    // Filter capacity operations by project
    if (projectId) {
      operations = _(operations).where({ project: projectId });
    }

    // Filter capacity operations by deliverer
    if (delivererId) {
      operations = _(operations).filter(operation => {
        return (operation.type === 'delivery' && operation.user._id === delivererId) || operation.type === 'booking';
      });
    }

    _(operations).chain()
      .groupBy(operation => operation.week)
      .each((weekOperations, weekId) => {
        weeks.push({
          id: weekId,
          projectOperations: _(weekOperations).chain()
            .reject(operation => {
              return operation.type === 'booking' && operation.value.isZero();
            })
            .groupBy(operation => operation.project._id)
            .value(),
          teamMemberOperations: _(weekOperations).chain()
            .where({ type: 'delivery' })
            .groupBy(operation => operation.user._id)
            .value()
        });
      });

    $scope.weeks = _(weeks).map(week => {
      week.isCurrent = week.id.toString() === currentWeekId;
      week.capacities = [];
      week.earnings = [];
      week.totalDelivered = Money.zero();
      week.totalBooked = Money.zero();

      // Projects bookings table
      _(week.projectOperations).each((projectOperations, projectId) => {
        const booking = _(projectOperations).findWhere({ type: 'booking' });

        if (!booking) {
          return $log.warn(`No booking in week #${week.id} for project with id: ${projectId}`);
        }

        const deliveries = _(projectOperations).where({ type: 'delivery' });
        const totalDelivered = _(deliveries).reduce((memo, delivery) => {
          return memo.add(delivery.value);
        }, Money.zero());

        week.capacities.push({
          booked: booking.value,
          isDemoAccepted: booking.isAccepted,
          delivered: totalDelivered,
          projectId: projectId
        });

        // Calculate total booked/delivered for whole week
        week.totalDelivered = week.totalDelivered.add(totalDelivered);
        week.totalBooked = week.totalBooked.add(booking.value);
      });

      // Team members earnings table
      _(week.teamMemberOperations).each(teamMemberOperations => {
        var totalEarned = _(teamMemberOperations).reduce((memo, delivery) => {
          return memo.add(delivery.value);
        }, Money.zero());

        week.earnings.push({
          userName: teamMemberOperations[0].user.name,
          earned: totalEarned
        });
      });

      return week;
    });
  };

  /**
   * Init
   */

  $scope.filterWeeks();
};
