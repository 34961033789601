import $ from 'jquery';
import angular from 'angular';
import locationHelper from 'modules/common/location-helper';

export default angular.module('app.common.segment', [
  require('@uirouter/angularjs').default,
  require('app-constants').default
])

  .factory('SegmentService', function ($location, $rootScope, $transitions, $window, SEGMENTIO) {
    'ngInject';

    $window.analytics = $window.analytics || [];
    $window.analytics.SNIPPET_VERSION = '4.1.0';

    // Define a factory that generates wrapper methods to push arrays of
    // arguments onto our `analytics` queue, where the first element of the arrays
    // is always the name of the analytics.js method itself (eg. `track`).
    $window.analytics.factory = function (methodName) {
      return function () {
        var e = Array.prototype.slice.call(arguments);
        e.unshift(methodName);
        $window.analytics.push(e);
        return $window.analytics;
      };
    };

    $window.analytics.load = function (key) {
      $.getScript(`https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`, () => {
        $window.analytics.ready(() => {
          if ($window.ga) {
            $window.ga('require', 'linker');
            $window.ga('linker:autoLink', ['squads.com']);
          }
        });
      });
    };

    $window.analytics.methods = [
      'addDestinationMiddleware', 'addIntegrationMiddleware', 'addSourceMiddleware',
      'alias', 'debug', 'group', 'identify', 'off', 'on', 'once', 'page', 'pageview',
      'ready', 'reset', 'setAnonymousId', 'track', 'trackClick', 'trackForm', 'trackLink', 'trackSubmit',
    ];

    for (let t=0; t < $window.analytics.methods.length; t++) {
      let e = $window.analytics.methods[t];
      $window.analytics[e] = $window.analytics.factory(e);
    }

    let service = {
      consentManager: {
        isAvailable: () => {
          return $window.consentManager && $window.consentManager.version;
        },
        open: () => {
          if ($window.consentManager && angular.isFunction($window.consentManager.openConsentManager)) {
            $window.consentManager.openConsentManager();
          }
        }
      }
    };

    const segmentDisabledInConfig = SEGMENTIO && !SEGMENTIO.ENABLED;
    const segmentDisabledInUrl = locationHelper.getParam('nosegment') === 'true';

    // If segmentio is disabled in the config, or in url – stop right here
    if (!SEGMENTIO || segmentDisabledInConfig || segmentDisabledInUrl) {
      let noopSegment = {};
      $window.analytics.methods.forEach(method => noopSegment[method] = angular.noop);
      return noopSegment;
    } else {
      // Loop through analytics.js' methods and map them to service
      $window.analytics.methods.forEach(method => service[method] = $window.analytics[method]);
    }

    // Consent manager set up
    // ----------------------

    initConsentManager();

    function initConsentManager() {
      if (window.__karma__) {
        return;
      }

      $window.consentManagerConfig = function (exports) {
        const React = exports.React;
        const inEU = exports.inEU;

        const bannerContent = React.createElement(
          'span',
          null,
          'We use cookies (and other similar technologies) to collect data to improve your experience on our site.' +
          'By using our website, you՚re agreeing to the collection of data as described in our',
          ' ',
          React.createElement(
            'a',
            { href: 'https://squads.com/privacy-statement', target: '_blank' },
            'Privacy Statement'
          ),
          '.'
        );
        const preferencesDialogTitle = 'Website Data Collection Preferences';
        // const preferencesDialogContent = 'We use data collected by cookies and JavaScript libraries to improve your browsing experience, analyze site traffic, and increase the overall performance of our site.';
        const preferencesDialogContent = React.createElement(
          'span',
          null,
          'We use data collected by cookies and JavaScript libraries to improve your browsing experience, ' +
          'analyze site traffic, and increase the overall performance of our site. Learn more at',
          ' ',
          React.createElement(
            'a',
            { href: 'https://squads.com/privacy-statement', target: '_blank' },
            'Privacy Statement'
          ),
          '.',
          React.createElement('br'),
          'Below you can refine which services your data can be shared with:'
        );
        const cancelDialogTitle = 'Are you sure you want to cancel?';
        const cancelDialogContent = 'Your preferences have not been saved. By continuing to use our website, you՚re agreeing to our Website Data Collection Policy.';

        return {
          container: '.segment-consent-manager',
          writeKey: SEGMENTIO.KEY,
          shouldRequireConsent: inEU,
          bannerContent: bannerContent,
          preferencesDialogTitle: preferencesDialogTitle,
          preferencesDialogContent: preferencesDialogContent,
          cancelDialogTitle: cancelDialogTitle,
          cancelDialogContent: cancelDialogContent
        };
      };

      let consentManagerScript = $('<script></script>').attr({
        crossorigin: 'anonymous',
        defer: true,
        integrity: 'sha256-cptjdzM9JCyAzOliNVMI+8U60l1MgdGbgop1MFfYoiU=',
        src: 'https://unpkg.com/@segment/consent-manager@1.1.1/standalone/consent-manager.js',
        onload: () => {
          // after file was loaded asynchronously it's initialized also asynchronously
          const timerId = setInterval(() => {
            if (service.consentManager.isAvailable()) {
              $rootScope.$apply(); // update view
              clearInterval(timerId);
            }
          }, 500);
        }
      });

      const firstScriptTag = document.getElementsByTagName('script')[0];
      consentManagerScript.insertBefore(firstScriptTag);
    }

    // push page event on state changed
    // --------------------------------

    service.transitionOnSuccessCallback = function (trans) {
      const to = trans.$to();
      if (service.location !== $location.path()) {
        service.location = $location.path();

        const segmentName = to.data && to.data.segmentName;
        const params = locationHelper.extractSegmentPageArguments(service.location, segmentName);
        // analytics.page([category], [name], [properties], [options], [callback]);
        service.page(params.category, params.name, params.properties);
      }
    };

    $transitions.onSuccess({}, service.transitionOnSuccessCallback);

    return service;
  })

  .directive('track', function (SegmentService) {
    'ngInject';

    return {
      restrict: 'A',
      scope: {
        track: '@',
        trackProperties: '='
      },
      link: function (scope, element) {
        element.bind('click', () => {
          if (scope.track) {
            SegmentService.track(`Click ${scope.track}`, scope.trackProperties);
          }
        });
      }
    };
  })
  .name;
