// @ts-ignore
import template from './buy-credits-form.component.html';
import { IOrderApiParams } from '../interfaces';
import { UserManagerService } from 'modules/user-manager/user-manager.service';

export const BuyCreditsFormComponent: ng.IComponentOptions = {
  bindings: {
    amount: '<',
    apiParams: '<',
    onShowCompanyForm: '&',
  },
  template,
  controller: class BuyCreditsFormController implements ng.IController {
    // Bindings
    // @ts-ignore
    amount: string = this.amount;
    // @ts-ignore
    apiParams: IOrderApiParams = this.apiParams;
    // @ts-ignore
    onShowCompanyForm: () => void = this.onShowCompanyForm;

    constructor(
      private $window: ng.IWindowService,
      private UserManager: UserManagerService,
    ) {
      'ngInject';
    }

    $onInit(): void {
      this.apiParams = {
        redirectUrl: '/projects', // Default redirect url
        ...this.apiParams
      };
    }

    get totalPrice() {
      const amount = parseInt(this.amount, 10) || 0;
      return this.isVatExempt ? amount : (amount * 121 / 100);
    }

    get isVatExempt() {
      return this.UserManager.data.vatExempt;
    }

    showCompanyForm() {
      this.onShowCompanyForm();
    }
  }
};
