import * as angular from 'angular';

import AccountResourceModule from 'modules/resources/account';
import CapacityModule from 'modules/capacity';
import FileChangeDirective from 'modules/common/file-change.directive';
import ModalModule from 'modules/common/ui-bootstrap-modal-2.5.6';
import MoneyModule from 'modules/money/index';
import OrderResourceModule from 'modules/resources/order';
import UserManagerModule from 'modules/user-manager/index';

import { SendInvoiceFormComponent } from './send-invoice-form/send-invoice-form.component';
import { SendInvoiceModalComponent } from './send-invoice-modal.component';
import { SendInvoiceModalService } from './send-invoice-modal.service';

export const SendInvoiceModalModule = angular.module('app.send-invoice-modal', [
  AccountResourceModule,
  CapacityModule,
  FileChangeDirective,
  ModalModule,
  MoneyModule,
  OrderResourceModule,
  UserManagerModule
])
  .component('sendInvoiceForm', SendInvoiceFormComponent)
  .component('sendInvoiceModal', SendInvoiceModalComponent)
  .service('SendInvoiceModalService', SendInvoiceModalService)
  .name;
