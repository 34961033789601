export const RequestTeamController = function ($scope, Account, UserManager) {
  'ngInject';

  $scope.user = UserManager.data;

  if (!$scope.user.requestedTeam) {
    $scope.user.requestedTeam = {
      isNotPreviouslyRequested: true
    };
  }

  $scope.requestTeam = function () {
    Account.requestTeam($scope.user, function (updatedUser) {
      UserManager.set(updatedUser);
    });
  };
};
