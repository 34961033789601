import angular from 'angular';
import { FinishSprintComponent } from './finish-sprint.component';
import { FinishSprintModalComponent } from './finish-sprint-modal.component';

export default angular.module('app.my-deliveries.finish-print', [
  require('modules/common/ui-bootstrap-modal-2.5.6').default,
  require('modules/money').default,
  require('modules/resources/account').default,
  require('modules/user-manager').default
])
  .component('finishSprint', FinishSprintComponent)
  .component('finishSprintModal', FinishSprintModalComponent)
  .name;
