import angular from 'angular';

export default angular.module('app.resources.team', [
  require('angular-resource')
])
  .service('Team', function ($resource) {
    'ngInject';

    return $resource('/api/teams/:id/:action', { id: '@_id', action: '@action' }, {
      query: {
        isArray: true,
        cache: true
      },
      update: {
        method: 'PUT',
        params: { id: '@_id' }
      },
      hireGun: {
        method: 'POST',
        params: { action: 'hiredGuns' }
      },
      fireGun: {
        url: '/api/teams/:id/hiredGuns/:gunId',
        method: 'DELETE'
      },
      inviteUser: {
        method: 'POST',
        params: { action: 'inviteUser' }
      },
      releaseProject: {
        method: 'POST',
        params: { action: 'releaseProject' }
      },
      leave: {
        method: 'POST',
        params: { action: 'leave' }
      },
      getFeed: {
        params: { action: 'feed' },
        isArray: true
      },
      getProjectWeekOperations: {
        url: '/api/teams/:id/feed/week/:week',
        isArray: true
      },
      restore: {
        method: 'POST',
        params: { action: 'restore' }
      }
    });
  })
  .name;
