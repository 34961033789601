import angular from 'angular';
import Clipboard from 'clipboard';

// wrapper for https://zenorocha.github.io/clipboard.js/
export default angular.module('app.ui.clipboard', [])
  .directive('clipboard', function (growl) {
    'ngInject';

    return function (scope, element) {
      var clipboard = new Clipboard(element.get(0));

      clipboard.on('success', function (e) {
        scope.$apply(function () {
          growl.success('Copied!');
        });
        e.clearSelection();
      });

      /*clipboard.on('error', function () {
        scope.$apply(function () {
          growl.addErrorMessage('Could not copy to the clipboard');
        });
      });*/

      element.on('$destroy', function () {
        clipboard.destroy();
      });
    };
  })
  .name;
