import template from './fadeout-overflow-scrollable.component.html';

export const FadeoutOverflowComponent = {
  bindings: {
    direction: '@',
    innerClass: '@'
  },
  template,
  transclude: true,
  controller: class FadeoutOverflowController {
    constructor($element, FadeoutOverflowService) {
      'ngInject';

      this.$element = $element;
      this.FadeoutOverflowService = FadeoutOverflowService;
    }

    $onInit() {
      this.FadeoutOverflowService.assertValidDirection(this.direction, 'fadeoutOverflowScrollable');
      this.$element.addClass('fadeout-overflow-scrollable');
      this.$element.addClass(`fadeout-overflow-scrollable_direction_${this.direction}`);
    }

    $postLink() {
      this.fadeoutElement = this.$element.find('.fadeout-overflow-scrollable__overlay');
      this.fadeoutElement.addClass(`fadeout-overflow_direction_${this.direction}`);

      this.scrollElement = this.$element.find('.fadeout-overflow-scrollable__inner');
      this.scrollElement.on('scroll', () => this.update(true));

      this.update(false);
    }

    update(shouldAnimate) {
      this.FadeoutOverflowService.update(this.fadeoutElement, this.scrollElement, this.direction, shouldAnimate);
    }
  }
};
