import angular from 'angular';
import EventEmitterModule from 'modules/common/event-emitter.module';
import { TrustedProjectLackingCreditsModalService } from './trusted-project-lacking-credits-modal.service';
import { TrustedProjectLackingCreditsModalComponent } from './trusted-project-lacking-credits-modal.component';

export const TrustedProjectLackingCreditsModalModule = angular.module('app.project-planner.trusted-project-lacking-credits-modal', [
  EventEmitterModule
]).component('trustedProjectLackingCreditsModal', TrustedProjectLackingCreditsModalComponent)
  .service('TrustedProjectLackingCreditsModalService', TrustedProjectLackingCreditsModalService)
  .name;
