// @ts-ignore
import template from './credits-details.component.html';

import { CapacityService } from 'modules/capacity/capacity.service';
import { IMoney } from 'models/money.model';
import { ICapacityServiceProject } from '../../../models/http/account-capacity-http.model';

export const CreditsDetailsComponent = {
  template,
  controller: class CreditsDetailsController {
    booked: IMoney | null = null;
    bookedPerProject: { [projectId: string]: ICapacityServiceProject<IMoney>; } = {};
    bookedPerDelegatedProject: { [projectId: string]: ICapacityServiceProject<IMoney>; } = {};
    bookedPerUserProject: { [projectId: string]: ICapacityServiceProject<IMoney>; } = {};
    delivered: IMoney | null = null;
    total: IMoney | null = null;

    constructor(
      private CapacityService: CapacityService,
    ) {
      'ngInject';
    }

    $onInit(): void {
      this.booked = this.CapacityService.data.booked.totalBooked;
      this.bookedPerProject = this.CapacityService.data.booked.perProject;
      this.delivered = this.CapacityService.data.awaitingAcceptance.totalDelivered;
      this.total = this.CapacityService.data.balance;

      this.bookedPerProject.forEach((item) => {
        if (item.project.isDelegated) {
          this.bookedPerDelegatedProject.push(item);
        } else {
          this.bookedPerUserProject.push(item);
        }
      });
    }
  }
};
