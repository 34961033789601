// @ts-ignore
import template from './add-referral-modal.component.html';

import { IModalInstanceService } from 'interfaces';
import { IAccount } from 'models/account.model';
import { IAccountResourceService } from 'models/account-resource.model';

export const AddReferralModalComponent = {
  template,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  controller: class AddReferralModalController {
    alreadyReferred = false;
    duplicatedUser = false;
    selfReferral = false;
    currentReferrer: IAccount | null = null;
    foundUser: IAccount | null = null;
    selectedUser: IAccount | null = null;

    // @ts-ignore
    account: IAccount | null = null;
    // @ts-ignore
    resolve: { account: IAccount } = this.resolve;
    // @ts-ignore
    close: (params: { $value: IAccount | null }) => void = this.close;
    // @ts-ignore
    dismiss: () => void = this.dismiss;

    constructor(
      private $uibModal: IModalInstanceService,
      private Account: IAccountResourceService,
    ) {
      'ngInject';
    }

    $onInit() {
      this.account = this.resolve.account;
    }

    select(user: IAccount) {
      this.selectedUser = user;
      this.alreadyReferred = Boolean(user.referrer);
      this.duplicatedUser = this.account!.referrals!.includes(user._id);
      this.selfReferral = this.account!._id === user._id;

      if (this.alreadyReferred && !this.duplicatedUser && !this.selfReferral) {
        this.Account.get({ id: user.referrer }).$promise.then((referrer: IAccount) => {
          this.currentReferrer = referrer;
        });
      }
    }

    done() {
      this.close({ $value: this.selectedUser });
    }

    clear() {
      this.selectedUser = null;
      this.alreadyReferred = false;
      this.duplicatedUser = false;
    }

    find(filter: string) {
      return this.Account.queryActive({ filter }).$promise
        .then((accounts) => accounts.data);
    }
  }
};
