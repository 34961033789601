import _ from 'underscore';
import angular from 'angular';
import { Money } from 'modules/money/money';

export const MyAccountReferralController = function ($scope, $location, referrals, referralTransactions, UrlService, UserManager, teams) {
  'ngInject';

  $scope.user = angular.copy(UserManager.data);
  $scope.teams = teams;
  $scope.referrals = referrals;

  const totalEarnedByReferrals = _(referralTransactions).reduce((memo, transaction) => {
    return memo + transaction.capacity;
  }, 0);

  $scope.totalEarnedByReferrals = Money.create(totalEarnedByReferrals);

  $scope.findTeam = function (query) {
    return _(teams).filter(function (team) {
      var selected = team.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
      return selected;
    });
  };

  $scope.regenerateReferralLink = function () {
    var path = $scope.selectedTeam ? '/teams/' + UrlService.slugify($scope.selectedTeam.name) : '';
    $scope.referralLink = $location.protocol() + '://' + $location.host() + path + '?referrer=' + UserManager.data._id;
  };

  $scope.regenerateReferralLink();
};
