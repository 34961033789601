import angular from 'angular';
import { RemoveAccountComponent } from './remove-account.component';
import 'angular-growl-v2';

export default angular.module('app.accounts.remove', [
  require('@uirouter/angularjs').default,
  require('modules/money').default,
  'angular-growl',
])
  .component('removeAccount', RemoveAccountComponent)
  .name;
