import angular from 'angular';

export default angular.module('app.resources.order', [
  require('angular-resource')
])
  .service('Order', function ($resource) {
    'ngInject';

    return $resource('/api/orders/:id/:path', { id: '@_id' }, {
      changeInternalFlag: {
        method: 'PUT',
        params: { path: 'isInternal' }
      },
      changeStatus: {
        method: 'PUT',
        params: { path: 'status' }
      },
      createSalesInvoiceFromOrder: {
        method: 'POST',
        params: { path: 'salesInvoice' }
      },
      getPage: {
        method: 'GET',
        params: { path: 'page' }
      },
      // Sends FormData payload
      requestPayment: {
        headers: {
          'Content-Type': undefined
        },
        method: 'POST',
        params: { path: 'requestPayment' },
        transformRequest: angular.identity,
      },
      updateDescription: {
        method: 'PUT',
        params: { path: 'updateDescription' }
      }
    });
  })
  .name;

