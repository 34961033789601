import angular from 'angular';
import template from './project-add-edit-modal.component.html';

export const ProjectAddEditModalComponent = {
  bindings: {
    close: '&',
    resolve: '<'
  },
  template,
  controller: class ProjectAddEditModalController {
    get isDraft() {
      return Boolean(this.project && this.project.unsaved);
    }

    get isExisting() {
      return Boolean(this.project && this.project._id);
    }

    $onInit() {
      this.project = angular.copy(this.resolve.project);
    }

    handleClose() {
      this.close({ $value: this.project });
    }

    handleRemove() {
      this.project.archived = true;
      this.close({ $value: this.project });
    }

    handleUpdate(project) {
      this.project = angular.copy(project);
    }
  }
};
