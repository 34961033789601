import angular from 'angular';

const allUsers = {};

export default angular.module('app.common.user', [
  'app.resources.account'
])
  .directive('user', () => {
    return {
      replace: true,
      template: '<div class="user">' +
      '<avatar ng-if="user._id" class="user__pic" hash="{{ user.emailHash }}" name="{{ user.name }}" size="20"></avatar>' +
      '<span ng-if="user._id" ng-bind="user.name"></span>' +
      '</div>',
      scope: {
        userId: '@user'
      },
      controller: function ($scope, $attrs, Account) {
        'ngInject';

        const handleUser = function () {
          if (allUsers[$scope.userId]) {
            $scope.user = allUsers[$scope.userId];
          } else {
            allUsers[$scope.userId] = Account.get({ id: $scope.userId });
            $scope.user = allUsers[$scope.userId];
          }
        };

        handleUser();

        $attrs.$observe('user', handleUser);
      }
    };
  })
  .name;
