import angular from 'angular';
import 'angular-growl-v2';
import UiRouterModule from '@uirouter/angularjs';
import ProjectsManageTemplate from './projects-manage.html';

import CapacityModule from 'modules/capacity';
import CapacityResourceModule from 'modules/resources/capacity';
import CommonEventEmitterModule from 'modules/common/event-emitter.module';
import CommonFiltersModule from 'modules/common/filters';
import ConstantsModule from 'app-constants';
import ModalModule from 'modules/common/ui-bootstrap-modal-2.5.6';
import MoneyModule from 'modules/money';
import OrderResourceModule from 'modules/resources/order';
import ProjectModalModule from 'modules/projects/modals';
import ProjectResourceModule from 'modules/resources/project';
import ProjectTeamMatchingModule from 'modules/projects/project-team-matching-service';
import TeamResourceModule from 'modules/resources/team';
import UserManagerModule from 'modules/user-manager';

import ProjectPlannerModule from './project-planner';
import ProjectFeedModule from './project-feed';
import ProjectAccountingModule from './project-accounting';
import { ProjectManageController } from './projects-manage-ctrl';
import { SendSalesInvoiceModalModule } from '../../send-sales-invoice-modal';

export default angular.module('app.projects.manage', [
  'angular-growl',
  CapacityModule,
  CapacityResourceModule,
  CommonEventEmitterModule,
  CommonFiltersModule,
  ConstantsModule,
  ModalModule,
  MoneyModule,
  OrderResourceModule,
  ProjectAccountingModule,
  ProjectFeedModule,
  ProjectModalModule,
  ProjectPlannerModule,
  ProjectResourceModule,
  ProjectTeamMatchingModule,
  SendSalesInvoiceModalModule,
  TeamResourceModule,
  UiRouterModule,
  UserManagerModule
])
  .config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state('projects.single.manage', {
      controller: 'ProjectsManageController',
      template: ProjectsManageTemplate,
      redirectTo: 'projects.single.manage.planner',
      resolve: {
        capacity: function (UserManager, CapacityService, project) {
          const isProjectDelegated = project.assistants.some(a => a === UserManager.data._id) && project.owner;

          if (isProjectDelegated) {
            return CapacityService.showStatsForUserInProject(project.owner, project);
          }
        }
      }
    });
  })
  .controller('ProjectsManageController', ProjectManageController)
  .name;
