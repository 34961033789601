import * as angular from 'angular';
import { ProjectViewComponent } from './project-view.component';

import AdminNoteModule from 'modules/admin-note';
import UiRouterModule, { StateProvider } from '@uirouter/angularjs';

export const ProjectViewModule = angular.module('app.projects.view', [
  AdminNoteModule,
  UiRouterModule
])
  .config(($stateProvider: StateProvider) => {
    'ngInject';

    $stateProvider.state('projects.single.view', {
      component: 'projectView',
      onEnter: () => {
        window.scrollTo(0, 0);
      },
      // project resolve is inherited from parent state
    });
  })
  .component('projectView', ProjectViewComponent)
  .name;
