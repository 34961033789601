import angular from 'angular';
import { BookingsDeliveriesListController } from './bookings-deliveries-list-ctrl';
import BookingsDeliveriesListTemplate from './bookings-deliveries-list.html';

export default angular.module('app.admin-panel.bookings-deliveries', [
  require('@uirouter/angularjs').default,
  require('modules/common/ui-bootstrap-modal-2.5.6').default,
  require('modules/money').default,
  require('modules/resources/account').default,
  require('modules/resources/capacity').default,
  require('modules/resources/project').default,
  require('modules/user-manager').default,
  require('app-constants').default
])
  .config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state('admin-panel.bookings-deliveries', {
      url: '/bookings-deliveries',
      resolve: {
        capacityOperations: (Capacity) => {
          return Capacity.getAll().$promise;
        }
      },
      views: {
        'admin-panel': {
          controller: 'AdminPanelBookingsDeliveriesListCtrl',
          template: BookingsDeliveriesListTemplate,
        }
      },
      permissions: ['admin'],
      data: {
        title: 'Bookings and deliveries'
      }
    });
  })
  .controller('AdminPanelBookingsDeliveriesListCtrl', BookingsDeliveriesListController)
  .name;
