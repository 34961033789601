import * as angular from 'angular';
import _ from 'underscore';
import template from './project-form.component.html';

export const ProjectFormComponent = {
  bindings: {
    autoOfferTeamId: '<',
    newClientEmail: '<',
    onRemove: '&',
    onUpdate: '&',
    project: '<',
    shouldShowDelegates: '<'
  },
  transclude: {
    header: '?projectFormHeader',
  },
  template,
  controller: class ProjectFormController {
    constructor(
      $location,
      $q,
      $scope,
      $state,
      $window,
      Account,
      BUDGET_CHOICES,
      EventEmitter,
      Project,
      PROJECT_QUESTIONS,
      projectService,
      SegmentService,
      UserManager
    ) {
      'ngInject';

      this.$location = $location;
      this.$q = $q;
      this.$scope = $scope;
      this.$state = $state;
      this.$window = $window;
      this.Account = Account;
      this.BUDGET_CHOICES = BUDGET_CHOICES;
      this.EventEmitter = EventEmitter;
      this.Project = Project;
      this.PROJECT_QUESTIONS = PROJECT_QUESTIONS;
      this.projectService = projectService;
      this.SegmentService = SegmentService;
      this.UserManager = UserManager;
    }

    AddEditProjectForm = null;
    consent = false;
    currentQuestionIndex = 0;
    debouncedSaveDraft = _.debounce(() => {
      this.$scope.$apply(() => this.saveDraft());
    }, 1000);
    isSubmitting = false;
    newClient = null;

    request = null;

    $onInit() {
      this.project = this.project
        ? this.initProject(this.project)
        : this.initDefaultProject();
      this.currentQuestionIndex = this.initCurrentQuestionIndex(this.project);

      if (!this.UserManager.isLogged) {
        this.newClient = new this.Account({
          accountType: 'client',
          email: this.newClientEmail
        });

        if (this.$window.localStorage.referrer) {
          this.newClient.referrer = this.$window.localStorage.referrer;
          this.$location.search('referrer', this.newClient.referrer);
          this.$window.localStorage.removeItem('referrer');
        }
      }
    }

    get fillScore() {
      const keys = ['name', 'budget', 'description', 'businessProblem', 'technicalSolution', 'kindOfPeople'];
      const values = keys.map(key => this.project[key]);

      const fillScore = values.reduce((memo, value) => {
        if (angular.isArray(value)) {
          return memo + Number(Boolean(value.length));
        }
        return memo + Number(Boolean(value));
      }, 0);

      return fillScore * 100 / values.length;
    }

    get isAdmin() {
      return this.UserManager.isLogged && this.UserManager.data.isAdmin;
    }

    get isDelegated() {
      return (
        this.UserManager.isLogged &&
        _(this.project.assistants).some(
          assistantId => assistantId === this.UserManager.data._id
        )
      );
    }

    get isDraft() {
      return Boolean(this.project.unsaved);
    }

    get isExisting() {
      return Boolean(this.project._id);
    }

    get isOwner() {
      return Boolean(
        this.UserManager.isLogged &&
          this.project.owner &&
          this.project.owner._id === this.UserManager.data._id
      );
    }

    get isSubmittable() {
      return !this.isSubmitting && this.AddEditProjectForm.$valid;
    }

    get projectLink() {
      if (this.project._id) {
        const href = this.$state.href('projects.single', { id: this.project._id });
        return this.$window.location.origin + href;
      }

      return null;
    }

    createAccount(account) {
      this.SegmentService.track('Signed up', {
        name: account.name,
        email: account.email,
        createdAt: new Date()
      });

      account.attribution = this.UserManager.getAttribution();

      return this.Account.save({
        account: account,
        consent: this.consent
      }).$promise.then(createdAccount => {
        this.SegmentService.alias(createdAccount._id);
        this.UserManager.set(createdAccount, { createdAt: new Date() });
      });
    }

    initCurrentQuestionIndex(project) {
      const currentQuestionIndex = _(this.PROJECT_QUESTIONS).findLastIndex(
        question => project[question.key]
      );

      return currentQuestionIndex === -1 ? 0 : currentQuestionIndex;
    }

    initDefaultProject() {
      const project = { budget: this.BUDGET_CHOICES[0].value };

      if (this.UserManager.isLogged) {
        project.owner = { _id: this.UserManager.data._id };
      }

      return project;
    }

    initProject(project) {
      return angular.copy(project);
    }

    nextQuestion() {
      this.currentQuestionIndex++;
      const currentQuestionKey = this.PROJECT_QUESTIONS[this.currentQuestionIndex].key;

      this.$window.requestAnimationFrame(() => {
        angular.element('#' + currentQuestionKey).focus();
      });
    }

    removeProject() {
      this.projectService.removeProject(this.project, () => {
        this.onRemove();
      });
    }

    saveDraft() {
      this.isSubmitting = true;

      const project = angular.copy(this.project);
      project.unsaved = true;

      if (this.saveDraftRequest) {
        this.saveDraftRequest.$cancelRequest();
        this.saveDraftRequest = null;
      }

      this.saveDraftRequest = this.isExisting
        ? this.Project.update(project)
        : this.Project.save(project);

      this.saveDraftRequest.$promise
        .then(savedDraft => {
          this.isSubmitting = false;
          this.saveDraftRequest = null;
          this.project._id = savedDraft._id;
          this.onUpdate(this.EventEmitter({ project: this.initProject(savedDraft) }));
        })
        .catch(error => {
          if (error.status !== -1) {
            this.isSubmitting = false;
            this.saveDraftRequest = null;
          }
        });
    }

    publishProject() {
      const project = angular.copy(this.project);
      project.unsaved = false;

      const whenPublished = this.isExisting
        ? this.Project.update(project).$promise
        : this.Project.save(project).$promise;

      return whenPublished.then(publishedProject => {
        const params = {
          autoOfferTeamId: this.autoOfferTeamId,
          id: publishedProject._id
        };
        this.$state.go('projects.single', params, { reload: true });
      });
    }

    submit() {
      if (!this.isSubmittable) {
        return;
      }

      this.isSubmitting = true;

      const whenSubmitted = this.newClient
        ? this.createAccount(this.newClient).then(() => this.publishProject())
        : this.publishProject();

      whenSubmitted.finally(() => {
        this.isSubmitting = false;
      });
    }
  }
};
