import _ from 'underscore';
import angular from 'angular';
import moment from 'moment';
import template from './project-card.html';
import { Money } from 'modules/money/money';

export default angular.module('app.projects.project-card', [
  require('modules/money').default,
  require('modules/projects/modals').default,
  require('modules/projects/planner-summary').default
])
  .directive('projectCard', function ($state, Account, CapacityService, CONFIG, Project, projectModals, UserManager) {
    'ngInject';

    return {
      restrict: 'A',
      template,
      scope: {
        onArchived: '&projectCardOnArchived',
        project: '=projectCard'
      },
      link: function (scope, element, attrs) {
        const isDelegated = scope.project.owner._id !== UserManager.data._id;
        scope.isPreview = ['preview', 'static'].indexOf(attrs.mode) !== -1;
        scope.isLink = attrs.mode !== 'static';

        scope.projectLink = function (project) {
          return $state.href('projects.single', { id: project._id });
        };

        scope.editProject = function (project, event) {
          if (!scope.isLink || !project.unsaved) {
            return;
          }

          projectModals.openAddEditProject(project, event)
            .result.then(function (updatedProject) {
              if (updatedProject.archived) {
                scope.onArchived(updatedProject);
              }
              angular.extend(project, updatedProject);
            });
        };

        scope.publishDraft = function ($event, project) {
          $event.preventDefault();

          var projectToSave = _(project).clone();
          projectToSave.unsaved = false;

          Project.update(projectToSave, function () {
            $state.go('projects.single', { id: project._id }, { reload: true })
              .then(function () {
                project.unsaved = false;
              });
          });
        };

        scope.updateView = function () {
          scope.$applyAsync(function () {
            scope.isButtonShown = element.find('.project-card__btn > .btn').length > 0;
          });
        };

        function getPlannerData(projectId) {
          Account.getProjectCapacityWeeks({
            id: UserManager.data._id,
            projectId: projectId,
            end: moment.utc().add(5, 'weeks').format(CONFIG.YEAR_WEEK_FORMAT)
          }).$promise
            .then(response => response.toJSON())
            .then(capacityWeeks => {
              scope.capacityWeeks = _(capacityWeeks).chain()
                .filter(week => week.booked)
                .map(week => {
                  week.booked = Money.create(week.booked);
                  week.delivered = Money.create(week.delivered);
                  return week;
                })
                .reverse()
                .value();

              scope.isAwaitingAcceptance = _(scope.capacityWeeks).some(function (week) {
                return week.isSprintFinished && !week.isDemoAccepted;
              });
              scope.updateView();
            });

          if (isDelegated && CapacityService.data.delegated.perProject.hasOwnProperty(projectId)) {
            scope.totalBooked = CapacityService.data.delegated.perProject[projectId].totalBooked;
          } else if (CapacityService.data.booked.perProject.hasOwnProperty(projectId)) {
            scope.totalBooked = CapacityService.data.booked.perProject[projectId].totalBooked;
          } else {
            scope.totalBooked = Money.create(0);
          }
        }

        if (scope.project._id && !scope.isPreview && scope.project.assignedTeam) {
          getPlannerData(scope.project._id);
        }

        scope.updateView();

        scope.$watch('project', defineProjectProperties, true);

        defineProjectProperties();

        function defineProjectProperties() {
          scope.isOwner =
            UserManager.isLogged &&
            scope.project.owner._id === UserManager.data._id;

          scope.composedDescription = _(scope.project).chain()
            .pick('description', 'businessProblem', 'technicalSolution', 'kindOfPeople')
            .values()
            .compact()
            .join('\n\n')
            .value();
        }
      }
    };
  })
  .name;
