// @ts-ignore
import template from './callout.component.html';

export const CalloutComponent: ng.IComponentOptions = {
  bindings: {
    heading: '@',
  },
  template,
  transclude: true,
  controller: class CalloutController {
    constructor(
      private $element: ng.IRootElementService
    ) {
      'ngInject';
    }

    $postLink() {
      this.$element.addClass('callout').addClass('__info');
    }
  }
};
