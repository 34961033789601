// @ts-ignore
import template from './company-details-form.component.html';

import { IAccountCompany, IAccount } from 'models/account.model';
import { IAccountUpdateCompanyDetailsRequest } from 'models/http/account-http.model';
import { IEventEmitter, IEventEmitterValue } from 'interfaces';
import { IAccountResourceService } from 'models/account-resource.model';

interface IValue {
  company: IAccountCompany;
  vatExempt: boolean;
}

export const CompanyDetailsFormComponent: ng.IComponentOptions = {
  bindings: {
    account: '<',
    onCancel: '&',
    onSuccess: '&',
    showCancel: '<',
  },
  template,
  controller: class CompanyDetailsFormController implements ng.IController {
    // Bindings
    // @ts-ignore
    account: IAccount = this.account;
    // @ts-ignore
    onCancel: () => void = this.onCancel;
    // @ts-ignore
    onSuccess: (value: IEventEmitterValue<IValue>) => void = this.onSuccess;
    // @ts-ignore
    showCancel: string = this.showCancel;

    // @NB: Keep aligned with server/services/vat.js
    euVatRegexp = new RegExp('^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$');
    nlVatRegexp = new RegExp('^((NL)?[0-9]{9}B[0-9]{2})$');
    sanitizeVatRegexp = new RegExp('[^A-Z0-9]', 'g');

    consent: boolean = false;

    constructor(
      private Account: IAccountResourceService,
      private EventEmitter: IEventEmitter<IValue>,
    ) {
      'ngInject';
    }

    $onInit(): void {
      this.consent = false; // reset consent
    }

    onVatIdChanged(): void {
      if (!this.account.company || !this.account.company.vatId) {
        return;
      }

      const sanitizedVatId = this.account.company.vatId.toUpperCase().replace(this.sanitizeVatRegexp, '');

      this.account.company.inEU = this.euVatRegexp.test(sanitizedVatId);
      this.account.company.inNL = this.nlVatRegexp.test(sanitizedVatId);
    }

    onInEuChanged(): void {
      if (this.account.company && this.account.company.inEU) {
        this.account.company.inNL = false;
      }
    }

    save(form: ng.IFormController): void {
      if (form.$invalid) {
        form.$submitted = true;
        return;
      }

      const requestParams = { id: this.account._id };
      const requestBody: IAccountUpdateCompanyDetailsRequest = {
        company: this.account.company as IAccountCompany,
        consent: this.consent
      };
      requestBody.company.inEU = requestBody.company.inEU || false;
      requestBody.company.inNL = requestBody.company.inNL || false;

      this.Account.updateCompanyDetails(requestParams, requestBody).$promise
        .then((updatedAccount) => {
          if (this.onSuccess) {
            this.onSuccess(this.EventEmitter({
              company: updatedAccount.company as IAccountCompany,
              vatExempt: Boolean(updatedAccount.vatExempt)
            }));
          }
        });
    }

    cancel(): void {
      if (this.onCancel) {
        this.onCancel();
      }
    }
  }
};
