// @ts-ignore
import template from './account-overview.component.html';
import { isEmpty } from 'underscore';
import { Money } from 'modules/money/money';
import { ICapacityServiceResponse } from '../../../../models/http/account-capacity-http.model';
import { IModalInstanceService } from '../../../../interfaces';
import { IAccountResourceService } from '../../../../models/account-resource.model';
import { CONSTANTS } from '../../../../constants';
import { UserManagerService } from '../../../user-manager/user-manager.service';
import { EAccountStatuses, IAccount } from '../../../../models/account.model';
import { IMoney } from '../../../../models/money.model';

export const AccountOverviewComponent = {
  bindings: {
    account: '<'
  },
  template,
  controller: class AccountOverviewController {
    // Bindings
    // @ts-ignore
    account: IAccount = this.account;

    currentStatus: EAccountStatuses;
    accountStatuses = CONSTANTS.ACCOUNT_STATUSES;

    // Money from capacity api
    balance: { [key: string]: IMoney } = {
      available: null,
      booked: null,
      total: null
    };

    isStatusChangeInProgress = false;
    isToggleVatExemptInProgress = false;
    isToggleRemovalRequestInProgress = false;

    constructor(
      private $uibModal: IModalInstanceService,
      private Account: IAccountResourceService,
      private UserManager: UserManagerService
    ) {
      'ngInject';
    }

    $onInit() {
      this.currentStatus = this.account.status;

      this.fetchBalance();
    }

    get hasAttribution() {
      return this.account.attribution && !isEmpty(this.account.attribution);
    }

    fetchBalance() {
      this.Account.capacity({ id: this.account._id }).$promise
        .then((response: ICapacityServiceResponse<number>) => {
          // Money from capacity api
          this.balance = {
            available: Money.create(response.balance - response.booked.totalBooked),
            booked: Money.create(response.booked.totalBooked),
            total: Money.create(response.balance)
          };
        });
    }

    changeAccountStatus(account: IAccount) {
      const id = account._id;

      if (this.isStatusChangeInProgress) {
        return;
      }

      this.isStatusChangeInProgress = true;

      this.Account.updateStatus({ id }, { status: account.status }).$promise
        .then(() => this.currentStatus = account.status)
        .catch(() => {
          // Optimistic UI: revert changes if server returned an error
          account.status = this.currentStatus;
        })
        .finally(() => this.isStatusChangeInProgress = false);
    }

    toggleVatExempt(account: IAccount) {
      const id = account._id;

      if (this.isToggleVatExemptInProgress) {
        return;
      }

      this.isToggleVatExemptInProgress = true;

      this.Account.updateVatExempt({ id }, { vatExempt: account.vatExempt }).$promise
        .then(() => {
          // Update vatExempt if it's logged in user
          if (this.UserManager.data._id === id) {
            this.UserManager.data.vatExempt = account.vatExempt;
          }
        })
        .catch(() => {
          // Optimistic UI: revert changes if server returned an error
          account.vatExempt = !account.vatExempt;
        })
        .finally(() => this.isToggleVatExemptInProgress = false);
    }

    toggleRemovalRequest(account: IAccount) {
      const id = account._id;

      if (this.isToggleRemovalRequestInProgress) {
        return;
      }

      this.isToggleRemovalRequestInProgress = true;

      this.Account.updateRemovalMark({ id }, { requestedRemoval: account.requestedRemoval }).$promise
        .then(() => {
          // Update vatExempt if it's logged in user
          if (this.UserManager.data._id === id) {
            this.UserManager.data.requestedRemoval = account.requestedRemoval;
          }
        })
        .catch(() => {
          // Optimistic UI: revert changes if server returned an error
          account.requestedRemoval = !account.requestedRemoval;
        })
        .finally(() => this.isToggleRemovalRequestInProgress = false);
    }

    showRechargeCapacityModal(account: IAccount) {
      this.$uibModal.open({
        resolve: {
          account: () => account,
          balance: () => this.balance
        },
        component: 'adminPanelRechargeModalComponent',
        size: 'small'
      }).result
        .finally(() => this.fetchBalance());
    }

    isLoggedInUser(id: string) {
      return this.UserManager.data._id === id;
    }
  }
};
