import * as angular from 'angular';

import EventEmitterModule from 'modules/common/event-emitter.module';
import { RecaptchaComponent } from './recaptcha.component';
import { NoopcaptchaService } from './noopcaptcha.service';
import { RecaptchaService } from './recaptcha.service';

export const RecaptchaModule = angular.module('app.recaptcha', [ EventEmitterModule ])
  .component('recaptcha', RecaptchaComponent)
  .service('NoopcaptchaService', NoopcaptchaService)
  .service('RecaptchaService', RecaptchaService)
  .name;
