import { IAccount } from './account.model';

export enum EOrderStatus {
  open = 'open',
  cancelled = 'cancelled',
  pending = 'pending',
  paid = 'paid',
  expired = 'expired',
  refunded = 'refunded',
}

export enum EOrderPaymentProvider {
  manual = 'manual',
  icepay = 'icepay',
  mollie = 'mollie',
  invoice = 'invoice',
  transactionFee = 'transactionFee',
}

export interface IOrder {
  _id: string;
  account: string;
  capacity: number;
  createdAt: Date;
  description: string;
  exactUserId: string;
  gdriveFileId: string;
  icepay?: any; // legacy
  isInternal: boolean;
  isReferral: boolean;
  linkedTransaction: string;
  paid: boolean;
  paidToReferrer: boolean;
  payment: any;
  paymentProvider: EOrderPaymentProvider;
  status: EOrderStatus;
  type: 'sales' | 'purchase' | 'fee' | 'bonus' | 'internal'; // Virtual model field
}

export interface IOrderExpandedAccount {
  _id: string;
  account: IAccount;
  capacity: number;
  createdAt: Date;
  description: string;
  exactUserId: string;
  gdriveFileId: string;
  icepay?: any; // legacy
  isInternal: boolean;
  isReferral: boolean;
  linkedTransaction: string;
  paid: boolean;
  paidToReferrer: boolean;
  payment: any;
  paymentProvider: EOrderPaymentProvider;
  status: EOrderStatus;
}
