export const FadeoutOverflowDirective = ($window, FadeoutOverflowService) => {
  'ngInject';

  const isResizeObserverSupported = 'ResizeObserver' in $window;

  return (scope, element, attrs) => {
    const direction = attrs.fadeoutOverflow;
    FadeoutOverflowService.assertValidDirection(direction, 'fadeoutOverflow');

    element.addClass('fadeout-overflow');
    element.addClass(`fadeout-overflow_direction_${direction}`);

    FadeoutOverflowService.update(element, element, direction, false);

    if (isResizeObserverSupported) {
      const observer = new $window.ResizeObserver(() => {
        FadeoutOverflowService.update(element, element, direction, true);
      });
      observer.observe(element[0]);
      element.on('$destroy', () => observer.disconnect());
    }
  };
};
