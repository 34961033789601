import _ from 'underscore';
import angular from 'angular';
import { ProjectAddEditModalComponent } from './project-add-edit-modal.component';

export default angular.module('app.projects.modals', [
  require('@uirouter/angularjs').default,
  require('modules/ui/clipboard').default,
])

  .service('projectModals', function ($uibModal) {
    'ngInject';

    return {
      openAddEditProject: function (project, event) {
        return $uibModal.open({
          event: event,
          component: 'projectAddEditModal',
          keyboard: false,
          resolve: {
            project: function () {
              if (_.isObject(project)) {
                return angular.copy(project);
              } else {
                return undefined;
              }
            },
            tags: function (Tag) {
              return Tag.query().$promise;
            }
          },
          backdrop: 'static',
          size: 'large'
        });
      }
    };
  })

  .component('projectAddEditModal', ProjectAddEditModalComponent)
  .name;
