import _ from 'underscore';
import angular from 'angular';

export const TeamsListController = function ($scope, UserManager, teams, TeamsModalsService) {
  'ngInject';

  $scope.teams = teams;
  $scope.user = UserManager.data;

  _(teams).each(function (team) {
    team.members = _(team.members).shuffle();
  });

  $scope.myTeams = _(teams).chain()
    .filter(function (team) {
      var me = _(team.members).find(function (member) {
        return member.user._id === UserManager.data._id;
      });

      return me;
    })
    .value();

  $scope.otherTeams = _(teams)
    .chain()
    .reject(function (team) {
      return _(team.members).find(function (member) {
        return member.user._id === UserManager.data._id;
      });
    })
    .value();

  $scope.showCreateNewTeamModal = function (event) {
    TeamsModalsService.openAddEditModal(null, true, true, undefined, event)
      .result.catch(angular.noop);
  };
};
