import template from './account.component.html';

export const AdminPanelAccountComponent = {
  bindings: {
    account: '<'
  },
  template,
  controller: class AdminPanelAccountController {
    constructor() {
      'ngInject';
    }
  }
};
