import angular from 'angular';
import { isEmpty, range } from 'underscore';
import template from './pagination.component.html';

export const PaginationComponent = {
  bindings: {
    // Optional namespace for pagination options stored in localStorage.
    // Defaults to current state name.
    key: '@',
    // Callback fired when page is selected. Accepts arguments:
    //  - page {number}
    //  - perPage {number}
    onSelect: '&',
    // Array of numbers of available page sizes
    pageSizes: '<',
    // Pages property of paginated api response. Structure is:
    //  - prev {number}
    //  - last {number}
    pages: '<',
  },
  template,
  controller: class PaginationController {
    constructor($element, $state, $window) {
      'ngInject';

      this.$element = $element;
      this.$state = $state;
      this.$window = $window;

      // Referred in $onChanges, hence should be defined in the constructor
      this.pagination = {
        page: 1,
        pages: [1],
        pageSizes: [50, 100, 150],
        perPage: null
      };
    }

    $onChanges(changes) {
      if (changes.key) {
        this.key = changes.key.currentValue;
      }

      if (changes.pages) {
        this.pages = angular.copy(changes.pages.currentValue);

        this.paginate(this.pages, this.pagination.page);
      }

      if (changes.pageSizes && changes.pageSizes.currentValue) {
        this.pagination.pageSizes = angular.copy(changes.pageSizes.currentValue);
      }
    }

    $onInit() {
      this.$element.addClass('pagination');

      // if there is no key attribute, assign key to current state name
      if (!this.key) {
        this.key = this.$state.current.name;
      }

      const storedPerPage = parseInt(this.$window.localStorage.getItem(this.storageKey), 10);
      const defaultPerPage = this.pagination.pageSizes[0];
      this.pagination.perPage = storedPerPage || defaultPerPage;

      if (this.pages) {
        this.paginate(this.pages);
      }

      this.fetchPage(this.pagination.page, this.pagination.perPage);
    }

    get storageKey() {
      return `pagination.${this.key}.perPage`;
    }

    getLastPageNumber(pagesData) {
      return pagesData.last + 1;
    }

    paginate(pagesInfo, page = 1) {
      if (!isEmpty(pagesInfo)) {
        this.pagination.pages = range(1, this.getLastPageNumber(pagesInfo));
        this.pagination.page = page;
      } else {
        this.pagination.pages = [1];
        this.pagination.page = 1;
      }
    }

    fetchPage(page, perPage) {
      this.onSelect({ page, perPage });
      this.pagination.page = page;
      this.$window.localStorage.setItem(this.storageKey, perPage);
    }
  }
};
