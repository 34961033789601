import angular from 'angular';

import AccountResourceModule from 'modules/resources/account';
import EventEmitterModule from 'modules/common/event-emitter.module';
import ModalModule from 'modules/common/ui-bootstrap-modal-2.5.6';
import MoneyModule from 'modules/money/index';
import UserManagerModule from 'modules/user-manager/index';
import { BuyCreditsModalComponent } from './buy-credits-modal.component';
import { BuyCreditsModalService } from './buy-credits-modal.service';
import { BuyCreditsFormComponent } from './buy-credits-form/buy-credits-form.component';
import { CompanyDetailsFormComponent } from './company-details-form/company-details-form.component';
import { CreditsDetailsComponent } from './credits-details/credits-details.component';
import { CreditsDetailsProjectComponent } from './credits-details/credits-details-project.component';

export const BuyCreditsModalModule = angular.module('app.buy-credits-modal', [
  AccountResourceModule,
  EventEmitterModule,
  ModalModule,
  MoneyModule,
  UserManagerModule
])
  .component('buyCreditsModal', BuyCreditsModalComponent)
  .component('buyCreditsForm', BuyCreditsFormComponent)
  .component('companyDetailsForm', CompanyDetailsFormComponent)
  .component('creditsDetails', CreditsDetailsComponent)
  .component('creditsDetailsProject', CreditsDetailsProjectComponent)
  .service('BuyCreditsModalService', BuyCreditsModalService)
  .name;
