import angular from 'angular';
import MyAccountTemplate from './my-account.html';

export default angular.module('app.accounts.my', [
  require('modules/resources/auth').default,
  require('modules/accounts/my/balance').default,
  require('modules/accounts/my/profile').default,
  require('modules/accounts/my/referral').default,
  require('modules/accounts/my/transactions').default,
  require('modules/accounts/my/settings').default,
  require('@uirouter/angularjs').default
])
  .config(function ($stateProvider) {
    'ngInject';

    $stateProvider
      .state('accounts.my', {
        url: '/my',
        abstract: true,
        controller: function ($scope, UserManager) {
          $scope.user = UserManager.data;
        },
        template: MyAccountTemplate,
        data: {
          title: 'My account'
        }
      });
  })
  .name;

