// @ts-ignore
import template from './hire-team.component.html';

import { ITeam } from 'models/team.model';
import { IProject } from 'models/project.model';
import { IProjectResourceService } from 'interfaces';
import { Recaptcha } from 'modules/recaptcha/recaptcha-component.abstract';

export const HireTeamModalComponent: ng.IComponentOptions = {
  bindings: {
    dismiss: '&',
    close: '&',
    resolve: '<'
  },
  template,
  controller: class HireTeamModalController extends Recaptcha {
    // Bindings
    // @ts-ignore
    close: () => void = this.close;
    // @ts-ignore
    dismiss: () => void = this.dismiss;
    // @ts-ignore
    resolve: { autoInviteTeam: string; project: IProject; team: ITeam; } = this.resolve;

    autoInviteTeam: string = this.resolve && this.resolve.autoInviteTeam;
    project: IProject = this.resolve && this.resolve.project;
    team: ITeam = this.resolve && this.resolve.team;

    isInProgress = false;

    constructor(
      private $q: ng.IQService,
      private $window: ng.IWindowService,
      private Project: IProjectResourceService,
    ) {
      'ngInject';

      super($q);
    }

    $onInit() {
      this.autoInviteTeam = this.resolve.autoInviteTeam;
      this.project = this.resolve.project;
      this.team = this.resolve.team;
    }

    acceptTeam() {
      if (this.isInProgress) {
        return;
      }

      this.isInProgress = true;

      this.recaptcha.execute()
        .then(this.recaptchaVerified)
        .then(($$recaptchaToken: string) => {
          return this.Project.acceptTeam({
            projectId: this.project._id,
            teamId: this.team._id,
            $$recaptchaToken
          }).$promise;
        })
        .then(() => {
          this.$window.location.reload();
        })
        .finally(() => this.isInProgress = false);
    }
  }
};
