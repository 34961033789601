import angular from 'angular';
import EventResourceModule from 'modules/resources/events';
import OrderResourceModule from 'modules/resources/order';
import UiRouterModule from '@uirouter/angularjs';
import { AdminTransactionEditDescriptionComponent } from './edit-description.component';
import { AdminTransactionEditInternalFlagComponent } from './edit-internal-flag.component';
import { AdminTransactionEditStatusComponent } from './edit-status.component';
import { AdminTransactionModalComponent } from './admin-transaction-modal.component';
import { AdminTransactionsListComponent } from './admin-transactions-list.component';
import { AdminTransactionExactEventsComponent } from './transaction-exact-events.component';

export const AdminPanelTransactionsModule = angular.module('app.admin-panel.transactions', [
  EventResourceModule,
  OrderResourceModule,
  UiRouterModule
])
  .component('adminTransactionEditDescription', AdminTransactionEditDescriptionComponent)
  .component('adminTransactionEditStatus', AdminTransactionEditStatusComponent)
  .component('adminTransactionEditInternalFlag', AdminTransactionEditInternalFlagComponent)
  .component('adminTransactionModal', AdminTransactionModalComponent)
  .component('adminTransactionsList', AdminTransactionsListComponent)
  .component('adminTransactionExactEvents', AdminTransactionExactEventsComponent)
  .config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state('admin-panel.transactions', {
      url: '/transactions',
      resolve: {
        showAccountName: () => true
      },
      views: {
        'admin-panel': {
          component: 'adminTransactionsList'
        }
      },
      permissions: ['admin'],
      data: {
        title: 'Transactions'
      }
    });
  })
  .name;

