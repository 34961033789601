import * as angular from 'angular';
import { CalloutModule } from './callout';
import { CheckboxModule } from './checkbox';
import { SendOrConfigureInvoicesModule } from './send-or-configure-invoices';

export default angular.module('app.ui', [
  CalloutModule,
  CheckboxModule,
  SendOrConfigureInvoicesModule,
  require('modules/ui/focus-directive').default,
  require('modules/ui/autosize-directive').default,
  require('modules/ui/dropdown').default,
  require('modules/ui/icon').default,
  require('modules/ui/line-cutter').default,
  require('modules/ui/management-dropdown-directive').default,
  require('modules/ui/menu').default,
  require('modules/ui/pagination').default,
  require('modules/ui/progress-caption').default,
  require('modules/ui/pinned-notification').default,
  require('modules/ui/fadeout-overflow').default,
  require('modules/ui/sparkline').default,
  require('modules/ui/switcher').default,
  require('modules/ui/txbox-delivery').default,
  require('modules/ui/typeahead').default,
  require('modules/ui/rating').default,
])
  .name;
