import angular from 'angular';
import UiRouter, { StateProvider } from '@uirouter/angularjs';
import SegmentServiceModule from 'modules/common/segment.service';
import TeamResourceModule from 'modules/resources/team';
import { TeamSettingsComponent } from './team-settings.component';

export default angular.module('app.teams.settings', [
  SegmentServiceModule,
  TeamResourceModule,
  UiRouter
])
  .config(($stateProvider: StateProvider) => {
    'ngInject';

    $stateProvider.state('teams.view.settings', {
      url: '/settings',
      views: {
        content: 'teamSettings'
      }
    });
  })
  .component('teamSettings', TeamSettingsComponent)
  .name;
