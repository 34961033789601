import angular from 'angular';
import template from './rating.html';

export default angular.module('app.ui.rating', [])

  .directive('rating', function () {
    return {
      require: 'ngModel',
      replace: true,
      restrict: 'E',
      template,
      scope: {
        tips: '=',
        max: '='
      },
      link: function (scope, elem, attrs, ngModel) {
        scope.toggle = function (index) {
          if (angular.isDefined(attrs.disabled)) {
            return;
          }
          ngModel.$setViewValue(index + 1);
        };


        scope.$watch(function () {
          return ngModel.$viewValue;
        }, function (newRating) {
          scope.stars = [];

          for (var i = 0; i < scope.max; i++) {
            scope.stars.push({
              checked: i < newRating
            });
          }
        });
      }
    };
  })
  .name;
