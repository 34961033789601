import template from './project-add-edit.component.html';

export const ProjectAddEditComponent = {
  bindings: {
    project: '<',
  },
  template,
  controller: class ProjectAddEditController {
    constructor($state) {
      'ngInject';

      this.$state = $state;
    }

    get isDraft() {
      return Boolean(this.project && this.project.unsaved);
    }

    get isExisting() {
      return Boolean(this.project && this.project._id);
    }

    handleRemove() {
      this.$state.go('projects.single', { id: this.project._id });
    }
  }
};
