import { IModalInstanceService } from 'interfaces';

export class TrustedProjectLackingCreditsModalService {
  constructor(
    private $uibModal: IModalInstanceService,
  ) {
    'ngInject';
  }

  open() {
    return this.$uibModal.open({
      component: 'trustedProjectLackingCreditsModal',
      size: 'small',
    }).result;
  }
}
