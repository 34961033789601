import angular from 'angular';
import { AccountOverviewComponent} from './account-overview.component';

export default angular.module('app.admin-panel.accounts.overview', [
  require('@uirouter/angularjs').default,
  require('modules/resources/account').default,
  require('modules/common/ui-bootstrap-modal-2.5.6').default,
  require('modules/money').default,
  require('modules/user-manager').default
])
  .config(function ($stateProvider) {
    'ngInject';

    $stateProvider
      .state('admin-panel.account.overview', {
        url: '/overview',
        views: {
          account: {
            component: 'accountOverview'
          }
        },
        permissions: ['admin'],
        data: {
          title: 'Account Overview'
        }
      });
  })
  .component('accountOverview', AccountOverviewComponent)
  .name;
