import _ from 'underscore';

export const PinnedNotificationsController = function ($scope, pinnedNotifications, Notification, notifications) {
  'ngInject';

  $scope.pinnedNotifications = {
    client: _.where(pinnedNotifications, { to: 'client' }),
    team_member: _.where(pinnedNotifications, { to: 'team_member' })
  };
  $scope.newClientNotification = new Notification({
    type: 'info',
    to: 'client'
  });

  $scope.newNotification = $scope.newClientNotification;

  $scope.newTeamMemberNotification = new Notification({
    type: 'info',
    to: 'team_member'
  });

  /**
   * Methods
   */

  $scope.pinClientNotification = function () {
    Notification.pin($scope.newClientNotification, function (savedNotification) {
      notifications.pinnedNotificationToClients = savedNotification;
      $scope.pinnedNotifications.client.unshift(savedNotification);
      resetInputs($scope.newClientNotification);
    });
  };

  $scope.pinTeamMemberNotification = function () {
    Notification.pin($scope.newTeamMemberNotification, function (savedNotification) {
      notifications.pinnedNotificationToTeamMembers = savedNotification;
      $scope.pinnedNotifications.team_member.unshift(savedNotification);
      resetInputs($scope.newTeamMemberNotification);
    });
  };

  $scope.extendNotification = function (newNotification, notification) {
    // Clean url, because it's not required and sometimes it does not exist and can't be overwritten
    _(newNotification).extend({ url: '' }, _(notification).pick('type', 'text', 'url'));
  };

  /**
   * Helpers
   */

  function resetInputs(newNotification) {
    newNotification.type = 'info';
    newNotification.text = '';
    newNotification.url = '';
  }
};
