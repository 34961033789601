import { stringToColour } from '../../common/string-to-color';

export const ColorTagComponent = {
  template: '<div style="display: inline-block; border-radius: 50%; width: 10px; height: 10px;" ng-style="{ \'background-color\': $ctrl.color }"></div>',
  bindings: {
    string: '<'
  },
  controller: class ColorTagController {
    string: string;
    color: string;

    constructor() {
      'ngInject';
    }

    $onChanges(changes: ng.IOnChangesObject) {
      if (changes.string) {
        this.string = changes.string.currentValue;
        this.color = stringToColour(changes.string.currentValue);
      }
    }
  }
};
