import _ from 'underscore';

// @ts-ignore
import template from './freelancer-project-overview.component.html';
import { IMoney } from '../../../../models/money.model';
import { Money } from '../../../money/money';
import { IModalInstanceService } from '../../../../interfaces';

export const FreelancerProjectOverviewComponent = {
  template,
  bindings: {
    data: '<',
  },
  controller: class FreelancerProjectOverviewController {
    // @ts-ignore
    data = this.data;

    weeksKeys: string[];
    weeklyDeliveries: { [key: string]: IMoney } = {};
    weeksNumber: number;

    constructor(
      private $uibModal: IModalInstanceService,
    ) {
      'ngInject';
    }

    $onInit() {
      this.weeksKeys = Object.keys(this.data.deliveries);
      this.weeksNumber = this.weeksKeys.length;

      this.weeksKeys.forEach((week) => {
        this.weeklyDeliveries[week] = _(this.data.deliveries[week]).reduce((memo, d) => memo.add(d.value), Money.create(0));
      });
    }

    openDetails(): ng.IPromise<void> {
      return this.$uibModal.open({
        size: 'small',
        component: 'projectOverviewDetails',
        resolve: {
          data: () => this.data
        }
      }).result;
    }
  }
};
