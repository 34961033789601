import angular from 'angular';
import { AdminPanelTeamsListController } from './teams-list-ctrl';
import AdminPanelTeamsListTemplate from './teams-list.html';

export default angular.module('app.admin-panel.teams', [
  require('@uirouter/angularjs').default,
  require('modules/teams/modals').default,
  require('modules/resources/team').default,
  require('modules/user-manager').default
])
  .config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state('admin-panel.teams', {
      url: '/teams',
      resolve: {
        teams: (Team) => {
          return Team.query({ archived: true }).$promise;
        }
      },
      views: {
        'admin-panel': {
          controller: 'AdminPanelTeamsListCtrl',
          template: AdminPanelTeamsListTemplate,
        }
      },
      permissions: ['admin'],
      data: {
        title: 'Teams'
      }
    });
  })
  .controller('AdminPanelTeamsListCtrl', AdminPanelTeamsListController)
  .name;

