import angular from 'angular';

import AccountResourceModule from 'modules/resources/account';
import CapacityResourceModule from '../resources/capacity';
import MoneyModule from 'modules/money';
import UserManagerModule from 'modules/user-manager';

import { CapacityService } from './capacity.service';
import { BalanceWidgetComponent } from './balance-widget/balance-widget.component';

export default angular.module('app.capacity', [
  AccountResourceModule,
  CapacityResourceModule,
  MoneyModule,
  UserManagerModule
])
  .component('balanceWidget', BalanceWidgetComponent)
  .service('CapacityService', CapacityService)
  .name;
