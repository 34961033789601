import angular from 'angular';
import _ from 'underscore';

export default angular
  .module('app.ui.line-cutter', [
    require('modules/ui/scroll').default
  ])
  .directive('lineCutter', ($window, ScrollService) => {
    'ngInject';

    const getLineHeight = element => {
      const css = angular.element(element).css('line-height');
      const number = parseFloat(css, 10);

      if (!_.isFinite(number)) {
        throw new Error(
          `[lineCutter] Expected numeric 'line-height' value but got '${css}'`
        );
      }

      return number;
    };

    return {
      restrict: 'A',
      link: (scope, element, attrs) => {
        const lineHeight = getLineHeight(element);
        const maxLines = Number(attrs.lineCutter) || 1;

        element.css({
          maxHeight: maxLines * lineHeight,
          overflow: 'hidden'
        });

        $window.requestAnimationFrame(() => {
          const itemElements = element.find('[line-cutter-item]');
          const moreElement = element.find('[line-cutter-more]');
          const moreTemplate = moreElement.text();

          const shouldCut = hiddenCount =>
            itemElements.length - 1 > hiddenCount &&
            ScrollService.getOverflowY(element) > 0;

          const cut = hiddenCount => {
            itemElements.eq(-hiddenCount).hide();
            moreElement.text(moreTemplate.replace('{}', hiddenCount));

            if (shouldCut(hiddenCount)) {
              cut(hiddenCount + 1);
            }
          };

          if (shouldCut(1)) {
            cut(1);
          } else {
            moreElement.hide();
          }
        });
      }
    };
  }).name;
