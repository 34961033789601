import angular from 'angular';

export default angular.module('app.ui.management-dropdown', [])
  .directive('managementDropdown', function ($document) {
    'ngInject';

    var activeClass = 'management-dropdown__active';
    var docElement = angular.element($document);

    return {
      restrict: 'A',
      link: function (scope, element) {
        var autofocusElement = element.find('[management-dropdown-autofocus]');

        // Hide dropdown when clicking the close icon
        element.find('.management-dropdown__close').on('click', function () {
          element.removeClass(activeClass);
        });

        // Hide dropdown when clicking anywhere...
        docElement.on('click', function () {
          element.removeClass(activeClass);
        });

        // ...except for the dropdown contents
        element.on('click', function (event) {
          event.stopPropagation();
        });

        // Show/Hide dropdown when clicking the toggle
        element.on('click', '[management-dropdown-toggle]', function () {
          if (element.hasClass(activeClass)) {
            element.removeClass(activeClass);
          } else {
            angular.element('[management-dropdown]').removeClass(activeClass);
            element.addClass(activeClass);
            autofocusElement.focus();
          }
        });

        // Persist autofocus
        autofocusElement.on('blur', function () {
          autofocusElement.focus();
        });

        // Hide on ESC
        autofocusElement.on('keydown', function (event) {
          if (event.keyCode === 27) {
            element.removeClass(activeClass);
          }
        });
      }
    };
  })
  .name;
