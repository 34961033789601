import angular from 'angular';
import moment from 'moment';

import MoneyModule from 'modules/money';
import UiRouterModule from '@uirouter/angularjs';

import { ProjectAccountingComponent } from './project-accounting.component';

export default angular.module('projects.single.manage.accounting', [
  MoneyModule,
  UiRouterModule
])
  .config(function ($stateProvider) {
    'ngInject';

    $stateProvider
      .state('projects.single.manage.accounting', {
        url: '/accounting',
        views: {
          manage: {
            component: 'projectAccounting'
          }
        },
        data: {
          title: 'Accounting'
        },
        resolve: {
          // project inherited from parent state
          capacityWeeks: function (Account, UserManager, CONFIG, project) {
            return Account.getProjectCapacityWeeks({
              id: UserManager.data._id,
              projectId: project._id,
              end: moment.utc().add(2, 'weeks').format(CONFIG.YEAR_WEEK_FORMAT)
            }).$promise.then(response => response.toJSON());
          }
        }
      });
  })
  .component('projectAccounting', ProjectAccountingComponent)
  .name;
