// @ts-ignore
import template from './accepted-demo-event.component.html';
import { IProjectResourceService } from 'interfaces';
import { IClientAcceptedDemoEvent } from '../../../models/event.model';

export const AcceptedDemoEventComponent = {
  template,
  bindings: {
    event: '<'
  },
  controller: class AcceptedDemoEventController {

    deliveries: any[] = [];
    event: IClientAcceptedDemoEvent;
    isFetching = false;
    isFetched = false;
    showDetails = false;

    constructor(
      private Project: IProjectResourceService,
    ) {
      'ngInject';
    }

    fetchDetails() {
      if (this.isFetching) {
        return;
      }

      if (this.isFetched) {
        this.showDetails = !this.showDetails;
        return;
      }

      this.isFetching = true;

      this.Project.getWeekDeliveries({
        id: this.event.data.project._id,
        week: this.event.data.values.weekId
      }).$promise
        .then(deliveries => {
          this.deliveries = deliveries;
          this.showDetails = true;
          this.isFetched = true;
        })
        .finally(() => this.isFetching = false);
    }
  }
};
