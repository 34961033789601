import angular from 'angular';

export default angular.module('app.common.validate-form-control', [])
  .directive('validateFormControl', () => {
    return {
      restrict: 'A',
      require: '^form',
      link: function (scope, element, attrs, formCtrl) {
        // find the text box element
        const inputSelector = attrs.validateFormControl || '[name]';
        const inputEl = element[0].querySelector(inputSelector);
        // convert the native text box element to an angular element
        const inputNgEl = angular.element(inputEl);
        // get the name on the text box so we know the property to check on the form controller
        const inputName = inputNgEl.attr('name');

        function validate() {
          const control = formCtrl[inputName];
          element.toggleClass('has-error', control.$invalid && control.$dirty);
          element.toggleClass('has-success', control.$valid && control.$dirty);
        }

        inputNgEl.bind('input change', validate);

        const ngModelName = inputNgEl.attr('ng-model');
        if (ngModelName) {
          scope.$watch(ngModelName, validate);

          scope.$watch(function () {
            // whole form validation can be triggered with FormCtrl.$setSubmitted();
            // and individual fields with FormCtrl.field.$setDirty();
            return formCtrl.$submitted || formCtrl[inputName].$dirty;
          }, function (submitted) {
            if (submitted) {
              formCtrl[inputName].$dirty = true;
            }
            validate();
          });

          scope.$watch(function () {
            return formCtrl[inputName].$valid;
          }, function () {
            if (formCtrl[inputName].$dirty) {
              validate();
            }
          });
        }
      }
    };
  })
  .name;
