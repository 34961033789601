import angular from 'angular';
import { StateProvider } from '@uirouter/angularjs';
import { MetricsUnacceptedWeeksComponent } from './unaccepted-weeks.component';

export const MetricsUnacceptedWeeksModule = angular
  .module('app.admin-panel.metrics.unaccepted-weeks', [])
  .config(($stateProvider: StateProvider) => {
    'ngInject';

    $stateProvider
      .state('admin-panel.metrics.unaccepted-weeks', {
        url: '/unaccepted-weeks',
        views: {
          metric: {
            component: 'metricsUnacceptedWeeks'
          }
        },
        // @ts-ignore
        permissions: ['admin'],
        data: {
          title: 'Unaccepted weeks | Metrics | Admin panel'
        }
      });
  })
  .component('metricsUnacceptedWeeks', MetricsUnacceptedWeeksComponent)
  .name;
