/**
 * This helper unlike ng.$location is independent and can be used in any place
 */
import { partition, rest } from 'underscore';

var helper = {
  /**
   * Returns value of GET parameter
   *
   * @param paramName - get parameter name
   * @returns {boolean|String}
   */
  getParam: function (paramName) {
    var result = false,
      tmp = [];

    var urlParts = helper.getUrl().split('?');

    if (!urlParts[1]) {
      return result;
    }

    urlParts[1].split('&')
      .forEach(function (item) {
        tmp = item.split('=');
        if (tmp[0] === paramName) {
          result = decodeURIComponent(tmp[1]);
        }
      });

    return result;
  },

  /**
   * Return page absolute url
   * Extracted for the unit tests purpose
   *
   * @returns {string}
   */
  getUrl: function () {
    return location.href;
  },

  // this is well tested, to change it use TDD
  extractSegmentPageArguments: function (uri, stateSegment) {
    var idRegexp = /[a-f\d]{24}/;
    var pieces = uri.replace(/^\//, '').split('/');

    var idsAndNonIds = partition(pieces, function (piece) {
      return idRegexp.test(piece);
    });

    var result = {
      category: stateSegment ? stateSegment : idsAndNonIds[1][0],
      name: rest(idsAndNonIds[1], 1).join('/')
    };

    if (idsAndNonIds[0].length) {
      result.properties = {
        id: idsAndNonIds[0][0]
      };
    }

    return result;
  }
};

export default helper;
