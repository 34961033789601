import angular from 'angular';

import { FadeoutOverflowComponent } from './fadeout-overflow-scrollable.component';
import { FadeoutOverflowDirective } from './fadeout-overflow.directive';
import { FadeoutOverflowService } from './fadeout-overflow.service';

export default angular.module('app.ui.fadeout-overflow', [
  require('modules/ui/scroll').default
])
  .component('fadeoutOverflowScrollable', FadeoutOverflowComponent)
  .directive('fadeoutOverflow', FadeoutOverflowDirective)
  .service('FadeoutOverflowService', FadeoutOverflowService)
  .name;
