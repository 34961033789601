// @ts-ignore
import template from './general-event.component.html';

export const AdminPanelGeneralEventComponent = {
  template,
  bindings: {
    event: '<'
  },
  controller: class AdminPanelGeneralEventController {
    constructor() {
      'ngInject';
    }
  }
};
