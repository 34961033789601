import angular from 'angular';
import { ProjectsListController } from './project-list-ctrl';
import ProjectsListTemplate from './projects-list.html';

export default angular.module('app.projects.list', [
  require('modules/resources/account').default,
  require('modules/resources/order').default,
  require('modules/ui/scroll').default,
  require('modules/user-manager').default,
  require('@uirouter/angularjs').default
])
  .config(function ($stateProvider) {
    'ngInject';

    $stateProvider
      .state('projects.list', {
        url: '?order&openModal',
        params: {
          openModal: {
            value: '',
            squash: true,
          },
          order: {
            value: '',
            squash: true,
          }
        },
        controller: 'ProjectListController',
        template: ProjectsListTemplate,
        resolve: {
          order: function (Order, $transition$) {
            const params = $transition$.params();

            if (!params.order) {
              return;
            }

            return Order.get({ id: params.order }).$promise;
          },
          optionOpenModal: function ($transition$) {
            return $transition$.params().openModal === 'true';
          },
          userProjects: function (Account, UserManager) {
            return UserManager.isLogged && Account.getProjects({ id: UserManager.data._id }).$promise;
          },
          userTeams: function ($window, Team, UserManager) {
            return Team.query({
              cache: $window.localStorage.resourceCacheBuster,
              hiredGun: true,
              memberId: UserManager.data._id
            }).$promise;
          }
        },
        data: {
          title: 'Projects'
        }
      });
  })
  .controller('ProjectListController', ProjectsListController)
  .name;
