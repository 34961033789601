import angular from 'angular';

export default angular.module('app.resources.events', [
  require('angular-resource')
])
  .service('Events', function ($resource) {
    'ngInject';

    return $resource('/api/events/:path',
      { event: '@event',
        projectId: '@projectId',
        teamId: '@teamId',
        initiatingUserId: '@initiatingUserId'
      },
      {
        query: {
          method: 'GET',
          isArray: false
        },
        getTypes: {
          method: 'GET',
          params: {
            path: 'types'
          },
          isArray: true
        }
      }
    );
  })
  .name;
