import _ from 'underscore';
import angular from 'angular';

export default angular.module('app.projects.project-team-matching-service', [])

  .service('projectTeamMatching', function () {
    var service = {
      /**
       * Return relevant entries from `list` related to `resource`
       *
       * @example
       * var relevantTeams = service.getRelevant(project, allTeams)
       * @example
       * var relevantProjects = service.getRelevant(team, allProjects)
       * @param {Object} resource A single object with `tags` property
       * @param {Array} list A list of objects with `tags` property
       */
      defineRelevance: function (resource, list) {
        _(list).each(function (item) {
          var matchedTags = _(item.tags).chain().pluck('_id').intersection(_(resource.tags).pluck('_id')).value();

          item.relevancy = 0;

          if (matchedTags.length) {
            item.relevancy = matchedTags.length / Math.max(resource.tags.length, item.tags.length) * 100;
          }

          item.relevancy = +item.relevancy.toFixed(2);
        });

        return list;
      },

      getRecommendedTeams: function (allTeams, length) {
        var isRelevant = function (team) {
          return team.relevancy > 0;
        };

        var recommendedTeams = [];
        var teamPartitions = _(allTeams).partition(isRelevant);
        var relevantTeams = teamPartitions[0];
        var notRelevantTeams = teamPartitions[1];

        var pickWinner = function (hayStack, sortBy, label, filterFn) {
          if (recommendedTeams.length >= length) {
            return;
          }

          if (filterFn) {
            hayStack = _(hayStack).filter(filterFn);
          }

          var needle = _(hayStack)
            .chain()
            .sortBy(sortBy)
            .reverse()
            .value()[0];

          if (needle) {
            needle.topLabel = label;
            recommendedTeams.push(needle);

            // Remove pushed team from relevantTeams to avoid duplicates
            hayStack = _(hayStack).without(needle);
          }

          return hayStack;
        };

        var pickWinners = function (haystack) {
          var hasTags = function (team) {
            return team.tags && team.tags.length > 0;
          };

          var modifiedHaystack = pickWinner(haystack, 'relevancy', 'Best skills match', hasTags);
          modifiedHaystack = pickWinner(modifiedHaystack, 'totalScore', 'Best reputation');
          modifiedHaystack = pickWinner(modifiedHaystack, 'rating', 'Most appreciated');
          modifiedHaystack = pickWinner(modifiedHaystack, 'projects', 'Most experienced');
          modifiedHaystack = pickWinner(modifiedHaystack, function (team) {
            return team.tags.length;
          }, 'Most flexible', hasTags);

          pickWinner(modifiedHaystack, function (team) {
            return -team.tags.length;
          }, 'Most focused', hasTags);
        };

        if (relevantTeams.length) {
          pickWinners(relevantTeams);
        }

        pickWinners(notRelevantTeams);

        return recommendedTeams;
      }
    };

    return service;
  })
  .name;
