import angular from 'angular';
import { TeamsListController } from './teams-list-ctrl';
import template from './teams-list.html';

export default angular.module('app.teams.list', [
  require('@uirouter/angularjs').default,
  require('modules/common/url.module').default,
  require('modules/teams/team-card').default,
  require('modules/teams/modals').default,
  require('modules/resources/team').default,
  require('modules/user-manager').default,
])
  .config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state('teams.list', {
      url: '/',
      controller: 'TeamsListCtrl',
      template,
      data: {
        title: 'Teams',
        type: 'public'
      },
      resolve: {
        teams: function ($window, Team) {
          return Team.query({
            populate: 'members.user',
            stats: true,
            cache: $window.localStorage.resourceCacheBuster
          }).$promise;
        },
        redirectToTeamCardIfNeeded: function ($q, $state, teams, UrlService, UserManager) {
          var d = $q.defer();
          if (!UserManager.isLogged || (UserManager.data.accountType !== 'team_member' && !UserManager.data.isTeamMember)) {
            $state.transitionTo('teams.visit-card', { name: UrlService.slugify(teams[0].name) });
            // prevent current state from loading
            d.reject('redirecting to teams.visit-card');
          } else {
            // let current state to load
            d.resolve('ok');
          }
          return d.promise;
        }
      }
    });
  })
  .controller('TeamsListCtrl', TeamsListController)
  .name;
