import angular from 'angular';
import moment from 'moment';

import AccountResourceModule from 'modules/resources/account';
import CapacityModule from 'modules/capacity';
import MoneyModule from 'modules/money';
import UserManagerModule from 'modules/user-manager';
import { BuyCreditsModalModule } from 'modules/buy-credits-modal';

import { ProjectPlannerComponent } from './project-planner.component';
import { ProjectPlannerBudgetComponent } from './project-planner-budget/project-planner-budget.component';
import { ProjectPlannerWeekRatingsModule } from './week-ratings';
import { TrustedProjectLackingCreditsModalModule } from './trusted-project-lacking-credits-modal';

export default angular.module('projects.single.manage.planner', [
  AccountResourceModule,
  BuyCreditsModalModule,
  CapacityModule,
  MoneyModule,
  ProjectPlannerWeekRatingsModule,
  TrustedProjectLackingCreditsModalModule,
  UserManagerModule
])
  .config(function ($stateProvider) {
    'ngInject';

    $stateProvider
      .state('projects.single.manage.planner', {
        url: '/planner?order?weekToAccept',
        views: {
          manage: {
            component: 'projectPlanner'
          }
        },
        data: {
          title: 'Planner'
        },
        resolve: {
          // project inherited from parent state
          capacityWeeks: function (Account, UserManager, CONFIG, project) {
            return Account.getProjectCapacityWeeks({
              id: UserManager.data._id,
              projectId: project._id,
              end: moment.utc().add(2, 'weeks').format(CONFIG.YEAR_WEEK_FORMAT)
            }).$promise.then(response => response.toJSON());
          },
          transaction: function (Order, $transition$) {
            const params = $transition$.params();

            if (!params.order) {
              return;
            }

            return Order.get({ id: params.order }).$promise;
          }
        }
      });
  })
  .component('projectPlanner', ProjectPlannerComponent)
  .component('projectPlannerBudget', ProjectPlannerBudgetComponent)
  .name;
