import angular from 'angular';

export default angular.module('app.resources.metrics', [
  require('angular-resource')
])
  .service('Metrics', function ($resource) {
    'ngInject';

    return $resource('/api/metrics/:path', { path: '@path' }, {
      getAnnualEarnings: {
        params: {
          path: 'annual-earnings'
        }
      },
      getWeeklyStats: {
        params: {
          path: 'weekly-stats'
        }
      },
      getEndOfYearBalances: {
        params: {
          path: 'end-of-year-balances'
        }
      },
      getUnacceptedWeeks: {
        isArray: true,
        params: {
          path: 'unaccepted-weeks'
        }
      }
    });
  })
  .name;

