import angular from 'angular';
import MoneyModule from 'modules/money';
import OrderResourceModule from 'modules/resources/order';
import UiRouter from '@uirouter/angularjs';
import { AdminPanelTransactionsModule } from 'modules/admin-panel/transactions';

export default angular.module('app.admin-panel.accounts.transactions', [
  AdminPanelTransactionsModule,
  MoneyModule,
  OrderResourceModule,
  UiRouter
])
  .config(function ($stateProvider) {
    'ngInject';

    $stateProvider
      .state('admin-panel.account.transactions', {
        url: '/transactions?description',
        resolve: {
          accountId: ($stateParams) => $stateParams.id,
          description: ($stateParams) => $stateParams.description,
          showAccountName: () => false
        },
        views: {
          'account': {
            component: 'adminTransactionsList'
          }
        },
        permissions: ['admin'],
        data: {
          title: 'Account Transactions'
        }
      });
  })
  .name;
