import _, { groupBy } from 'underscore';
import * as angular from 'angular';

// @ts-ignore
import template from './admin-transactions-list.component.html';

import { Money } from 'modules/money/money';
import { EOrderStatus, IOrderExpandedAccount } from 'models/order.model';
import { IEvent } from 'models/event.model';
import { IModalInstanceService } from 'interfaces';
import { IMoney } from 'models/money.model';
import { IPaginatedResponse } from 'models/http/paginated-response.model';

interface IUIOrder extends IOrderExpandedAccount {
  amount: IMoney;
  value: IMoney;
}

interface IFilter {
  accountName: string;
  description: string;
  maxCapacity: string;
  minCapacity: string;
  orderStatus?: EOrderStatus;
  isInternal?: boolean;
}

interface IPagination {
  page: number;
  perPage: number;
  totalItems?: number;
  pages?: number[];
  response?: { next: number; last: number; };
}

interface ISearchParams {
  accountName: string;
  description: string;
  maxAmount: number;
  minAmount: number;
  orderStatus: string;
  isInternal: boolean;
}

export const AdminTransactionsListComponent = {
  bindings: {
    accountId: '<',
    description: '<',
    showAccountName: '<'
  },
  template,
  controller: class AdminPanelMetricsComponent {
    // Bindings
    // @ts-ignore
    accountId: string = this.accountId || '';
    // @ts-ignore
    description: string = this.description || '';
    // @ts-ignore
    showAccountName: boolean = this.showAccountName;

    readonly PER_PAGE = 100;
    readonly pageSizes = [this.PER_PAGE];

    filter: IFilter = {
      accountName: '',
      description: this.description || '',
      maxCapacity: '',
      minCapacity: '',
      orderStatus: undefined,
      isInternal: undefined,
    };

    transactions?: IUIOrder[];
    events: { [key: string]: IEvent[] } = {};

    pagination: IPagination = {
      page: 1,
      perPage: 100
    };

    constructor(
      private $uibModal: IModalInstanceService,
      private Order: any,
      private Events: any,
      private ORDER_STATUSES: EOrderStatus[], // used in template
    ) {
      'ngInject';
    }

    $onChanges(changes: ng.IOnChangesObject): void {
      if (changes.description && changes.description.currentValue) {
        this.filter.description = changes.description.currentValue;
      }
    }

    get search(): ISearchParams {
      const search = {} as ISearchParams;

      if (this.filter.accountName) {
        search.accountName = this.filter.accountName;
      }

      if (this.filter.orderStatus) {
        search.orderStatus = this.filter.orderStatus;
      }

      if (this.filter.description) {
        search.description = this.filter.description;
      }

      if (angular.isNumber(this.filter.minCapacity)) {
        search.minAmount = this.filter.minCapacity * 100;
      }

      if (angular.isNumber(this.filter.maxCapacity)) {
        search.maxAmount = this.filter.maxCapacity * 100;
      }

      if (_.isBoolean(this.filter.isInternal)) {
        search.isInternal = this.filter.isInternal;
      }

      return search;
    }

    goToPage(page: number): void {
      const search = this.search;

      this.Order.getPage({ filter: this.accountId || 'all', page, perPage: this.PER_PAGE, ...search }).$promise
        .then((response: IPaginatedResponse<IUIOrder>) => {
          if (response.data.length) {
            const transactionsIds: string[] = [];

            this.transactions = response.data.map((tx) => {
              transactionsIds.push(tx._id);

              const icePayAmount = tx.icepay && tx.icepay.Amount;
              const paymentAmount = tx.payment && tx.payment.amount;

              // Amount:
              //  can be higher than value, when users are charging money inside with VAT included,
              //  can be lower than value, when users are invoicing money outside.
              tx.amount = Money.createFromEuros(icePayAmount || paymentAmount);
              tx.value = Money.create(tx.capacity);

              return tx as IUIOrder;
            });

            this.fetchEventsForTransactions(transactionsIds);
          } else {
            this.transactions = [];
            this.events = {};
          }

          this.pagination.page = page;
          this.pagination.pages = _.range(1, (response.pages.last || 0) + 1);
          this.pagination.response = response.pages;
        });
    }

    fetchEventsForTransactions(ids: string[]): void {
      const event = [
        'ADMIN_CREATE_EXACT_TRANSACTION',
        'ADMIN_EXACT_TRANSACTION_RESULT',
        'AUTOMATIC_CREATE_EXACT_TRANSACTION'
      ].join(',');

      this.Events.query({
        event,
        perPage: ids.length,
        transactionId: ids.join(',')
      }).$promise.then((response: IPaginatedResponse<IEvent>) => {
        this.events = groupBy(response.data, (event: any) => {
          return event.data.transaction;
        });
      });
    }

    filterTransactions(): void {
      this.goToPage(1);
    }

    openTransactionModal(event: Event, transaction: IUIOrder): ng.IPromise<void> {
      return this.$uibModal.open({
        event,
        resolve: {
          transaction: () => transaction
        },
        component: 'adminTransactionModal'
      }).result;
    }
  }
};
