// @ts-ignore
import template from './annual-earnings-chart.component.html';
import moment from 'moment';
import { clone, isArray, object, pluck } from 'underscore';
import { IMoney } from 'models/money.model';
import { Money } from '../../../money/money';

export interface IAnnualEarningsChartItem {
  key: string;
  amount: number;
  isPredicted?: boolean;
}

export interface IUIChartBar extends IAnnualEarningsChartItem {
  heightInPercents: number;
  amountMoney: IMoney;
  label: string;
}

export const AnnualEarningsChartComponent: ng.IComponentOptions = {
  template,
  bindings: {
    data: '<',
    year: '<',
    isLoading: '<'
  },
  controller: class AnnualEarningsChartController {
    // Bindings
    // @ts-ignore
    year: string = this.year;
    // @ts-ignore
    isLoading: boolean = this.isLoading;

    formattedItems: { [key: string]: IUIChartBar } = {};

    // Chart options (defaults)
    scaleToMax = 100000; // Euros
    gridlinesStep = this.scaleToMax / 5; // Euros
    snapGridlinesToNumber = 50000; // Euros

    monthMap: { [key: string]: string } = {
      jan: '01',
      feb: '02',
      mar: '03',
      apr: '04',
      may: '05',
      jun: '06',
      jul: '07',
      aug: '08',
      sep: '09',
      oct: '10',
      nov: '11',
      dec: '12'
    };

    currentYearMonth = moment().format('YYYYMM');

    constructor() {
      'ngInject';
    }

    $onChanges(changes: ng.IOnChangesObject) {
      const items = changes.data && changes.data.currentValue;

      if (isArray(items) && items.length) {
        const highestAmount = Math.max.apply(Math, pluck(items, 'amount'));

        // Round scaleToMax to nearest 50k (50k, 100k, 150k, 200k, ...)
        this.scaleToMax = Math.ceil(highestAmount / this.snapGridlinesToNumber) * this.snapGridlinesToNumber;
        this.gridlinesStep = Math.round(this.scaleToMax / 5);

        this.formattedItems = object(items.map((item) => {
          const cloned = clone(item);

          cloned.heightInPercents = cloned.amount / this.scaleToMax * 100;
          cloned.amountMoney = Money.createFromEuros(cloned.amount);
          cloned.label = this.getKFormatted(cloned.amount);

          const key = cloned.key.toString();
          return [key.substring(key.length - 2), cloned];
        }));
      }
    }

    getKFormatted(value: number) {
      if (value === 0) {
        return '0';
      }

      return (value / 1000).toFixed(value < 1000 ? 1 : 0) + 'k';
    }
  }
};
