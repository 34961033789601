import angular from 'angular';
import BootstrapModal from 'modules/common/ui-bootstrap-modal-2.5.6';
import UiRouter from '@uirouter/angularjs';

import { AdminPanelAccountsCompanyModule } from './account-company';
import AccountIntegrationsModule from './account-integrations';
import AccountResourceModule from 'modules/resources/account';
import AccountOverviewModule from './account-overview';
import { AccountReferralsModule } from './account-referrals';
import AccountTransactions from './account-transactions';
import MoneyModule from 'modules/money';
import UserManagerModule from 'modules/user-manager';
import { AdminPanelAccountsComponent } from './accounts.component';
import { AdminPanelAccountComponent } from './account.component';
import { AdminPanelRechargeModalComponent } from './recharge-modal/recharge-modal.component';

export default angular.module('app.admin-panel.accounts', [
  AccountIntegrationsModule,
  AccountReferralsModule,
  AccountResourceModule,
  AccountOverviewModule,
  AccountTransactions,
  AdminPanelAccountsCompanyModule,
  BootstrapModal,
  MoneyModule,
  UiRouter,
  UserManagerModule
])
  .config(function ($stateProvider) {
    'ngInject';

    $stateProvider
      .state('admin-panel.accounts', {
        url: '/accounts',
        views: {
          'admin-panel': {
            component: 'adminPanelAccountsComponent'
          }
        },
        permissions: ['admin'],
        data: {
          title: 'Accounts'
        }
      })
      .state('admin-panel.account', {
        url: '/accounts/:id',
        resolve: {
          account: ($transition$, Account) => Account.get({ id: $transition$.params().id }).$promise
        },
        views: {
          'admin-panel': {
            component: 'adminPanelAccountComponent'
          }
        },
        permissions: ['admin'],
        redirectTo: 'admin-panel.account.overview'
      });
  })
  .component('adminPanelAccountsComponent', AdminPanelAccountsComponent)
  .component('adminPanelAccountComponent', AdminPanelAccountComponent)
  .component('adminPanelRechargeModalComponent', AdminPanelRechargeModalComponent)
  .name;
