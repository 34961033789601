import angular from 'angular';

import MoneyModule from 'modules/money';
import ProjectResourceModule from 'modules/resources/project';
import TeamResourceModule from 'modules/resources/team';

import { ProjectFeedComponent } from './project-feed.component';

export default angular.module('projects.single.manage.feed', [
  MoneyModule,
  ProjectResourceModule,
  TeamResourceModule
])
  .config(function ($stateProvider) {
    'ngInject';

    $stateProvider
      .state('projects.single.manage.feed', {
        url: '/history',
        views: {
          manage: {
            component: 'projectFeed'
          }
        },
        data: {
          title: 'Project History'
        }
      });
  })
  .component('projectFeed', ProjectFeedComponent)
  .name;
