// @ts-ignore
import template from './edit-status.component.html';

import { IEventEmitter, IEventEmitterValue, IOrderResourceService } from 'interfaces';
import { IOrder } from 'models/order.model';

export const AdminTransactionEditStatusComponent = {
  bindings: {
    onUpdate: '&',
    transaction: '<',
  },
  template,
  controller: class AdminTransactionEditStatusController {
    // Bindings
    // @ts-ignore
    transaction: IOrder = this.transaction;
    // @ts-ignore
    onUpdate: (value: IEventEmitterValue<{ value: string; }>) => void = this.onUpdate;

    showForm = false;

    constructor(
      private $window: ng.IWindowService,
      private EventEmitter: IEventEmitter<{ value: string; }>,
      private growl: ng.growl.IGrowlService,
      private Order: IOrderResourceService,
      public ORDER_STATUSES: string[], // used in template
    ) {
      'ngInject';
    }

    save() {
      const sendNotification = this.$window.confirm('Do you want to send email about status change to user?\r\n(Cancel means "Do not notify")');

      this.Order.changeStatus(
        { id: this.transaction._id },
        {
          status: this.transaction.status,
          silent: !sendNotification
        }
      ).$promise.then((response) => {
        this.onUpdate(this.EventEmitter({ value: response.status }));
        this.growl.success('Status updated.');
      });
    }
  }
};
