// @ts-ignore
import template from './team-feed-new-member.component.html';

import { ITeamInvitedUserEvent } from '../../../../../models/event.model';

export const TeamFeedNewMemberComponent: ng.IComponentOptions = {
  bindings: {
    item: '<',
  },
  template,
  controller: class TeamFeedNewMemberController {
    // Bindings
    // @ts-ignore
    item: ITeamInvitedUserEvent = this.item;

    get user() {
      return this.item.data.user;
    }
  }
};
