import _ from 'underscore';
import template from './edit-description.component.html';

export const AdminTransactionEditDescriptionComponent = {
  bindings: {
    onUpdate: '&',
    transaction: '<',
  },
  template,
  controller: class AdminTransactionEditDescriptionController {
    constructor($element, EventEmitter, growl, Order) {
      'ngInject';

      this.$element = $element;
      this.EventEmitter = EventEmitter;
      this.GrowlService = growl;
      this.OrderResource = Order;
    }

    $onInit() {
      this.showForm = false;
    }

    edit() {
      this.transaction.oldDescription = this.transaction.description;
      this.showForm = true;
      setTimeout(() => {
        this.$element.find('#description').focus();
      });
    }

    save() {
      this.showForm = false;
      this.OrderResource.updateDescription(
        { id: this.transaction._id },
        _(this.transaction).pick('description')
      ).$promise.then(() => {
        this.onUpdate(this.EventEmitter({ value: this.transaction.description }));
        this.GrowlService.success('Description updated.');
      });
    }

    cancel() {
      this.showForm = false;
      this.transaction.description = this.transaction.oldDescription;
    }
  }
};
