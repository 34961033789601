import angular from 'angular';

/**
 * <input autofocus="selectTextOrNot">
 *
 * If you provide a value in autofocus attribute and it's true - field text will be selected when autofocused.
 */
export default angular.module('app.ui.autofocus', [])
  .directive('autofocus', function ($timeout) {
    'ngInject';

    return function (scope, element, attrs) {
      var doSelectText = scope.$eval(attrs.autofocus);

      $timeout(function () {
        element.focus();

        if (doSelectText) {
          element.get(0).select();
        }
      }, 0, false);
    };

  })
  .name;
