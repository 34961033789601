import angular from 'angular';

import MoneyModule from 'modules/money';
import OrderResourceModule from 'modules/resources/order';
import UiRouterModule from '@uirouter/angularjs';
import UserManagerModule from 'modules/user-manager';
import { MyAccountTransactionsController } from './transactions-ctrl';
import MyAccountTransactionsTemplate from './transactions.html';

export default angular.module('app.accounts.my.transactions', [
  MoneyModule,
  OrderResourceModule,
  UiRouterModule,
  UserManagerModule
])
  .config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state('accounts.my.transactions', {
      url: '/transactions',
      resolve: {
        transactions: Order => Order.query().$promise
      },
      views: {
        account: {
          template: MyAccountTransactionsTemplate,
          controller: 'MyAccountTransactionsCtrl',
        }
      },
      data: {
        title: 'Transactions'
      }
    });
  })
  .controller('MyAccountTransactionsCtrl', MyAccountTransactionsController)
  .name;
