import angular from 'angular';
import template from './txbox-delivery.component.html';

// Create ghostElement out of component to reuse it between component instances
const ghostElement = angular.element(document.createElement('div'));
ghostElement.addClass('txbox-delivery-ghost');
ghostElement.appendTo('body');

const CURSOR_WIDTH = 1;

const KEY_0 = 47;
const KEY_9 = 58;
const KEY_COMA = 44;
const KEY_DOT = 46;
const KEY_LEFT = 37;
const KEY_RETURN = 13;
const KEY_RIGHT = 39;

export const TxboxDeliveryComponent = {
  template,
  bindings: {
    comment: '<',
    max: '<',
    removable: '<',
    value: '<',
    onChange: '&',
    onRemove: '&'
  },
  controller: class TxboxDeliveryController {
    constructor($element, $scope, $window, EventEmitter) {
      'ngInject';

      this.$element = $element;
      this.$scope = $scope;
      this.$window = $window;
      this.EventEmitter = EventEmitter;
    }

    $onChanges(changes) {
      if (changes.value) {
        this.dirtyValue = this.parseValue(changes.value.currentValue) || '';
      }

      if (changes.comment) {
        this.dirtyComment = changes.comment.currentValue || '';
      }
    }

    $postLink() {
      this.commentInput= this.$element.find('.txbox-delivery_comment');
      this.plusElement = this.$element.find('.txbox-delivery_plus');
      this.valueInput = this.$element.find('.txbox-delivery_value');

      this.valueInput.css({
        paddingLeft: '+=' + this.plusElement.width()
      });

      ghostElement.css({
        paddingLeft: this.valueInput.css('padding-left')
      });

      this.updateValueInputSize();

      this.valueInput.on('blur', () => {
        this.dirtyValue = this.parseValue(this.dirtyValue) || '';
      });

      // Move caret to field on the right, when arrow right pressed
      this.valueInput.on('keydown', event => {
        const isRightKey = event.which === KEY_RIGHT;
        const isCaretAtEnd = this.valueInput.caret() === this.dirtyValue.toString().length;
        if (!this.checkShortcut(event) && isRightKey && isCaretAtEnd && this.dirtyComment) {
          event.preventDefault();
          this.commentInput.caretToStart();
        }
      });

      // If typing not numbers into value input – switch cursor to comment input
      this.valueInput.on('keypress', event => {
        const isComaOrDotKey = event.which === KEY_COMA || event.which === KEY_DOT;

        // Ignore coma/dot in value input
        if (event.charCode && isComaOrDotKey) {
          event.preventDefault();
          return;
        }

        const isReturnKey = (event.which === KEY_RETURN);
        const isNumericKey = (event.which > KEY_0 && event.which < KEY_9);
        if (event.charCode && !isNumericKey && !isReturnKey && !isComaOrDotKey) {
          event.preventDefault();

          // Don't switch focus when the value is not provided yet
          if (!event.target.value) {
            return;
          }

          this.$scope.$apply(() => {
            this.dirtyComment += String.fromCharCode(event.which);
            // wait for comment to propagate
            this.$window.requestAnimationFrame(() => this.commentInput.caretToEnd());
          });
        }
      });

      // Move caret to field on the left, when arrow left pressed
      this.commentInput.on('keydown', event => {
        let isLeftKey = event.which === KEY_LEFT;
        let isCaretAtStart = this.commentInput.caret() === 0;
        if (!this.checkShortcut(event) && isLeftKey && isCaretAtStart) {
          event.preventDefault();
          this.valueInput.caretToEnd();
        }
      });
    }

    checkShortcut(event) {
      return event.altKey || event.ctrlKey || event.metaKey || event.shiftKey;
    }

    checkValidity() {
      return this.parseValue(this.dirtyValue) > 0;
    }

    // Move focus back to amount input when user removes comment
    dirtyCommentChanged() {
      if (!this.dirtyComment && this.commentInput.is(':focus')) {
        this.$window.requestAnimationFrame(() => this.valueInput.caretToEnd());
      }
    }

    onFormSubmitted(event) {
      event.preventDefault();

      this.dirtyValue = this.parseValue(this.dirtyValue) || '';
      this.onChange(this.EventEmitter({
        value: this.parseValue(this.dirtyValue),
        comment: this.dirtyComment
      }));
    }

    parseValue(input) {
      let value = Math.abs(parseInt(input, 10)) || 0;
      if (typeof this.max === 'number') {
        value = Math.min(value, this.max);
      }
      return value;
    }

    // Update amount input size
    updateValueInputSize() {
      ghostElement.text(this.dirtyValue);
      this.valueInput.outerWidth(ghostElement.outerWidth() + CURSOR_WIDTH);
    }
  }
};
