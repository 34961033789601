import angular from 'angular';
import { AdminPanelTribesController } from './tribes-ctrl';
import AdminPanelTribesTemplate from './tribes.html';

export default angular.module('app.admin-panel.tribes', [
  require('modules/resources/account').default,
  require('modules/resources/tribe').default,
  require('modules/teams/modals').default,
  require('modules/tribes/modals').default,
  require('@uirouter/angularjs').default
])
  .config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state('admin-panel.tribes', {
      url: '/tribes',
      resolve: {
        tribes: (Tribe) => {
          return Tribe.query().$promise;
        }
      },
      views: {
        'admin-panel': {
          template: AdminPanelTribesTemplate,
          controller: 'AdminPanelTribesCtrl',
        }
      },
      data: {
        title: 'Tribes'
      }
    });
  })
  .controller('AdminPanelTribesCtrl', AdminPanelTribesController)
  .name;

