import angular from 'angular';

export default angular.module('app.accounts', [
  require('@uirouter/angularjs').default,
  require('modules/accounts/my').default
])
  .config(function ($stateProvider) {
    'ngInject';

    $stateProvider.state('accounts', {
      url: '/accounts',
      abstract: true,
      template: '<div ui-view></div>'
    });
  })
  .name;
