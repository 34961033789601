// @ts-ignore
import template from './settings.component.html';

import { Recaptcha } from 'modules/recaptcha/recaptcha-component.abstract';
import { IAuthResourceService, ISegmentService, ISegmentServiceConsentManager } from 'interfaces';
import { UserManagerService } from 'modules/user-manager/user-manager.service';
import { IAccount } from 'models/account.model';
import { IAccountResourceService } from 'models/account-resource.model';

export const MyAccountSettingsComponent = {
  template,
  controller: class MyAccountSettingsController extends Recaptcha {
    changeEmailInProgress = false;
    changePasswordInProgress = false;
    confirmNewPassword = '';
    consentManager: ISegmentServiceConsentManager;
    newEmail = '';
    newPassword = '';
    notificationToMute: string;
    oldPassword = '';
    user: IAccount;
    noEmail: boolean;

    constructor(
      private $location: ng.ILocationService,
      private $q: ng.IQService,
      private Account: IAccountResourceService,
      private Auth: IAuthResourceService,
      private growl: ng.growl.IGrowlService,
      private UserManager: UserManagerService,
      SegmentService: ISegmentService,
    ) {
      'ngInject';

      super($q);

      this.consentManager = SegmentService.consentManager ;
      this.user = UserManager.data;
      this.noEmail = !this.user.preferences.notifyPayments && !this.user.preferences.notifyProject && !this.user.preferences.notifyReferral;
    }

    $onInit() {
      this.notificationToMute = this.$location.search().mute;

      if (this.notificationToMute) {
        if (this.notificationToMute === 'all') {
          if (!this.noEmail) {
            this.noEmail = true;
            this.changeNoEmail();
          }
        } else {
          if (!this.user.preferences[this.notificationToMute]) {
            this.user.preferences[this.notificationToMute] = false;
            this.changeEmailDetail();
          }
        }
      }
    }

    hideEmail(email: string) {
      return email[0] + '***@' + email.split('@')[1];
    }

    changeEmail() {
      if (this.changeEmailInProgress) {
        return;
      }

      this.changeEmailInProgress = true;

      this.recaptcha.execute()
        .then(this.recaptchaVerified)
        .then(($$recaptchaToken: string) => {
          return this.Auth.changeEmail({ newEmail: this.newEmail, $$recaptchaToken }).$promise;
        })
        .then(() => {
          this.newEmail = '';
          this.growl.success('A confirmation link is sent to your new email address');
        })
        .finally(() => {
          this.changeEmailInProgress = false;
        });
    }

    changePassword() {
      if (this.changePasswordInProgress) {
        return;
      }

      this.changePasswordInProgress = true;

      this.recaptcha.execute()
        .then(this.recaptchaVerified)
        .then(($$recaptchaToken: string) => {
          return this.Auth.changePassword({
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
            $$recaptchaToken
          }).$promise;
        })
        .then(() => {
          this.oldPassword = this.newPassword = this.confirmNewPassword = '';
          this.growl.success('Your password has been changed');
        })
        .finally(() => {
          this.changePasswordInProgress = false;
        });
    }

    changeNoEmail() {
      this.user.preferences.notifyProject = !this.noEmail;
      this.user.preferences.notifyPayment = !this.noEmail;
      this.user.preferences.notifyReferral = !this.noEmail;

      this.saveChanges();
    }

    changeEmailDetail() {
      this.noEmail = false;
      this.saveChanges();
    }

    saveChanges() {
      this.Account.updatePreferences({ id: this.user._id }, this.user.preferences).$promise.then(() => {
        this.growl.success('Notifications settings updates.');
      });
    }
  }
};
