import _ from 'underscore';
import angular from 'angular';

export const AddEditTeamController = function ($scope, $state, $window, Account, Team, UserManager, tribes, team, addMeAutomatically, forceRedirectToCreatedTeam) {
  'ngInject';

  /**
   * Init models
   */

  var teamMembersClone = angular.copy(team.members);
  $scope.team = team;
  $scope.tribes = tribes;
  $scope.addMeAutomatically = addMeAutomatically;

  if (addMeAutomatically) {
    $scope.team.members.push({
      user: _(UserManager.data).pick('_id', 'name', 'emailHash')
    });
  }

  if (team.tribe) {
    $scope.tribe = team.tribe;
  }

  /**
   * Methods
   */

  $scope.addTeam = function () {
    var teamToSave = deflateTeam($scope.team);

    teamToSave.$save(function (createdTeam) {
      if (forceRedirectToCreatedTeam) {
        $state.go('teams.view', { id: createdTeam._id, name: createdTeam.name });
      } else {
        $scope.$close(createdTeam);
      }
    });
  };

  $scope.saveTeam = function () {
    var teamToSave = deflateTeam($scope.team);

    teamToSave.$update(function (updatedTeam) {
      $scope.$close(updatedTeam);
    });
  };

  function deflateTeam(team) {
    var deflated = angular.copy(team);

    _(deflated.members).each(function (member) {
      member.user = member.user._id;
    });

    deflated.tribe = deflated.tribe._id;

    return deflated;
  }

  $scope.findUser = function (name) {
    return Account.queryActive({ filter: name }).$promise.then(accounts => accounts.data);
  };

  $scope.addMember = function (user) {
    function memberIdMatcher(id) {
      return (member) => member.user._id === id;
    }

    var isAlreadyMember = _($scope.team.members).any(memberIdMatcher(user._id));

    if (isAlreadyMember) {
      return;
    }

    var oldMember = _(teamMembersClone).find(memberIdMatcher(user._id));

    if (oldMember) {
      $scope.team.members.push(oldMember);
    } else {
      $scope.team.members.push({ user });
    }
  };

  $scope.setTribe = function (tribe) {
    $scope.team.tribe = tribe;
  };

  $scope.removeMember = function (member) {
    $scope.team.members = _($scope.team.members).without(member);
  };
};
