import _ from 'underscore';
import moment from 'moment';
import template from './project-feed.component.html';
import { Money } from 'modules/money/money';

export const ProjectFeedComponent = {
  bindings: {
    project: '<'
  },
  template,
  controller: class ProjectFeedController {
    constructor($q, CONFIG, Project) {
      'ngInject';

      this.$q = $q;
      this.CONFIG = CONFIG;
      this.ProjectResource = Project;

      this.loading = true;
    }

    $onInit() {
      this.fetchFeed(this.project._id).then(feed => {
        this.weeks = _(feed).chain()
          .map(item => this.createWeekFromFeedEntry(item))
          .sortBy('id')
          .reverse()
          .value();
        this.loading = false;
      });
    }

    fetchFeed(projectId) {
      return this.ProjectResource.getFeed({
        id: projectId,
        startWeek: moment.utc().subtract(100, 'weeks').format(this.CONFIG.YEAR_WEEK_FORMAT),
        endWeek: moment.utc().add(1, 'week').format(this.CONFIG.YEAR_WEEK_FORMAT)
      }).$promise;
    }

    createWeekFromFeedEntry(entry) {
      return {
        client: entry.data.initiatingUser,
        id: entry.data.values.weekId,
        delivered: Money.create(entry.data.values.delivered),
        deliveryDescription: entry.data.values.deliveryDescription,
        sprintGoal: entry.data.values.sprintGoal,
        feedback: entry.data.values.feedback,
        orderedRatings: _.map(entry.data.values.ratings, (value, key) => ({ key, value })),
        team: entry.data.team
      };
    }

    isGreaterThanZero(value) {
      return Money.isGreaterThanZero(value);
    }
  }
};
