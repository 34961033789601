// @ts-ignore
import template from './recurring-invoices.component.html';
import { IProject } from '../../../models/project.model';

export const AdminRecurringInvoicesComponent = {
  bindings: {
    projectsWithRecurringSalesInvoices: '<'
  },
  template,
  controller: class RecurringInvoicesController {
    // Bindings
    // @ts-ignore
    projectsWithRecurringSalesInvoices: IProject[] = this.projectsWithRecurringSalesInvoices;

    constructor() {
      'ngInject';
    }
  }
};
