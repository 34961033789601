// @ts-ignore
import template from './send-sales-invoice-modal.component.html';
import { IProjectResourceService } from '../../interfaces';
import { Money } from '../money/money';
import { IProject } from '../../models/project.model';
import { IMoney } from '../../models/money.model';

type ScreenType = 'send' | 'configure';

export const SendSalesInvoiceModalComponent: ng.IComponentOptions = {
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  template,
  controller: class SendInvoiceModalController implements ng.IController {
    // bindings
    // @ts-ignore
    dismiss: () => void = this.dismiss;
    // @ts-ignore
    close: (params: { $value?: boolean }) => void = this.close;
    // @ts-ignore
    resolve = this.resolve;

    project: IProject;
    amount: number;

    screen: ScreenType = 'send';
    recurringInvoicesInput: number;

    isSendingInProgress = false;
    isRecurringInvoicesInProgress = false;

    constructor(
      private Project: IProjectResourceService,
      private growl: angular.growl.IGrowlService,
    ) {
      'ngInject';
    }

    $onInit() {
      this.project = this.resolve.project;

      if (this.project.recurringSalesInvoices) {
        this.recurringInvoicesInput = this.project.recurringSalesInvoices.amount / 100;
      }
    }

    setRecurringInvoices() {
      if (this.isRecurringInvoicesInProgress) {
        return;
      }

      this.isRecurringInvoicesInProgress = true;
      const amount = Money.createFromEuros(this.recurringInvoicesInput);

      this.Project.setRecurringSalesInvoices({
        id: this.project._id,
        amount: amount.getAmount()
      }).$promise
        .then(() => {
          this.project.recurringSalesInvoices = {
            amount: amount.getAmount(),
            createdAt: Date.now()
          };

          this.growl.success('Successfully set up recurring invoices');
          this.dismiss();
        })
        .finally(() => {
          this.isRecurringInvoicesInProgress = false;
        });
    }
    updateRecurringInvoices() {
      if (this.isRecurringInvoicesInProgress) {
        return;
      }

      this.isRecurringInvoicesInProgress = true;
      const amount = Money.createFromEuros(this.recurringInvoicesInput);

      this.Project.updateRecurringSalesInvoices({
        id: this.project._id,
        amount: amount.getAmount()
      }).$promise
        .then(() => {
          this.project.recurringSalesInvoices.amount = amount.getAmount();

          this.growl.success('Successfully updated recurring invoices amount');
          this.dismiss();
        })
        .finally(() => {
          this.isRecurringInvoicesInProgress = false;
        });
    }

    disableRecurringInvoices() {
      if (this.isRecurringInvoicesInProgress) {
        return;
      }

      this.isRecurringInvoicesInProgress = true;

      this.Project.removeRecurringSalesInvoices({
        id: this.project._id
      }).$promise
        .then(() => {
          this.project.recurringSalesInvoices = null;

          this.growl.success('Successfully disabled recurring invoices');
          this.dismiss();
        })
        .finally(() => {
          this.isRecurringInvoicesInProgress = false;
        });
    }

    handleSubmit() {
      if (this.isSendingInProgress) {
        return;
      }

      this.isSendingInProgress = true;

      this.Project.sendSalesInvoice({
        id: this.project._id,
        amount: Money.createFromEuros(this.amount).getAmount(),
      }).$promise
        .then(() => this.close({ $value: true }))
        .finally(() => this.isSendingInProgress = false);
    }
  }
};
