import angular from 'angular';

export default angular.module('app.resources.my-deliveries', [
  require('angular-resource')
])
  .service('MyDeliveries', function ($resource) {
    'ngInject';

    return $resource('/api/my-deliveries');
  })
  .name;
