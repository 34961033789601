import angular from 'angular';
import UiRouter, { StateProvider } from '@uirouter/angularjs';

import ConstantsModule from 'app-constants';
import MoneyModule from 'modules/money';
import TeamResourceModule from 'modules/resources/team';

import { TeamFeedComponent } from './team-feed.component';
import { TeamFeedClientRatingComponent } from './feed-items/team-feed-client-rating.component';
import { TeamFeedNewMemberComponent } from './feed-items/team-feed-new-member.component';
import { TeamFeedNewProjectComponent } from './feed-items/team-feed-new-project.component';
import { TeamFeedWeekComponent } from './team-feed-week.component';
import { TeamFeedProjectTrustSetComponent } from './feed-items/team-feed-project-trust-set.component';
import { TeamFeedWeeklyEarningsComponent } from './feed-items/team-feed-weekly-earnings.component';

export default angular.module('app.teams.feed', [
  ConstantsModule,
  MoneyModule,
  TeamResourceModule,
  UiRouter
])
  .config(($stateProvider: StateProvider) => {
    'ngInject';

    $stateProvider.state('teams.view.feed', {
      url: '/feed',
      views: {
        content: 'teamFeed'
      }
    });
  })
  .component('teamFeed', TeamFeedComponent)
  .component('teamFeedWeek', TeamFeedWeekComponent)
  .component('teamFeedClientRating', TeamFeedClientRatingComponent)
  .component('teamFeedNewMember', TeamFeedNewMemberComponent)
  .component('teamFeedNewProject', TeamFeedNewProjectComponent)
  .component('teamFeedProjectTrustSet', TeamFeedProjectTrustSetComponent)
  .component('teamFeedWeeklyEarnings', TeamFeedWeeklyEarningsComponent)
  .name;
