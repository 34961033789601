import * as angular from 'angular';

const initials = (name: string = '') => {
  let initials = '';
  const parts = name.toUpperCase().split(' ');

  if (parts.length > 1) {
    parts.forEach((part) => {
      initials += part[0];
    });
  } else {
    initials = name;
  }

  return initials.substr(0, 2);
};

export const AvatarDirective = () => {
  return {
    template: '<img />',
    restrict: 'E',
    link: (scope: ng.IScope, element: ng.IRootElementService, attrs: ng.IAttributes) => {
      const size = parseInt(attrs.size, 10) || 300;
      const retinaReadySize = size * 3;
      const disallowDefault = attrs.hasOwnProperty('disallowDefault');

      const imgElement = element.find('img');

      imgElement.attr('width', size);
      imgElement.attr('height', size);

      element.addClass('avatar');
      element.css('width', size);
      element.css('height', size);

      function render() {
        const src = `https://secure.gravatar.com/avatar/${attrs.hash}?d=404&s=${retinaReadySize}`;

        imgElement[0].onerror = () => {
          if (disallowDefault) {
            const rootElement = element.closest('[avatar-root]');
            if (rootElement.length) {
              rootElement.remove();
            }
            element.remove();
          } else {
            const fallback = angular.element(`<div class="avatar_fallback"><span>${ initials(attrs.name) }</span></div>`);
            fallback.css('height', size);
            fallback.css('width', size);
            fallback.css('font-size', size / 2.5 + 'px');
            imgElement.after(fallback);
            imgElement.remove();
          }
        };

        imgElement.attr('src', src);
      }

      attrs.$observe('hash', render);
      attrs.$observe('name', render);
    }
  };
};
