import { extend } from 'angular';
import { IAccount, IAccountAttribution, IAccountAttributionKeys } from '../../models/account.model';

export class UserManagerService {
  data: IAccount = {} as IAccount;

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $window: ng.IWindowService,
    private SegmentService: any
  ) {
    'ngInject';
  }

  // Checks that company details were set
  get isCompanySet(): boolean {
    const company = this.data.company;

    if (!company) {
      return false;
    }

    return company.hasOwnProperty('inEU') || company.hasOwnProperty('inNL');
  }

  get isLogged() {
    return Boolean(this.data._id);
  }

  clean() {
    // Safely clean User object
    Object.keys(this.data).map((key: keyof IAccount) => delete this.data[key]);
  }

  set(data: Partial<IAccount>, segmentAdditionalData: object, silent = false) {
    extend(this.data, data);
    this.$rootScope.$emit('UserManager:set', this.data);

    if (!silent) {
      const segmentData = extend({
        createdAt: this.data.createdAt,
        email: this.data.email,
        name: this.data.name,
        role: this.data.accountType || 'team_member',
        utm_campaign: this.$window.localStorage.getItem('utm_campaign'),
        utm_content: this.$window.localStorage.getItem('utm_content'),
        utm_medium: this.$window.localStorage.getItem('utm_medium'),
        utm_source: this.$window.localStorage.getItem('utm_source'),
        utm_term: this.$window.localStorage.getItem('utm_term')
      }, segmentAdditionalData || {});

      if (this.data.referrer) {
        segmentData.referredBy = this.data.referrer;
      }

      this.SegmentService.identify(this.data._id, segmentData);
    }
  }

  getAttribution() {
    const params: IAccountAttributionKeys[] = [
      'utm_campaign',
      'utm_content',
      'utm_medium',
      'utm_source',
      'utm_term',
    ];

    const attribution: Partial<IAccountAttribution> = {};

    params.forEach((param) => {
      const paramValue = this.$window.localStorage.getItem(param);
      if (paramValue) {
        attribution[param] = paramValue;
      }
    });

    return attribution;
  }
}
