export const CONSTANTS = {
  BUDGET_CHOICES: [
    { label: 'up to € 10k', value: 10, icon: '$' },
    { label: '€ 10-50k', value: 50, icon: '$$' },
    { label: '€ 50-100k', value: 100, icon: '$$$' },
    { label: '€ 100-250k', value: 250, icon: '$$$$' },
    { label: 'more than € 250k', value: 251, icon: '$$$$+' }
  ],
  CONFIG: {
    MONTH_NUMBER: 4,
    YEAR_WEEK_FORMAT: 'GGGGWW',
    SAFE_BOOKING_TIMER: 1000 * 60 * 60, // 1 hour
    MIN_CREDITS_TO_NOTICE: 500 * 100, // in cents
    WEEKS_PER_PAGE: 4
  },
  CURRENCY: 'EUR',
  MONEY_ROUNDING_MODE: 'DOWN',
  MARKED_OPTIONS: {},
  ORDER_STATUSES: ['open', 'pending', 'paid', 'expired', 'cancelled', 'refunded'],
  TYPOGRAPHY: {
    HS: '<span class="hs"></span>',
    HSR: '<span class="hsr">&nbsp;</span>',
    THIN_SPACE: ' '
  },
  PROJECT_QUESTIONS: [
    {
      key: 'description',
      title: 'How may we help you?',
      description: 'Summarize what you need in a headline, so teams can see \
                      if they might be interested at a glance.'
    }, {
      key: 'businessProblem',
      title: 'What business problem do you have?',
      description: 'Describe why a solution needs to be implemented. From \
                      the current reality take us through the improvements \
                      that should be achieved.'
    }, {
      key: 'technicalSolution',
      title: 'Which technical solution do you think would solve your \
                problem?',
      description: 'Describe what the solution looks like technically. Is it \
                      an iPhone app, a web site, some embedded logic in your \
                      fridge?'
    }, {
      key: 'kindOfPeople',
      title: 'What kind of people should work on this?',
      description: 'Describe what people you hope to find in the team. \
                      Should the team be good at integrating with an existing \
                      team? Did you pick a technology stack already? Is \
                      front-end and UX important, or are you looking for \
                      scalability on the back-end?'
    }
  ],
  ACCOUNT_STATUSES: ['active', 'removed', 'blocked', 'inactive'],
  COUNTRIES: {
    AF: 'Afghanistan',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'American Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctica',
    AG: 'Antigua and Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BA: 'Bosnia and Herzegovina',
    BW: 'Botswana',
    BV: 'Bouvet Island',
    BR: 'Brazil',
    IO: 'British Indian Ocean Territory',
    VG: 'British Virgin Islands',
    BN: 'Brunei',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    CI: 'Côte d\'Ivoire',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    CV: 'Cape Verde',
    KY: 'Cayman Islands',
    CF: 'Central African Republic',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos (Keeling) Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CG: 'Congo',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    HR: 'Croatia',
    CU: 'Cuba',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    CD: 'Democratic Republic of the Congo',
    DK: 'Denmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    TP: 'East Timor',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    ET: 'Ethiopia',
    FO: 'Faeroe Islands',
    FK: 'Falkland Islands',
    FJ: 'Fiji',
    FI: 'Finland',
    MK: 'Former Yugoslav Republic of Macedonia',
    FR: 'France',
    FX: 'France, Metropolitan',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    TF: 'French Southern Territories',
    GA: 'Gabon',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard and Mc Donald Islands',
    HN: 'Honduras',
    HK: 'Hong Kong',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran',
    IQ: 'Iraq',
    IE: 'Ireland',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: 'Laos',
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macau',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    FM: 'Micronesia',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands',
    AN: 'Netherlands Antilles',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    KP: 'North Korea',
    MP: 'Northern Marianas',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestine',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PN: 'Pitcairn Islands',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    RE: 'Reunion',
    RO: 'Romania',
    RU: 'Russia',
    RW: 'Rwanda',
    ST: 'São Tomé and Príncipe',
    SH: 'Saint Helena',
    PM: 'St. Pierre and Miquelon',
    KN: 'Saint Kitts and Nevis',
    LC: 'Saint Lucia',
    VC: 'Saint Vincent and the Grenadines',
    WS: 'Samoa',
    SM: 'San Marino',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    SO: 'Somalia',
    ZA: 'South Africa',
    GS: 'South Georgia and the South Sandwich Islands',
    KR: 'South Korea',
    ES: 'Spain',
    LK: 'Sri Lanka',
    SD: 'Sudan',
    SR: 'Suriname',
    SJ: 'Svalbard and Jan Mayen Islands',
    SZ: 'Swaziland',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syria',
    TW: 'Taiwan',
    TJ: 'Tajikistan',
    TZ: 'Tanzania',
    TH: 'Thailand',
    BS: 'The Bahamas',
    GM: 'The Gambia',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad and Tobago',
    TN: 'Tunisia',
    TR: 'Turkey',
    TM: 'Turkmenistan',
    TC: 'Turks and Caicos Islands',
    TV: 'Tuvalu',
    VI: 'US Virgin Islands',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    US: 'United States',
    UM: 'United States Minor Outlying Islands',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VA: 'Vatican City',
    VE: 'Venezuela',
    VN: 'Vietnam',
    WF: 'Wallis and Futuna Islands',
    EH: 'Western Sahara',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe'
  },
  TIMEZONES: [ // fetched and prettified from http://api.timezonedb.com/v2/list-time-zone?key=T2FZMZHD4L96&format=json
    {countryCode: 'AD', zoneName: 'Europe/Andorra', suffix: '(UTC/GMT +2)'},
    {countryCode: 'AE', zoneName: 'Asia/Dubai', suffix: '(UTC/GMT +4)'},
    {countryCode: 'AF', zoneName: 'Asia/Kabul', suffix: '(UTC/GMT +4.5)'},
    {countryCode: 'AG', zoneName: 'America/Antigua', suffix: '(UTC/GMT -4)'},
    {countryCode: 'AI', zoneName: 'America/Anguilla', suffix: '(UTC/GMT -4)'},
    {countryCode: 'AL', zoneName: 'Europe/Tirane', suffix: '(UTC/GMT +2)'},
    {countryCode: 'AM', zoneName: 'Asia/Yerevan', suffix: '(UTC/GMT +4)'},
    {countryCode: 'AO', zoneName: 'Africa/Luanda', suffix: '(UTC/GMT +1)'},
    {countryCode: 'AQ', zoneName: 'Antarctica/Casey', suffix: '(UTC/GMT +8)'},
    {countryCode: 'AQ', zoneName: 'Antarctica/Davis', suffix: '(UTC/GMT +7)'},
    {countryCode: 'AQ', zoneName: 'Antarctica/DumontDUrville', suffix: '(UTC/GMT +10)'},
    {countryCode: 'AQ', zoneName: 'Antarctica/Mawson', suffix: '(UTC/GMT +5)'},
    {countryCode: 'AQ', zoneName: 'Antarctica/McMurdo', suffix: '(UTC/GMT +12)'},
    {countryCode: 'AQ', zoneName: 'Antarctica/Palmer', suffix: '(UTC/GMT -3)'},
    {countryCode: 'AQ', zoneName: 'Antarctica/Rothera', suffix: '(UTC/GMT -3)'},
    {countryCode: 'AQ', zoneName: 'Antarctica/Syowa', suffix: '(UTC/GMT +3)'},
    {countryCode: 'AQ', zoneName: 'Antarctica/Troll', suffix: '(UTC/GMT +2)'},
    {countryCode: 'AQ', zoneName: 'Antarctica/Vostok', suffix: '(UTC/GMT +6)'},
    {countryCode: 'AR', zoneName: 'America/Argentina/Buenos_Aires', suffix: '(UTC/GMT -3)'},
    {countryCode: 'AR', zoneName: 'America/Argentina/Catamarca', suffix: '(UTC/GMT -3)'},
    {countryCode: 'AR', zoneName: 'America/Argentina/Cordoba', suffix: '(UTC/GMT -3)'},
    {countryCode: 'AR', zoneName: 'America/Argentina/Jujuy', suffix: '(UTC/GMT -3)'},
    {countryCode: 'AR', zoneName: 'America/Argentina/La_Rioja', suffix: '(UTC/GMT -3)'},
    {countryCode: 'AR', zoneName: 'America/Argentina/Mendoza', suffix: '(UTC/GMT -3)'},
    {countryCode: 'AR', zoneName: 'America/Argentina/Rio_Gallegos', suffix: '(UTC/GMT -3)'},
    {countryCode: 'AR', zoneName: 'America/Argentina/Salta', suffix: '(UTC/GMT -3)'},
    {countryCode: 'AR', zoneName: 'America/Argentina/San_Juan', suffix: '(UTC/GMT -3)'},
    {countryCode: 'AR', zoneName: 'America/Argentina/San_Luis', suffix: '(UTC/GMT -3)'},
    {countryCode: 'AR', zoneName: 'America/Argentina/Tucuman', suffix: '(UTC/GMT -3)'},
    {countryCode: 'AR', zoneName: 'America/Argentina/Ushuaia', suffix: '(UTC/GMT -3)'},
    {countryCode: 'AS', zoneName: 'Pacific/Pago_Pago', suffix: '(UTC/GMT -11)'},
    {countryCode: 'AT', zoneName: 'Europe/Vienna', suffix: '(UTC/GMT +2)'},
    {countryCode: 'AU', zoneName: 'Antarctica/Macquarie', suffix: '(UTC/GMT +11)'},
    {countryCode: 'AU', zoneName: 'Australia/Adelaide', suffix: '(UTC/GMT +9.5)'},
    {countryCode: 'AU', zoneName: 'Australia/Brisbane', suffix: '(UTC/GMT +10)'},
    {countryCode: 'AU', zoneName: 'Australia/Broken_Hill', suffix: '(UTC/GMT +9.5)'},
    {countryCode: 'AU', zoneName: 'Australia/Currie', suffix: '(UTC/GMT +10)'},
    {countryCode: 'AU', zoneName: 'Australia/Darwin', suffix: '(UTC/GMT +9.5)'},
    {countryCode: 'AU', zoneName: 'Australia/Eucla', suffix: '(UTC/GMT +8.75)'},
    {countryCode: 'AU', zoneName: 'Australia/Hobart', suffix: '(UTC/GMT +10)'},
    {countryCode: 'AU', zoneName: 'Australia/Lindeman', suffix: '(UTC/GMT +10)'},
    {countryCode: 'AU', zoneName: 'Australia/Lord_Howe', suffix: '(UTC/GMT +10.5)'},
    {countryCode: 'AU', zoneName: 'Australia/Melbourne', suffix: '(UTC/GMT +10)'},
    {countryCode: 'AU', zoneName: 'Australia/Perth', suffix: '(UTC/GMT +8)'},
    {countryCode: 'AU', zoneName: 'Australia/Sydney', suffix: '(UTC/GMT +10)'},
    {countryCode: 'AW', zoneName: 'America/Aruba', suffix: '(UTC/GMT -4)'},
    {countryCode: 'AZ', zoneName: 'Asia/Baku', suffix: '(UTC/GMT +4)'},
    {countryCode: 'BA', zoneName: 'Europe/Sarajevo', suffix: '(UTC/GMT +2)'},
    {countryCode: 'BB', zoneName: 'America/Barbados', suffix: '(UTC/GMT -4)'},
    {countryCode: 'BD', zoneName: 'Asia/Dhaka', suffix: '(UTC/GMT +6)'},
    {countryCode: 'BE', zoneName: 'Europe/Brussels', suffix: '(UTC/GMT +2)'},
    {countryCode: 'BF', zoneName: 'Africa/Ouagadougou', suffix: '(UTC/GMT 0)'},
    {countryCode: 'BG', zoneName: 'Europe/Sofia', suffix: '(UTC/GMT +3)'},
    {countryCode: 'BH', zoneName: 'Asia/Bahrain', suffix: '(UTC/GMT +3)'},
    {countryCode: 'BI', zoneName: 'Africa/Bujumbura', suffix: '(UTC/GMT +2)'},
    {countryCode: 'BJ', zoneName: 'Africa/Porto-Novo', suffix: '(UTC/GMT +1)'},
    {countryCode: 'BM', zoneName: 'Atlantic/Bermuda', suffix: '(UTC/GMT -3)'},
    {countryCode: 'BN', zoneName: 'Asia/Brunei', suffix: '(UTC/GMT +8)'},
    {countryCode: 'BO', zoneName: 'America/La_Paz', suffix: '(UTC/GMT -4)'},
    {countryCode: 'BR', zoneName: 'America/Araguaina', suffix: '(UTC/GMT -3)'},
    {countryCode: 'BR', zoneName: 'America/Bahia', suffix: '(UTC/GMT -3)'},
    {countryCode: 'BR', zoneName: 'America/Belem', suffix: '(UTC/GMT -3)'},
    {countryCode: 'BR', zoneName: 'America/Boa_Vista', suffix: '(UTC/GMT -4)'},
    {countryCode: 'BR', zoneName: 'America/Campo_Grande', suffix: '(UTC/GMT -4)'},
    {countryCode: 'BR', zoneName: 'America/Cuiaba', suffix: '(UTC/GMT -4)'},
    {countryCode: 'BR', zoneName: 'America/Eirunepe', suffix: '(UTC/GMT -5)'},
    {countryCode: 'BR', zoneName: 'America/Fortaleza', suffix: '(UTC/GMT -3)'},
    {countryCode: 'BR', zoneName: 'America/Maceio', suffix: '(UTC/GMT -3)'},
    {countryCode: 'BR', zoneName: 'America/Manaus', suffix: '(UTC/GMT -4)'},
    {countryCode: 'BR', zoneName: 'America/Noronha', suffix: '(UTC/GMT -2)'},
    {countryCode: 'BR', zoneName: 'America/Porto_Velho', suffix: '(UTC/GMT -4)'},
    {countryCode: 'BR', zoneName: 'America/Recife', suffix: '(UTC/GMT -3)'},
    {countryCode: 'BR', zoneName: 'America/Rio_Branco', suffix: '(UTC/GMT -5)'},
    {countryCode: 'BR', zoneName: 'America/Santarem', suffix: '(UTC/GMT -3)'},
    {countryCode: 'BR', zoneName: 'America/Sao_Paulo', suffix: '(UTC/GMT -3)'},
    {countryCode: 'BS', zoneName: 'America/Nassau', suffix: '(UTC/GMT -4)'},
    {countryCode: 'BT', zoneName: 'Asia/Thimphu', suffix: '(UTC/GMT +6)'},
    {countryCode: 'BW', zoneName: 'Africa/Gaborone', suffix: '(UTC/GMT +2)'},
    {countryCode: 'BY', zoneName: 'Europe/Minsk', suffix: '(UTC/GMT +3)'},
    {countryCode: 'BZ', zoneName: 'America/Belize', suffix: '(UTC/GMT -6)'},
    {countryCode: 'CA', zoneName: 'America/Atikokan', suffix: '(UTC/GMT -5)'},
    {countryCode: 'CA', zoneName: 'America/Blanc-Sablon', suffix: '(UTC/GMT -4)'},
    {countryCode: 'CA', zoneName: 'America/Cambridge_Bay', suffix: '(UTC/GMT -6)'},
    {countryCode: 'CA', zoneName: 'America/Creston', suffix: '(UTC/GMT -7)'},
    {countryCode: 'CA', zoneName: 'America/Dawson', suffix: '(UTC/GMT -7)'},
    {countryCode: 'CA', zoneName: 'America/Dawson_Creek', suffix: '(UTC/GMT -7)'},
    {countryCode: 'CA', zoneName: 'America/Edmonton', suffix: '(UTC/GMT -6)'},
    {countryCode: 'CA', zoneName: 'America/Fort_Nelson', suffix: '(UTC/GMT -7)'},
    {countryCode: 'CA', zoneName: 'America/Glace_Bay', suffix: '(UTC/GMT -3)'},
    {countryCode: 'CA', zoneName: 'America/Goose_Bay', suffix: '(UTC/GMT -3)'},
    {countryCode: 'CA', zoneName: 'America/Halifax', suffix: '(UTC/GMT -3)'},
    {countryCode: 'CA', zoneName: 'America/Inuvik', suffix: '(UTC/GMT -6)'},
    {countryCode: 'CA', zoneName: 'America/Iqaluit', suffix: '(UTC/GMT -4)'},
    {countryCode: 'CA', zoneName: 'America/Moncton', suffix: '(UTC/GMT -3)'},
    {countryCode: 'CA', zoneName: 'America/Nipigon', suffix: '(UTC/GMT -4)'},
    {countryCode: 'CA', zoneName: 'America/Pangnirtung', suffix: '(UTC/GMT -4)'},
    {countryCode: 'CA', zoneName: 'America/Rainy_River', suffix: '(UTC/GMT -5)'},
    {countryCode: 'CA', zoneName: 'America/Rankin_Inlet', suffix: '(UTC/GMT -5)'},
    {countryCode: 'CA', zoneName: 'America/Regina', suffix: '(UTC/GMT -6)'},
    {countryCode: 'CA', zoneName: 'America/Resolute', suffix: '(UTC/GMT -5)'},
    {countryCode: 'CA', zoneName: 'America/St_Johns', suffix: '(UTC/GMT -2.5)'},
    {countryCode: 'CA', zoneName: 'America/Swift_Current', suffix: '(UTC/GMT -6)'},
    {countryCode: 'CA', zoneName: 'America/Thunder_Bay', suffix: '(UTC/GMT -4)'},
    {countryCode: 'CA', zoneName: 'America/Toronto', suffix: '(UTC/GMT -4)'},
    {countryCode: 'CA', zoneName: 'America/Vancouver', suffix: '(UTC/GMT -7)'},
    {countryCode: 'CA', zoneName: 'America/Whitehorse', suffix: '(UTC/GMT -7)'},
    {countryCode: 'CA', zoneName: 'America/Winnipeg', suffix: '(UTC/GMT -5)'},
    {countryCode: 'CA', zoneName: 'America/Yellowknife', suffix: '(UTC/GMT -6)'},
    {countryCode: 'CC', zoneName: 'Indian/Cocos', suffix: '(UTC/GMT +6.5)'},
    {countryCode: 'CD', zoneName: 'Africa/Kinshasa', suffix: '(UTC/GMT +1)'},
    {countryCode: 'CD', zoneName: 'Africa/Lubumbashi', suffix: '(UTC/GMT +2)'},
    {countryCode: 'CF', zoneName: 'Africa/Bangui', suffix: '(UTC/GMT +1)'},
    {countryCode: 'CG', zoneName: 'Africa/Brazzaville', suffix: '(UTC/GMT +1)'},
    {countryCode: 'CH', zoneName: 'Europe/Zurich', suffix: '(UTC/GMT +2)'},
    {countryCode: 'CI', zoneName: 'Africa/Abidjan', suffix: '(UTC/GMT 0)'},
    {countryCode: 'CK', zoneName: 'Pacific/Rarotonga', suffix: '(UTC/GMT -10)'},
    {countryCode: 'CL', zoneName: 'America/Santiago', suffix: '(UTC/GMT -3)'},
    {countryCode: 'CL', zoneName: 'Pacific/Easter', suffix: '(UTC/GMT -5)'},
    {countryCode: 'CM', zoneName: 'Africa/Douala', suffix: '(UTC/GMT +1)'},
    {countryCode: 'CN', zoneName: 'Asia/Shanghai', suffix: '(UTC/GMT +8)'},
    {countryCode: 'CN', zoneName: 'Asia/Urumqi', suffix: '(UTC/GMT +6)'},
    {countryCode: 'CO', zoneName: 'America/Bogota', suffix: '(UTC/GMT -5)'},
    {countryCode: 'CR', zoneName: 'America/Costa_Rica', suffix: '(UTC/GMT -6)'},
    {countryCode: 'CU', zoneName: 'America/Havana', suffix: '(UTC/GMT -4)'},
    {countryCode: 'CV', zoneName: 'Atlantic/Cape_Verde', suffix: '(UTC/GMT -1)'},
    {countryCode: 'CX', zoneName: 'Indian/Christmas', suffix: '(UTC/GMT +7)'},
    {countryCode: 'CY', zoneName: 'Asia/Nicosia', suffix: '(UTC/GMT +3)'},
    {countryCode: 'CZ', zoneName: 'Europe/Prague', suffix: '(UTC/GMT +2)'},
    {countryCode: 'DE', zoneName: 'Europe/Berlin', suffix: '(UTC/GMT +2)'},
    {countryCode: 'DE', zoneName: 'Europe/Busingen', suffix: '(UTC/GMT +2)'},
    {countryCode: 'DJ', zoneName: 'Africa/Djibouti', suffix: '(UTC/GMT +3)'},
    {countryCode: 'DK', zoneName: 'Europe/Copenhagen', suffix: '(UTC/GMT +2)'},
    {countryCode: 'DM', zoneName: 'America/Dominica', suffix: '(UTC/GMT -4)'},
    {countryCode: 'DO', zoneName: 'America/Santo_Domingo', suffix: '(UTC/GMT -4)'},
    {countryCode: 'DZ', zoneName: 'Africa/Algiers', suffix: '(UTC/GMT +1)'},
    {countryCode: 'EC', zoneName: 'America/Guayaquil', suffix: '(UTC/GMT -5)'},
    {countryCode: 'EC', zoneName: 'Pacific/Galapagos', suffix: '(UTC/GMT -6)'},
    {countryCode: 'EE', zoneName: 'Europe/Tallinn', suffix: '(UTC/GMT +3)'},
    {countryCode: 'EG', zoneName: 'Africa/Cairo', suffix: '(UTC/GMT +2)'},
    {countryCode: 'EH', zoneName: 'Africa/El_Aaiun', suffix: '(UTC/GMT +1)'},
    {countryCode: 'ER', zoneName: 'Africa/Asmara', suffix: '(UTC/GMT +3)'},
    {countryCode: 'ES', zoneName: 'Africa/Ceuta', suffix: '(UTC/GMT +2)'},
    {countryCode: 'ES', zoneName: 'Atlantic/Canary', suffix: '(UTC/GMT +1)'},
    {countryCode: 'ES', zoneName: 'Europe/Madrid', suffix: '(UTC/GMT +2)'},
    {countryCode: 'ET', zoneName: 'Africa/Addis_Ababa', suffix: '(UTC/GMT +3)'},
    {countryCode: 'FI', zoneName: 'Europe/Helsinki', suffix: '(UTC/GMT +3)'},
    {countryCode: 'FJ', zoneName: 'Pacific/Fiji', suffix: '(UTC/GMT +12)'},
    {countryCode: 'FK', zoneName: 'Atlantic/Stanley', suffix: '(UTC/GMT -3)'},
    {countryCode: 'FM', zoneName: 'Pacific/Chuuk', suffix: '(UTC/GMT +10)'},
    {countryCode: 'FM', zoneName: 'Pacific/Kosrae', suffix: '(UTC/GMT +11)'},
    {countryCode: 'FM', zoneName: 'Pacific/Pohnpei', suffix: '(UTC/GMT +11)'},
    {countryCode: 'FO', zoneName: 'Atlantic/Faroe', suffix: '(UTC/GMT +1)'},
    {countryCode: 'FR', zoneName: 'Europe/Paris', suffix: '(UTC/GMT +2)'},
    {countryCode: 'GA', zoneName: 'Africa/Libreville', suffix: '(UTC/GMT +1)'},
    {countryCode: 'GB', zoneName: 'Europe/London', suffix: '(UTC/GMT +1)'},
    {countryCode: 'GD', zoneName: 'America/Grenada', suffix: '(UTC/GMT -4)'},
    {countryCode: 'GE', zoneName: 'Asia/Tbilisi', suffix: '(UTC/GMT +4)'},
    {countryCode: 'GF', zoneName: 'America/Cayenne', suffix: '(UTC/GMT -3)'},
    {countryCode: 'GH', zoneName: 'Africa/Accra', suffix: '(UTC/GMT 0)'},
    {countryCode: 'GI', zoneName: 'Europe/Gibraltar', suffix: '(UTC/GMT +2)'},
    {countryCode: 'GL', zoneName: 'America/Danmarkshavn', suffix: '(UTC/GMT 0)'},
    {countryCode: 'GL', zoneName: 'America/Godthab', suffix: '(UTC/GMT -2)'},
    {countryCode: 'GL', zoneName: 'America/Scoresbysund', suffix: '(UTC/GMT 0)'},
    {countryCode: 'GL', zoneName: 'America/Thule', suffix: '(UTC/GMT -3)'},
    {countryCode: 'GM', zoneName: 'Africa/Banjul', suffix: '(UTC/GMT 0)'},
    {countryCode: 'GN', zoneName: 'Africa/Conakry', suffix: '(UTC/GMT 0)'},
    {countryCode: 'GP', zoneName: 'America/Guadeloupe', suffix: '(UTC/GMT -4)'},
    {countryCode: 'GQ', zoneName: 'Africa/Malabo', suffix: '(UTC/GMT +1)'},
    {countryCode: 'GR', zoneName: 'Europe/Athens', suffix: '(UTC/GMT +3)'},
    {countryCode: 'GS', zoneName: 'Atlantic/South_Georgia', suffix: '(UTC/GMT -2)'},
    {countryCode: 'GT', zoneName: 'America/Guatemala', suffix: '(UTC/GMT -6)'},
    {countryCode: 'GU', zoneName: 'Pacific/Guam', suffix: '(UTC/GMT +10)'},
    {countryCode: 'GW', zoneName: 'Africa/Bissau', suffix: '(UTC/GMT 0)'},
    {countryCode: 'GY', zoneName: 'America/Guyana', suffix: '(UTC/GMT -4)'},
    {countryCode: 'HK', zoneName: 'Asia/Hong_Kong', suffix: '(UTC/GMT +8)'},
    {countryCode: 'HN', zoneName: 'America/Tegucigalpa', suffix: '(UTC/GMT -6)'},
    {countryCode: 'HR', zoneName: 'Europe/Zagreb', suffix: '(UTC/GMT +2)'},
    {countryCode: 'HT', zoneName: 'America/Port-au-Prince', suffix: '(UTC/GMT -5)'},
    {countryCode: 'HU', zoneName: 'Europe/Budapest', suffix: '(UTC/GMT +2)'},
    {countryCode: 'ID', zoneName: 'Asia/Jakarta', suffix: '(UTC/GMT +7)'},
    {countryCode: 'ID', zoneName: 'Asia/Jayapura', suffix: '(UTC/GMT +9)'},
    {countryCode: 'ID', zoneName: 'Asia/Makassar', suffix: '(UTC/GMT +8)'},
    {countryCode: 'ID', zoneName: 'Asia/Pontianak', suffix: '(UTC/GMT +7)'},
    {countryCode: 'IE', zoneName: 'Europe/Dublin', suffix: '(UTC/GMT +1)'},
    {countryCode: 'IL', zoneName: 'Asia/Jerusalem', suffix: '(UTC/GMT +3)'},
    {countryCode: 'IN', zoneName: 'Asia/Kolkata', suffix: '(UTC/GMT +5.5)'},
    {countryCode: 'IO', zoneName: 'Indian/Chagos', suffix: '(UTC/GMT +6)'},
    {countryCode: 'IQ', zoneName: 'Asia/Baghdad', suffix: '(UTC/GMT +3)'},
    {countryCode: 'IR', zoneName: 'Asia/Tehran', suffix: '(UTC/GMT +4.5)'},
    {countryCode: 'IS', zoneName: 'Atlantic/Reykjavik', suffix: '(UTC/GMT 0)'},
    {countryCode: 'IT', zoneName: 'Europe/Rome', suffix: '(UTC/GMT +2)'},
    {countryCode: 'JM', zoneName: 'America/Jamaica', suffix: '(UTC/GMT -5)'},
    {countryCode: 'JO', zoneName: 'Asia/Amman', suffix: '(UTC/GMT +3)'},
    {countryCode: 'JP', zoneName: 'Asia/Tokyo', suffix: '(UTC/GMT +9)'},
    {countryCode: 'KE', zoneName: 'Africa/Nairobi', suffix: '(UTC/GMT +3)'},
    {countryCode: 'KG', zoneName: 'Asia/Bishkek', suffix: '(UTC/GMT +6)'},
    {countryCode: 'KH', zoneName: 'Asia/Phnom_Penh', suffix: '(UTC/GMT +7)'},
    {countryCode: 'KI', zoneName: 'Pacific/Enderbury', suffix: '(UTC/GMT +13)'},
    {countryCode: 'KI', zoneName: 'Pacific/Kiritimati', suffix: '(UTC/GMT +14)'},
    {countryCode: 'KI', zoneName: 'Pacific/Tarawa', suffix: '(UTC/GMT +12)'},
    {countryCode: 'KM', zoneName: 'Indian/Comoro', suffix: '(UTC/GMT +3)'},
    {countryCode: 'KN', zoneName: 'America/St_Kitts', suffix: '(UTC/GMT -4)'},
    {countryCode: 'KP', zoneName: 'Asia/Pyongyang', suffix: '(UTC/GMT +8.5)'},
    {countryCode: 'KR', zoneName: 'Asia/Seoul', suffix: '(UTC/GMT +9)'},
    {countryCode: 'KW', zoneName: 'Asia/Kuwait', suffix: '(UTC/GMT +3)'},
    {countryCode: 'KY', zoneName: 'America/Cayman', suffix: '(UTC/GMT -5)'},
    {countryCode: 'KZ', zoneName: 'Asia/Almaty', suffix: '(UTC/GMT +6)'},
    {countryCode: 'KZ', zoneName: 'Asia/Aqtau', suffix: '(UTC/GMT +5)'},
    {countryCode: 'KZ', zoneName: 'Asia/Aqtobe', suffix: '(UTC/GMT +5)'},
    {countryCode: 'KZ', zoneName: 'Asia/Oral', suffix: '(UTC/GMT +5)'},
    {countryCode: 'KZ', zoneName: 'Asia/Qyzylorda', suffix: '(UTC/GMT +6)'},
    {countryCode: 'LA', zoneName: 'Asia/Vientiane', suffix: '(UTC/GMT +7)'},
    {countryCode: 'LB', zoneName: 'Asia/Beirut', suffix: '(UTC/GMT +3)'},
    {countryCode: 'LC', zoneName: 'America/St_Lucia', suffix: '(UTC/GMT -4)'},
    {countryCode: 'LI', zoneName: 'Europe/Vaduz', suffix: '(UTC/GMT +2)'},
    {countryCode: 'LK', zoneName: 'Asia/Colombo', suffix: '(UTC/GMT +5.5)'},
    {countryCode: 'LR', zoneName: 'Africa/Monrovia', suffix: '(UTC/GMT 0)'},
    {countryCode: 'LS', zoneName: 'Africa/Maseru', suffix: '(UTC/GMT +2)'},
    {countryCode: 'LT', zoneName: 'Europe/Vilnius', suffix: '(UTC/GMT +3)'},
    {countryCode: 'LU', zoneName: 'Europe/Luxembourg', suffix: '(UTC/GMT +2)'},
    {countryCode: 'LV', zoneName: 'Europe/Riga', suffix: '(UTC/GMT +3)'},
    {countryCode: 'LY', zoneName: 'Africa/Tripoli', suffix: '(UTC/GMT +2)'},
    {countryCode: 'MA', zoneName: 'Africa/Casablanca', suffix: '(UTC/GMT +1)'},
    {countryCode: 'MC', zoneName: 'Europe/Monaco', suffix: '(UTC/GMT +2)'},
    {countryCode: 'MD', zoneName: 'Europe/Chisinau', suffix: '(UTC/GMT +3)'},
    {countryCode: 'ME', zoneName: 'Europe/Podgorica', suffix: '(UTC/GMT +2)'},
    {countryCode: 'MG', zoneName: 'Indian/Antananarivo', suffix: '(UTC/GMT +3)'},
    {countryCode: 'MH', zoneName: 'Pacific/Kwajalein', suffix: '(UTC/GMT +12)'},
    {countryCode: 'MH', zoneName: 'Pacific/Majuro', suffix: '(UTC/GMT +12)'},
    {countryCode: 'MK', zoneName: 'Europe/Skopje', suffix: '(UTC/GMT +2)'},
    {countryCode: 'ML', zoneName: 'Africa/Bamako', suffix: '(UTC/GMT 0)'},
    {countryCode: 'MM', zoneName: 'Asia/Rangoon', suffix: '(UTC/GMT +6.5)'},
    {countryCode: 'MN', zoneName: 'Asia/Choibalsan', suffix: '(UTC/GMT +9)'},
    {countryCode: 'MN', zoneName: 'Asia/Hovd', suffix: '(UTC/GMT +8)'},
    {countryCode: 'MN', zoneName: 'Asia/Ulaanbaatar', suffix: '(UTC/GMT +9)'},
    {countryCode: 'MO', zoneName: 'Asia/Macau', suffix: '(UTC/GMT +8)'},
    {countryCode: 'MP', zoneName: 'Pacific/Saipan', suffix: '(UTC/GMT +10)'},
    {countryCode: 'MQ', zoneName: 'America/Martinique', suffix: '(UTC/GMT -4)'},
    {countryCode: 'MR', zoneName: 'Africa/Nouakchott', suffix: '(UTC/GMT 0)'},
    {countryCode: 'MS', zoneName: 'America/Montserrat', suffix: '(UTC/GMT -4)'},
    {countryCode: 'MT', zoneName: 'Europe/Malta', suffix: '(UTC/GMT +2)'},
    {countryCode: 'MU', zoneName: 'Indian/Mauritius', suffix: '(UTC/GMT +4)'},
    {countryCode: 'MV', zoneName: 'Indian/Maldives', suffix: '(UTC/GMT +5)'},
    {countryCode: 'MW', zoneName: 'Africa/Blantyre', suffix: '(UTC/GMT +2)'},
    {countryCode: 'MX', zoneName: 'America/Bahia_Banderas', suffix: '(UTC/GMT -5)'},
    {countryCode: 'MX', zoneName: 'America/Cancun', suffix: '(UTC/GMT -5)'},
    {countryCode: 'MX', zoneName: 'America/Chihuahua', suffix: '(UTC/GMT -6)'},
    {countryCode: 'MX', zoneName: 'America/Hermosillo', suffix: '(UTC/GMT -7)'},
    {countryCode: 'MX', zoneName: 'America/Matamoros', suffix: '(UTC/GMT -5)'},
    {countryCode: 'MX', zoneName: 'America/Mazatlan', suffix: '(UTC/GMT -6)'},
    {countryCode: 'MX', zoneName: 'America/Merida', suffix: '(UTC/GMT -5)'},
    {countryCode: 'MX', zoneName: 'America/Mexico_City', suffix: '(UTC/GMT -5)'},
    {countryCode: 'MX', zoneName: 'America/Monterrey', suffix: '(UTC/GMT -5)'},
    {countryCode: 'MX', zoneName: 'America/Ojinaga', suffix: '(UTC/GMT -6)'},
    {countryCode: 'MX', zoneName: 'America/Tijuana', suffix: '(UTC/GMT -7)'},
    {countryCode: 'MY', zoneName: 'Asia/Kuala_Lumpur', suffix: '(UTC/GMT +8)'},
    {countryCode: 'MY', zoneName: 'Asia/Kuching', suffix: '(UTC/GMT +8)'},
    {countryCode: 'MZ', zoneName: 'Africa/Maputo', suffix: '(UTC/GMT +2)'},
    {countryCode: 'NA', zoneName: 'Africa/Windhoek', suffix: '(UTC/GMT +2)'},
    {countryCode: 'NC', zoneName: 'Pacific/Noumea', suffix: '(UTC/GMT +11)'},
    {countryCode: 'NE', zoneName: 'Africa/Niamey', suffix: '(UTC/GMT +1)'},
    {countryCode: 'NF', zoneName: 'Pacific/Norfolk', suffix: '(UTC/GMT +11)'},
    {countryCode: 'NG', zoneName: 'Africa/Lagos', suffix: '(UTC/GMT +1)'},
    {countryCode: 'NI', zoneName: 'America/Managua', suffix: '(UTC/GMT -6)'},
    {countryCode: 'NL', zoneName: 'Europe/Amsterdam', suffix: '(UTC/GMT +2)'},
    {countryCode: 'NO', zoneName: 'Europe/Oslo', suffix: '(UTC/GMT +2)'},
    {countryCode: 'NP', zoneName: 'Asia/Kathmandu', suffix: '(UTC/GMT +5.75)'},
    {countryCode: 'NR', zoneName: 'Pacific/Nauru', suffix: '(UTC/GMT +12)'},
    {countryCode: 'NU', zoneName: 'Pacific/Niue', suffix: '(UTC/GMT -11)'},
    {countryCode: 'NZ', zoneName: 'Pacific/Auckland', suffix: '(UTC/GMT +12)'},
    {countryCode: 'NZ', zoneName: 'Pacific/Chatham', suffix: '(UTC/GMT +12.75)'},
    {countryCode: 'OM', zoneName: 'Asia/Muscat', suffix: '(UTC/GMT +4)'},
    {countryCode: 'PA', zoneName: 'America/Panama', suffix: '(UTC/GMT -5)'},
    {countryCode: 'PE', zoneName: 'America/Lima', suffix: '(UTC/GMT -5)'},
    {countryCode: 'PF', zoneName: 'Pacific/Gambier', suffix: '(UTC/GMT -9)'},
    {countryCode: 'PF', zoneName: 'Pacific/Marquesas', suffix: '(UTC/GMT -9.5)'},
    {countryCode: 'PF', zoneName: 'Pacific/Tahiti', suffix: '(UTC/GMT -10)'},
    {countryCode: 'PG', zoneName: 'Pacific/Bougainville', suffix: '(UTC/GMT +11)'},
    {countryCode: 'PG', zoneName: 'Pacific/Port_Moresby', suffix: '(UTC/GMT +10)'},
    {countryCode: 'PH', zoneName: 'Asia/Manila', suffix: '(UTC/GMT +8)'},
    {countryCode: 'PK', zoneName: 'Asia/Karachi', suffix: '(UTC/GMT +5)'},
    {countryCode: 'PL', zoneName: 'Europe/Warsaw', suffix: '(UTC/GMT +2)'},
    {countryCode: 'PM', zoneName: 'America/Miquelon', suffix: '(UTC/GMT -2)'},
    {countryCode: 'PN', zoneName: 'Pacific/Pitcairn', suffix: '(UTC/GMT -8)'},
    {countryCode: 'PR', zoneName: 'America/Puerto_Rico', suffix: '(UTC/GMT -4)'},
    {countryCode: 'PS', zoneName: 'Asia/Gaza', suffix: '(UTC/GMT +3)'},
    {countryCode: 'PS', zoneName: 'Asia/Hebron', suffix: '(UTC/GMT +3)'},
    {countryCode: 'PT', zoneName: 'Atlantic/Azores', suffix: '(UTC/GMT 0)'},
    {countryCode: 'PT', zoneName: 'Atlantic/Madeira', suffix: '(UTC/GMT +1)'},
    {countryCode: 'PT', zoneName: 'Europe/Lisbon', suffix: '(UTC/GMT +1)'},
    {countryCode: 'PW', zoneName: 'Pacific/Palau', suffix: '(UTC/GMT +9)'},
    {countryCode: 'PY', zoneName: 'America/Asuncion', suffix: '(UTC/GMT -4)'},
    {countryCode: 'QA', zoneName: 'Asia/Qatar', suffix: '(UTC/GMT +3)'},
    {countryCode: 'RE', zoneName: 'Indian/Reunion', suffix: '(UTC/GMT +4)'},
    {countryCode: 'RO', zoneName: 'Europe/Bucharest', suffix: '(UTC/GMT +3)'},
    {countryCode: 'RS', zoneName: 'Europe/Belgrade', suffix: '(UTC/GMT +2)'},
    {countryCode: 'RU', zoneName: 'Asia/Anadyr', suffix: '(UTC/GMT +12)'},
    {countryCode: 'RU', zoneName: 'Asia/Barnaul', suffix: '(UTC/GMT +7)'},
    {countryCode: 'RU', zoneName: 'Asia/Chita', suffix: '(UTC/GMT +9)'},
    {countryCode: 'RU', zoneName: 'Asia/Irkutsk', suffix: '(UTC/GMT +8)'},
    {countryCode: 'RU', zoneName: 'Asia/Kamchatka', suffix: '(UTC/GMT +12)'},
    {countryCode: 'RU', zoneName: 'Asia/Khandyga', suffix: '(UTC/GMT +9)'},
    {countryCode: 'RU', zoneName: 'Asia/Krasnoyarsk', suffix: '(UTC/GMT +7)'},
    {countryCode: 'RU', zoneName: 'Asia/Magadan', suffix: '(UTC/GMT +11)'},
    {countryCode: 'RU', zoneName: 'Asia/Novokuznetsk', suffix: '(UTC/GMT +7)'},
    {countryCode: 'RU', zoneName: 'Asia/Novosibirsk', suffix: '(UTC/GMT +7)'},
    {countryCode: 'RU', zoneName: 'Asia/Omsk', suffix: '(UTC/GMT +6)'},
    {countryCode: 'RU', zoneName: 'Asia/Sakhalin', suffix: '(UTC/GMT +11)'},
    {countryCode: 'RU', zoneName: 'Asia/Srednekolymsk', suffix: '(UTC/GMT +11)'},
    {countryCode: 'RU', zoneName: 'Asia/Tomsk', suffix: '(UTC/GMT +7)'},
    {countryCode: 'RU', zoneName: 'Asia/Ust-Nera', suffix: '(UTC/GMT +10)'},
    {countryCode: 'RU', zoneName: 'Asia/Vladivostok', suffix: '(UTC/GMT +10)'},
    {countryCode: 'RU', zoneName: 'Asia/Yakutsk', suffix: '(UTC/GMT +9)'},
    {countryCode: 'RU', zoneName: 'Asia/Yekaterinburg', suffix: '(UTC/GMT +5)'},
    {countryCode: 'RU', zoneName: 'Europe/Astrakhan', suffix: '(UTC/GMT +4)'},
    {countryCode: 'RU', zoneName: 'Europe/Kaliningrad', suffix: '(UTC/GMT +2)'},
    {countryCode: 'RU', zoneName: 'Europe/Kirov', suffix: '(UTC/GMT +3)'},
    {countryCode: 'RU', zoneName: 'Europe/Moscow', suffix: '(UTC/GMT +3)'},
    {countryCode: 'RU', zoneName: 'Europe/Samara', suffix: '(UTC/GMT +4)'},
    {countryCode: 'RU', zoneName: 'Europe/Simferopol', suffix: '(UTC/GMT +3)'},
    {countryCode: 'RU', zoneName: 'Europe/Ulyanovsk', suffix: '(UTC/GMT +4)'},
    {countryCode: 'RU', zoneName: 'Europe/Volgograd', suffix: '(UTC/GMT +3)'},
    {countryCode: 'RW', zoneName: 'Africa/Kigali', suffix: '(UTC/GMT +2)'},
    {countryCode: 'SA', zoneName: 'Asia/Riyadh', suffix: '(UTC/GMT +3)'},
    {countryCode: 'SB', zoneName: 'Pacific/Guadalcanal', suffix: '(UTC/GMT +11)'},
    {countryCode: 'SC', zoneName: 'Indian/Mahe', suffix: '(UTC/GMT +4)'},
    {countryCode: 'SD', zoneName: 'Africa/Khartoum', suffix: '(UTC/GMT +3)'},
    {countryCode: 'SE', zoneName: 'Europe/Stockholm', suffix: '(UTC/GMT +2)'},
    {countryCode: 'SG', zoneName: 'Asia/Singapore', suffix: '(UTC/GMT +8)'},
    {countryCode: 'SH', zoneName: 'Atlantic/St_Helena', suffix: '(UTC/GMT 0)'},
    {countryCode: 'SI', zoneName: 'Europe/Ljubljana', suffix: '(UTC/GMT +2)'},
    {countryCode: 'SJ', zoneName: 'Arctic/Longyearbyen', suffix: '(UTC/GMT +2)'},
    {countryCode: 'SK', zoneName: 'Europe/Bratislava', suffix: '(UTC/GMT +2)'},
    {countryCode: 'SL', zoneName: 'Africa/Freetown', suffix: '(UTC/GMT 0)'},
    {countryCode: 'SM', zoneName: 'Europe/San_Marino', suffix: '(UTC/GMT +2)'},
    {countryCode: 'SN', zoneName: 'Africa/Dakar', suffix: '(UTC/GMT 0)'},
    {countryCode: 'SO', zoneName: 'Africa/Mogadishu', suffix: '(UTC/GMT +3)'},
    {countryCode: 'SR', zoneName: 'America/Paramaribo', suffix: '(UTC/GMT -3)'},
    {countryCode: 'ST', zoneName: 'Africa/Sao_Tome', suffix: '(UTC/GMT 0)'},
    {countryCode: 'SV', zoneName: 'America/El_Salvador', suffix: '(UTC/GMT -6)'},
    {countryCode: 'SY', zoneName: 'Asia/Damascus', suffix: '(UTC/GMT +3)'},
    {countryCode: 'SZ', zoneName: 'Africa/Mbabane', suffix: '(UTC/GMT +2)'},
    {countryCode: 'TC', zoneName: 'America/Grand_Turk', suffix: '(UTC/GMT -4)'},
    {countryCode: 'TD', zoneName: 'Africa/Ndjamena', suffix: '(UTC/GMT +1)'},
    {countryCode: 'TF', zoneName: 'Indian/Kerguelen', suffix: '(UTC/GMT +5)'},
    {countryCode: 'TG', zoneName: 'Africa/Lome', suffix: '(UTC/GMT 0)'},
    {countryCode: 'TH', zoneName: 'Asia/Bangkok', suffix: '(UTC/GMT +7)'},
    {countryCode: 'TJ', zoneName: 'Asia/Dushanbe', suffix: '(UTC/GMT +5)'},
    {countryCode: 'TK', zoneName: 'Pacific/Fakaofo', suffix: '(UTC/GMT +13)'},
    {countryCode: 'TM', zoneName: 'Asia/Ashgabat', suffix: '(UTC/GMT +5)'},
    {countryCode: 'TN', zoneName: 'Africa/Tunis', suffix: '(UTC/GMT +1)'},
    {countryCode: 'TO', zoneName: 'Pacific/Tongatapu', suffix: '(UTC/GMT +13)'},
    {countryCode: 'TR', zoneName: 'Europe/Istanbul', suffix: '(UTC/GMT +3)'},
    {countryCode: 'TT', zoneName: 'America/Port_of_Spain', suffix: '(UTC/GMT -4)'},
    {countryCode: 'TV', zoneName: 'Pacific/Funafuti', suffix: '(UTC/GMT +12)'},
    {countryCode: 'TW', zoneName: 'Asia/Taipei', suffix: '(UTC/GMT +8)'},
    {countryCode: 'TZ', zoneName: 'Africa/Dar_es_Salaam', suffix: '(UTC/GMT +3)'},
    {countryCode: 'UA', zoneName: 'Europe/Kiev', suffix: '(UTC/GMT +3)'},
    {countryCode: 'UA', zoneName: 'Europe/Uzhgorod', suffix: '(UTC/GMT +3)'},
    {countryCode: 'UA', zoneName: 'Europe/Zaporozhye', suffix: '(UTC/GMT +3)'},
    {countryCode: 'UG', zoneName: 'Africa/Kampala', suffix: '(UTC/GMT +3)'},
    {countryCode: 'UM', zoneName: 'Pacific/Johnston', suffix: '(UTC/GMT -10)'},
    {countryCode: 'UM', zoneName: 'Pacific/Midway', suffix: '(UTC/GMT -11)'},
    {countryCode: 'UM', zoneName: 'Pacific/Wake', suffix: '(UTC/GMT +12)'},
    {countryCode: 'US', zoneName: 'America/Adak', suffix: '(UTC/GMT -9)'},
    {countryCode: 'US', zoneName: 'America/Anchorage', suffix: '(UTC/GMT -8)'},
    {countryCode: 'US', zoneName: 'America/Boise', suffix: '(UTC/GMT -6)'},
    {countryCode: 'US', zoneName: 'America/Chicago', suffix: '(UTC/GMT -5)'},
    {countryCode: 'US', zoneName: 'America/Denver', suffix: '(UTC/GMT -6)'},
    {countryCode: 'US', zoneName: 'America/Detroit', suffix: '(UTC/GMT -4)'},
    {countryCode: 'US', zoneName: 'America/Indiana/Indianapolis', suffix: '(UTC/GMT -4)'},
    {countryCode: 'US', zoneName: 'America/Indiana/Knox', suffix: '(UTC/GMT -5)'},
    {countryCode: 'US', zoneName: 'America/Indiana/Marengo', suffix: '(UTC/GMT -4)'},
    {countryCode: 'US', zoneName: 'America/Indiana/Petersburg', suffix: '(UTC/GMT -4)'},
    {countryCode: 'US', zoneName: 'America/Indiana/Tell_City', suffix: '(UTC/GMT -5)'},
    {countryCode: 'US', zoneName: 'America/Indiana/Vevay', suffix: '(UTC/GMT -4)'},
    {countryCode: 'US', zoneName: 'America/Indiana/Vincennes', suffix: '(UTC/GMT -4)'},
    {countryCode: 'US', zoneName: 'America/Indiana/Winamac', suffix: '(UTC/GMT -4)'},
    {countryCode: 'US', zoneName: 'America/Juneau', suffix: '(UTC/GMT -8)'},
    {countryCode: 'US', zoneName: 'America/Kentucky/Louisville', suffix: '(UTC/GMT -4)'},
    {countryCode: 'US', zoneName: 'America/Kentucky/Monticello', suffix: '(UTC/GMT -4)'},
    {countryCode: 'US', zoneName: 'America/Los_Angeles', suffix: '(UTC/GMT -7)'},
    {countryCode: 'US', zoneName: 'America/Menominee', suffix: '(UTC/GMT -5)'},
    {countryCode: 'US', zoneName: 'America/Metlakatla', suffix: '(UTC/GMT -8)'},
    {countryCode: 'US', zoneName: 'America/New_York', suffix: '(UTC/GMT -4)'},
    {countryCode: 'US', zoneName: 'America/Nome', suffix: '(UTC/GMT -8)'},
    {countryCode: 'US', zoneName: 'America/North_Dakota/Beulah', suffix: '(UTC/GMT -5)'},
    {countryCode: 'US', zoneName: 'America/North_Dakota/Center', suffix: '(UTC/GMT -5)'},
    {countryCode: 'US', zoneName: 'America/North_Dakota/New_Salem', suffix: '(UTC/GMT -5)'},
    {countryCode: 'US', zoneName: 'America/Phoenix', suffix: '(UTC/GMT -7)'},
    {countryCode: 'US', zoneName: 'America/Sitka', suffix: '(UTC/GMT -8)'},
    {countryCode: 'US', zoneName: 'America/Yakutat', suffix: '(UTC/GMT -8)'},
    {countryCode: 'US', zoneName: 'Pacific/Honolulu', suffix: '(UTC/GMT -10)'},
    {countryCode: 'UY', zoneName: 'America/Montevideo', suffix: '(UTC/GMT -3)'},
    {countryCode: 'UZ', zoneName: 'Asia/Samarkand', suffix: '(UTC/GMT +5)'},
    {countryCode: 'UZ', zoneName: 'Asia/Tashkent', suffix: '(UTC/GMT +5)'},
    {countryCode: 'VA', zoneName: 'Europe/Vatican', suffix: '(UTC/GMT +2)'},
    {countryCode: 'VC', zoneName: 'America/St_Vincent', suffix: '(UTC/GMT -4)'},
    {countryCode: 'VE', zoneName: 'America/Caracas', suffix: '(UTC/GMT -4)'},
    {countryCode: 'VG', zoneName: 'America/Tortola', suffix: '(UTC/GMT -4)'},
    {countryCode: 'VI', zoneName: 'America/St_Thomas', suffix: '(UTC/GMT -4)'},
    {countryCode: 'VN', zoneName: 'Asia/Ho_Chi_Minh', suffix: '(UTC/GMT +7)'},
    {countryCode: 'VU', zoneName: 'Pacific/Efate', suffix: '(UTC/GMT +11)'},
    {countryCode: 'WF', zoneName: 'Pacific/Wallis', suffix: '(UTC/GMT +12)'},
    {countryCode: 'WS', zoneName: 'Pacific/Apia', suffix: '(UTC/GMT +13)'},
    {countryCode: 'YE', zoneName: 'Asia/Aden', suffix: '(UTC/GMT +3)'},
    {countryCode: 'YT', zoneName: 'Indian/Mayotte', suffix: '(UTC/GMT +3)'},
    {countryCode: 'ZA', zoneName: 'Africa/Johannesburg', suffix: '(UTC/GMT +2)'},
    {countryCode: 'ZM', zoneName: 'Africa/Lusaka', suffix: '(UTC/GMT +2)'},
    {countryCode: 'ZW', zoneName: 'Africa/Harare', suffix: '(UTC/GMT +2)'}
  ]
};
