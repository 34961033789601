import angular from 'angular';
import _ from 'underscore';
import { AddEditTeamController } from './add-edit-team-ctrl';
import { HireGunController } from './hire-gun-ctrl';
import { HireTeamModalComponent } from './hire-team.component';
import TeamAddEditModalTemplate from './add-edit-team.html';
import HireGunModalTemplate from './hire-gun.html';

export default angular.module('app.teams.modals', [
  require('modules/common/segment.service').default,
  require('modules/resources/account').default,
  require('modules/resources/project').default,
  require('modules/resources/team').default,
  require('modules/common/ui-bootstrap-modal-2.5.6').default,
  require('modules/user-manager').default,
])
  .service('TeamsModalsService', function ($uibModal) {
    'ngInject';

    var service = {

      openAddEditModal: function (teamId, addMeAutomatically, forceRedirectToCreatedTeam, tribe, event) {
        return $uibModal.open({
          event: event,
          template: TeamAddEditModalTemplate,
          controller: 'AddEditTeamCtrl',
          resolve: {
            team: function (UserManager, Team) {
              return (teamId && Team.get({ id: teamId }).$promise) || new Team({
                members: [],
                tribe: tribe
              });
            },
            tribes: function (Tribe) {
              return Tribe.query().$promise;
            },
            addMeAutomatically: _(addMeAutomatically).constant(),
            forceRedirectToCreatedTeam: _(forceRedirectToCreatedTeam).constant()
          },
          size: 'small'
        });
      },

      openHireGunModal: function (team, event) {
        return $uibModal.open({
          event,
          template: HireGunModalTemplate,
          controller: 'HireGunCtrl',
          resolve: {
            team: _(team).constant(),
            myTeamsProjects: (Project) => {
              return Project.queryMy({}).$promise;
            }
          },
          size: 'small'
        });
      },

      openHireModal: function (team, project, autoInviteTeam) {
        return $uibModal.open({
          component: 'hireTeamModal',
          resolve: {
            autoInviteTeam: _(autoInviteTeam).constant(),
            team: _(team).constant(),
            project: _(project).constant()
          },
          size: 'small'
        });
      }
    };

    return service;
  })
  .controller('AddEditTeamCtrl', AddEditTeamController)
  .controller('HireGunCtrl', HireGunController)
  .component('hireTeamModal', HireTeamModalComponent)
  .name;
