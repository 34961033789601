import * as angular from 'angular';

import AccountResourceModule from 'modules/resources/account';
import ModalModule from 'modules/common/ui-bootstrap-modal-2.5.6';
import MoneyModule from 'modules/money/index';
import { SendSalesInvoiceModalComponent } from './send-sales-invoice-modal.component';
import { SendSalesInvoiceModalService } from './send-sales-invoice-modal.service';

export const SendSalesInvoiceModalModule = angular.module('app.send-sales-invoice-modal', [
  AccountResourceModule,
  ModalModule,
  MoneyModule
])
  .component('sendSalesInvoiceModal', SendSalesInvoiceModalComponent)
  .service('SendSalesInvoiceModalService', SendSalesInvoiceModalService)
  .name;
