import _ from 'underscore';
import { Money } from 'modules/money/money';

export default class MyDeliveryWeek {
  constructor(id, booking, myDeliveries, otherDeliveries) {
    this.id = id;
    this.booking = booking;
    this.myDeliveries = myDeliveries;
    this.otherDeliveries = otherDeliveries;

  }

  // Money available to be booked in a week
  get availableMoney() {
    return this.booking.value.subtract(this.deliveriesSum());
  }

  get hasBooking() {
    return this.booking && Money.isGreaterThanZero(this.booking.value);
  }

  get isAccepted() {
    return this.booking && this.booking.isAccepted;
  }

  get isAvailableToBook() {
    return !this.isFullyDelivered && this.hasBooking && !this.isAccepted;
  }

  get isFullyDelivered() {
    return this.booking && Money.isGreaterThanZero(this.booking.value) && this.deliveriesSum().equalsTo(this.booking.value);
  }

  deliveriesSum(type = 'all') {
    return _(this._deliveriesByType(type)).reduce((memo, operation) => memo.add(operation.value), Money.zero());
  }

  hasDeliveries(type = 'all') {
    const notEmpty = _(this._deliveriesByType(type)).find(delivery => Money.isGreaterThanZero(delivery.value));
    return Boolean(notEmpty);
  }

  addDelivery(delivery) {
    this.myDeliveries.push(delivery);
  }

  removeDelivery(delivery) {
    this.myDeliveries = _(this.myDeliveries).without(delivery);
  }

  setBooking(booking) {
    this.booking = booking;
  }

  _deliveriesByType(type) {
    let deliveries;
    if (type === 'all') {
      deliveries = this.myDeliveries.concat(this.otherDeliveries);
    } else if (type === 'my') {
      deliveries = this.myDeliveries;
    } else if (type === 'other') {
      deliveries = this.otherDeliveries;
    }

    return deliveries;
  }
}
