// @ts-ignore
import template from './year-picker.component.html';
import moment, { Moment } from 'moment';

const CURRENT_YEAR = moment();

export const YearPicker: ng.IComponentOptions = {
  template,
  bindings: {
    min: '<',
    max: '<',
    onChange: '&'
  },
  controller: class BalanceWidgetController {
    // Bindings
    // @ts-ignore
    min: Moment | undefined = this.min;
    // @ts-ignore
    max: Moment | undefined = this.max;
    // @ts-ignore
    onChange: ({ $event: string }) => void = this.onChange;

    private _selectedYear = CURRENT_YEAR.clone();

    constructor() {
      'ngInject';
    }

    $onInit() {
      this.min = this.min && moment(this.min, 'YYYY');
      this.max = this.max ? moment(this.max, 'YYYY') : CURRENT_YEAR.clone();
    }

    get selectedYear() {
      return this._selectedYear.format('YYYY');
    }

    get isPrevDisabled() {
      return this._selectedYear.isSame(this.min, 'year');
    }

    get isNextDisabled() {
      return this._selectedYear.isSame(this.max, 'year');
    }

    handleYearClick() {
      this._selectedYear = CURRENT_YEAR.clone();
      this.onChange({ $event: this.selectedYear });
    }

    handlePrevClick() {
      this._selectedYear.subtract(1, 'year');
      this.onChange({ $event: this.selectedYear });
    }

    handleNextClick() {
      this._selectedYear.add(1, 'year');
      this.onChange({ $event: this.selectedYear });
    }
  }
};
