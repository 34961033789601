import angular from 'angular';
import Dinero from 'dinero.js';
import ConstantsModule from 'app-constants';
import { InvoiceableMoneyFilter } from './invoiceable-money.filter';
import { MoneyFilter } from './money.filter';
import { MonthFilter } from './month.filter';

Dinero.defaultCurrency = 'EUR';
Dinero.defaultPrecision = 2;
Dinero.globalFormat = '$0';

export default angular.module('app.money', [ConstantsModule])
  .filter('money', MoneyFilter)
  .filter('invoiceableMoney', InvoiceableMoneyFilter)
  .filter('month', MonthFilter)
  .name;
