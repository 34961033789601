// @ts-ignore
import template from './send-or-configure-invoices.component.html';
import moment from 'moment';
import { IProject } from '../../../models/project.model';
import { IMoney } from '../../../models/money.model';
import { SendSalesInvoiceModalService } from '../../send-sales-invoice-modal/send-sales-invoice-modal.service';

export const SendOrConfigureInvoicesComponent: ng.IComponentOptions = {
  bindings: {
    project: '<',
    disabled: '<'
  },
  template,
  controller: class SendOrConfigureInvoicesController {
    // Bindings
    // @ts-ignore
    project: IProject = this.project;
    // @ts-ignore
    disabled: boolean = this.disabled;

    isSent = false;
    amount: IMoney;
    daysRemaining: number;

    constructor(
      private SendSalesInvoiceModalService: SendSalesInvoiceModalService
    ) {
      'ngInject';
    }

    $onInit() {
      this.daysRemaining = moment().endOf('month').diff(moment(), 'days') + 1;
    }

    showSendSalesInvoiceModal() {
      this.SendSalesInvoiceModalService
        .open(this.project)
        .then((isSent) => this.isSent = isSent);
    }
  }
};
